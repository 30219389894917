import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
  floatingButton: {
    transition: 'transform 0.3s',
  
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  }
}));

const CategorySchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required")
});

const CategoryModal = ({ open, onClose, categoryId }) => {
  const classes = useStyles();

	const initialState = {
		name: "",
	};

  const [category, setCategory] = useState(initialState);

  useEffect(() => {
		(async () => {
			if (!categoryId) return;
			try {
				const { data } = await api.get(`/category/${categoryId}`);
				setCategory(prevState => {
					return { ...prevState, ...data };
				});
			} catch (error) {
        console.log("Category Modal Use Effect Error:", error);
				toastError(error);
			}
		})();

		return () => {
			setCategory({
				name: "",
			});
		};
	}, [categoryId, open]);

  const handleClose = () => {
		onClose();
		setCategory(initialState);
	};

  const handleSaveCategory = async values => {
		try {
			if (categoryId) {
				await api.put(`/category/${categoryId}`, values);
			} else {
				await api.post("/category", values);
			}
			toast.success(`${i18n.t("categoryModal.toast.add")}`);
			handleClose();
		} catch (error) {
      console.log("Handle Save Category Error:", error);
			toastError(error);
		}
	};

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle>
          {categoryId
            ? `${i18n.t("categoryModal.title.edit")}`
            : `${i18n.t("categoryModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={category}
          enableReinitialize={true}
          validationSchema={CategorySchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveCategory(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label={i18n.t("categoryModal.form.name")}
                  autoFocus
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="inherit"
                  disabled={isSubmitting}
                  variant="outlined"
                  className={classes.floatingButton}
                >
                  {i18n.t("categoryModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={`${classes.btnWrapper} ${classes.floatingButton}`}
                >
                  {categoryId
                    ? `${i18n.t("categoryModal.buttons.okEdit")}`
                    : `${i18n.t("categoryModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default CategoryModal;