import { cnpj, cpf } from "cpf-cnpj-validator";
import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";

const validateCnpj = (cnpjValue) => {
  if (cnpjValue.lengh <= 0) return false;

  if (cnpjValue.length < 18 || cnpjValue.length > 18) {
    toast.info(i18n.t("contactModal.toasts.invalidCNPJ"));
    return false;
  }

  if (!cnpj.isValid(cnpjValue)) {
    toast.info(i18n.t("contactModal.toasts.nonexistentCNPJ"));
    return false;
  }

  return true;
};

const validateCpf = (cpfValue) => {
  if (cpfValue.length <= 0) return false;

  if (cpfValue.length < 14 || cpfValue.length > 14) {
    toast.info(i18n.t("contactModal.toasts.invalidCPF"));
    return false;
  }

  if (!cpf.isValid(cpfValue)) {
    toast.info(i18n.t("contactModal.toasts.nonexistentCPF"));
    return false;
  }

  return true;
};

const validateCpfCnpj = (cpfCnpj, isCpf) => {
  if (isCpf) return validateCpf(cpfCnpj);
  return validateCnpj(cpfCnpj);
};

export default validateCpfCnpj;