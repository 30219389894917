import React, { useEffect, useRef, useState } from "react";
import { Bar } from "@nivo/bar";
import { makeStyles } from "@material-ui/core/styles";

import ErrorBoundary from "../../../../errors/Boundary";

const useStyles = makeStyles(theme => ({
  dashboardContainer: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    padding: "20px 0px 20px 0px",
    textIndent: "15px",
  },

  emptyBoxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "50px",
  },

  emptyBoxImage: { width: "250px", height: "250px" },
  emptyBoxSpan: { fontSize: "20px" },
}));

const ChartAllUsersRatings = ({ data }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  
  const containerRef= useRef(null);
  const [width, setWidth] = useState(0);
  const height = 100 + 20 * data.length;



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    setWidth(containerRef.current.offsetWidth);
  }, []);



  //  **********************
  //  ** Resize Observers **
  //  **********************
  function outputsize() {
    const mainContainer = document.getElementById("mainContainer");
    if (mainContainer) {
      setWidth(mainContainer.offsetWidth);
    }
  }

  useEffect(() => {
    const mainContainer = document.getElementById("mainContainer");
    if (mainContainer) {
      outputsize();
      new ResizeObserver(outputsize).observe(mainContainer);
    }
  }, []);



  //  ************
  //  ** Return **
  //  ************
  return (
    <div ref={containerRef} className={classes.dashboardContainer} id="mainContainer">
      <ErrorBoundary>
        {data.length > 0 && (
          <Bar
            data={data}
            keys={["rate"]}
            indexBy="name"
            width={width}
            height={height}

            theme={{
              textColor: "#999",
              tooltip: { container: { background: "#999", color: "#fff" } },
            }}
            
            margin={{ top: 25, right: 75, bottom: 25, left: 50 }}
            padding={0.3}
            layout="horizontal"
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={(data) => data.data.rate > 8 ? "#BCE29E" : data.data.rate > 6 ? "#EBEF95" : "#E97777"}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -40,
            }}

            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={"#000"}
            tooltipLabel={(data) => data.data.name}
            motionConfig={{
              mass: 1,
              tension: 170,
              friction: 71,
              clamp: false,
              precision: 0.01,
              velocity: 0,
            }}
            
            role="application"
          />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default ChartAllUsersRatings;