import React from "react";
import { makeStyles, Card, CardHeader, Tooltip, Badge, Avatar } from "@material-ui/core";

import BusinessIcon from "@material-ui/icons/Business";
import SmartphoneOutlinedIcon from '@material-ui/icons/SmartphoneOutlined';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f6f6ff",
    color: "#363636",
    boxShadow: "0 1px 1px #b3b3b3",
    display: "inline-block",
    borderRadius: 16,
    transform: "filter 0.3s",
    cursor: "pointer",
    transition: "all 0.3s ease",

    "&:hover": {
      filter: "brightness(0.92)",
      transform: "translateY(-5px)",
    },
  },
  avatar: {
    backgroundColor: "#ECECF4",
  },
}));

const TreeCard = (props) => {
  const classes = useStyles();
  let icon;

  if (props.type === "0") { icon = <BusinessIcon color="primary" /> }
  else if (props.type === "1") { icon = <SmartphoneOutlinedIcon color="primary" /> }
  else if (props.type === "2") { icon = <BookmarksOutlinedIcon color="primary" /> }
  else if (props.type === "3") { icon = <LabelOutlinedIcon color="primary" /> }
  else { icon = <BookOutlinedIcon color="primary" /> }

  return (
    <Card variant="outlined" className={classes.root} onClick={props.onClick}>
      <CardHeader
        title={props.title}
        avatar={
          <Tooltip title={props.subject} arrow >
            <Badge
              color="secondary"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              showZero
              overlap="circle"
            >
              <Avatar className={classes.avatar}>{icon}</Avatar>
            </Badge>
          </Tooltip>
        }
      />
    </Card>
  );
};

export default TreeCard;
