import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Radio,
  RadioGroup
} from "@material-ui/core";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';

import { i18n } from "../../translate/i18n";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
  dialogContent: { display: "flex", flexDirection: "column", padding: "1em", },

  optionContainer: {
    borderRadius: "10px",
    marginLeft: "5px",
    "&:hover": { backgroundColor: theme.palette.text.reaction, },
  },

  optionTitle: { fontWeight: "bold", fontSize: "1.25em", },
  optionDescription: { fontSize: "0.95em", },
  floatingButton: { transition: 'transform 0.3s', '&:hover': { transform: 'translateY(-5px)' }, },
}));

const ListPreviewModal = ({ message, ticketId, open, onClose }) => {
  //  *************
  //  ** Classes **
  //  *************
  const classes = useStyles();
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    setIsSendButtonDisabled(true);
    setSelectedOption("");
    onClose();
  };

  const handleSelectOption = (event) => {
    if (event.target.value === null || event.target.value === undefined || event.target.value === 0) return;
    setSelectedOption(parseInt(event.target.value, 10));
    setIsSendButtonDisabled(false);
  };

  const handleSendListResponse = async () => {
    if (selectedOption === null || selectedOption === undefined || selectedOption === 0 ||selectedOption === "") {
      toast.info(i18n.t("listPreviewModal.toasts.notSelectedOption"));
    } else {
      try {
        const selectedListOption = message.listOptions.filter(option => option.id === selectedOption)[0];
        await api.post(`/messagesListResponse/${ticketId}`, {
          body: selectedListOption.title,
          quotedMsgId: message.id
        });
        handleClose();
      } catch (err) { toast.error(err); }
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" scroll="paper" fullWidth>
        <DialogTitle>{i18n.t("listPreviewModal.title")}</DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <FormControl>
            <List>
              <RadioGroup>
                {message.listOptions.map(option => (
                  <>
                  <FormControlLabel className={classes.optionContainer} onClick={handleSelectOption} value={`${option.id}`} 
                    label={
                      <ListItem dense>
                        <div>
                          <ListItemText primary={<span className={classes.optionTitle}>{option.title}</span>} />
                          <ListItemText primary={<span className={classes.optionDescription}>{option.description}</span>} />
                        </div>
                      </ListItem>
                    }
                    control={<Radio />}
                  />

                  <Divider /><Divider /><Divider /><Divider /><Divider />
                  </>
                ))}
              </RadioGroup>
            </List>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" className={classes.floatingButton} onClick={handleClose}>
            <CloseOutlinedIcon />
          </Button>

          <Button color="inherit" className={classes.floatingButton} onClick={handleSendListResponse} disabled={isSendButtonDisabled}>
            <SendOutlinedIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ListPreviewModal;