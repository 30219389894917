import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
  root: { display: "flex", flexWrap: "wrap", },
  
  displayContent: { padding: "25px", ...theme.scrollbarStyles, },
  
  selectInputField: { "& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary, }, },
  
  btnWrapper: { position: "relative", },

  floatingButton: { transition: "transform 0.3s", "&:hover": { transform: "translateY(-5px)", }, },
}));

const ExportCSVModal = ({ open, onClose }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const [contactTypeToExport, setContactTypeToExport] = useState(1);
  const [separateContacts, setSeparateContacts] = useState(false);
  const [loading, setLoading] = useState(false);




  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    if (!loading) {
      setContactTypeToExport(1);
      setSeparateContacts(false);
      onClose();
    }
  };

  const handleCSVFile = (contacts, fileName="contatos.csv") => {
    const headers = ["ContactName", "ContactNumber", "ContactEmail", "ContactConnectionName"];
    const csvRows = [];
    csvRows.push(headers.join(","));

    contacts.forEach(contact => {
      csvRows.push([
        contact.name,
        contact.number,
        contact.email,
        contact.whatsapp?.name || ""
      ].join(","));
    });

    const csvString = csvRows.join("\n");
    const csvBlob = new Blob([csvString], { type: "text/csv" });
    const FileSaver = require("file-saver");
    FileSaver.saveAs(csvBlob, fileName);
  };

  const handleExportCSV = async () => {
    try {
      if (!loading) {
        setLoading(true);
        const { data: { contacts } } = await api.get(`/contact/CSVExport/${contactTypeToExport}`);
        
        //             there are no contacts to export?
        //                   /                    \
        //                 yes                    no
        //                 /                        \
        //  throws a toast message to the user     separate contacts?
        //                                         /                \
        //                                       yes                 no
        //                                       /                    \
        //                        export in multiple files         export in a single file
        //
        if (contacts.length <= 0) { toast.info(i18n.t("exportCSVModal.toasts.noContactsToExport")); }
        else if (separateContacts) {
          handleCSVFile(contacts.filter(contact => !contact.isBlocked && !contact.isInactive), "contatos-desbloqueados.csv");
          handleCSVFile(contacts.filter(contact => contact.isBlocked), "contatos-bloqueados.csv");
          handleCSVFile(contacts.filter(contact => contact.isInactive), "contatos-inativados.csv");
        }
        else { handleCSVFile(contacts); }
      }
    }
    catch (err) { console.log(err); toast.error(i18n.t("exportCSVModal.toasts.error")); }
    finally { setLoading(false); }
  };




  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} scroll="paper">
        {/*
          ***---- Title ----***
        */}
        <DialogTitle>{i18n.t("exportCSVModal.title")}</DialogTitle>

        {/*
          ***---- Content ----***
        */}
        <DialogContent dividers className={classes.dialogContent}>

          {/* Contact Type to Export */}
          <FormControl
            fullWidth
            margin="dense"
            variant="outlined"
            classes={{ root: classes.selectInputField }}
          >
            <InputLabel>{i18n.t("exportCSVModal.form.contactType.label")}</InputLabel>
            <Select
              value={contactTypeToExport}
              onChange={(event) => {
                setContactTypeToExport(event.target.value);
                if (event.target.value !== 0 && separateContacts) setSeparateContacts(false);
              }}
              label={i18n.t("exportCSVModal.form.contactType.label")}
              fullWidth
            >
              <MenuItem value={0}>{i18n.t("exportCSVModal.form.contactType.bothOption")}</MenuItem>
              <MenuItem value={1}>{i18n.t("exportCSVModal.form.contactType.nonBlockedOption")}</MenuItem>
              <MenuItem value={2}>{i18n.t("exportCSVModal.form.contactType.blockedOption")}</MenuItem>
              <MenuItem value={3}>{i18n.t("exportCSVModal.form.contactType.inactivatedOption")}</MenuItem>
            </Select>
          </FormControl>

          <br /><br />

          {/* Separate Contacts Type */}
          <FormControlLabel 
            label={i18n.t("exportCSVModal.form.separateContacts.label")}
            labelPlacement={"end"}
            disabled={contactTypeToExport !== 0}
            control={<Switch checked={separateContacts} onChange={() => setSeparateContacts(value => !value)} />}
          />
        </DialogContent>

        {/* 
          ***---- Actions ----***
        */}
        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            variant="outlined"
            className={classes.floatingButton}
            disabled={loading}
          >{i18n.t("exportCSVModal.buttons.cancel")}</Button>

          <Button
            onClick={async () => await handleExportCSV()}
            type="submit"
            color="primary"
            variant="contained"
            className={`${classes.btnWrapper} ${classes.floatingButton}`}
            disabled={loading}
          >{i18n.t("exportCSVModal.buttons.export")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExportCSVModal;