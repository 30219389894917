import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";

import Alert from '@material-ui/lab/Alert';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";

import useSubqueues from "../../hooks/useSubqueues";
import useLabels from "../../hooks/useLabels";
import useWhatsappQueues from "../../hooks/useWhatsappQueues";


const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
	floatingButton: {
    transition: 'transform 0.3s',
  
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  }
}));

const EditClosingTicketModal = ({
	modalOpen, onClose, ticket, selectedIndex, requireTagOnContactWhenClosingTicket
}) => {
	//	***************
	//	** Variables **
	//	***************
	const classes = useStyles();
	const history = useHistory();
  const { user } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	
	const [selectedQueue, setSelectedQueue] = useState(
		ticket.queueId && ticket.queueId !== undefined
			? ticket.queueId
			: ''
	);

	const [subqueues, setSubqueues] = useState(0);
	const [selectedSubqueue, setSelectedSubqueue] = useState(
		ticket.subqueueId && ticket.subqueueId !== undefined
			? ticket.subqueueId
			: ''
	);
	const { findAll: findAllSubqueues } = useSubqueues();

	const [labels, setLabels] = useState(0);
	const [selectedLabel, setSelectedLabel] = useState('');
	const { findAll: findAllLabels } = useLabels();

	const [whatsappQueues, setWhatsappQueues] = useState([]);
	const { findAll: findAllWhatsappQueues } = useWhatsappQueues(ticket.whatsappId);

	const [contactSerialNumber, setContactSerialNumber] = useState("");
	const [contactResaleSerialNumber, setContactResaleSerialNumber] = useState("");



	//	*****************
	//	** Use Effects **
	//	*****************
	useEffect(() => {
		setContactSerialNumber(ticket.contact?.serialNumber);
		setContactResaleSerialNumber(ticket.contact?.resaleSerialNumber);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const loadWhatsappQueues = async() => {
			const list = await findAllWhatsappQueues();
			setWhatsappQueues(list);

			if (selectedQueue && selectedQueue !== undefined && selectedQueue !== "") {
				const queuesList = await findAllSubqueues();
				setSubqueues(queuesList);
			}
		}

		loadWhatsappQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const loadLabels = async() => {
			const list = await findAllLabels();
			setLabels(list);
		}

		loadLabels();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	//	***************
	//	** Functions **
	//	***************
	const handleQueueChange = (e) => {
		setSelectedQueue(e.target.value);
		setSelectedSubqueue("");

		const loadSubqueues = async () => {
			const list = await findAllSubqueues();
			setSubqueues(list);
		}

		loadSubqueues();
	};

	const handleLabelChange = (e) => {
		setSelectedLabel(e.target.value);
	}

	const handleClose = () => { 
		onClose(); 
		setSelectedQueue(
			ticket.queueId && ticket.queueId !== undefined
				? ticket.queueId
				: ""
		);
		setSelectedSubqueue(
			ticket.subqueueId && ticket.subqueueId !== undefined
				? ticket.subqueueId
				: ""
		);
		setSelectedLabel("");
		setContactSerialNumber(ticket.contact?.serialNumber);
		setContactResaleSerialNumber(ticket.contact?.resaleSerialNumber);
	};

	const handleEditTicket = async e => {
		e.preventDefault();
		const fxSistemasTenantId = (user.tenantId.toString() === "1");
		const ticketType = (ticket.type.toString() === "0");

		// ***---- Serial Number not Informed ----***
		if (
			(fxSistemasTenantId) &&
			((ticketType) && 
			(contactSerialNumber === "" || !contactSerialNumber)))
		{ 
			toast.error(i18n.t("backendErrors.SERIAL_NUMBER_NOT_INFORMED")); 
		}

		// ***---- Resale Serial Number not Informed ----***
		else if (
			(fxSistemasTenantId) &&
			((ticketType) &&
			(contactResaleSerialNumber === "" || !contactResaleSerialNumber)))
		{ 
			toast.error(i18n.t("backendErrors.RESALE_SERIAL_NUMBER_NOT_INFORMED"));
		}

		// ***---- Label not Selected ----***
		else if (requireTagOnContactWhenClosingTicket === "enabled" && ticket.contact?.labels.length <= 0 && selectedLabel === "") { toast.error(i18n.t("backendErrors.LABEL_NOT_SELECTED")); }

		// ***---- Queue not Selected ----***
		else if (whatsappQueues.length > 0 && selectedQueue === "" && whatsappQueues.length > 0) { toast.error(i18n.t("backendErrors.QUEUE_NOT_SELECTED")); }

		// ***---- Queue's Related Subqueues Not Loaded ----***
		else if (whatsappQueues.length > 0 && subqueues === 0) { toast.info(i18n.t("editTicketModal.toast.subqueuesNotLoaded")); }

		// ***---- Subqueue not Selected ----***
		else if (whatsappQueues.length > 0 && subqueues.some(subqueue => subqueue.queueId === selectedQueue) && selectedSubqueue === "") { toast.error(i18n.t("backendErrors.SUBQUEUE_NOT_SELECTED")); }

		// ***---- Update Contact and Ticket ----***
		else {
			setLoading(true);

			try {
				let contactData;

				if (requireTagOnContactWhenClosingTicket === "enabled" && ticket.contact?.labels.length <= 0) {
					contactData = {
						name: ticket.contact.name,
						number: ticket.contact.number,
						serialNumber: fxSistemasTenantId ? contactSerialNumber : null,
						resaleSerialNumber: fxSistemasTenantId ? contactResaleSerialNumber : null,
						labelIds: [selectedLabel]
					};
				} else {
					contactData = {
						name: ticket.contact.name,
						number: ticket.contact.number,
						serialNumber: fxSistemasTenantId ? contactSerialNumber : null,
						resaleSerialNumber: fxSistemasTenantId ? contactResaleSerialNumber : null,
					};
				}

				await api.put(`/contacts/${ticket.contactId}`, contactData);

				// Updating Ticket
				let ticketData = {
					queueId: selectedQueue !== "" ? selectedQueue : null,
					subqueueId: selectedSubqueue !== "" ? selectedSubqueue : null
				};
				const editingTicket = true;
				await api.put(`/tickets/${ticket.id}/${editingTicket}`, ticketData);
	
				setLoading(false);
				history.push(`/tickets`);
				toast.success(i18n.t("editTicketModal.successToast"));
				selectedQueue("");
				selectedSubqueue("");
			}
			catch (err) {
				setLoading(false);
				toast.error(err);
			}

      try {
        if (selectedIndex === 0) {
          await api.put(`/tickets/${ticket.id}`, {
            status: "closed",
            userId: user.id || null,
						queueId: selectedQueue !== "" ? selectedQueue : null,
						subqueueId: selectedSubqueue !== "" ? selectedSubqueue : null
          });
        }
        else {
					if (ticket.type === 0) {
						try {
							await api.put(`/contacts/${ticket.contactId}`, { 
								name: ticket.contact.name,
								number: ticket.contact.number,
								isBot: true
							});
						} catch (error) {
							setLoading(false);
							console.log("Handle Edit Ticket 1 Error:", error);
							toastError(error);
						}
					}

          await api.put(`/tickets/${ticket.id}`, {
            status: "closed",
            userId: user.id || null,
						queueId: selectedQueue !== "" ? selectedQueue : null,
						subqueueId: selectedSubqueue !== "" ? selectedSubqueue : null
          });

					if (ticket.type === 0) {
						try {
							await api.put(`/contacts/${ticket.contactId}`, { 
								name: ticket.contact.name,
								number: ticket.contact.number,
								isBot: false
							});
						} catch (error) {
							setLoading(false);
							console.log("Handle Edit Ticket 2 Error:", error);
							toastError(error);
						}
					}
        }

        setLoading(false);
        history.push("/tickets");
      } catch (error) {
        setLoading(false);
				console.log("Handle Edit Ticket 3 Error:", error);
        toastError(error);
      }
		}
	};

	const handleContactSerialNumber = (event) => {
		setContactSerialNumber(event.target.value);
	}

	const handleContactResaleSerialNumber = (event) => {
		setContactResaleSerialNumber(event.target.value);
	}



	//	************
	//	** Return **
	//	************
	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form style={{padding: "0px 15px 15px 15px"}} onSubmit={handleEditTicket}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("editTicketModal.title")}
				</DialogTitle>

				<DialogContent dividers>
					{((user.tenantId.toString() === "1") && (ticket.type.toString() === "0")) && (
						<>
						<FormControl variant="outlined" className={classes.maxWidth}>
							<TextField
								variant="outlined"
								margin="dense"
								type="text"
								name="resaleSerialNumber"
								label={i18n.t("contactModal.form.resaleSerialNumber")}
								value={contactResaleSerialNumber}
								onChange={handleContactResaleSerialNumber}
							/>

							<TextField
								variant="outlined"
								margin="dense"
								type="text"
								name="serialNumber"
								label={i18n.t("contactModal.form.serialNumber")}
								value={contactSerialNumber}
								onChange={handleContactSerialNumber}
							/>
						</FormControl>

						<br />
						<Divider />
						<br />
						</>
					)}

					{/* 
						************
						** Labels **
						************
					*/}
					{requireTagOnContactWhenClosingTicket === "enabled"
						&& labels !== 0
						&& ticket.contact?.labels.length <= 0
						&& (
						<>
						<FormControl variant="outlined" className={classes.maxWidth}>
							<InputLabel>{i18n.t("editTicketModal.fieldLabelLabel")}</InputLabel>
							<Select
								value={selectedLabel}
								onChange={(e) => handleLabelChange(e)}
								label={i18n.t("editTicketModal.fieldLabelPlaceholder")}
							>
								<MenuItem value={''}>&nbsp;</MenuItem>
								{labels.map((label) => (
									<MenuItem key={label.id} value={label.id}>{label.name}</MenuItem>
								))}
							</Select>
						</FormControl>
						
						{labels.length <= 0 && (
							<Alert severity="warning">
								{i18n.t("editTicketModal.labelInfo")}
							</Alert>
						)}

						<br /><br />
						</>
					)}


					{whatsappQueues.length > 0 && (
						<>
						<FormControl variant="outlined" className={classes.maxWidth}>
							<InputLabel>{i18n.t("editTicketModal.fieldQueueLabel")}</InputLabel>
							<Select
								value={selectedQueue}
								onChange={(e) => handleQueueChange(e)}
								label={i18n.t("editTicketModal.fieldQueuePlaceholder")}
							>
								<MenuItem value={''}>&nbsp;</MenuItem>
								{whatsappQueues.map((whatsappQueue) => (
									<MenuItem key={whatsappQueue.queueId} value={whatsappQueue.queueId}>{whatsappQueue.queue.name}</MenuItem>
								))}
							</Select>
						</FormControl>

						{selectedQueue !== "" && subqueues !== 0 && subqueues.some(subqueue => subqueue.queueId === selectedQueue) && (
							<>
							<br /><br />
							<FormControl variant="outlined" className={classes.maxWidth}>
								<InputLabel>{i18n.t("editTicketModal.fieldSubqueueLabel")}</InputLabel>
						
								<Select 
									value={selectedSubqueue}
									onChange={(e) => setSelectedSubqueue(e.target.value)}
									label={i18n.t("editTicketModal.fieldSubqueuePlaceholder")}
								>
									<MenuItem value={''}>&nbsp;</MenuItem>

									{subqueues.map((subqueue) => (
										subqueue.queueId === selectedQueue && (
											<MenuItem key={subqueue.id} value={subqueue.id}>{subqueue.name}</MenuItem>
										)
									))}
								</Select>
							</FormControl>
							</>
						)} 
						</>
					)}
				</DialogContent>
				<br />
				<DialogActions>
					<Button
						onClick={handleClose}
						color="inherit"
						disabled={loading}
						variant="outlined"
						className={classes.floatingButton}
					>
						{i18n.t("editTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
						className={classes.floatingButton}
					>
						{i18n.t("editTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default EditClosingTicketModal;