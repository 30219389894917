import React, { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, useMediaQuery } from "@material-ui/core";
import { MoreVert, Replay, SaveAltOutlined } from "@material-ui/icons";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import calculateTimeAwaitingFlag from "../../utils/calculateTimeAwaitingFlag";
import getTicketsListAwaitingTimeFlag from "../../utils/getTicketsListAwaitingTimeFlag";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ConfirmationModal from "../ConfirmationModal";
import ReopenTicketModal from "../ReopenTicketModal";
import SplitButton from "../SplitButton";
import TicketOptionsMenu from "../TicketOptionsMenu";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "5px",
		flexWrap: "wrap",
		height: "75%",
		borderRadius:"20px",
		marginRight: 6,
		flex: "1",
		marginLeft: "auto",

		[theme.breakpoints.down("300")]: { flexDirection: "column", },
		"& > *": { margin: theme.spacing(1), },
	},

	actionButton: { "&:hover": {color: theme.palette.primary.main, }, },

	floatingButton: {
		transition: 'transform 0.3s',
		'&:hover': { transform: 'translateY(-5px)', },
	}
}));

const TicketActionButtons = ({ ticket, setTabOpen }) => {
	//	***************
	//	** Variables **
	//	***************
	const classes = useStyles();
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [ticketsList, setTicketsList] = useState([]);

	const [importMessages, setImportMessages] = useState("disabled");

	const [openReopenTicketModal, setOpenReopenTicketModal] = useState(false);



	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		const fetchSettings = async () => {
			try {
				const apiCalls = [api.get("/importMessages")];
				const [importMessagesResponse] = await Promise.all(apiCalls);
				setImportMessages(importMessagesResponse.data.importMessages);
			} catch (error) {
				console.log("Ticket Actions Buttons Use Effect 1 Error:", error);
				toastError(error);
			}
		};

		fetchSettings();
	}, []);

	useEffect(() => {
		if (ticket.status === "pending") {
			setLoading(true);

			const delayDebounceFn = setTimeout(() => {
				const fetchPendingTickets = async () => {
					try {
						const { data: userQueuesIds } = await api.get(`/userQueuesIds/${user.id}`);
						const { data: { tickets } } = await api.get("/tickets/", {
							params: { status: "pending", queueIds: userQueuesIds },
						});
						setTicketsList(tickets);
						setLoading(false);
					} catch (error) {
						setLoading(false);
						console.log("Ticket Actions Buttons Use Effect 2 Error:", error);
						toastError(error);
					}
				};
	
				fetchPendingTickets();
			}, 500);
			return () => clearTimeout(delayDebounceFn);
		}
	}, [ticket, user.id]);
	


	//	***************
	//	** Functions **
	//	***************
	const handleOpenReopenTicketModal = () => {
		setOpenReopenTicketModal(true);
	};

	const handleCloseReopenTicketModal = () => {
		setOpenReopenTicketModal(false);
	};

	const handleConfirmationOpen = () => { setConfirmationOpen(true); }

	const handleConfirmationClose = () => { setConfirmationOpen(false); }

	const handleImportMessages = async () => {
		try {
			const values = { isMessagesImported: true, whatsappId: ticket.whatsappId };
			await api.put(`/contactImportMessages/${ticket.contactId}`, values);
			toast.success(i18n.t("contactModal.importingMessagesBaileys"));
			setTimeout(() => { history.push(`/tickets`); }, 5000);
		} catch (err) { toast.error(err); }
	};

	const handleOpenTicketOptionsMenu = e => { setAnchorEl(e.currentTarget); };

	const handleCloseTicketOptionsMenu = e => { setAnchorEl(null); };

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);

		if (status === "open") {
			const apiCalls = [
				api.get("/settings/awaitingTime"),
				api.get("/settings/forceAcceptLongAwaitingTimeTickets")
			];

			const [
				AwaitingTimeResponse,
				ForceAcceptLongAwaitingTimeTicketsResponse
			] = await Promise.all(apiCalls);

			const awaitingTimeSetting = AwaitingTimeResponse.data;
			const forceAcceptSetting = ForceAcceptLongAwaitingTimeTicketsResponse.data;
			const timeAwaitingFlag = calculateTimeAwaitingFlag(ticket, parseInt(awaitingTimeSetting.value, 10));
			const ticketsListFlags = getTicketsListAwaitingTimeFlag(ticketsList, parseInt(awaitingTimeSetting.value, 10), true);

      //  - setting is "enabled"
      //  - ticket is not awaiting for so much time
      //  - there are pending tickets awaiting for long time
			//  - the user is forced to accept long awaiting time tickets
      if (
        forceAcceptSetting.value === "enabled"
        && !timeAwaitingFlag 
        && ticketsListFlags.includes(true)
				&& user.forceAcceptLongAwaitingTimeTickets
      ) {
        toast.error(i18n.t("backendErrors.ERR_FORCE_ACCEPT_LONG_AWAITING_TIME_TICKETS"));
				setLoading(false);
        return;
      }

			try {
				await api.put(`/tickets/${ticket.id}`, {
					status: status,
					userId: userId,
					directClosedById: null,
					isReturned: false,
					isTransfered: ticket.isTransfered,
					isBotInteractionFinished: true,
					updateLastActivityAt: true
				});

				setLoading(false);
	
				history.push(`/tickets/${ticket.id}`);
				setTabOpen("open");
				localStorage.setItem("tabOpenTickets", "open");
			} catch (error) {
				setLoading(false);
				console.log("Handle Update Ticket Status Error:", error);
				toastError(error);
			}
			
		} else {
			try {
				await api.put(`/tickets/${ticket.id}`, {
					status: status,
					userId: userId && status !== "closed" ? userId: null,
					directClosedById: userId && status === "closed" ? userId : null,
					isReturned: status !== "pending" ? false : true,
					isTransferred: status === "pending" ? false : ticket.isTransfered,
					isBotInteractionFinished: true,
					updateLastActivityAt: status === "pending"
				});

				setLoading(false);

				if (status === "pending") {
					history.push("/tickets");
					setTabOpen("pending");
					localStorage.setItem("tabOpenTickets", "pending");
				}
				else {
					history.push("/tickets");
				}
			} catch (error) {
				setLoading(false);
				console.log("Handle Update Ticket Status Error:", error);
				toastError(error);
			}
		}
	};



	//	************
	//	** Return **
	//	************
	return (
		<>
		<ReopenTicketModal
			modalOpen={openReopenTicketModal}
			onClose={handleCloseReopenTicketModal}
			setTabOpen={setTabOpen}
			ticket={ticket}
		/>

		<div className={classes.actionButtons}>
			{/* 

				***---- Reopen Ticket ----***
			
			*/}
			{!ticket.isGroup && ticket.status === "closed" && ticket.type !== 3 && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={handleOpenReopenTicketModal}
					className={classes.floatingButton}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}



			{/* 

				***---- Import Messages ----***

			*/}
			{ticket.status === "open" && ticket.type === 0 && !ticket.contact.isMessagesImported && (
				<>
					<ConfirmationModal
						title={i18n.t("contactModal.confirmationModal.titleBaileys")}
						open={confirmationOpen}
						onClose={handleConfirmationClose}
						onConfirm={async () => { await handleImportMessages() }}
					>
						{i18n.t("contactModal.confirmationModal.messageBaileys")}
					</ConfirmationModal>

					{!isSmallScreen && importMessages === "enabled" && (
						<Button
							startIcon={<SaveAltOutlined />}
							onClick={handleConfirmationOpen}
							color="inherit"
							variant="text"
							className={classes.floatingButton}
						>
							{i18n.t("contactModal.buttons.importMessages")}
						</Button>
					)}
				</>
			)}



			{/* 

				***---- Return Ticket ----***

			*/}
			{!ticket.isGroup && ticket.status === "open" && ticket.type !== 3 && (
				<>
					<ButtonWithSpinner
						loading={loading}
						startIcon={<Replay />}
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "pending", null)}
						className={classes.floatingButton}
					>
						{i18n.t("messagesList.header.buttons.return")}
					</ButtonWithSpinner>
				</>
			)}



			{/* 
			
				***---- Close Ticket ----***
			
			*/}
			{!ticket.isGroup && ticket.status === "pending" && user.profile === "admin" && (
				<>
					<ButtonWithSpinner
						loading={loading}
						startIcon={<Replay />}
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
						className={classes.floatingButton}
					>
						{i18n.t("messagesList.header.buttons.close")}
					</ButtonWithSpinner>
				</>
			)}



			{/* 
			
				***---- Resolve Ticket ----***

			*/}
			{!ticket.isGroup && ticket.status === "open" && (<SplitButton ticket={ticket} />)}



			{/* 
			
				***---- Accept Ticket ----***
			
			*/}
			{!ticket.isGroup && ticket.status === "pending" && ticket.type !== 3 && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
					className={classes.floatingButton}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}



			{/* 
			
				***---- Ticket Options ----***
			
			*/}
			{((ticket.isGroup && user.profile === "admin")
				|| ticket.status === "open"
				|| ticket.status === "pending")
			&& (
				<>
					<IconButton 
						color="inherit"
						onClick={handleOpenTicketOptionsMenu}
						className={classes.actionButton}>
						<MoreVert />
					</IconButton>
					
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
		</div>
		</>
	);
};

export default TicketActionButtons;
