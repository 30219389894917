import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketCategories = ({ dispatch }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { socket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const handleCategory = (data) => {
      if ((data.action === "update" || data.action === "create") && (`${api.defaults.headers.tenantId.toString()}` === `${data.tenantId.toString()}`)) {
        dispatch({ type: "UPDATE_CATEGORIES", payload: data.category });
      }

      if ((data.action === "delete") && (`${api.defaults.headers.tenantId.toString()}` === `${data.tenantId.toString()}`)) {
        dispatch({ type: "DELETE_CATEGORY", payload: data.categoryId }); 
      }
    };

    socket.on("category", handleCategory);

    return () => {
      socket.off("category", handleCategory);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, socket]);


  
  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketCategories;