import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  groupMembersMentionWrapper: {
    margin: 0,
    position: "absolute",
    bottom: "50px",
    background: theme.palette.background.paper,
    padding: "2px",
    border: "1px solid",
    borderColor: theme.palette.background.paper,
    left: 0,
    width: "100%",
    maxHeight: "150px",
    overflowY: "auto",
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxHeight: "32px",
        "&:hover": {
          backgroundColor: theme.palette.background.default,
          cursor: "pointer",
        },
      },
    },
  },

  groupMembersMentionWrapperItemAnchor: {
    color: theme.palette.text.primary,
  },
}));

const GroupMembersMentionList = ({
  groupMembers,
  handleGroupMembersClick,
  typeAt,
  setTypeAt,
  filter
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const delimiter = "@";
  const [structuredFilter, setStructuredFilter] = useState("");



  //  *************
  //  ** Effects **
  //  *************
  useEffect(() => {
    if (typeAt) {
      const lastIndexOfDelimiter = filter.lastIndexOf(delimiter);
      setStructuredFilter(filter.substring(lastIndexOfDelimiter + 1));

      window.addEventListener("click", (event) => {
        const groupMembersMentionList = document.getElementById("groupMembersMentionList");

        const shouldHideGroupMembersMentionList = groupMembersMentionList !== null
          && groupMembersMentionList !== undefined
          && !(groupMembersMentionList.contains(event.target));

        // Group Members Mention List is hidden when:
        //  - is visible AND;
        //  - user clicked outside the list.
        if (shouldHideGroupMembersMentionList) setTypeAt(false);
      });
    }
  }, [filter, setTypeAt, typeAt]);



  //  ************
  //  ** Return **
  //  ************
  return (
    typeAt
      ? (
        <ul id="groupMembersMentionList" className={classes.groupMembersMentionWrapper}>
          {groupMembers.map((value, index) => {
            const listItemMemberContent = `${value.name} [${value.number}]`;
            const lowerMemberName = listItemMemberContent.toLowerCase();
            const lowerFilter = structuredFilter.toLowerCase();

            if (lowerFilter !== "" && !lowerMemberName.includes(lowerFilter)) return <></>;

            return (
              <li key={index}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className={classes.groupMembersMentionWrapperItemAnchor}
                  onClick={() => handleGroupMembersClick(value.number)}
                >
                  {listItemMemberContent}
                </a>
              </li>
            )
          })}
        </ul>
      )
      : (
        <div></div>
      )
  );
};

export default GroupMembersMentionList;
