import api from "../../services/api";
import toastError from "../../errors/toastError";

const useWhatsAppsPriorization = () => {
  const findAllWhatsApps = async (userId) => {
    try {
      const { data } = await api.get(`/whatsappPriorization/${userId}`);
      return data;
    } catch (error) {
      console.log("Use WhatsApps Priorization Error:", error);
      toastError(error);
    }
  }

  return { findAllWhatsApps };
};

export default useWhatsAppsPriorization;
