import React from "react";
import { TripOriginOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  viewOnceRepliedMessageMainContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "5px",
    color: "#999",
  },
}));

const ViewOnceRepliedMessage = ({ isPhoto }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.viewOnceRepliedMessageMainContainer}>
      <TripOriginOutlined fontSize="small" />
      <span>
        {isPhoto
          ? i18n.t("viewOnceRepliedMessage.photo")
          : i18n.t("viewOnceRepliedMessage.video")}
      </span>
    </div>
  );
};

export default ViewOnceRepliedMessage;