import { useState, useEffect, useReducer } from "react";
import { useSocket } from "../../context/Socket/SocketContext";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const reducer = (state, action) => {
	if (action.type === "LOAD_QUEUES") {
		const queues = action.payload;

		return [...queues];
	}

	if (action.type === "UPDATE_QUEUES") {
		const queue = action.payload;
		const queueIndex = state.findIndex(q => q.id === queue.id);

		if (queueIndex !== -1) {
			state[queueIndex] = queue;
			return [...state];
		} else {
			return [queue, ...state];
		}
	}

	if (action.type === "DELETE_QUEUE") {
		const queueId = action.payload;

		const queueIndex = state.findIndex(q => q.id === queueId);
		if (queueIndex !== -1) {
			state.splice(queueIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const useQueuesAll = () => {
const [queues, dispatch] = useReducer(reducer, []);
const [loading, setLoading] = useState(true);
const { socket } = useSocket();

useEffect(() => {
  setLoading(true);
  const fetchSession = async () => {
    try {
      const { data } = await api.get("/queue/");
      dispatch({ type: "LOAD_QUEUES", payload: data });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Use Queues All Error:", error);
      toastError(error);
    }
  };
  fetchSession();
}, []);

useEffect(() => {
  const handleUpdateQueues = (data) => {
    if (data.action === "update" && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
      dispatch({ type: "UPDATE_QUEUES", payload: data.queues });
    }
  };

  const handleDeleteQueues = (data) => {
    if (data.action === "delete" && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
      dispatch({ type: "DELETE_QUEUE", payload: data.queuesId });
    }
  };

  socket.on("queues", handleUpdateQueues);
  socket.on("queues", handleDeleteQueues);

  return () => {
    socket.off("queues", handleUpdateQueues);
    socket.off("queues", handleDeleteQueues);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [socket]);

return { queues, loading };
};

export default useQueuesAll;