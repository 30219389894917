import { useEffect, useState } from "react";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useGroupTicketsPerConnection = ({ initialDate, finalDate }) => {
  const [tickets, setTickets] = useState(0);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchGroupTicketsPerConnection = async () => {
        try {
          const { data } = await api.get("/groupTicketsPerConnection", { params: { initialDate, finalDate } });
          setTickets(data);
        } catch (exception) {
          console.log("Use Group Tickets Per Connection Exception Caught:", exception);
          toastError(exception);
        }
      };

      fetchGroupTicketsPerConnection();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [initialDate, finalDate]);

  return tickets;
};

export default useGroupTicketsPerConnection;