function saveFile(message) {
  const FileSaver = require('file-saver');

  let fileName;
  if (message.mediaUrl.includes("-bzf-")) fileName = message.mediaUrl.substring(message.mediaUrl.lastIndexOf('-bzf-') + 5);
  else fileName = message.mediaUrl.substring(message.mediaUrl.lastIndexOf('/') + 1);
  
  if (fileName.split(".")[fileName.split(".").length - 1] === "octet-stream") fileName = message.body.split("\n")[0]

  FileSaver.saveAs(message.mediaUrl, fileName);
};

export default saveFile;