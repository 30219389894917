import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, } from '@material-ui/core';

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
  //  *************
  //  ** Content **
  //  *************
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const AudioGalleryModal = ({ open, onClose, audioUrl }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => { onClose(); };

 

  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        scroll="paper"
        fullWidth
      >
        <DialogTitle>
          <span>{i18n.t("mediasGalleryModal.title.up")}</span>
        </DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <audio controls>
            <source src={audioUrl} type="audio/ogg"></source>
          </audio>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AudioGalleryModal;