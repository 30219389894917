import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
	Menu,
	MenuItem,
	useMediaQuery,
} from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import EditTicketModal from "../EditTicketModal";
import toastError from "../../errors/toastError";
import TransferTicketModal from "../TransferTicketModal";

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const history = useHistory();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationImportMessagesOpen, setConfirmationImportMessagesOpen] = useState(false);
	const [blockContactConfirmationOpen, setBlockContactConfirmationOpen] = useState(false);
	const [unblockContactConfirmationOpen, setUnblockContactConfirmationOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const [editTicketModalOpen, setEditTicketModalOpen] = useState(false);

	const [importMessages, setImportMessages] = useState("disabled");
	


	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		const fetchSettings = async () => {
			try {
				const apiCalls = [api.get("/importMessages")];
				const [importMessagesResponse] = await Promise.all(apiCalls);
				setImportMessages(importMessagesResponse.data.importMessages);
			} catch (error) {
				console.log("Ticket Options Menu Use Effect 1 Error:", error);
				toastError(error);
			}
		};

		fetchSettings();
	}, []);

	useEffect(() => {
		return () => { isMounted.current = false; };
	}, []);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleConfirmationImportMessagesOpen = () => {
		handleClose();
		setConfirmationImportMessagesOpen(true);
	}

	const handleConfirmationImportMessagesClose = () => { setConfirmationImportMessagesOpen(false); }

	const handleImportMessages = async () => {
		try {
			const values = { isMessagesImported: true, whatsappId: ticket.whatsappId };
			await api.put(`/contactImportMessages/${ticket.contactId}`, values);
			toast.success(i18n.t("contactModal.importingMessagesBaileys"));
			setTimeout(() => { history.push(`/tickets`); }, 5000);
		} catch (err) { toast.error(err); }
	};

	const handleDeleteTicket = async () => {
		try {
			if (ticket.isGroup) {
				await api.delete(`/ticketsGroup/${ticket.id}`, {
					data: { contactId: ticket.contactId }
				});
			} else {
				await api.delete(`/ticketsChat/${ticket.id}`);
			}
		}
		catch (error) {
			console.log("Handle Delete Ticket Error:", error);
			toastError(error);
		}
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
		handleClose();
	};

	const handleOpenEditTicketModal = e => {
		setEditTicketModalOpen(true);
		handleClose();
	}

	const handleCloseEditTicketModal = e => {
		if (isMounted.current) { setEditTicketModalOpen(false); }
	};

	const handleOpenTransferModal = e => {
		setTransferTicketModalOpen(true);
		handleClose();
	};

	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) { setTransferTicketModalOpen(false); }
	};

	const handleBlockContact = async (contactId) => {
		if (!contactId) return;
		try {
			await api.put(`/blockContact/${contactId}`);
			toast.success(i18n.t("contacts.confirmationModal.toast.blockedContact"));
			history.push(`/tickets`);
		} catch (error) {
			console.log("Handle Block Contact Error:", error);
			toastError(error);
		}
	};

	const handleUnblockContact = async (contactId) => {
		if (!contactId) return;
		try {
			await api.put(`/unblockContact/${contactId}`);
			toast.success(i18n.t("contacts.confirmationModal.toast.unblockedContact"));
			history.push(`/tickets`);
		} catch (error) {
			console.log("Handle Unblock Contact Error:", error);
			toastError(error);
		}
	};



	// 	************
	// 	** Return **
	// 	************
	return (
		<>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
				keepMounted
				transformOrigin={{ vertical: "top", horizontal: "right", }}
				open={menuOpen}
				onClose={handleClose}
			>
				{/* ***---- Import Messages ----*** */}
				{ticket.type === 0
					&& ticket.status === "open"
					&& isSmallScreen
					&& !ticket.contact.isMessagesImported
					&& importMessages === "enabled"
					&& (
					<MenuItem onClick={handleConfirmationImportMessagesOpen}>
						{i18n.t("contactModal.buttons.importMessages")}
					</MenuItem>
				)}

				{/* ***---- Edit Ticket ----*** */}
				{ticket.type !== 3 && !ticket.isGroup && (
					<MenuItem onClick={handleOpenEditTicketModal}>
						{i18n.t("ticketOptionsMenu.edit")}
					</MenuItem>
				)}

				{/* ***---- Block Contact ----*** */}
				{(ticket.status === "open" || ticket.status === "pending")
				 && ticket.type === 0 && ticket.contact && !ticket.contact.isBlocked
				 && user.profile === "admin"
				 && (
					<MenuItem onClick={() => setBlockContactConfirmationOpen(true)}>
						{i18n.t("ticketOptionsMenu.block")}
					</MenuItem>
				)}

				{/* ***---- Unblock Contact ----*** */}
				{(ticket.status === "open" || ticket.status === "pending")
				 && ticket.type === 0 && ticket.contact && ticket.contact.isBlocked
				 && user.profile === "admin"
				 && (
					<MenuItem onClick={() => setUnblockContactConfirmationOpen(true)}>
						{i18n.t("ticketOptionsMenu.unblock")}
					</MenuItem>
				)}

				{/* ***---- Transfer and Delete Open Tickets ----*** */}
				{ticket.status === "open" && (
					<>
					{/* ***---- Transfer Ticket ----*** */}
					{ticket.type !== 3 && (
						<MenuItem onClick={handleOpenTransferModal}>
							{i18n.t("ticketOptionsMenu.transfer")}
						</MenuItem>
					)}

					{/* ***---- Delete Ticket ----*** */}
					<Can
						role={user.profile}
						perform="ticket-options:deleteTicket"
						yes={() => (
							<MenuItem onClick={handleOpenConfirmationModal}>
								{i18n.t("ticketOptionsMenu.delete")}
							</MenuItem>
						)}
					/>
					</>
				)}

				{/* ***---- Delete Group Tickets ----*** */}
				{ticket.status === "group" && user.profile === "admin" && (
					<MenuItem onClick={handleOpenConfirmationModal}>
						{i18n.t("ticketOptionsMenu.delete")}
					</MenuItem>
				)}
			</Menu>
			
			{/* ***---- Block Contact ----*** */}
			<ConfirmationModal
				title={i18n.t("contacts.confirmationModal.blockTitle")}
				open={blockContactConfirmationOpen}
				onClose={() => setBlockContactConfirmationOpen(false)}
				onConfirm={() => handleBlockContact(ticket.contact.id)}
			>
				{i18n.t("contacts.confirmationModal.blockMessage")}
			</ConfirmationModal>

			{/* ***---- Unblock Contact ----*** */}
			<ConfirmationModal
				title={i18n.t("contacts.confirmationModal.unblockTitle")}
				open={unblockContactConfirmationOpen}
				onClose={() => setUnblockContactConfirmationOpen(false)}
				onConfirm={() => handleUnblockContact(ticket.contact.id)}
			>
				{i18n.t("contacts.confirmationModal.unblockMessage")}
			</ConfirmationModal>
			
			{/* ***---- Import Messages ----*** */}
			<ConfirmationModal
				title={i18n.t("contactModal.confirmationModal.titleBaileys")}
				open={confirmationImportMessagesOpen}
				onClose={handleConfirmationImportMessagesClose}
				onConfirm={async () => { await handleImportMessages() }}
			>
				{i18n.t("contactModal.confirmationModal.messageBaileys")}
			</ConfirmationModal>
			
			{/* ***---- Delete Ticket ----*** */}
			<ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}
							  ${ticket.id}
							  ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")}
								${ticket.type === 0 ? ticket.contact.name: ticket.noteName}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>

			{/* ***---- Transfer Ticket ----*** */}
			<TransferTicketModal
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketid={ticket.id}
				ticketType={ticket.type}
				ticketWhatsappId={ticket.whatsappId}
			/>

			{/* ***---- Edit Ticket (Pending) ----*** */}
			{ticket.status === "pending" && (
				<EditTicketModal
					modalOpen={editTicketModalOpen}
					onClose={handleCloseEditTicketModal}
					ticket={ticket}
					ticketStatus={ticket.status}
				/>
			)}

			{/* ***---- Edit Ticket (Open) ----*** */}
			{ticket.status === "open" && (
				<EditTicketModal
					modalOpen={editTicketModalOpen}
					onClose={handleCloseEditTicketModal}
					ticket={ticket}
					ticketStatus={ticket.status}
				/>
			)}
		</>
	);
};

export default TicketOptionsMenu;
