import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FileCopyOutlined } from "@material-ui/icons";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Tooltip
} from "@material-ui/core";


import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
  root: { userSelect: "none" },

	dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    gap: "1em",
  },

  infoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    gap: "1em",
    width: "100%",
  },

  copyButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    justifyContent: "center",
    padding: "1em",
  },

  copyButton: {
    cursor: "pointer",
    transition: "all 0.3s ease",

    "&:hover": {
      transform: "translateY(-5px)",
    }
  },
}));

const VersionModal = ({ open, onClose, applicationVersion }) => {
  //  ***************
  //  ** Variables **
  //  ***************
	const classes = useStyles();
  const [copyTooltip, setCopyTooltip] = useState(i18n.t("versionModal.tooltips.copy"));
  const dateInfo = applicationVersion.split(".");
  const timeInfo = dateInfo[3].split("-");

	

  //  ***************
  //  ** Functions **
  //  ***************
	const handleClose = () => { onClose(); };

  const handleCopyInfo = async () => {
    const clipboardInfo = `${i18n.t("versionModal.spans.year")}: ${dateInfo[0]}\n${i18n.t("versionModal.spans.month")}: ${dateInfo[1]}\n${i18n.t("versionModal.spans.day")}: ${dateInfo[2]}
---
${i18n.t("versionModal.spans.hour")}: ${timeInfo[0].substring(0,2)}\n${i18n.t("versionModal.spans.minute")}: ${timeInfo[0].substring(2)}
---
${i18n.t("versionModal.spans.group")}: ${timeInfo[1]}
${i18n.t("versionModal.spans.tenant")}: ${timeInfo[2]}`;
    await navigator.clipboard.writeText(clipboardInfo);

    setCopyTooltip(i18n.t("versionModal.tooltips.copied"));

    setTimeout(() => {
      setCopyTooltip(i18n.t("versionModal.tooltips.copy"));
    }, 700);
  };



  //  ************
  //  ** Return **
  //  ************
	return (
		<div>
			<Dialog className={classes.root} open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>{i18n.t("versionModal.title.up")}</DialogTitle>
			
        <DialogContent className={classes.dialogContent} dividers>
          <div className={classes.infoContainer}>
            <span>{i18n.t("versionModal.spans.year")}: <b>{dateInfo[0]}</b></span>
            <span>{i18n.t("versionModal.spans.month")}: <b>{dateInfo[1]}</b></span>
            <span>{i18n.t("versionModal.spans.day")}: <b>{dateInfo[2]}</b></span>

            <Divider />

            <span>{i18n.t("versionModal.spans.hour")}: <b>{timeInfo[0].substring(0, 2)}</b></span>
            <span>{i18n.t("versionModal.spans.minute")}: <b>{timeInfo[0].substring(2)}</b></span>

            <Divider />

            <span>{i18n.t("versionModal.spans.group")}: <b>{timeInfo[1]}</b></span>
            <span>{i18n.t("versionModal.spans.tenant")}: <b>{timeInfo[2]}</b></span>
          </div>

          <div className={classes.copyButtonContainer}>
            <Tooltip title={copyTooltip} placement="top-start" arrow>
              <span className={classes.copyButton} onClick={handleCopyInfo}>
                <FileCopyOutlined />
              </span>
            </Tooltip>
          </div>
        </DialogContent>			
			</Dialog>
		</div>
	);
};

export default VersionModal;
