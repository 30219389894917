import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles(theme => ({
  floatingButton: {
    transition: "transform 0.3s",
    "&:hover": { transform: "translateY(-5px)", },
  },
}));

const ReopenTicketModal = ({ modalOpen, onClose, setTabOpen, ticket }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { whatsApps: whatsAppsList } = useWhatsApps();
  const defaultWhatsapp = whatsAppsList.length === 1 ? whatsAppsList[0] : null;
  const [selectedWhatsappId, setSelectedWhatsappId] = useState("");

  const { user } = useContext(AuthContext);



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    if (modalOpen) {
      setSelectedWhatsappId(selectedWhatsappId === "" && defaultWhatsapp ? defaultWhatsapp.id : selectedWhatsappId);
      return;
    }
  }, [modalOpen, selectedWhatsappId, defaultWhatsapp]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
    setSelectedWhatsappId("");
  };

  const handleReopenChat = async (event) => {
    try {
      if (!selectedWhatsappId || selectedWhatsappId === undefined) {
        toast.error(i18n.t("newTicketModal.toasts.whatsappNotSelected"));
      } else {
        // - whatsapp
        if (ticket.type === 0) {
          const params = {
            contactId: ticket.contact.id,
            userId: user.id,
            status: "open",
            isCreatedByUser: true,
            isBotInteractionFinished: true,
            whatsappId: selectedWhatsappId
          };

          const { data } = await api.post("/tickets", params);
          history.push(`/tickets/${data.id}`);
        }

        // - note
        else if (ticket.type === 1) {
          const params = {
            name: ticket.noteName,
            assignToMe: true,
            extraInfo: [],
            userId: user.id,
            status: "open",
            noteCreatedBy: user.id,
            isCreatedByUser: true,
            queueId: null,
            subqueueId: null,
            whatsappId: selectedWhatsappId
          };

          const { data } = await api.post("/notes", params);
          history.push(`/tickets/${data.id}`);
        }

        // - bot
        else {
          const params = {
            name: ticket.noteName,
            assignToMe: true,
            extraInfo: [],
            userId: user.id,
            status: "open",
            noteCreatedBy: user.id,
            isVreatedByUser: true,
            queueId: null,
            subqueueId: null,
            whatsappId: selectedWhatsappId
          };

          const { data } = await api.post("/botChats", params);
          history.push(`/tickets/${data.id}`);
        }

        setTabOpen("open");
        localStorage.setItem("tabOpenTickets", "open");
      }
    } catch (exception) {
      console.log("Handle Reopen Chat Modal Exception:", exception);
      toastError(exception);
    } finally {
      handleClose();
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <Dialog open={modalOpen} onClose={handleClose}>
      <DialogTitle>
        {i18n.t("contacts.confirmationModal.reopenPrivateChat.title")}
      </DialogTitle>

      <DialogContent dividers>
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel>{i18n.t("newTicketModal.connection")}</InputLabel>
          <Select
            value={selectedWhatsappId}
            onChange={(event) => setSelectedWhatsappId(event.target.value)}
            label={i18n.t("newTicketModal.connection")}
            fullWidth
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {whatsAppsList.map((whatsapp) => (
              <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          color="inherit"
          variant="outlined"
          className={classes.floatingButton}
        >
          {i18n.t("newTicketModal.buttons.cancel")}
        </Button>

        <ButtonWithSpinner
          className={classes.floatingButton}
          variant="contained"
          type="button"
          onClick={(event) => handleReopenChat(event)}
          color="primary"
        >
          {i18n.t("newTicketModal.buttons.ok")}
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
};

export default ReopenTicketModal;
