import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tab,
  Tabs,
  Tooltip
} from '@material-ui/core';
import Viewer from 'react-viewer';

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import AudioGalleryModal from "../AudioGalleryModal";
import audioIcon from "../../assets/audio-waves.png";
import docIcon from "../../assets/google-docs.png";
import videoIcon from "../../assets/video.png";

const useStyles = makeStyles(theme => ({
  //  *************
  //  ** Content **
  //  *************
  dialogContent: {
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  //  **********************
  //  ** Medias Container **
  //  **********************
  mediasList: {
    display: "flex",
		flexDirection: "row",
    flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "space-around",
		gap: "1em",
    marginTop: "1em",
  },

  mediaItem: {
    borderRadius: "5px",
		width: "75px",
		height: "75px",
		cursor: "pointer",
		transition: "all 0.3s ease",
		userDrag: "none",
    marginTop: "1em",

		"&:hover": {
			opacity: "0.9",
			transform: "translateY(-5px)",
		},
  },

  mediaItemSpan: {
    borderRadius: "5px",
    border: `2px solid ${theme.palette.primary.pastel}`,
		width: "100px",
		height: "100px",
		cursor: "pointer",
		transition: "all 0.3s ease",
		userDrag: "none",
    padding: "5px",
    marginTop: "1em",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

		"&:hover": {
			opacity: "0.9",
			transform: "translateY(-5px)",
		},
  },

  fileIcon: {
    borderRadius: "5px",
		width: "75px",
		height: "75px",
		cursor: "pointer",
    userDrag: "none",
  },
}));

const MediasGalleryModal = ({ open, onClose, contactId }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [mediasTab, setMediasTab] = useState("images");

  const [mediasListImages, setMediasListImages] = useState([]);
  const [mediasListVideos, setMediasListVideos] = useState([]);
  const [mediasListAudios, setMediasListAudios] = useState([]);
  const [mediasListOthers, setMediasListOthers] = useState([]);

  const [viewerVisible, setViewerVisible] = useState(false);
	const [viewerImageUrl, setViewerImageUrl] = useState([]);
  const [viewerImageIndex, setViewerImageIndex] = useState(0);

  const [audioModalOpen, setAudioModalOpen] = useState(false);
  const [selectedAudioUrl, setSelectedAudioUrl] = useState("");

  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (open) {
      const delayDebounceFN = setTimeout(() => {
        const fetchMediasList = async () => {
          const { data: imagesList } = await api.get(`/mediasListImages/${contactId}`);
          const { data: videosList } = await api.get(`/mediasListVideos/${contactId}`);
          const { data: audiosList } = await api.get(`/mediasListAudios/${contactId}`);
          const { data: othersList } = await api.get(`/mediasListOthers/${contactId}`);

          setMediasListImages(imagesList);
          setMediasListVideos(videosList);
          setMediasListAudios(audiosList);
          setMediasListOthers(othersList);

          const images = [];

          if (imagesList.rows?.length > 0) {
            imagesList.rows.map(image => {
              images.push({ src: image.mediaUrl, downloadUrl: image.mediaUrl });
            });
          }
          setViewerImageUrl(images);
        };
        fetchMediasList();
      }, 500);
      return () => clearTimeout(delayDebounceFN);
    } else {
      setMediasListImages([]);
      setMediasListVideos([]);
      setMediasListAudios([]);
      setMediasListOthers([]);

      setViewerVisible(false);
      setViewerImageUrl([]);
      setViewerImageIndex(0);
      
      setAudioModalOpen(false);
      setSelectedAudioUrl("");
    }
  }, [open, contactId]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => { 
    setMediasListImages([]);
    setMediasListVideos([]);
    setMediasListAudios([]);
    setMediasListOthers([]);

    setMediasTab("images");

    setViewerVisible(false);
    setViewerImageUrl([]);
    setViewerImageIndex(0);
    
    setAudioModalOpen(false);
    setSelectedAudioUrl("");

    onClose();
  };

  const handleOpenAudioModal = (audioUrl) => {
    setAudioModalOpen(true);
    setSelectedAudioUrl(audioUrl);
  };

  const handleCloseAudioModal = () => { 
    setAudioModalOpen(false);
    setSelectedAudioUrl("");
  };

  const handleChange = (event, newTab) => { setMediasTab(newTab); };

  const renderMediasList = (selectedTab) => {
    if (open && selectedTab === "images") {
      return (
        <div className={classes.mediasList}>
          {mediasListImages.rows?.length > 0 && mediasListImages.rows.map((image, index) => (
            <Tooltip title={image.mediaUrl.split("/")[image.mediaUrl.split("/").length - 1]} placement="top-start" arrow>
              <img key={image.id} className={classes.mediaItem} src={image.mediaUrl} alt="" 
                onClick={() => {
                  setViewerVisible(true);
                  setViewerImageIndex(index);
                }}
              />
            </Tooltip>
          ))}
        </div>
      );
    }

    if (open && selectedTab === "videos") {
      return (
        <div className={classes.mediasList}>
          {mediasListVideos.rows?.length > 0 && mediasListVideos.rows.map(video => (
            <Tooltip title={video.mediaUrl.split("/")[video.mediaUrl.split("/").length - 1]} placement="top-start" arrow>
              <a href={video.mediaUrl} target="_blank" rel="noreferrer">
                <span key={video.id} className={classes.mediaItemSpan}>
                  <img className={classes.fileIcon} src={videoIcon} alt="" />
                </span>
              </a>
            </Tooltip>
          ))}
        </div>
      );
    }

    if (open && selectedTab === "audios") {
      return (
        <div className={classes.mediasList}>
          {mediasListAudios.rows?.length > 0 && mediasListAudios.rows.map(audio => (
            <Tooltip title={audio.mediaUrl.split("/")[audio.mediaUrl.split("/").length - 1]} placement="top-start" arrow>
              <span key={audio.id} className={classes.mediaItemSpan} onClick={() => { handleOpenAudioModal(audio.mediaUrl) }}>
                <img className={classes.fileIcon} src={audioIcon} alt="" />
              </span>
            </Tooltip>
          ))}
        </div>
      );
    }

    if (open && selectedTab === "others") {
      return (
        <div className={classes.mediasList}>
          {mediasListOthers.rows?.length > 0 && mediasListOthers.rows.map(other => (
            <Tooltip title={other.mediaUrl.split("/")[other.mediaUrl.split("/").length - 1]} placement="top-start" arrow>
              <a href={other.mediaUrl} target="_blank" rel="noreferrer">
                <span key={other.id} className={classes.mediaItemSpan}>
                  <img className={classes.fileIcon} src={docIcon} alt="" />
                </span>
              </a>
            </Tooltip>
          ))}
        </div>
      );
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Viewer
				downloadable={true}
				downloadInNewWindow={true}
				images={viewerImageUrl}
				onClose={() => { setViewerVisible(false); } }
				onMaskClick={() => { setViewerVisible(false); }}
				visible={viewerVisible}
				zIndex={10000}
        activeIndex={viewerImageIndex}
			/>

      <AudioGalleryModal
        open={audioModalOpen}
        onClose={handleCloseAudioModal}
        audioUrl={selectedAudioUrl}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        scroll="paper"
        fullWidth
      >
        <DialogTitle>
          <span>{i18n.t("mediasGalleryModal.title.up")}</span>
        </DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <Tabs
            value={mediasTab}
            onChange={handleChange}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={i18n.t("mediasGalleryModal.tabBar.images")} value="images" />
            <Tab label={i18n.t("mediasGalleryModal.tabBar.videos")} value="videos" />
            <Tab label={i18n.t("mediasGalleryModal.tabBar.audios")} value="audios" />
            <Tab label={i18n.t("mediasGalleryModal.tabBar.others")} value="others" />
          </Tabs>

          {renderMediasList(mediasTab)}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MediasGalleryModal;