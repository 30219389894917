import api from "../../services/api";

const useSubqueues = () => {
    const findAll = async () => {
        const { data } = await api.get(`/subqueue`);
        return data;
    }

	return { findAll };
}

export default useSubqueues;
