import React from "react";
import { toast } from "react-toastify";
import { Button, makeStyles } from "@material-ui/core";

import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  },

  buttonOption: {
    width: "250px",
    color: theme.palette.secondary.ackCheck,
    backgroundColor: theme.palette.background.paper,
    "&:hover": { backgroundColor: theme.palette.background.paper, },
  },

  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)' },
  },
}));

const ButtonPreview = ({ message, ticketId }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  ***************
  //  ** Functions **
  //  ***************
  const handleSelectOption = async (selectedOption) => {
    try {
      const selectedButtonOption = message.buttonOptions.filter(option => option.id === selectedOption)[0];
      await api.post(`/messagesButtonResponse/${ticketId}`, {
        body: selectedButtonOption.description,
        quotedMsgId: message.id
      });
    } catch (err) { toast.error(err); }
  };

  

  //  ************
  //  ** Return **
  //  ************
  return (
    <>
    <br />

    <div className={classes.buttonsContainer}>
      {message.buttonOptions.map(option => (
        <Button
          className={`${classes.buttonOption} ${classes.floatingButton}`}
          onClick={() => handleSelectOption(option.id)}
          variant="contained"
        >
          {option.description}
        </Button>
      ))}
    </div>

    <br />
    </>
  );
};

export default ButtonPreview;