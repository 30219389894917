import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Typography
} from "@material-ui/core";
import { StarRateOutlined } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import truncateString from "../../utils/truncateString";

const useStyles = makeStyles(theme => ({
  //  ********************
  //  ** Dialog Content **
  //  ********************
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },



  //  ****************
  //  ** Containers **
  //  ****************
  infoContainer: {
    padding: "5px",
    backgroundColor: theme.palette.primary.messageBox,
    borderRadius: "20px",
  },

  infoContainerTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px",
  },

  votesContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "20px",
    padding: "2px",
    width: "100px",
    float: "right",
  },

  containerTitle: { width: "calc(100% - 100px)" },
  infoContainerContacts: { padding: "5px", maxHeight: "150px", overflowY: "auto" },



  //  ***********
  //  ** Cards **
  //  ***********
  contactCard: {
    borderRadius: "20px",
    padding: "10px",
    marginBottom: "5px",
  },

  contactCardActionArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "20px",
    padding: "5px",
    cursor: "default",
  },

  contactName: { fontSize: "16px" },
  contactNumber: { fontSize: "13px", color: "hsl(217, 12%, 63%)" },
}));

const PollVotesModal = ({ open, onClose, message }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => { onClose(); };



  //  ************
  //  ** Return **
  //  ************
  if (open) {
    return (
      <div className={classes.root}>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="xs"
          scroll="paper"
          fullWidth
        >
          <DialogTitle>
            <span>{i18n.t("pollVotesModal.title.up")}</span>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>

            <Paper className={classes.infoContainer}>
              {message.pollsOptions.map(option => (
                <>
                {/* ***---- Title ----*** */}
                <Container className={classes.infoContainerTitle}>
                  <Container className={classes.containerTitle}>
                    <Typography>{truncateString(option.description, 27)}</Typography>
                  </Container>

                  <Container className={classes.votesContainer}>
                    <StarRateOutlined />
                    <span>{option.pollsVotes.length} {i18n.t("pollVotesModal.votes")}</span>
                  </Container>
                </Container>
                <Divider />

                {/* ***---- Content ----*** */}
                {option.pollsVotes.map(vote => (
                  <Container className={classes.infoContainerContacts}>
                    <Card className={classes.contactCard}>
                      <CardActionArea className={classes.contactCardActionArea}>
                        <Avatar src={vote.contact?.profilePicUrl} alt="Contact" className={classes.contactAvatar} />

                        <CardContent>
                          <Typography className={classes.contactName} gutterBottom component="h3">{vote.contact.name}</Typography>
                          <Typography className={classes.contactNumber} gutterBottom component="h3">{vote.contact.number}</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Container>
                ))}
                </>
              ))}

              
            </Paper>

          </DialogContent>
        </Dialog>
      </div>
    );
  }
  else { return <></>; }
};

export default PollVotesModal;