import React, { useState } from "react";
import { makeStyles } from '@material-ui/core';

import { i18n } from "../../translate/i18n";
import UpToDateImg from '../../assets/upToDate.svg';

const useStyles = makeStyles(theme => ({
  releasesNoteContainerUpToDate: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },

  upToDateImg: { width: "100%", height: "75%" },

  upToDateSpan: {
    width: "100%",
    height: "25%",
    textAlign: "center",
    fontSize: "1.5em",
  },

  releasesNoteContainer: { width: "100%", height: "100%" },
}));

const ReleaseNotesContents = ({ open }) => {

  // ***----- Datas -----***
	const classes = useStyles();
  const fxNewsSource = "https://service.fxsistemas.com.br/fx-news/noticiaDiretaAPI.php?numeroSerie=19&sistema=7&type=bestzap";
  const [releasesContent, setReleasesContent] = useState("");

  // ***---- Functions and Use States ----***
  function reqListener () { setReleasesContent(this.responseText); }

  useState(() => {
    if (open) {
      const xhr = new XMLHttpRequest();
      xhr.onload = reqListener;
      xhr.open('GET', fxNewsSource, true);
      xhr.send(null);
    }
  }, [open]);

  // ***----- Return -----***
	return (
    <>
    {releasesContent === "" && (
      <div className={classes.releasesNoteContainerUpToDate}>
        <span className={classes.upToDateSpan}>{i18n.t("releasesModal.upToDateSpan")}</span>
        <img className={classes.upToDateImg} src={UpToDateImg} alt="Up to date" />
      </div>
    )}

    {releasesContent !== "" && (
      <div className={classes.releasesNoteContainer} dangerouslySetInnerHTML={{__html: releasesContent}}></div>
    )}
    </>
	);
};

export default ReleaseNotesContents;
