import { useState, useEffect } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useTickets = ({
  pageNumber,
  status,
  date,
  showAll,
  queueIds,
  withUnreadMessages,
  isGroup,
  isChannel,
  filter,
  selectedUserIds,
  selectedConnectionIds
}) => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTickets = async() => {
        try {
          const { data } = await api.get("/tickets", {
            params: {
              pageNumber,
              status,
              date,
              showAll,
              queueIds,
              withUnreadMessages,
              isGroup,
              isChannel,
              filter,
              selectedUserIds,
              selectedConnectionIds
            },
          })
          
          setTickets(data.tickets);
          setHasMore(data.hasMore)
          setCount(data.count)
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log("Use Tickets Error:", error);
          toastError(error)
        }
      }
      fetchTickets()
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [
      pageNumber,
      status,
      date,
      showAll,
      queueIds,
      withUnreadMessages,
      isGroup,
      isChannel,
      filter,
      selectedUserIds,
      selectedConnectionIds
  ])

  return { tickets, loading, hasMore, count };
};

export default useTickets;