import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ResponsivePie } from "@nivo/pie";

import { i18n } from "../../../../translate/i18n";
import api from "../../../../services/api";
import ErrorBoundary from "../../../../errors/Boundary";
import NoDataImg from "../../../../assets/noData.svg";
import useGroupTicketsPerConnection from "../../../../hooks/useGroupTicketsPerConnection";

const useStyles = makeStyles(theme => ({
  dashboardContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    padding: "20px 0px 20px 0px",
    textIndent: "15px",
  },

  emptyBoxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "50px",
  },

  emptyBoxImage: { width: "250px", height: "250px", },

  emptyBoxSpan: { fontSize: "20px", },
}));

const ChartTicketsPerConnection = ({ initialDate, finalDate }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [allowGroupsSetting, setAllowGroupsSetting] = useState("disabled");



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const apiCalls = [api.get("/settings/allowGroups")];
        const [AllowGroupsResponse] = await Promise.all(apiCalls);
        setAllowGroupsSetting(AllowGroupsResponse.data.value); // getting "allowGroups" setting
      } catch (exception) {
        console.log("Chart Tickets per Connection Use Effect Exception:", exception);
      }
    };

    fetchSettings();
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const GetTicketsPerConnection = (initialDate, finalDate) => {
    const structuredInitialDate = initialDate ? `${initialDate}T00:00:01` : "2020-12-29T00:00:01";
    const structuredFinalDate = finalDate ? `${finalDate}T23:59:59` : "2020-12-29T23:59:59";

    const counts = useGroupTicketsPerConnection({
      initialDate: structuredInitialDate,
      finalDate: structuredFinalDate
    });

    return counts;
  };



  //  **********
  //  ** Data **
  //  **********
  const counts = GetTicketsPerConnection(initialDate, finalDate);



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.dashboardContainer}>
      <ErrorBoundary>
        {counts.length > 0 && (
          <ResponsivePie
            data={counts}
            theme={{ tooltip: { container: { background: "#999", color: "#fff" } }, }}
            colors={{ scheme: "nivo" }}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.50}
            padAngle={0.70}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.20]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#999"
            arcLinkLabelsThickness={20}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 10]] }}
            legends={[{
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemSpacing: 5,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "top-to-bottom",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [{ on: "hover", style: { itemTextColor: "#c6c6c6" } }]
            }]}
            motionConfig={{
              mass: 1,
              tension: 170,
              friction: 26,
              clamp: false,
              precision: 0.01,
              velocity: 0
            }}
            transitionMode="outerRadius"
          />
        )}

        {(counts.length === 0 || allowGroupsSetting === "disabled") && (
          <div className={classes.emptyBoxContainer}>
            <img className={classes.emptyBoxImage} src={NoDataImg} alt="No Data" />

            <span className={classes.emptyBoxSpan}>
              {
                allowGroupsSetting === "disabled"
                  ? i18n.t("dashboard.settings.allowGroupsDisabled")
                  : i18n.t("dashboard.emptyBox")
              }
            </span>
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default ChartTicketsPerConnection;
