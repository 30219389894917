import api from "../../services/api";

const useLabels = () => {
  const findAll = async () => {
    const { data } = await api.get(`/label`);
    return data;
  }

  return { findAll };
}

export default useLabels;