import { ResponsiveRadar } from '@nivo/radar';
import ErrorBoundary from "../../../../errors/Boundary";

const ChartTicketsPerUsers = props => {
  return (
    <ErrorBoundary>
      <ResponsiveRadar
        data={props.countedData}
        keys={[ 'Quantidade' ]}
        indexBy="queue"
        theme={{ 
          textColor: "#999",
          tooltip: { container: { background: "#999", color: "#fff" } },
        }}
        valueFormat=">-.2f"
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        borderColor={{ from: 'color' }}
        gridLabelOffset={36}
        dotSize={10}
        dotColor="#ff6961"
        dotBorderWidth={2}
        colors={"#B20009"}
        blendMode="multiply"
        motionConfig="wobbly"
        role="application"
        legends={[
          {
            anchor: 'top-left',
            direction: 'column',
            translateX: -50,
            translateY: -40,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: '#999',
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [{ on: 'hover', style: { itemOpacity: 0.8 } }],
          }
        ]}
      />
    </ErrorBoundary>
  );
};

export default ChartTicketsPerUsers;