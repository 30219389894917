import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useNewContactsCount = ({ initialDate, finalDate, contactType }) => {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    
		const delayDebounceFn = setTimeout(() => {
      const fetchNewContacts = async() => {
        try {
          const { data } = await api.get("/newContactsCount", {
            params: { initialDate, finalDate, contactType },
          });
          
					setCount(data.count);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log("Use New Contacts Count Error:", error);
          toastError(error);
        }
    	}
			
			fetchNewContacts();
  	}, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [initialDate, finalDate, contactType])

  return { loading, count };
};

export default useNewContactsCount;