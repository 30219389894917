import api from "../../services/api";

const useAllUsersList = () => {
    const findAll = async () => {
        const { data } = await api.get("/AllUsers");
        return data.users;
    }

	return { findAll };
}

export default useAllUsersList;
