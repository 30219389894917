import { useState, useEffect } from "react";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useAllUsers = () => {
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchTickets = async() => {
        try {
          const { data } = await api.get("/AllUsers")
          setAllUsers(data.users);
        } catch (error) {
          console.log("Use All Users Error:", error);
          toastError(error);
        }
      }
      fetchTickets();

    }, 500);

    return () => clearTimeout(delayDebounceFn)
  }, []);

  return { allUsers };
};

export default useAllUsers;