import { useState, useEffect } from "react";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useClosedTicketsPerQueue = ({ initialDate, finalDate, considerInternalChats }) => {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    
    const delayDebounceFn = setTimeout(() => {
      const fetchClosedTicketsPerQueue = async() => {
        try {
          const { data } = await api.get("/closedTicketsPerQueue", {
            params: { initialDate, finalDate, considerInternalChats },
          });

          setCount(data);
          setLoading(false);
        } catch (error) {
          setLoading(false)
          console.log("Use Closed Tickes Per Queue Error:", error);
          toastError(error);
        }
      }
      fetchClosedTicketsPerQueue()
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [initialDate, finalDate, considerInternalChats]);

  return { loading, count };
};

export default useClosedTicketsPerQueue;