import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Grid,
  Hidden,
  Paper,
  Tooltip,
} from "@material-ui/core";
import AudioPlayer from "material-ui-audio-player";

import { i18n } from "../../translate/i18n";
import ChatImg from '../../assets/chat.svg';
import ErrorBoundary from "../../errors/Boundary";
import Ticket from "../../components/Ticket/";
import TicketsManager from "../../components/TicketsManager/";

const useStyles = makeStyles((theme) => ({
  //  ****************************
  //  ** Audio Player Component **
  //  ****************************
  audioPlayerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.15em",
  },

  currentPlayingAudioAvatar: {
    width: "35px",
    height: "35px",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.8",
    },
  },

  root: {
    width: "85%",
    maxHeight: "70px",
    borderRadius: "5px",
    backgroundColor: theme.palette.background.default,
  },

  playIcon: {
    color: theme.palette.audioPlayer.buttons,
    '&:hover': {
      opacity: "0.8",
      color: theme.palette.audioPlayer.buttons,
    },
  },

  closeIcon: {
    color: theme.palette.audioPlayer.buttons,
    // borderRadius: "50%",
    fontSize: "25px",
    position: "relative",
    left: "5px",
    top: "-4px",
    '&:hover': {
      opacity: "0.8",
      color: theme.palette.audioPlayer.buttons,
    },
  },

  replayIcon: {
    color: theme.palette.audioPlayer.buttons,
    '&:hover': {
      opacity: "0.8",
      color: theme.palette.audioPlayer.buttons,
    },
  },

  pauseIcon: {
    color: theme.palette.audioPlayer.buttons,
    '&:hover': {
      opacity: "0.8",
      color: theme.palette.audioPlayer.buttons,
    },
  },

  volumeSlider: {
    color: theme.palette.audioPlayer.buttons,
  },

  progressTime: {
    color: theme.palette.audioPlayer.buttons,
  },

  mainSlider: {
    '& .MuiSlider-rail': {
      color: theme.palette.primary.main,
    },
    '& .MuiSlider-track': {
      color: theme.palette.primary.main,
    },
    '& .MuiSlider-thumb': {
      color: theme.palette.primary.main,
    },
  },



  //  **********************
  //  ** Ticket Container **
  //  **********************
  chatContainer: {
    padding: "0 0 0 5px",
    flex: 1,
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100%)`,
    },
  },

  chatPapper: {
    display: "flex",
    height: "100%",
    borderRadius:"20px",
  },

  contactsWrapper: {
    padding: "0 5px 0 0",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    borderRadius:"20px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    borderRadius:"20px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    borderRadius: "20px",
    overflowY: "visible",
  },
  welcomeMsg: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: "20px",
  },
  welcomeImg: {
    width: "500px",
    height: "500px",
  },
  ticket: {
    padding: "0 0 0 5px"
  },
}));

const Chat = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { ticketId } = useParams();

  const [tabOpen, setTabOpen] = useState(localStorage.getItem("tabOpenTickets"));

  const [currentPlayingAudio, setCurrentPlayingAudio] = useState(localStorage.getItem("currentPlayingAudio"));
  const [currentPlayingAudioTime, setCurrentPlayingAudioTime] = useState(localStorage.getItem("currentPlayingAudioTime"));
  const [currentPlayingAudioTicketId, setCurrentPlayingAudioTicketId] = useState(localStorage.getItem("currentPlayingAudioTicketId"));
  const [currentPlayingAudioContactProfilePic, setCurrentPlayingAudioContactProfilePic] = useState(localStorage.getItem("currentPlayingAudioContactProfilePic"));
  const [currentPlayingAudioContactName, setCurrentPlayingAudioContactName] = useState(localStorage.getItem("currentPlayingAudioContactName"));
  const [currentPlayingAudioContactId, setCurrentPlayingAudioContactId] = useState(localStorage.getItem("currentPlayingAudioContactId"));



  //  ***************
  //  ** Functions **
  //  ***************
  const setAudioPlayer = (cpa, cpaTime, cpaTicketId, cpaContactProfilePic, cpaContactName, cpaContactId) => {
    // CPA: Current Playing Audio
    setCurrentPlayingAudio(cpa);
    setCurrentPlayingAudioTime(cpaTime);
    setCurrentPlayingAudioTicketId(cpaTicketId);
    setCurrentPlayingAudioContactProfilePic(cpaContactProfilePic);
    setCurrentPlayingAudioContactName(cpaContactName);
    setCurrentPlayingAudioContactId(cpaContactId);

    localStorage.setItem("currentPlayingAudio", cpa);
    localStorage.setItem("currentPlayingAudioTime", cpaTime);
    localStorage.setItem("currentPlayingAudioTicketId", cpaTicketId);
    localStorage.setItem("currentPlayingAudioContactProfilePic", cpaContactProfilePic);
    localStorage.setItem("currentPlayingAudioContactName", cpaContactName);
    localStorage.setItem("currentPlayingAudioContactId", cpaContactId);
  };

  const resetAudioPlayer = () => {
    setCurrentPlayingAudio("");
    setCurrentPlayingAudioTime("");
    setCurrentPlayingAudioTicketId("");
    setCurrentPlayingAudioContactProfilePic("");
    setCurrentPlayingAudioContactName("");
    setCurrentPlayingAudioContactId("");

    localStorage.setItem("currentPlayingAudio", "");
    localStorage.setItem("currentPlayingAudioTime", "");
    localStorage.setItem("currentPlayingAudioTicketId", "");
    localStorage.setItem("currentPlayingAudioContactProfilePic", "");
    localStorage.setItem("currentPlayingAudioContactName", "");
    localStorage.setItem("currentPlayingAudioContactId", "");
  };

  const handleCurrentPlayingAudioTicketRedirect = () => {
    history.push(`/tickets/${currentPlayingAudioTicketId}`);
    resetAudioPlayer();
  };



  
  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={4} className={ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper}>
            <TicketsManager 
              className={classes.ticketsManager} 
              tabOpen={tabOpen}
              setTabOpen={setTabOpen}
              resetAudioPlayer={resetAudioPlayer}
            />
          </Grid>
          
          <Grid item xs={12} md={8} className={classes.messagessWrapper}>
            {currentPlayingAudio !== "" && currentPlayingAudioTicketId !== ticketId
              && currentPlayingAudioContactId !== ""
              && (localStorage.getItem("selectedTicketContactId") !== currentPlayingAudioContactId)
              && (
                <div className={classes.audioPlayerContainer}>
                  <Tooltip title={currentPlayingAudioContactName} placement="top-start" arrow>
                    <Avatar
                      alt={currentPlayingAudioContactName}
                      src={currentPlayingAudioContactProfilePic}
                      className={classes.currentPlayingAudioAvatar}
                      onClick={handleCurrentPlayingAudioTicketRedirect}
                    />
                  </Tooltip>

                  <AudioPlayer
                    src={`${currentPlayingAudio}#t=${currentPlayingAudioTime}`}
                    width={"100%"}
                    variation="default"
                    download={false}
                    volume={false}
                    autoplay={true}
                    elevation={0}
                    rounded={true}
                    spacing={0}
                    order="standart"
                    loop={false}
                    displayCloseButton={true}
                    useStyles={useStyles}
                    preload="auto"
                    time="single"
                    onFinished={resetAudioPlayer}
                    onClose={resetAudioPlayer}
                  />
                </div>
            )}


            {ticketId ? (
              <>
                <ErrorBoundary>
                  <Ticket 
                    className={classes.ticket}
                    setTabOpen={setTabOpen}
                    setAudioPlayer={setAudioPlayer}
                    setCurrentPlayingAudioTime={setCurrentPlayingAudioTime}
                  />
                </ErrorBoundary>
              </>
            ) : (
              <Hidden only={["sm", "xs"]}>
                <Paper className={classes.welcomeMsg}>
                  <img className={classes.welcomeImg} src={ChatImg} alt="" />
                  <span>{i18n.t("chat.noTicketMessage")}</span>
                </Paper>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Chat;
