import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";


import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  labelsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "5px",
  },
}));

const ContactTagsModal = ({ open, onClose, labelsList }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  *****************
  //  ** Use Effects **
  //  *****************


  

  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <div className={classes.root}>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="xs"
          fullWidth
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            <span>{i18n.t("contacts.accordion.title")}</span>
          </DialogTitle>

          <DialogContent 
            className={classes.dialogContent}
            dividers
          >
            <div className={classes.labelsContainer}>
              {labelsList.length > 0 && labelsList.map(label => (
                <span style={{ border: `2px solid ${label.color}`, padding: "7px", borderRadius: "20px" }}>
                  {label.name}
                </span>
              ))}

              {labelsList.length === 0 && (
                <Typography gutterBottom>{i18n.t("contacts.accordion.noLabels")}</Typography>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default ContactTagsModal;