import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";

const toastError = err => {
	try {
		const errorMsg = err.response?.data?.message || err.response.data.error;
		const errorComplement = err.response?.data?.complement || err.response.data.complement;

		// error predicted by developers
		if (errorMsg) {

			// error already translated
			if (i18n.exists(`backendErrors.${errorMsg}`)) {

				// error with complement
				if (errorComplement !== "" && errorComplement !== undefined && errorComplement !== null) {
					const subMessage = errorMsg === "ERR_OTHER_OPEN_TICKET" 
														? i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET_USER")
														: "";
					const message = `${i18n.t(`backendErrors.${errorMsg}`)} ${subMessage} ${errorComplement}.`;
					toast.error(message, { toastId: errorMsg });
				}

				// error without complement - special error (ERR_OTHER_OPEN_TICKET)
				else if (errorMsg === "ERR_OTHER_OPEN_TICKET") {
					toast.error(i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET_EXCEPTION"), { toastId: errorMsg });
				}

				// error without complement
				else {
					toast.error(i18n.t(`backendErrors.${errorMsg}`), { toastId: errorMsg });
				}
			} 

			// error not translated yet
			else { toast.error(errorMsg, { toastId: errorMsg }); }
		} 

		// error not predicted by developers - developers should treat when it's triggered
		else {
			console.log("- ❌ Toast Error not Treated Message:", err);
			toast.error("❌ Entre em contato com o suporte para ajuda!");
		}
	} catch (error) {
		console.log("Toast Error Exception Message:", error);
	}
};

export default toastError;
