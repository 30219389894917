import React, { useState, useContext } from "react";
import { Menu, MenuItem } from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import api from "../../services/api";
import cleanTextMessage from "../../utils/cleanTextMessage";
import ConfirmationModal from "../ConfirmationModal";
import textToSpeech from "../../utils/textToSpeech";
import toastError from "../../errors/toastError";


const MessageOptionsMenu = ({
  message, ticketType, menuOpen, handleClose, anchorEl, onUpdate,
  updateIsForwarding, handleOpenMessageEditModal, handleOpenMessagePropertiesModal,
  ticketStatus
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { user: { profile: userProfile, id: userId } } = useContext(AuthContext);
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);



  //  **********************
  //  ** Allow Conditions **
  //  **********************

  // ***---- User Conditions ----***
  const userIdOrAdmin = message.userId === userId || userProfile === "admin";
  const notTicketPendingAndUserNotAdmin = !(ticketStatus === "pending" && userProfile === "user");



  // ***---- Actions Conditions ----***
  const allowDelete = userIdOrAdmin
    && (
      (
        message.fromMe
        && !message.isDeleted
        && (ticketType === 0 || ticketType === 1)
        && notTicketPendingAndUserNotAdmin
        && !["call", "transference_observation"].includes(message.mediaType)
      )
      || (ticketType === 3 && message.userId === userId)
    );

  const allowEdit = userIdOrAdmin
    && (
      (
        ticketType === 0
        && message.fromMe
        && message.ack !== 0 && message.ack !== 1
        && notTicketPendingAndUserNotAdmin
        && !message.isViewOnce
        && [
          // "application",
          "chat",
          // "image",
          "transference_observation",
          // "video"
        ].includes(message.mediaType)
      )
      || (
        ticketType === 1
        && ["application", "chat", "image", "video"].includes(message.mediaType)
      )
      || (
        ticketType === 3
        && message.userId === userId
        && ["application", "chat", "image", "video"].includes(message.mediaType)
      )
    );

  const allowReplyAndForward = (
      (ticketType === 0 && message.ticket !== undefined)
      || ticketType === 1
      || ticketType === 3
    )
    && (!message.fromMe || (message.fromMe && message.ack !== 0 && message.ack !== 9))
    && notTicketPendingAndUserNotAdmin
    && ![
      "button_creation",
      "button_response",
      "call",
      "list_creation",
      "list_response",
      "transference_observation"
    ].includes(message.mediaType);

  const allowForward = (ticketType === 0 || ticketType === 3)
    && !message.isViewOnce
    && !["group_invite", "multi_vcard", "poll_creation"].includes(message.mediaType);

  const allowCopy = message.body !== ""
    && [
      "application",
      "call",
      "chat",
      "image",
      "transference_observation",
      "video"
    ].includes(message.mediaType);

  const allowStarAndUnstar = message.ticket !== undefined
    && ticketType === 0
    && notTicketPendingAndUserNotAdmin
    && !message.isViewOnce
    && ![
      "button_creation",
      "button_response",
      "call",
      "list_creation",
      "list_response",
      "transference_observation"
    ].includes(message.mediaType);

  const allowSpeech = message.ticket !== undefined
    && [
      "button_creation",
      "button_response",
      "chat",
      "list_creation",
      "list_response",
      "transference_observation"
    ].includes(message.mediaType);

  const allowProperties = message.ticket !== undefined && message.createdAt !== undefined;



  //  ***************
  //  ** Functions **
  //  ***************
  const handleDeleteMessage = async () => {
    try { await api.delete(`/messages/${message.id}`); }
    catch (error) {
      console.log("Handle Delete Message Error:", error);
      toastError(error);
    }
  };

  const handleReplyMessage = () => {
    setReplyingMessage(message);
    handleClose();
  };

  const handleEditMessage = async () => {
    handleClose();

    try {
      await api.put(`/messagesValidateEdit/${message.id}`, { ticketType });
      handleOpenMessageEditModal();
    } catch(error) {
      console.log("Handle Edit Message Error:", error);
      toastError(error);
    }
  };

  const handleForwardMessage = () => {
    onUpdate(true);
    updateIsForwarding(true);
    handleClose();
  }

  const handleStarMessage = async () => {
    try {
      handleClose();
      await api.put(`/messages/${message.id}`);
    } catch (error) {
      console.log("Handle Star Message Error:", error);
      toastError(error);
    }
  };

  const handleSpeechMessage = () => {
    handleClose();
    textToSpeech(message.body);
  };

  const handlePropertiesMessage = () => {
    handleClose();
    handleOpenMessagePropertiesModal(message);
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
    handleClose();
  };

  const getTextSelection = () => {
    if (!window.getSelection) return "";
    return window.getSelection().toString();
  };

  const getTextMessageBodyWithoutSignature = (message) => {
    const bodySplit = message.body.split("\n");
    const signatureRegex = /^\*[^*]*\*$/;
    if (signatureRegex.test(bodySplit[0])) bodySplit.shift();
    return bodySplit.join("\n");
  };

  const chooseTextToCopy = (message) => {
    return getTextSelection() === ""
      ? cleanTextMessage(getTextMessageBodyWithoutSignature(message))
      : cleanTextMessage(getTextSelection());
  };

  const handleCopyMessage = async () => {
    handleClose();
    
    const textToCopy = chooseTextToCopy(message);

    await navigator.clipboard.writeText(textToCopy);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      {/* ***---- Confirmation Modal for Deleting Messages ----*** */}
      <ConfirmationModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteMessage}
      >{i18n.t("messageOptionsMenu.confirmationModal.message")}</ConfirmationModal>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        transformOrigin={{vertical: "top", horizontal: "right"}}
        open={menuOpen}
        onClose={handleClose}
        onContextMenu={event => event.preventDefault()}
      >
        {/* ***---- Action: Delete ----*** */}
        {allowDelete && (
          <MenuItem onClick={handleOpenConfirmationModal}>{i18n.t("messageOptionsMenu.delete")}</MenuItem>
        )}

        {/* ***---- Action: Edit ----*** */}
        {allowEdit && (
          <MenuItem onClick={handleEditMessage}>{i18n.t("messageOptionsMenu.edit")}</MenuItem>
        )}

        {/* ***---- Action: Reply and Forward ----*** */}
        {allowReplyAndForward && (
          <>
            <MenuItem onClick={handleReplyMessage}>{i18n.t("messageOptionsMenu.reply")}</MenuItem>

            {allowForward && (
              <MenuItem onClick={handleForwardMessage}>{i18n.t("messageOptionsMenu.forward")}</MenuItem>
            )}
          </>
        )}

        {/* ***---- Action: Copy ----*** */}
        {allowCopy && (
          <MenuItem onClick={handleCopyMessage}>{i18n.t("messageOptionsMenu.copy")}</MenuItem>
        )}

        {/* ***---- Action: Star ----*** */}
        {allowStarAndUnstar && !message.isStarred && (
          <MenuItem onClick={handleStarMessage}>{i18n.t("messageOptionsMenu.star")}</MenuItem>
        )}

        {/* ***---- Action: Unstar ----*** */}
        {allowStarAndUnstar && message.isStarred && (
          <MenuItem onClick={handleStarMessage}>{i18n.t("messageOptionsMenu.unstar")}</MenuItem>
        )}

        {/* ***---- Action: Speech ----*** */}
        {allowSpeech && (
          <MenuItem onClick={handleSpeechMessage}>{i18n.t("messageOptionsMenu.speech")} {speechSynthesis.speaking ? "❌" : "✔️"}</MenuItem>
        )}

        {/* ***---- Action: Properties ----*** */}
        {allowProperties && (
          <MenuItem onClick={handlePropertiesMessage}>{i18n.t("messageOptionsMenu.properties")}</MenuItem>
        )}

        {/* ***---- Action: Close ----*** */}
        <MenuItem onClick={handleClose}>{i18n.t("messageOptionsMenu.close")}</MenuItem>
      </Menu>
    </>
  );
};

export default MessageOptionsMenu;
