import React from "react";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(theme => ({
  //  **********
  //  ** Slot **
  //  **********
  contactSlot: {
    border: `2px solid ${theme.palette.primary.pastel}`,
		borderRadius: "20px",
    padding: "5px",
    width: "250px",
		minHeight: "150px",
    maxHeight: "150px",
		transition: 'transform 0.3s',
		opacity: "0.90",

    display: "flex",
    flexDirection: "row",

		'&:hover': {
			transform: "translateY(-5px)",
			opacity: "1",
    },
  },

  profilePicContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    borderRadius: "20px 0px 0px 20px",
    padding: "10px",
  },



  //  **********
  //  ** Info **
  //  **********
  infoContainer: {
    width: "100%",
    borderRadius: "0px 20px 20px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  contactInfoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "5px",

    width: "100%",
    height: "70%",

    borderRadius: "0px 20px 0px 0px",
  },



  //  *************
  //  ** Buttons **
  //  *************
  actionButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "2px",

    width: "100%",
    height: "30%",
    borderRadius: "0px 0px 20px 0px",
  },
}));

const CardContactSkeleton = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.contactSlot}>
      
      {/* Profile Picture */}
      <div className={classes.profilePicContainer}>
        <Skeleton animation="wave" variant="circle" width={40} height={40} />
      </div>

      {/* Info Container */}
      <div className={classes.infoContainer}>

        {/* Contact Info */}
        <div className={classes.contactInfoContainer}>
          <Skeleton align="center" animation="wave" height={30} width={"95%"} />
          <Skeleton align="center" animation="wave" height={30} width={"95%"} />
          <Skeleton align="center" animation="wave" height={30} width={"95%"} />
        </div>

        {/* Action Buttons */}
        <div className={classes.actionButtonsContainer}>
          <Skeleton animation="wave" variant="circle" width={30} height={30} />
          <Skeleton animation="wave" variant="circle" width={30} height={30} />
        </div>
      </div>
    </div>
  );
}

export default CardContactSkeleton;