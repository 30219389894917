import React, { useEffect } from 'react';
import { Button, Divider, Typography } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const LocationPreview = ({ image, link, description }) => {
	// 	*****************
	// 	** Use Effects **
	// 	*****************
  useEffect(() => {}, [image, link, description]);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleLocation = async() => {
		try { window.open(link); }
		catch (error) {
			console.log("Handle Location Error:", error);
			toastError(error);
		}
	}



	// 	************
	// 	** Return **
	// 	************

	return (
		<div style={{ minWidth: "250px" }}>
			<div>
				<div style={{ float: "left" }}>
					<img src={image} onClick={handleLocation} style={{ width: "100px" }} alt="Localization on WhatsApp" />
				</div>

				{description && (
					<div style={{ display: "flex", flexWrap: "wrap" }}>
						<Typography style={{ marginTop: "12px", marginLeft: "15px", marginRight: "15px", float: "left" }} variant="subtitle1" color="inherit" gutterBottom>
							<div dangerouslySetInnerHTML={{ __html: description.replace('\\n', '<br />') }}></div>
						</Typography>
					</div>
				)}

				<div style={{ display: "block", content: "", clear: "both" }}></div>

				<div>
					<Divider />
					<Button
						fullWidth
						color="inherit"
						onClick={handleLocation}
						disabled={!link}
					>
						{i18n.t("locationPreview.viewButton")}
					</Button>
				</div>
			</div>
		</div>
	);

};

export default LocationPreview;