import { useState, useEffect } from "react";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useTimePerUsers = ({ initialDate, finalDate, considerInternalChats }) => {
  const [data, setData] = useState(0);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async() => {
        try {
          const data = await api.get("/totalTimePerUsers", {
            params: { initialDate, finalDate, considerInternalChats },
          });

          setData(data);
        } catch (error) {
          console.log("Use Total Time Per Users Error:", error);
          toastError(error);
        }
      }

      fetchMessages();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [initialDate, finalDate, considerInternalChats]);

  return data;
};

export default useTimePerUsers;