import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  messageQuickAnswersWrapper: {
    margin: 0,
    position: "absolute",
    bottom: "50px",
    background: theme.palette.background.paper,
    padding: "2px",
    border: "1px solid",
    borderColor: theme.palette.background.paper,
    left: 0,
    width: "100%",
    maxHeight: "150px",
    overflowY: "auto",
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxHeight: "32px",
        "&:hover": {
          backgroundColor: theme.palette.background.default,
          cursor: "pointer",
        },
      },
    },
  },

  messageQuickAnswersWrapperItemAnchor: {
    color: theme.palette.text.primary,
  },
}));

const QuickAnswersList = ({
  quickAnswers,
  handleQuickAnswersClick,
  typeBar,
  setTypeBar,
  filter
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [structuredFilter, setStructuredFilter] = useState("");



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (typeBar) {
      setStructuredFilter(filter.substring(1));

      window.addEventListener("click", (event) => {
        const quickAnswersList = document.getElementById("quickAnswersList");
        
        const shouldHideAnswersList = quickAnswersList !== null
          && quickAnswersList !== undefined
          && !(quickAnswersList.contains(event.target))
  
        // Quick Answers List is hidden when:
        //  - is visible AND;
        //  - user clicked outside the list.
        if (shouldHideAnswersList) setTypeBar(false);
      });
    }
  }, [filter, setTypeBar, typeBar]);



  //  ************
  //  ** Return **
  //  ************
  return (
    typeBar
      ? (
        <ul id="quickAnswersList" className={classes.messageQuickAnswersWrapper}>
          {quickAnswers.map((value, index) => {
            const lowerQuickAnswerShortcut = value.shortcut.toLowerCase();
            const lowerFilter = structuredFilter.toLowerCase();

            if (lowerFilter !== "" && !lowerQuickAnswerShortcut.includes(lowerFilter)) return <></>;

            return (
              <li key={index}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className={classes.messageQuickAnswersWrapperItemAnchor} onClick={() => handleQuickAnswersClick(value.message)}>
                  {`${value.shortcut} - ${value.message}`}
                </a>
              </li>
            )
          })}
        </ul>
      )
      : (
        <div></div>
      )
  );  
};

export default QuickAnswersList;
