import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  useMediaQuery
} from "@material-ui/core";
import { CancelOutlined, CheckCircleOutlineOutlined } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import iconFieldAndFolder from '../../assets/file_and_folder.png';
import toastError from "../../errors/toastError";
import truncateString from "../../utils/truncateString";

const useStyles = makeStyles((theme) => ({
  //  ********************
  //  ** Main Container **
  //  ********************
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "25px",

    padding: "1.5em",
    background: theme.backgroundImage,
  },



  //  *************************
  //  ** File View Container **
  //  *************************
  fileViewContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  fileView: {
    width: "100%",
    borderRadius: "5px",
    userSelect: "none",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  fileIcon: {
    width: "100px",
    filter: "grayscale(.9)",
    opacity: "0.9",
    userDrag: "none",
  },

  fileSpan: {
    color: theme.palette.text.primary,
    textAlign: "center",
    opacity: "0.9",
  },

  fileName: {
    opacity: "0.9",
    textAlign: "center",
    marginTop: "1em",
  },



  //  *************************
  //  ** File Name Container **
  //  *************************
  fileNameContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },



  //  ******************
  //  ** Legend Field **
  //  ******************
  legendFieldContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "1em",
  },

  legendIcons: { borderRadius: "20px", color: "grey" },
}));

const ViewOnceFileContextModal = ({open, onClose, media, setMedia}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const { ticketId } = useParams();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [fileLegend, setFileLegend] = useState("");

  const [mediaTreatment, setMediaTreatment] = useState(3);
  const [mediaUrl, setMediaUrl] = useState("");

  

  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (media && media !== undefined) setMediaUrl(URL.createObjectURL(media));
  }, [media]);

  useEffect(() => {
    return () => URL.revokeObjectURL(mediaUrl);
  }, [mediaUrl]);

  useEffect(() => {
    if (media && media !== undefined) {
      // 
      // Codes:
      // 
      // - 1 > image (img tag)
      // - 2 > video (video tag)
      // - 3 > other file type (general visualization)
      let treatment;
      const mediaType = media.type.split("/")[0];

      if (isSmallScreen) { treatment = 3; }
      else if (mediaType === "image") { treatment = 1; }
      else if (mediaType === "video") { treatment = 2; }
      else { treatment = 3; }
      setMediaTreatment(treatment);
    }
  }, [media, isSmallScreen]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
    setFileLegend("");
    setMedia(null);

    setMediaTreatment([]);
    setMediaUrl("");

    document.getElementById("upload-view-once-button").value = "";
  };

  const handleUploadMedia = async (file) => {
    handleClose();

    try {
      const formData = new FormData();
      formData.append("fromMe", true);

      if (file.size > 8000000 * 15) {
        toast.error(i18n.t("backendErrors.ERR_FILE_SIZE_UPLOAD"));
      } else {
        formData.append("medias", file);
        formData.append("body",  fileLegend);
        formData.append("isNotVoice", true);
        formData.append("isViewOnce", true);
        await api.post(`/messages/${ticketId}`, formData);
      }
    } catch (error) {
      console.log("Handle Upload Media Error:", error);
      toastError(error);
    }
  };

  const handleFileLegend = (event) => {
    event.target.value.replaceAll("\n", "") === ""
      ? setFileLegend("")
      : setFileLegend(event.target.value);
  };

  const renderMediaTreatment = (media) => {
    return (
      <Tooltip title={media.name} placement="top-start" arrow>
        <span>{truncateString(media.name, 50)}</span>
      </Tooltip>
    );
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" scroll="paper" fullWidth>
      <DialogTitle>
        <span>{i18n.t("viewOnceFileContextModal.title.up")}</span>
      </DialogTitle>

      <div className={classes.dialogContent}>

        {/* 
          ***************
          ** File View **
          ***************
        */}
        <div className={classes.fileViewContainer}>
          {mediaTreatment === 1 && (<img className={classes.fileView} alt="📁" src={mediaUrl} />)}
          {mediaTreatment === 2 && (<video className={classes.fileView} controls><source src={mediaUrl} type={media.type} /></video>)}

          {mediaTreatment === 3 && (
            <div className={classes.fileView}>
              <img className={classes.fileIcon} src={iconFieldAndFolder} alt="Icon" />
              <span className={classes.fileSpan}>{i18n.t("fileContextModal.noVisualizationMessage")}</span>
            </div>)
          }
        </div>
        
        

        {/* 
          ***************
          ** File Name **
          ***************
        */}
        <div className={classes.fileNameContainer}>
          {media && media !== undefined && renderMediaTreatment(media)}
        </div>



        <Divider />



        {/* 
          ******************
          ** Legend Field **
          ******************
        */}
        <div className={classes.legendFieldContainer}>
          <Tooltip title={i18n.t("fileContextModal.tooltips.cancel")} placement="top-start" arrow>
            <IconButton onClick={handleClose}>
              <CancelOutlined className={classes.legendIcons} />
            </IconButton>
          </Tooltip>

          <TextField
            autoFocus
            focused
            fullWidth
            multiline

            label={i18n.t("fileContextModal.textfield")}
            margin="dense"
            maxRows={3}
            name="fileLegend"
            type="text"
            value={fileLegend}
            variant="outlined"

            onChange={handleFileLegend}
            onKeyDown={(e) => { e.key === "Enter" && !e.shiftKey && handleUploadMedia(media); }}
          />

          <Tooltip title={i18n.t("fileContextModal.tooltips.confirm")} placement="top-start" arrow>
            <IconButton onClick={() => handleUploadMedia(media)}>
              <CheckCircleOutlineOutlined className={classes.legendIcons} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Dialog>
  );
};

export default ViewOnceFileContextModal;