const formatPhoneNumberVCards = (number) => {
  let formattedNumber = number.replace(/\D/g, "");

  if (formattedNumber.startsWith("155") && formattedNumber.length > 11) {
    formattedNumber = formattedNumber.replace(/^155/, "55");
  }

  return formattedNumber;
};

export default formatPhoneNumberVCards;