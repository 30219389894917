import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tab,
  Tabs,
} from '@material-ui/core';
import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import splitStickerName from "../../utils/splitStickerName";


//  **************
//  ** Stickers **
//  **************
// ***---- Christmas ----***
import christmasAngry from "../../assets/stickers/christmas/angry.png";
import christmasAnnouncemnet from "../../assets/stickers/christmas/announcement.png";
import christmasBoring from "../../assets/stickers/christmas/boring.png";
import christmasEat from "../../assets/stickers/christmas/eat.png";
import christmasElf from "../../assets/stickers/christmas/elf.png";

import christmasFever from "../../assets/stickers/christmas/fever.png";
import christmasFlower from "../../assets/stickers/christmas/flower.png";
import christmasFull from "../../assets/stickers/christmas/full.png";
import christmasHi from "../../assets/stickers/christmas/hi.png";
import christmasHooray from "../../assets/stickers/christmas/hooray.png";

import christmasHug from "../../assets/stickers/christmas/hug.png";
import christmasLol from "../../assets/stickers/christmas/lol.png";
import christmasLove from "../../assets/stickers/christmas/love.png";
import christmasMocking from "../../assets/stickers/christmas/mocking.png";
import christmasNo from "../../assets/stickers/christmas/no.png";

import christmasOk from "../../assets/stickers/christmas/ok.png";
import christmasQuestion from "../../assets/stickers/christmas/question.png";
import christmasSad from "../../assets/stickers/christmas/sad.png";
import christmasShocked from "../../assets/stickers/christmas/shocked.png";
import christmasSleep from "../../assets/stickers/christmas/sleep.png";


// ***---- Default ----***
import defaultAmazed from "../../assets/stickers/default/amazed.png";
import defaultAngel from "../../assets/stickers/default/angel.png";
import defaultAngry from "../../assets/stickers/default/angry.png";
import defaultAnnoyed from "../../assets/stickers/default/annoyed.png";
import defaultCool from "../../assets/stickers/default/cool.png";

import defaultCry from "../../assets/stickers/default/cry.png";
import defaultDead from "../../assets/stickers/default/dead.png";
import defaultDevil from "../../assets/stickers/default/devil.png";
import defaultDizzy from "../../assets/stickers/default/dizzy.png";
import defaultExcited from "../../assets/stickers/default/excited.png";

import defaultHi from "../../assets/stickers/default/hi.png";
import defaultHungry from "../../assets/stickers/default/hungry.png";
import defaultInLove from "../../assets/stickers/default/in-love.png";
import defaultLove from "../../assets/stickers/default/love.png";
import defaultMute from "../../assets/stickers/default/mute.png";

import defaultPanic from "../../assets/stickers/default/panic.png";
import defaultParty from "../../assets/stickers/default/party.png";
import defaultSick from "../../assets/stickers/default/sick.png";
import defaultSinging from "../../assets/stickers/default/singing.png";
import defaultSleepy from "../../assets/stickers/default/sleepy.png";

// ***---- Dracula ----***
import draculaAngry from "../../assets/stickers/dracula/angry.png";
import draculaBirthday from "../../assets/stickers/dracula/birthday.png";
import draculaEat from "../../assets/stickers/dracula/eat.png";
import draculaHappy from "../../assets/stickers/dracula/happy.png";
import draculaHi from "../../assets/stickers/dracula/hi.png";

import draculaLove from "../../assets/stickers/dracula/love.png";
import draculaMock from "../../assets/stickers/dracula/mock.png";
import draculaNo from "../../assets/stickers/dracula/no.png";
import draculaPeace from "../../assets/stickers/dracula/peace.png";
import draculaProud from "../../assets/stickers/dracula/proud.png";

import draculaRelaxed from "../../assets/stickers/dracula/relaxed.png";
import draculaRun from "../../assets/stickers/dracula/run.png";
import draculaSad from "../../assets/stickers/dracula/sad.png";
import draculaSick from "../../assets/stickers/dracula/sick.png";
import draculaSing from "../../assets/stickers/dracula/sing.png";

import draculaStuffed from "../../assets/stickers/dracula/stuffed.png";
import draculaSurprised from "../../assets/stickers/dracula/surprised.png";
import draculaYank from "../../assets/stickers/dracula/yank.png";
import draculaYes from "../../assets/stickers/dracula/yes.png";
import draculaYoga from "../../assets/stickers/dracula/yoga.png";

// ***---- Penguin ----***
import penguinAngry from "../../assets/stickers/penguin/angry.png";
import penguinBirthday from "../../assets/stickers/penguin/birthday.png";
import penguinBirthday2 from "../../assets/stickers/penguin/birthday2.png";
import penguinDrink from "../../assets/stickers/penguin/drink.png";
import penguinEat from "../../assets/stickers/penguin/eat.png";

import penguinFish from "../../assets/stickers/penguin/fish.png";
import penguinFishing from "../../assets/stickers/penguin/fishing.png";
import penguinHappy from "../../assets/stickers/penguin/happy.png";
import penguinHi from "../../assets/stickers/penguin/hi.png";
import penguinLaugh from "../../assets/stickers/penguin/laugh.png";

import penguinLove from "../../assets/stickers/penguin/love.png";
import penguinPenguin from "../../assets/stickers/penguin/penguin.png";
import penguinPlay from "../../assets/stickers/penguin/play.png";
import penguinSad from "../../assets/stickers/penguin/sad.png";
import penguinSick from "../../assets/stickers/penguin/sick.png";

import penguinSit from "../../assets/stickers/penguin/sit.png";
import penguinSki from "../../assets/stickers/penguin/ski.png";
import penguinTravel from "../../assets/stickers/penguin/travel.png";
import penguinWalk from "../../assets/stickers/penguin/walk.png";
import penguinWarm from "../../assets/stickers/penguin/warm.png";

// ***---- Easter ----***
import easterAngry from "../../assets/stickers/easter/angry.png";
import easterBirthday from "../../assets/stickers/easter/birthday.png";
import easterBored from "../../assets/stickers/easter/bored.png";
import easterEat from "../../assets/stickers/easter/eat.png";
import easterFever from "../../assets/stickers/easter/fever.png";

import easterFull from "../../assets/stickers/easter/full.png";
import easterHappy from "../../assets/stickers/easter/happy.png";
import easterHi from "../../assets/stickers/easter/hi.png";
import easterLove from "../../assets/stickers/easter/love.png";
import easterMocking from "../../assets/stickers/easter/mocking.png";

import easterNo from "../../assets/stickers/easter/no.png";
import easterPeace from "../../assets/stickers/easter/peace.png";
import easterProud from "../../assets/stickers/easter/proud.png";
import easterRelaxed from "../../assets/stickers/easter/relaxed.png";
import easterRun from "../../assets/stickers/easter/run.png";

import easterSad from "../../assets/stickers/easter/sad.png";
import easterShocked from "../../assets/stickers/easter/shocked.png";
import easterSing from "../../assets/stickers/easter/sing.png";
import easterYes from "../../assets/stickers/easter/yes.png";
import easterYoga from "../../assets/stickers/easter/yoga.png";


// ***---- Halloween ----***
import halloweenAngry from "../../assets/stickers/halloween/angry.png";
import halloweenBirthday from "../../assets/stickers/halloween/birthday.png";
import halloweenEat from "../../assets/stickers/halloween/eat.png";
import halloweenFalling from "../../assets/stickers/halloween/falling.png";
import halloweenFever from "../../assets/stickers/halloween/fever.png";

import halloweenFull from "../../assets/stickers/halloween/full.png";
import halloweenHappy from "../../assets/stickers/halloween/happy.png";
import halloweenHappy2 from "../../assets/stickers/halloween/happy2.png";
import halloweenHi from "../../assets/stickers/halloween/hi.png";
import halloweenHi2 from "../../assets/stickers/halloween/hi2.png";

import halloweenLove from "../../assets/stickers/halloween/love.png";
import halloweenMocking from "../../assets/stickers/halloween/mocking.png";
import halloweenNo from "../../assets/stickers/halloween/no.png";
import halloweenRelaxed from "../../assets/stickers/halloween/relaxed.png";
import halloweenRun from "../../assets/stickers/halloween/run.png";

import halloweenSad from "../../assets/stickers/halloween/sad.png";
import halloweenSing from "../../assets/stickers/halloween/sing.png";
import halloweenYawn from "../../assets/stickers/halloween/yawn.png";
import halloweenYes from "../../assets/stickers/halloween/yes.png";
import halloweenYoga from "../../assets/stickers/halloween/yoga.png";


const useStyles = makeStyles(theme => ({

  // *************
  // ** Content **
  // *************
  dialogContent: { overflowY: "scroll", ...theme.scrollbarStyles, },

  stickersContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },

  sticker: {
    cursor: "pointer",
    width: 75,
    height: 75,
    transition: "transform 0.3s",
    "&:hover": { transform: "translateY(-5px)", },
  },
}));

const StickersModal = ({ open, onClose, ticketId, handleIsSendingSticker }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [stickerTab, setStickerTab] = useState("dracula");
  const [stickersList, setStickersList] = useState({
    "dracula" : [draculaAngry, draculaBirthday, draculaEat,
          draculaHappy, draculaHi, draculaLove,
          draculaMock, draculaNo, draculaPeace,
          draculaProud, draculaRelaxed, draculaRun,
          draculaSad, draculaSick, draculaSing,
          draculaStuffed, draculaSurprised, draculaYank,
          draculaYes, draculaYoga],
    "penguin": [penguinAngry, penguinBirthday, penguinBirthday2,
                penguinDrink, penguinEat, penguinFish,
                penguinFishing, penguinHappy, penguinHi,
                penguinLaugh, penguinLove, penguinPenguin,
                penguinPlay, penguinSad, penguinSick,
                penguinSit, penguinSki, penguinTravel,
                penguinWalk, penguinWarm],
    "halloween" : [halloweenAngry, halloweenBirthday, halloweenEat,
             halloweenFalling, halloweenFever, halloweenFull,
             halloweenHappy, halloweenHappy2, halloweenHi,
             halloweenHi2, halloweenLove, halloweenMocking,
             halloweenNo, halloweenRelaxed, halloweenRun,
             halloweenSad, halloweenSing, halloweenYawn, 
             halloweenYes, halloweenYoga],
    "easter" : [easterAngry, easterBirthday, easterBored,
            easterEat, easterFever, easterFull,
          easterHappy, easterHi, easterLove,
          easterMocking, easterNo, easterPeace,
            easterProud, easterRelaxed, easterRun,
          easterSad, easterShocked, easterSing,
          easterYes, easterYoga],
    "default" : [defaultAmazed, defaultAngel, defaultAngry,
           defaultAnnoyed, defaultCool, defaultCry,
           defaultDead, defaultDevil, defaultDizzy,
           defaultExcited, defaultHi, defaultHungry,
           defaultInLove, defaultLove, defaultMute,
           defaultPanic, defaultParty, defaultSick,
           defaultSinging, defaultSleepy],
    "christmas" : [christmasAngry, christmasAnnouncemnet, christmasBoring,
             christmasEat, christmasElf, christmasFever,
             christmasFlower, christmasFull, christmasHi,
             christmasHooray, christmasHug, christmasLol,
             christmasLove, christmasMocking, christmasNo,
             christmasOk, christmasQuestion, christmasSad,
             christmasShocked, christmasSleep]
  });


  
  //  *****************
  //  ** Use Effects **
  //  *****************




  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
  };

  const handleChange = (event, newTab) => {
    setStickerTab(newTab);
  };

  const sendSticker = (selectedSticker) => {
    handleIsSendingSticker();

    fetch(selectedSticker)
    .then(response => response.blob())
    .then(async blob => {
      try {
        const formData = new FormData();
        formData.append("medias", blob, "sticker.png");
        onClose();
        await api.post(`/stickers/${ticketId}`, formData);
        handleIsSendingSticker();
      } catch (err) {
        toast.error(i18n.t("stickersModal.toasts.sendSticker"));
        handleIsSendingSticker();
      }
    });
  };

  const renderStickerLists = () => {
    return Object.keys(stickersList).map(key => {
      if (stickerTab === key) {
        return (
          <div className={classes.stickersContainer}>
            {stickersList[key].map(sticker => (
              <img
                className={classes.sticker}
                onClick={() => sendSticker(sticker)}
                src={sticker}
                alt={splitStickerName(sticker)}
              />
            ))}
          </div>
        );
      }
      return null;
    });
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          <span>{i18n.t("stickersModal.title.up")}</span>
        </DialogTitle>

        <DialogContent 
          className={classes.dialogContent}
          dividers
        >

          <Tabs 
            value={stickerTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={i18n.t("stickersModal.tabBar.dracula")} value="dracula" />
            <Tab label={i18n.t("stickersModal.tabBar.penguin")} value="penguin" />
            <Tab label={i18n.t("stickersModal.tabBar.pumpkin")} value="halloween" />
            <Tab label={i18n.t("stickersModal.tabBar.bunny")} value="easter" />
            <Tab label={i18n.t("stickersModal.tabBar.bot")} value="default" />
            <Tab label={i18n.t("stickersModal.tabBar.elf")} value="christmas" />
          </Tabs>

        
          {renderStickerLists()}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default StickersModal;