import { useEffect } from "react";
import { toast } from "react-toastify";

import { i18n } from "../../../../translate/i18n";
import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketTicket = ({
  handleBack,
  history,
  setContact,
  setLastKnownPresenceCode,
  setLastSeenTimestamp,
  setTicket,
  ticketId
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { socket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    if (ticketId) socket.emit("joinTicket", ticketId);

    const handleTicketUpdate = (data) => {
      if (data.ticketId.toString() === ticketId.toString() && `${api.defaults.headers.tenantId}` === `${data.tenantId}`) {
        handleBack(data.userId, data.userIdInternalChat);
      }
    };

    const handleTicketPresence = (data) => {
      if (data.action === "update" && ticketId === data.ticketId && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
        setLastKnownPresenceCode(data.lastKnownPresenceCode);
        setLastSeenTimestamp(data.lastSeenTimestamp === null ? null : parseInt(data.lastSeenTimestamp, 10));
      }
    };

    const handleTicket = (data) => {
      if (data.action === "updateTicket" && data.ticket.id.toString() === ticketId.toString() && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
        setTicket(data.ticket);
      }

      if (data.action === "deleteTicketDatabase" && data.ticketId.toString() === ticketId.toString() && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
        toast.success(`${i18n.t("tickets.toasts.delete")}`);
        history.push("/tickets");
      }
    };

    const handleContact = (data) => {
      if (data.action === "update" && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
        setContact((prevState) => {
          if (prevState !== null && prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    };

    socket.on("ticketUpdate", handleTicketUpdate);
    socket.on("ticketPresence", handleTicketPresence);
    socket.on("ticket", handleTicket);
    socket.on("contact", handleContact);

    return () => {
      socket.off("ticketUpdate", handleTicketUpdate);
      socket.off("ticketPresence", handleTicketPresence);
      socket.off("ticket", handleTicket);
      socket.off("contact", handleContact);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleBack,
    history,
    setContact,
    setLastKnownPresenceCode,
    setLastSeenTimestamp,
    setTicket,
    socket,
    ticketId
  ]);


  
  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketTicket;