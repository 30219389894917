import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { TripOriginOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  messageMedia: {
    cursor: "pointer",
    objectFit: "cover",
    transition: "all 0.3s",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  messageMediaFullScreen: {
    transition: "all 0.30s",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  deletedMediaMessage: {
    opacity: 0.7,
    filter: "grayscale(80%)",
  },

  spoilerSpan: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",

    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "2",
    cursor: "pointer",
  },

  deletedMediaSpoilerSpan: {
    opacity: 0.7,
    filter: "grayscale(80%)",
  },
}));

const ViewOnceMessage = ({ message, isPhoto }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    document.addEventListener("fullscreenchange", () => {
      setIsFullscreen(!!document.fullscreenElement);
    });

    return () => {
      document.removeEventListener("fullscreenchange", setIsFullscreen(!!document.fullscreenElement));
    };
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleViewOnceImageVisibility = () => { setIsVisible((prevValue) => !prevValue); };
  
  const handleViewOnceVideoVisibility = () => {
    setIsVisible((prevValue) => !prevValue);

    setTimeout(() => {
      const video = document.getElementById(`viewOnceVideo-${message.id}`);
      if (!isVisible) video.play();
      else video.pause();
    }, 300);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div style={{position: "relative"}}>
      {/*
        *******************
        ** Spoiler Alert **
        *******************
      */}
      {!isVisible && (
        <span
          className={message.isDeleted ? `${classes.spoilerSpan} ${classes.deletedMediaSpoilerSpan}` : classes.spoilerSpan}
          onClick={() => {
            if (!isPhoto) handleViewOnceVideoVisibility();
            else handleViewOnceImageVisibility();
          }}
        >
          <TripOriginOutlined /> Spoiler
        </span>
      )}

      {/* 
        ************************
        ** Not Visible: Video **
        ************************
      */}
      {!isPhoto && (
        <video
          id={`viewOnceVideo-${message.id}`}
          className={
            message.isDeleted
              ? isFullscreen
                ? `${classes.messageMediaFullScreen} ${classes.deletedMediaMessage}`
                : `${classes.messageMedia} ${classes.deletedMediaMessage}`
              : isFullscreen
                ? classes.messageMediaFullScreen
                : classes.messageMedia
          }
          src={message.mediaUrl}
          controls={isVisible}
          autoPlay={false}
          muted={false}
          loop={false}
          style={{ filter: !isVisible ? "blur(18px)" : "blur(0px)" }}
          onClick={handleViewOnceVideoVisibility}
        />
      )}



      {/* 
        ***********
        ** Image **
        ***********
      */}
      {isPhoto && (
        <img
          className={message.isDeleted ? `${classes.messageMedia} ${classes.deletedMediaMessage}` : classes.messageMedia}
          src={message.mediaUrl}
          alt={message.body}
          style={{ filter: !isVisible ? "blur(18px)" : "blur(0px)" }}
          onClick={handleViewOnceImageVisibility}
        />
      )}
    </div>
  );
};

export default ViewOnceMessage;