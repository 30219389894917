import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, FormGroup, FormControlLabel, makeStyles, Switch } from "@material-ui/core";
import { ptBR } from "@material-ui/core/locale";

import whatsBackgroundLight from "../src/assets/wa-background.png";
import whatsBackgroundDark from "../src/assets/wa-background-black.png";

const useStyles = makeStyles(() => ({
  switch: { margin: "2px", position: 'absolute', right: 0, display: "none", },
}));

const App = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [isThemechecked, setIsThemeChecked] = useState(false);
  const [locale, setLocale] = useState();
  const [theme, setTheme] = useState("light");

  const lightTheme = createTheme(
    {
      overrides: { MuiCssBaseline: { "@global": { a: { color: "#34B7F1", }, } } },

      scrollbarStyles: {
        "&::-webkit-scrollbar": { width: "8px", height: "8px", },
        "&::-webkit-scrollbar-thumb": { boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)", backgroundColor: "#e8e8e8", },
      },

      palette: {
        primary: { main: "#F4323C", pastel: "#ff6961", rgbaPastel: "rgba(255, 105, 97, 0.55)", messageBox: "#dcf8c6", quotedMessageBox: "#d8edc7", hoverBadge: "rgba(200,200,200,255)" },
        secondary: { main: "#F4323C", ackCheck: "#34B7F1" },
        background: { default:  "#eeeee4", paper: "#f5f5ef", quotedPaper: "#ebebe9", },
        text: { reaction: '#d9d9d4', },
        audioPlayer: { buttons: "#2c2f33", },
        internalMessages: { transferenceObservation: "#FB8B24", },
      },

      backgroundImage: `url(${whatsBackgroundLight})`,
    },
    locale
  );

  
  const darkTheme = createTheme(
    {
      overrides: { MuiCssBaseline: { "@global": { body: { backgroundColor: "#282b30", }, a: { color: "#34B7F1", }, } } },

      scrollbarStyles: {
        "&::-webkit-scrollbar": { width: "8px", height: "8px", },
        "&::-webkit-scrollbar-thumb": { boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)", backgroundColor: "#ffffff", },
      },
      
      palette: {
        primary: { main: "#F4323C", pastel: "#ff6961", rgbaPastel: "rgba(255, 105, 97, 0.55)", messageBox: "#005d4b", quotedMessageBox: "#095449", hoverBadge: "rgba(120,120,120,255)" },
        secondary: { main: "#F4323C", ackCheck: "#34B7F1" },
        background: { default: "#2c2f33", paper: "#36393e", quotedPaper: "#303239", },
        text: { primary: '#ffffff', secondary: '#777777', reaction: '#36393e', },
        audioPlayer: { buttons: "#eeeee4", },
        internalMessages: { transferenceObservation: "#E36414", },
      },

      backgroundImage: `url(${whatsBackgroundDark})`,
    },
    locale
  );



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    // - sempre que for utilizar uma localStorage, adicionar uma condição aqui
    // para inializá-la pela primeira vez que o usuário for usar a aplicação.
    
    // *** Theme ***
    if (localStorage.getItem("theme") === null) {
      localStorage.setItem("theme", "light");
      document.getElementById("root").style.background = "#eeeee4";
      document.getElementById("root").style.color = "#000000";
      document.body.style.background = "#eeeee4";
    } else {
      document.body.style.background = localStorage.getItem("theme") === "dark" ? "#2c2f33" : "#eeeee4";
      document.getElementById("root").style.color = localStorage.getItem("theme") === "dark" ? "#eeeee4" : "#000000";
      document.getElementById("root").style.background = localStorage.getItem("theme") === "dark" ? "#2c2f33" : "#eeeee4";
    }

    // *** Tabs ***
    if (localStorage.getItem("tabOpenTickets") === null) { localStorage.setItem("tabOpenTickets", "open"); }
    if (localStorage.getItem("tabOpenSettings") === null) { localStorage.setItem("tabOpenSettings", "general"); }

    // *** Settings ***
    if (localStorage.getItem("flagNotificationSound") === null) { localStorage.setItem("flagNotificationSound", "true"); }
    if (localStorage.getItem("showAllTickets") === null) { localStorage.setItem("showAllTickets", "0"); }
    if (localStorage.getItem("latestNews") === null) { localStorage.setItem("latestNews", "none"); }
    
    // *** Views ***
    if (localStorage.getItem("contactsViewLayout") === null) { localStorage.setItem("contactsViewLayout", "list"); }
    if (localStorage.getItem("contactsViewType") === null) { localStorage.setItem("contactsViewType", "0"); }
    if (localStorage.getItem("usersViewLayout") === null) { localStorage.setItem("usersViewLayout", "list"); }
    if (localStorage.getItem("usersViewType") === null) { localStorage.setItem("usersViewType", "0"); }

    // *** Current Playing Audio ***
    if (localStorage.getItem("currentPlayingAudio") === null) { localStorage.setItem("currentPlayingAudio", ""); }
    if (localStorage.getItem("currentPlayingAudioTime") === null) { localStorage.setItem("currentPlayingAudioTime", ""); }
    if (localStorage.getItem("currentPlayingAudioTicketId") === null) { localStorage.setItem("currentPlayingAudioTicketId", ""); }
    if (localStorage.getItem("currentPlayingAudioContactProfilePic") === null) { localStorage.setItem("currentPlayingAudioContactProfilePic", ""); }
    if (localStorage.getItem("currentPlayingAudioContactName") === null) { localStorage.setItem("currentPlayingAudioContactName", ""); }
    if (localStorage.getItem("currentPlayingAudioContactId") === null) { localStorage.setItem("currentPlayingAudioContactId", ""); }

    // *** Selected Ticket - Contact Id ***
    if (localStorage.getItem("selectedTicketContactId") === null) { localStorage.setItem("selectedTicketContactId", "-1"); }

    // *** Locale ***
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale = i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);
    if (browserLocale === "ptBR") { setLocale(ptBR); }
  }, []);


  

  //  ***************
  //  ** Functions **
  //  ***************
  const themeToggler = () => { theme === "light" ? setTheme("dark") : setTheme("light"); };

  const handleChange = (event) => {
    setIsThemeChecked(event.target.checked);
    if(isThemechecked === false) { themeToggler(); }
    else if (isThemechecked === true ){ themeToggler(); }
  };




  //  ************
  //  ** Return **
  //  ************
  return (
    <ThemeProvider theme={localStorage.getItem("theme") === "light" ? lightTheme : darkTheme}>
      <Routes />
      <CssBaseline />
      <FormGroup row className={classes.switch}>
        <FormControlLabel control={<Switch id="containerToggle" checked={isThemechecked} onChange={handleChange} />} />
      </FormGroup>
    </ThemeProvider>
  );
};

export default App;
