import React, { useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import groupInviteIcon from "../../assets/group-invite.png";
import MarkdownWrapper from "../MarkdownWrapper";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((themes) => ({
  //  ********************
  //  ** Main Container **
  //  ********************
  groupMainContainer: {
    transition: "all 0.3s ease",
  },

  deletedGroupInviteMessage: {
		opacity: 0.7,
		filter: "grayscale(80%)",
	},



  //  **************************
  //  ** Group Info Container **
  //  **************************
  groupInfoContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    userSelect: "none",
    padding: "1em",
    gap: "1em",
  },

  groupName: {
    fontSize: "1.0625em",
  },



  //  ****************************
  //  ** Group Invite Container **
  //  ****************************
  groupInviteContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const GroupInvitePreview = ({ message, isQuotedMessage }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const nullValue = "---";
  const groupName = message.groupName && message.groupName !== undefined
    ? message.groupName : nullValue;
  const inviteCode = message.inviteCode && message.inviteCode !== undefined
    ? message.inviteCode : nullValue;



  //  ***************
  //  ** Functions **
  //  ***************
  const joinGroup = async () => {
    try {
      if (inviteCode !== nullValue && !message.isGroupInviteExpired) {
        await api.post("/joinGroupInvite/", { ticketId: message.ticketId, messageId: message.id });
        toast.success(i18n.t("groupInvitePreview.toasts.joined"));
      }
      else if (inviteCode === nullValue) toast.error(i18n.t("groupInvitePreview.toasts.invalidInviteCode"));
      else toast.error(i18n.t("groupInvitePreview.toasts.inviteExpired"));
    } catch (error) {
      console.log("Join Group Error:", error);
      toastError(error);
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <ConfirmationModal
        title={`${i18n.t("groupInvitePreview.confirmationModal.title")} ${groupName}?`}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={joinGroup}
      />
      
      <Card
        className={message.isDeleted ? `${classes.groupMainContainer} ${classes.deletedGroupInviteMessage}` : classes.groupMainContainer}
        style={message.fromMe
          ? { backgroundColor: localStorage.getItem("theme") === "light" ? "#dcf8c6" : "#005d4b" }
          : { backgroundColor: localStorage.getItem("theme") === "light" ? "#f5f5ef" : "#36393e" }
        }
      >
        <CardActionArea
          className={classes.groupInfoContainer}
          onClick={setConfirmationOpen}
          disabled={message.isDeleted || message.fromMe || message.isGroupInviteExpired || isQuotedMessage}
        >
          <Avatar src={groupInviteIcon} />
          <div>
            <p className={classes.groupName}>{groupName}</p>
            <p>{i18n.t("groupInvitePreview.description")}</p>
          </div>
        </CardActionArea>

        <Divider /><Divider /><Divider /><Divider />

        <CardContent className={classes.groupInviteContainer}>
          <MarkdownWrapper>{message.body}</MarkdownWrapper>
        </CardContent>
        
        <Button
          fullWidth
          color="inherit"
          onClick={setConfirmationOpen}
          disabled={message.isDeleted || message.fromMe || message.isGroupInviteExpired || isQuotedMessage}
        >
          {!message.isGroupInviteExpired
            ? i18n.t("groupInvitePreview.joinButton")
            : i18n.t("groupInvitePreview.expiredButton")}
        </Button>
      </Card>
    </>
  );
};

export default GroupInvitePreview;