import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useAttendanceTime = ({
    initialDate,
    finalDate,
    considerInternalChats
}) => {
    const [loading, setLoading] = useState(true);
    const [avg, setAvg] = useState(0);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchMessages = async() => {
                try {
                    const data = await api.get("/avgAttendanceTime", {
                        params: {
                            initialDate,
                            finalDate,
                            considerInternalChats
                        },
                    })
                    setAvg(data)
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                    console.log("Use Attendance Time Error:", error);
                    toastError(error)
                }
            }

            fetchMessages()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [
        initialDate,
        finalDate,
        considerInternalChats
    ])


    return { loading, avg };
};

export default useAttendanceTime;