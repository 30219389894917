function extractDayMonthYearFromStringDateTime(stringDateTime) {
  /* Receives a datatime string "YYYY-MM-DDTHH:mm:SS.000Z" and returns a
  string containing only the day, month and year in the format "DD-MM-YYYY".
  
    *** Params ***
    - stringDateTime: string;

    *** Returns ***
    - extractedStringDateTime: string;
  */
  
  const dateTime = new Date(stringDateTime);

  const extractedDay = dateTime.getDate().toString().length === 1 
    ? `0${dateTime.getDate()}`
    : dateTime.getDate();


  const extractedMonth = (dateTime.getMonth() + 1).toString().length === 1 
    ? `0${dateTime.getMonth() + 1}`
    : (dateTime.getMonth() + 1).toString();
    
  const extractedFullYear = dateTime.getFullYear().toString();

  return `${extractedDay}/${extractedMonth}/${extractedFullYear}`;
}

export default extractDayMonthYearFromStringDateTime;