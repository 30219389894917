import React from "react";

import { Container, makeStyles, Typography } from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import { i18n } from "../../translate/i18n";
import Img404 from "../../assets/404.svg";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "5px",
  },
  gif: {
    width: "400px",
    height: "400px",
  },
  message: {
    fontSize: "20px",
  },
}));

const Page404 = () => {
  const classes = useStyles();

  return (
    <MainContainer>
      <Container className={classes.container}>
        <img src={Img404} className={classes.gif} alt="Page Not Found" />
        <Container className={classes.container}>
          <Typography className={classes.message}>Oops...</Typography>
          <Typography className={classes.message}>{i18n.t("page404.message")}</Typography>
        </Container>
      </Container>
    </MainContainer>
  );
};

export default Page404;