import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useOpenTickets = ({ initialDate, finalDate, considerInternalChats}) => {
  const [rows, setRows] = useState(0);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchClosedTickets = async() => {
        try {
          const { data } = await api.get("/openTicketsPerUser", {
            params: { initialDate, finalDate, considerInternalChats },
          });
                  
          setRows(data);
        } catch (error) {
          try {
            console.log("Use Open Tickets Per User 1 Error:", error);
            toastError(error)
          }
          catch (error) {
            console.log("Use Open Tickets Per User 2 Error:", error);
            toast.error(error);
          }
        }
      }

      fetchClosedTickets();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [ initialDate, finalDate, considerInternalChats]);

  return rows;
}

export default useOpenTickets;