import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Checkbox,
	Chip,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	chips: { display: "flex", flexWrap: "wrap" },

	chip: { margin: 2, color: theme.palette.text.primary },

	chipCheckbox: {
		color: theme.palette.primary.main,
		'&.Mui-checked': { color: theme.palette.primary.main }
	},

	selectInputField: {
		"& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary },
		width: "250px",
	},
}));

const LabelSelect = ({ selectedLabelIds, onChange, labelsOptions, styleSelectInputField=true }) => {
	// 	***************
	// 	** Variables **
	// 	***************
  const classes = useStyles();
	const [labels, setLabels] = useState([]);



	// 	*****************
	// 	** Use Effects **
	// 	*****************
  useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/label");
				setLabels(data);
			} catch (error) {
				console.log("Label Select Use Effect Error:", error);
				toastError(error);
			}
		})();
	}, []);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleChange = event => { onChange(event.target.value);	};



	// 	************
	// 	** Return **
	// 	************
  return (
		<div>
			<FormControl 
				fullWidth
				size="small"
				margin="dense"
				variant="outlined"
				classes={styleSelectInputField ? { root: classes.selectInputField } : { }}
			>
				<InputLabel>{i18n.t("labelSelect.inputLabel")}</InputLabel>
				<Select
					multiple
					label={i18n.t("labelSelect.inputLabel")}
					value={selectedLabelIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: { vertical: "bottom", horizontal: "left" },
						transformOrigin: { vertical: "top", horizontal: "left" },
						getContentAnchorEl: null,
					}}
					renderValue={selected => (
						<div className={classes.chips}>
							{selected?.length > 0 &&
								selected.map(id => {
									const label = labels.find(l => l.id === id);
									return label
										? (<Chip key={id} variant="outlined" label={label.name} className={classes.chip} style={{ border: `2px solid ${label.color}` }} />)
										: null;
								})}
						</div>
					)}
				>
					{labelsOptions.map(label => (
						<MenuItem key={label.id} value={label.id}>
							<Checkbox className={classes.chipCheckbox} checked={selectedLabelIds.indexOf(label.id) > -1} />
              <ListItemText primary={label.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default LabelSelect;