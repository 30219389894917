import React, { useEffect, useState } from "react";
import QRCodeSVG from "qrcode.react";
import { Dialog, DialogContent, Paper, Typography, Container, useMediaQuery } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import SocketQRCode from "../../context/Socket/Listeners/SocketQRCode";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  qrCodeModalContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "row",
		padding: "0px 0px 25px 0px",
		[theme.breakpoints.down("sm")]: { flexDirection: "column", gap: "10px", },
	},

	qrCodeContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		gap: "5px",
	},

	stepsContainer: { display: "flex", justifyContent: "center", flexDirection: "column", gap: "5px", },

	loadingScreenStatus: {
		fontWeight: "bold",
		color: "#f6f6ff",
		backgroundColor: "red",
		padding: "2px",
		borderRadius: "5px",
	},
}));

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const [qrCode, setQrCode] = useState("");



	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`/whatsapp/${whatsAppId}`);
				setQrCode(data.qrcode);
			} catch (error) {
				console.log("QR Code Modal Error:", error);
				toastError(error);
			}
		};
		fetchSession();
	}, [whatsAppId]);



	// 	************
	// 	** Return **
	// 	************
	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
			<SocketQRCode
				onClose={onClose}
				setQrCode={setQrCode}
				whatsAppId={whatsAppId}
			/>

			<DialogContent>
				<Paper elevation={0} className={classes.qrCodeModalContainer}>
					{/* 
						*************
						** QR Code **
						*************
					*/}
					<Container className={classes.qrCodeContainer}>
						<Typography gutterBottom>{i18n.t("qrCode.message")}</Typography>
						{isSmallScreen && qrCode && (<QRCodeSVG value={qrCode} size={180} />)}
						{!isSmallScreen && qrCode && (<QRCodeSVG value={qrCode} size={256} />)}
						{!qrCode && (<span>Waiting for QR Code</span>)}
					</Container>



					{/* 
						***********
						** Steps **
						***********
					*/}
					<Container className={classes.stepsContainer}>
						<Typography gutterBottom>{i18n.t("qrCode.steps.message1")}</Typography>
						<Typography gutterBottom>{i18n.t("qrCode.steps.message2")}</Typography>
						<Typography gutterBottom>{i18n.t("qrCode.steps.message3")}</Typography>
						<Typography gutterBottom>{i18n.t("qrCode.steps.message4")}</Typography>
						<Typography gutterBottom>{i18n.t("qrCode.steps.message5")}</Typography>
					</Container>
				</Paper>
			</DialogContent>
		</Dialog>
	);
};

export default React.memo(QrcodeModal);
