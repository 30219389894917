const getParentNodeByClassName = (targetClassName, childElement) => {
  let isTargetElementFound = false;
  let currentElement = childElement;
  let finalElement = childElement;

  while (currentElement && !isTargetElementFound) {
    currentElement = currentElement.parentNode;

    const classList = currentElement.classList;
    if (!(classList && classList.length > 0)) continue;

    for (let className of classList) {
      if (className.includes(targetClassName)) {
        isTargetElementFound = true;
        finalElement = currentElement;
        break;
      }
    }
  }

  return finalElement;
};

export default getParentNodeByClassName;