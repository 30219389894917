function comparePersonalizedMessagesLength(checkboxPersonalizedMessageComparisons, personalizedMessageComparisons) {
  /*
    Recebe um array contendo os status de todos os checkboxes que habilitam as mensagens personalizadas
  e outro com os valores preenchidos nos campos de mensagens personalizadas.
    
    Caso um checkbox esteja marcado e o seu respectivo campo de mensagem personalizada esteja vazio
  ou com menos de 4 caracteres, é retornado 'True', caso contrário, 'False'.
  */
  let flag = false;

  const checkedIndexes = checkboxPersonalizedMessageComparisons.reduce((acc, val, index) => {
    if (val) { acc.push(index); }
    return acc;
  }, []);

  checkedIndexes.forEach((index) => {
    if (personalizedMessageComparisons[index].length <= 3) { flag = true; }
  });

  return flag;
}

export default comparePersonalizedMessagesLength;