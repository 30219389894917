import millisecondsToTime from "./millisecondsToTime";
import { i18n } from "../translate/i18n";

function getTicketsListAwaitingTimeFlag(ticketsList, awaitingTimeSetting, considerOnlyWhatsappTickets=false) {
  const ticketsListFlags = [];
  const tempTicketsList = considerOnlyWhatsappTickets
    ? ticketsList.filter(ticket => ticket.type === 0 || ticket.type === "0")
    : ticketsList;

  tempTicketsList.forEach(ticket => {
    if (awaitingTimeSetting === -1) {
      ticketsListFlags.push(false);
    }
    else {
      const timeAwaiting = millisecondsToTime(new Date() - new Date(ticket.createdAt));
      let [timeAwaitingNumber, timeAwaitingMetric] = [parseInt(timeAwaiting.split(" ")[0]), timeAwaiting.split(" ")[1]];
      const secondMetrics = [i18n.t("ticketsList.timeAwaitSecond"), i18n.t("ticketsList.timeAwaitSecond") + "s"];
      const hourDayMetrics = [
        i18n.t("ticketsList.timeAwaitHour"), i18n.t("ticketsList.timeAwaitDay"),
        i18n.t("ticketsList.timeAwaitHour") + "s", i18n.t("ticketsList.timeAwaitDay") + "s"
      ];

      timeAwaitingNumber = hourDayMetrics.includes(timeAwaitingMetric) ? timeAwaitingNumber * 60 : timeAwaitingNumber;
      ticketsListFlags.push(timeAwaitingNumber >= awaitingTimeSetting && !secondMetrics.includes(timeAwaitingMetric));
    }
  });

  return ticketsListFlags;
};

export default getTicketsListAwaitingTimeFlag;