function compareIntervalAndBusinessHours (
  intervalInitialHour
  , businessInitialHour
  , intervalFinalHour
  , businessFinalHour
) {
   /*
        Essa função recebe quatro horários e realiza comparações entre eles.

        Retornos:
            - 0 >> horário inicial e final do intervalo são 00:00 (sem intervalo)
            - 1 >> horário inicial do intervalo é igual ou maior do que o final
            - 2 >> horário inicial/final do intervalo é menor/maior/igual ao horário inicial/final de trabalho
            - 3 >> horário de intervalo está entre o horário de serviço
  */

  // 
  //  ***---- Transforming Time into Minutes ----***
  //
  let intervalInitialHourMinutes = intervalInitialHour.split(":");
  intervalInitialHourMinutes = parseInt(intervalInitialHourMinutes[0]) * 60 + parseInt(intervalInitialHourMinutes[1]);

  let businessInitialHourMinutes = businessInitialHour.split(":");
  businessInitialHourMinutes = parseInt(businessInitialHourMinutes[0]) * 60 + parseInt(businessInitialHourMinutes[1]);

  let intervalFinalHourMinutes = intervalFinalHour.split(":");
  intervalFinalHourMinutes = parseInt(intervalFinalHourMinutes[0]) * 60 + parseInt(intervalFinalHourMinutes[1]);

  let businessFinalHourMinutes = businessFinalHour.split(":");
  businessFinalHourMinutes = parseInt(businessFinalHourMinutes[0]) * 60 + parseInt(businessFinalHourMinutes[1]);



  //
  //  ***---- Comparisons ----***
  //
  let comparisonResult;

  // interval initial and final hour are equal to zero (00:00)
  if (intervalInitialHourMinutes === 0 && intervalFinalHourMinutes === 0) {
    comparisonResult = 0;
  }

  // interval initial hour equals or greather than interval final hour
  else if (intervalInitialHourMinutes >= intervalFinalHourMinutes) {
    comparisonResult = 1;
  }

  // intervals values are before or after final hour
  else if (
    intervalInitialHourMinutes     <=  businessInitialHourMinutes
    || intervalInitialHourMinutes  >=  businessFinalHourMinutes
    || intervalFinalHourMinutes    <=  businessInitialHourMinutes
    || intervalFinalHourMinutes    >=  businessFinalHourMinutes
  ) {
    comparisonResult = 2;
  }

  // intervals values are between business hours
  else { comparisonResult = 3; }

  return comparisonResult;
};

export default compareIntervalAndBusinessHours;