import React, { useReducer, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";

import SearchIcon from "@material-ui/icons/Search";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import SocketLabels from "../../context/Socket/Listeners/SocketLabels";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal";
import LabelModal from "../../components/LabelModal";

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";


const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
		color: theme.palette.text.primary,
	},
  floatingButton: {
    transition: 'transform 0.3s',
  
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
  actionButton: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_LABELS") {
    const labels = action.payload;
    const newLabels = [];

    labels.forEach((label) => {
      const labelIndex = state.findIndex((l) => l.id === label.id);
      if (labelIndex !== -1) {
        state[labelIndex] = label;
      } else {
        newLabels.push(label);
      }
    });

    return [...state, ...newLabels];
  }

  if (action.type === "UPDATE_LABELS") {
    const label = action.payload;
    const labelIndex = state.findIndex((l) => l.id === label.id);
    if (labelIndex !== -1) {
      state[labelIndex] = label;
      return [...state];
    } else {
      return [label, ...state];
    }
  }

  if (action.type === "DELETE_LABEL") {
    const labelId = action.payload;
    const labelIndex = state.findIndex((l) => l.id === labelId);
    if (labelIndex !== -1) {
      state.splice(labelIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Contacts = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  
  const [labels, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [labelModalOpen, setLabelModalOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const { data } = await api.get("/label");
        dispatch({ type: "LOAD_LABELS", payload: data });
        setLoading(false);
      } catch (error) {
        console.log("Labels Use Effect 1 Error:", error);
        toastError(error);
        setLoading(false);
      }
    })();
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenLabelModal = () => {
    setLabelModalOpen(true);
    setSelectedLabel(null);
  };

  const handleCloseLabelModal = () => {
    setLabelModalOpen(false);
    setSelectedLabel(null);
  };

  const handleEditLabel = (label) => {
    setSelectedLabel(label);
    setLabelModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedLabel(null);
  };

  const handleDeleteLabel = async (labelId) => {
    try {
      await api.delete(`/label/${labelId}`);
      toast.success(i18n.t("labels.toasts.delete"));
    } catch (error) {
      console.log("Handle Delete Label Error:", error);
      toastError(error);
    }
    setSelectedLabel(null);
  };

  

  //  ************
  //  ** Return **
  //  ************
  return (
    <MainContainer>
      <SocketLabels
        dispatch={dispatch}
      />


      {/* 
        ************************
        ** Confirmation Modal **
        ************************
      */}
      <ConfirmationModal
        title={
          selectedLabel &&
          `${i18n.t("labels.confirmationModal.deleteTitle")} ${
            selectedLabel.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteLabel(selectedLabel.id)}
      >
        {i18n.t("labels.confirmationModal.deleteMessage")}
      </ConfirmationModal>


      
      {/* 
        *****************
        ** Label Modal **
        *****************
      */}
      <LabelModal
        open={labelModalOpen}
        onClose={handleCloseLabelModal}
        labelId={selectedLabel?.id}
      />



      {/* 
        ************
        ** Header **
        ************
      */}
      <MainHeader>
        <Title>{i18n.t("labels.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("labels.searchPlaceholder")}
            type="search"
            // value={searchParam}
            // onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenLabelModal}
            className={classes.floatingButton}
          >
            {i18n.t("labels.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>



      {/* 
        ***********
        ** Table **
        ***********
      */}
      <Paper
        className={classes.mainPaper}
        variant="outlined"
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("labels.table.name")}</TableCell>
              <TableCell align="center">{i18n.t("labels.table.color")}</TableCell>
              <TableCell align="center">{i18n.t("labels.table.description")}</TableCell>
              <TableCell align="center">{i18n.t("labels.table.actions")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <>
              {labels.map((label) => (
                <TableRow key={label.id}>
                  <TableCell align="center">{label.name}</TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <span
                        style={{
                          backgroundColor: label.color,
                          width: 60,
                          height: 20,
                          alignSelf: "center",
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">{label.description}</TableCell>
                  <TableCell align="center">
                    <IconButton 
                      className={classes.icon}
                      size="small"
                      onClick={() => handleEditLabel(label)}
                    >
                      <EditIcon className={classes.actionButton} />
                    </IconButton>

                    <IconButton
                      className={classes.icon}
                      size="small"
                      onClick={() => {
                        setSelectedLabel(label);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <DeleteOutlineIcon className={classes.actionButton} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
