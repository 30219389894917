import React, { useState, useContext, useEffect, useRef  } from "react";
import clsx from "clsx";

import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import VolumeDownOutlined from "@material-ui/icons/VolumeDownOutlined";
import VolumeOffOutlined from "@material-ui/icons/VolumeOffOutlined";


import { AuthContext } from "../context/Auth/AuthContext";
import { i18n } from "../translate/i18n";
import api from "../services/api";
import BackdropLoading from "../components/BackdropLoading";
import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import TranslateOutlinedIcon from '@material-ui/icons/TranslateOutlined';
import UserModal from "../components/UserModal";
import VersionModal from "../components/VersionModal";

import iconLight from '../assets/icon.png';
import iconDark from '../assets/icon-dark.png';

import BrazilFlag from '../assets/flags/br.svg';
import SpainFlag from '../assets/flags/es.svg';
import USFlag from '../assets/flags/us.svg';

import { useSocket } from "../context/Socket/SocketContext";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius:"20px",
    display: "flex",
    height: "100vh",
    [theme.breakpoints.down("sm")]: { height: "calc(100vh - 56px)", },
  },

  appBar: {
    display: "none",
    color: theme.palette.text.primary,
    width: "calc(100% - 10px)",
    height: "50px",
    left: "5px",
    right:"5px",
    borderRadius: "0px 0px 20px 20px",
    top: "0px",
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '@media (max-width: 600px)': {
      display: 'flex',
      flexDirection: "row",
      flexWrap: "wrap",
      alingItems: "center",
      justifyContent: "flex-start",
    },
  },

  appBarMenuIconContainer: { cursor: "pointer", width: "15%", },

  appBarTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "35%",
    fontSize: "1.5em",
  },

  appBarButtonsContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  applicationVersionContainer: {
    paddingLeft: "1em",
    userSelect: "none",
    alignSelf: "center",
    marginBottom: "1em",
    transition: "all 0.3s ease",
    cursor: "pointer",
    "&:hover": { transform: "translateY(-5px)", textDecoration: "underline", },
  },

  flag: { borderRadius: "50%", height: "15px", marginRight: "5px", },
  toolbar: { cursor: "pointer", borderRadius:"20px", display: "flex", paddingRight: "24px", },
  toolbarMobile: { borderRadius:"20px", display: "flex", justifyContent: "center", alignItems: "center", },

  toolbarIcon: {
    borderRadius:"20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "48px",
  },

  toolbarIconChevron: { color: theme.palette.text.primary, },
  title: { userSelect: "none", margin: theme.spacing(2), flexGrow: 1, },

  drawerPaper: {
    ...theme.scrollbarStyles,
    overflowX: "hidden",
    padding: "0 5px 0 0",
    borderRadius:"20px",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerPaperClose: {
    borderRadius:"20px",
    overflowX: "hidden",
    ...theme.scrollbarStyles,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: { width: theme.spacing(9), },
  },

  content: { borderRadius:"20px", flex: 1, overflow: "auto", '@media (max-width: 600px)': { marginTop: "50px", }, },
  container: { borderRadius:"20px", paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), },

  paper: {
    borderRadius:"20px",
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  avatar: {
    borderRadius:"20px",
    margin: theme.spacing(1, 1, 1, 2),
    width: "35px",
    height: "auto",
    userSelect: "none",
  },

  roundButton: { borderRadius:"20px", },
  speedDialButton: { position: "relative", whiteSpace: "nowrap", transform: 'translateZ(0px)', },

  buttonFirst: {
    borderRadius:"10px",
    margin: theme.spacing(1, 1, 0, 1.5),
    alignItems: "left",
    justifyContent: "flex-left",
  },

  buttonSecond: { borderRadius:"10px", margin: theme.spacing(1, 0, 0, 0), },
  buttonClose: { borderRadius: "10px", margin: theme.spacing(1, 0, 0, 0.5), },
  logo: { alignItems: "center", },
  miscButtons: { margin: theme.spacing(0, 0, 0, -2), transition: "all 0.3s ease" },
  miscButtonsClose: { margin: theme.spacing(0, 0, 0, 0), transition: "all 0.3s ease" },
}));

const LoggedInLayout = ({ children }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const { loading } = useContext(AuthContext);
  const { user } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [applicationVersion, setApplicationVersion] = useState("0000.00.00.0000");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const [flagNotificationSound, setFlagNotificationSound] = useState(localStorage.getItem("flagNotificationSound") === "true" || localStorage.getItem("flagNotificationSound") === null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [versionModalOpen, setVersionModalOpen] = useState(false);

  const { socket } = useSocket();

  let currentIcon = localStorage.getItem("theme") ? iconDark : iconLight;

  const socketConditionIntervalSuccessCount = useRef(0);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    try {
      const socketConditionInterval = setInterval(
        () => {
          if (socket === null || socket === undefined) {
            window.location.reload();
          } else {
            const socketConditionSuccessLimit = 150;

            const interestListeners = ["$connect", "$connect_error", "$disconnect"];

            const socketCallbacksEntries = Object
              .entries(socket._callbacks)
              .map(([key, value]) => key)
              .filter(listener => interestListeners.includes(listener));

            if (socketCallbacksEntries.length < interestListeners.length) {
              window.location.reload();
            } else {
              console.log(`- Is socket active: ${socket.active}`);
              console.log(`- Is socket connected: ${socket.connected}`);

              if (socket.active && socket.connected) {
                socketConditionIntervalSuccessCount.current += 1;

                if (socketConditionIntervalSuccessCount.current >= socketConditionSuccessLimit) {
                  socketConditionIntervalSuccessCount.current = 0;
                  console.clear();
                }
              } else {
                socketConditionIntervalSuccessCount.current = 0;
              }
            }
          }
        },
        5 * 1000 // 5 secs in milliseconds
      );

      return () => {
        clearInterval(socketConditionInterval);
      };
    } catch (exception) {
      console.log(`- Caught exception when trying to check socket condition: ${JSON.stringify(exception)}`);
    }
  }, [socket, user])

  useEffect(() => {
    const delayDebounceFN = setTimeout(() => {
      const fetchVersion = async () => {
        const { data } = await api.get("/app/version/");
        setApplicationVersion(`${data.version}-${data.group}-${user.tenantId}`);
      };
      fetchVersion();
    }, 500);
    return () => clearTimeout(delayDebounceFN);
  }, [user]);

  useEffect(() => {
    if (document.body.offsetWidth > 600) { setDrawerOpen(true); }
  }, []);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
      setIsSmallScreen(true);
    } else {
      setDrawerVariant("permanent");
      setIsSmallScreen(false);
    }
  }, [drawerOpen]);

  useEffect(() => {
    window.onload = () => {
      document.addEventListener("keydown", (event) => {
        if (event.ctrlKey && (event.key === "b" || event.key === "B")) {
          setDrawerOpen(previousValue => !previousValue);
        }
      });
    };
  });



  //  ***************
  //  ** Functions **
  //  ***************
  const handleCloseMenu = () => { setAnchorEl(null); };

  const handleLanguageMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setLanguageMenuOpen(true);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorEl(null);
    setLanguageMenuOpen(false);
  };

  const handleChangingLanguage = (i18nCode) => {
    handleCloseLanguageMenu();
    localStorage.setItem("i18nextLng", i18nCode);
    i18n.changeLanguage(i18nCode);
    window.location.href = window.location.href + "#translated";
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleNotificationSound = () => {
    if (localStorage.getItem("flagNotificationSound") === "true") {
      localStorage.setItem("flagNotificationSound", "false");
      setFlagNotificationSound(false);
    } else {
      localStorage.setItem("flagNotificationSound", "true");
      setFlagNotificationSound(true);
    }
  }



  // **********************
  // ** Return - Loading **
  // **********************
  if (loading) { return <BackdropLoading />; }


  
  // **********************
  // ** Return - Content **
  // **********************
  return (
    <div className={classes.root}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose)}}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div className={classes.toolbar} onClick={() => {setDrawerOpen(!drawerOpen)}}>
          <img className={classes.avatar} src={currentIcon} alt="BestZap Logo" id="bestzapLogo" />

          <Typography component="h3" variant="h7" color="inherit" noWrap className={classes.title}>
            BestZap
          </Typography>

          <div className={classes.toolbarIcon}>  
            <IconButton>
              <ChevronLeftIcon  className={classes.toolbarIconChevron}/>
            </IconButton>
          </div>
        </div>

        <Divider className={classes.roundButton}/>

        <List className={classes.roundButton}>
          <MainListItems closeDrawer={() => setDrawerOpen(false)} isSmallScreen={isSmallScreen} />
        </List> 

        <Divider />

        <div className={drawerOpen ? classes.miscButtons : classes.miscButtonsClose }>   
          <Button 
            className={drawerOpen ? classes.buttonFirst : classes.buttonClose}
            aria-label="sound"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleNotificationSound}
            color="inherit"
            startIcon={flagNotificationSound ? <VolumeDownOutlined /> : <VolumeOffOutlined />}>      
          </Button>
  
          {drawerOpen ? <></> : <br></br>}  

          <Button 
            className={drawerOpen ? classes.buttonSecond : classes.buttonClose}
            aria-label="languages"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleLanguageMenu}
            color="inherit"
            startIcon={<TranslateOutlinedIcon />}>
          </Button>

          <Menu
            id="menu-language"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={languageMenuOpen}
            onClose={handleCloseLanguageMenu}
          >
            <MenuItem onClick={() => { handleChangingLanguage("pt-BR") }}>
              <img className={classes.flag} src={BrazilFlag} alt="Brasil - " />
              {i18n.t("languagesMenu.language1")}
            </MenuItem>

            <MenuItem onClick={() => { handleChangingLanguage("en-US") }}>
              <img className={classes.flag} src={USFlag} alt="US - " />
              {i18n.t("languagesMenu.language2")}
            </MenuItem>

            <MenuItem onClick={() => { handleChangingLanguage("es") }}>
              <img className={classes.flag} src={SpainFlag} alt="España - " />
              {i18n.t("languagesMenu.language3")}
            </MenuItem>
          </Menu> 

          {drawerOpen ? <></> : <br></br>} 

          <Button 
            className={drawerOpen ? classes.buttonFirst : classes.buttonClose}
            aria-label="userProfile"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenUserModal}
            color="inherit"
            startIcon={<AccountCircle />}>   
          </Button>   

          {drawerOpen ? <></> : <br></br>} 

          {user.id && (<NotificationsPopOver drawerOpen={drawerOpen} isNotAtMenu={false} isAtAppBar={false}/>)}
        </div> 

        <Divider className={classes.roundButton}/>
        
        {drawerOpen && (
          <>
            <br />
            <div className={classes.applicationVersionContainer} onClick={() => setVersionModalOpen(true)}>
              <span>{applicationVersion}</span>
            </div>
          </>
        )}
      </Drawer>
      
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      /> 

      <VersionModal
        open={versionModalOpen}
        onClose={() => setVersionModalOpen(false)}
        applicationVersion={applicationVersion}
      />
      
      <main className={classes.content}>
        {children ? children : null}
      </main>

      <AppBar position="fixed" className={classes.appBar} color="inherit" >
        <div className={classes.appBarMenuIconContainer}>
          <Toolbar variant="dense" className={classes.toolbarMobile}>
            <MenuIcon className={classes.menuButton} onClick={() => setDrawerOpen(!drawerOpen)} />
          </Toolbar>
        </div>

        <div className={classes.appBarTitleContainer}>
          <span>BestZap</span>
        </div>

        <div className={classes.appBarButtonsContainer}>
          <Button 
            className={classes.buttonClose}
            aria-label="sound"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleNotificationSound}
            color="inherit"
            startIcon={flagNotificationSound ? <VolumeDownOutlined /> : <VolumeOffOutlined />}>      
          </Button>

          {user.id && (<NotificationsPopOver drawerOpen={drawerOpen} isNotAtMenu={false} isAtAppBar={true} />)}
        </div> 
      </AppBar>
    </div>
  );
};

export default LoggedInLayout;
