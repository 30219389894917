import React, { useState, useEffect, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { toast } from "react-toastify";
import SearchIcon from "@material-ui/icons/Search";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) { state[contactIndex] = contact; }
      else { newContacts.push(contact); }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "RESET") { return []; }
};

const useStyles = makeStyles(theme => ({
  // ***********
  // ** Label **
  // ***********
  label: {
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    color: "#f5f5ff",
  },
  
  // *************
  // ** Content **
  // *************
  dialogContent: { overflowY: "scroll", ...theme.scrollbarStyles, },

  contactCard: { borderRadius: "20px", padding: "10px", marginBottom: "5px", },

  contactCardActionArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "20px",
    padding: "5px",
  },

  contactName: { fontSize: "16px", },

  contactNumber: { fontSize: "13px", color: "hsl(217, 12%, 63%)", },
}));

const ShareContactsModal = ({ open, onClose, ticket, replyingMessageId, ticketType }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const ticketId = ticket;
  const [loading, setLoading] = useState(false);
  const [contacts, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [hasMore, setHasMore] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          // ***---- Getting Contacts ----***
          //
          //  \ typeOfContact: 0 (it's not possible to share vcards of groups
          // so it'll just list contacts!!)
          //
          const { data } = await api.get("/contacts/", {
            params: { searchParam, pageNumber, typeOfContact: "0" },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (error) {
          console.log("Share Contacts Modal Use Effect Error:", error);
          toastError(error);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);


  
  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    if (contacts.length > 40) { dispatch({ type: "RESET" }); }

    onClose();
    setConfirmationOpen(false);
    setPageNumber(1);
    setSearchParam("");
    setSelectedContacts([]);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  }

  const handleScroll = (e) => {
    if (!hasMore || loading) { return; }
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) { loadMore(); }
  };

  const handleOpenConfirmationModal = () => {
    setConfirmationOpen(true);
  };

  const handleSendContact = async () => {
    if (ticketType === 1 || ticketType === 3) {
      await Promise.all(
        selectedContacts.map(async selectedContact => {
          const formattedContactName = selectedContact.name.trim().replaceAll(" ", ";"); 
          const body = "BEGIN:VCARD\n" +
            "VERSION:3.0\n" +
            `N:${formattedContactName};;;\n` +
            `FN:${selectedContact.name}\n` +
            `item1.TEL;waid=${selectedContact.number}:+${selectedContact.number}\n` +
            "item1.X-ABLabel:Mobile\n" +
            "END:VCARD";

          await api.post(`/messagesVCard/${ticketId}`, { body, quotedMsgId: replyingMessageId });
        })
      );
    } else {
      const vcardsBody = [];
      const displayName = [];
      const body = [];

      selectedContacts.forEach(selectedContact => {
        displayName.push(selectedContact.name);
        body.push(selectedContact.id);
        vcardsBody.push({
          vcard: "BEGIN:VCARD\n" +
            "VERSION:3.0\n" +
            `FN:${selectedContact.name}\n` +
            `ORG:${selectedContact.name}\n` +
            `TEL;type=CELL;type=VOICE;waid=${selectedContact.number}:${selectedContact.number}\n` +
            "END:VCARD"
        });
      });
      await api.post(`messagesVCard/${ticketId}`, { body, displayName, vcardsBody, quotedMsgId: replyingMessageId });
    }

    handleClose();
  };

  const selectContact = (contactId) => {
    const card = document.getElementById(`card-${contactId}`);
    const checkbox = document.getElementById(`checkbox-${contactId}`);

    // checkbox being selected
    if (!checkbox.checked) {
      if (selectedContacts.length < 5) {
        card.style.backgroundColor = "rgba(255, 105, 97, 0.55)";
        checkbox.checked = true;
        checkbox.click();
        const contact = contacts.filter(contact => contact.id === contactId);
        setSelectedContacts([...selectedContacts, ...contact]);
      }
      else {
        card.style.backgroundColor = "inherit";
        checkbox.checked = false;
        checkbox.click();
        toast.info(i18n.t("shareContactModal.toast.limit"));
      }
    }

    // checkbox being unselected
    else {
      card.style.backgroundColor = "inherit";
      checkbox.checked = false;
      checkbox.click();
      setSelectedContacts(selectedContacts.filter(contact => contact.id !== contactId));
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <ConfirmationModal
        title={i18n.t("shareContactModal.confirmationModal.title")}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleSendContact}
      />

    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          <span>{i18n.t("shareContactModal.title.up")}</span>

          <br /><br />

          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            autoFocus
            type="search"
            fullWidth
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
        </DialogTitle>

        <DialogContent 
          className={classes.dialogContent}
          onScroll={handleScroll}
          dividers
        >
          {contacts.map((contact) => (
            <Card 
              id={`card-${contact.id}`}
              className={classes.contactCard}
              onClick={() => selectContact(contact.id)}
            >
              <CardActionArea className={classes.contactCardActionArea}>
                
                <Checkbox id={`checkbox-${contact.id}`} style={{ display: "none" }} />

                <Avatar
                  alt={contact?.name}
                  src={contact?.profilePicUrl}
                  className={classes.contactAvatar}
                ></Avatar>
                
                <CardContent>
                  <Typography className={classes.contactName} gutterBottom component="h3">{contact.name}</Typography>
                  <Typography className={classes.contactNumber} gutterBottom component="h3">{contact.number}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </DialogContent>

        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={`${classes.btnWrapper} ${classes.floatingButton}`}
            onClick={handleOpenConfirmationModal}
          >
            SEND
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </>
  );
};

export default ShareContactsModal;