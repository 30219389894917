function checkOutIntervalHoursComparisons (comparisons) {
  /*
    Recebe um array de comparações de dois horários convertidos em minutos (Business Hours e Interval Hours).
  Tal array pode conter apenas quatro valores distintos, [0, 1, 2, 3], sendo:
    
    - 0 >> horário inicial e final do intervalo são 00:00 (sem intervalo)
    - 1 >> horário inicial do intervalo é igual ou maior do que o final
    - 2 >> horário inicial/final do intervalo é menor/maior/igual ao horário inicial/final de trabalho
    - 3 >> horário de intervalo está entre o horário de serviço

    Com isto, esta função poderá retornar um dos seguintes quatro valores, [0, 1, 2, 3],
  sendo:

    - Retornos:
      - 0 >> horário inicial e final do intervalo são 00:00 (sem intervalo)
      - 1 >> horário inicial do intervalo é igual ou maior do que o final
      - 2 >> horário inicial/final do intervalo é menor/maior/igual ao horário inicial/final de trabalho
      - 3 >> horário de intervalo está entre o horário de serviço

    - Exemplo:

      comparisons = [0, 0, 0, 1, 2, 0, 1]
      checkOutTimeInMinutesComparisons(comparisons) // Output: 1
  */
  return (
    comparisons.includes(1) ? 1 :
    comparisons.includes(2) ? 2 :
    comparisons.includes(0) ? 0 :
    3
  );
}

export default checkOutIntervalHoursComparisons;