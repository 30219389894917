import React, { useEffect, useReducer, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import SocketCategories from "../../context/Socket/Listeners/SocketCategories";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import CategoryModal from "../../components/CategoryModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";


const reducer = (state, action) => {
  if (action.type === "LOAD_CATEGORIES") {
    const categories = action.payload;
    const newCategories = [];

    categories.forEach((category) => {
      const categoryIndex = state.findIndex((c) => c.id === category.id);
      if (categoryIndex !== -1) {
        state[categoryIndex] = category;
      } else {
        newCategories.push(category);
      }
    });

    return [...state, ...newCategories];
  }

  if (action.type === "UPDATE_CATEGORIES") {
    const category = action.payload;
    const categoryIndex = state.findIndex((c) => c.id === category.id);

    if (categoryIndex !== -1) {
      state[categoryIndex] = category;
      return [...state];
    } else {
      return [category, ...state];
    }
  }

  if (action.type === "DELETE_CATEGORY") {
    const categoryId = action.payload;

    const categoryIndex = state.findIndex((c) => c.id === categoryId);
    if (categoryIndex !== -1) {
      state.splice(categoryIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
		color: theme.palette.text.primary,
	},
  floatingButton: {
    transition: 'transform 0.3s',
  
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
  actionButton: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));


const Categories = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [categories, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (user.profile === "user" || !user.configEnabled) { history.push(`/tickets`); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/category");
        dispatch({ type: "LOAD_CATEGORIES", payload: data });

        setLoading(false);
      } catch (error) {
        console.log("Categories Use Effect 1 Error:", error);
        toastError(error);
        setLoading(false);
      }
    })();
  }, []);

  const handleOpenCategoryModal = () => {
    setCategoryModalOpen(true);
    setSelectedCategory(null);
  };

  const handleCloseCategoryModal = () => {
    setCategoryModalOpen(false);
    setSelectedCategory(null);
  };

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setCategoryModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedCategory(null);
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await api.delete(`/category/${categoryId}`);
      toast.success(`${i18n.t("categoryModal.toast.delete")}`);
    } catch (error) {
      console.log("Handle Delete Category Error:", error);
      toastError(error);
    }
    setSelectedCategory(null);
  };

  return (
     <MainContainer>
        <SocketCategories
          dispatch={dispatch}
        />


        <ConfirmationModal
          title={`${i18n.t("categories.confirmationModal.deleteTitle")}?`}
          open={confirmModalOpen}
          onClose={handleCloseConfirmationModal}
          onConfirm={() => handleDeleteCategory(selectedCategory.id)}
        >
          {i18n.t("categories.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <CategoryModal
          open={categoryModalOpen}
          onClose={handleCloseCategoryModal}
          categoryId={selectedCategory?.id}
        />
        <MainHeader>
          <Title>{i18n.t("categories.title")}</Title>
          <MainHeaderButtonsWrapper>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenCategoryModal}
              className={classes.floatingButton}
            >
              {i18n.t("categories.buttons.add")}
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Paper className={classes.mainPaper} variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {i18n.t("queues.table.name")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("queues.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {categories.map((category) => (
                  <TableRow key={category.id}>
                    <TableCell align="center">{category.name}</TableCell>
                    <TableCell align="center">
                      <IconButton className={classes.icon}
                        size="small"
                        onClick={() => handleEditCategory(category)}
                      >
                        <Edit className={classes.actionButton} />
                      </IconButton>

                      <IconButton className={classes.icon}
                        size="small"
                        onClick={() => {
                          setSelectedCategory(category);
                          setConfirmModalOpen(true);
                        }}
                      >
                        <DeleteOutline className={classes.actionButton} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={2} />}
              </>
            </TableBody>
          </Table>
        </Paper>
     </MainContainer>
  );
};

export default Categories;