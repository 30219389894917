function dragScroll(elementId) {
  const slider = document.getElementById(elementId);
  
  if (slider) {
    let mouseDown = false;
    let startX, startY, scrollLeft, scrollTop;

    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      startY = e.pageY - slider.offsetTop;
      scrollLeft = slider.scrollLeft;
      scrollTop = slider.scrollTop;
    };

    let stopDragging = function (event) { mouseDown = false; };

    slider.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if(!mouseDown) { return; }
      const x = e.pageX - slider.offsetLeft;
      const y = e.pageY - slider.offsetTop;
      const horizontalScroll = x - startX;
      const verticalScroll = y - startY;

      slider.scrollLeft = scrollLeft - horizontalScroll;
      slider.scrollTop = scrollTop - verticalScroll;
    });

    // Add the event listeners
    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
  }
};

export default dragScroll;