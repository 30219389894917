import React, { useState, useRef, useEffect, useContext } from "react";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useSound from "use-sound";
import { Badge, IconButton, List, ListItem, ListItemText, Popover } from "@material-ui/core";
import NotificationsActiveOutlined from "@material-ui/icons/NotificationsActiveOutlined";
import NotificationsOutlined from "@material-ui/icons/NotificationsOutlined";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import alertSound from "../../assets/sound.mp3";
import SocketNotifications from "../../context/Socket/Listeners/SocketNotifications";
import TicketListItem from "../TicketListItem";
import useAllUsers from "../../hooks/useAllUsers";
import useTickets from "../../hooks/useTickets";

const useStyles = makeStyles(theme => ({
  tabContainer: {
    overflowY: "auto",
    maxHeight: 350,
    ...theme.scrollbarStyles,
  },
  popoverPaper: {
    width: "100%",
    maxWidth: 350,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(-10),
    [theme.breakpoints.down("sm")]: {
      maxWidth: 270,
    },
  },

  popoverPaperAppBar: {
    width: 270,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(0),
  },

  noShadow: {
    boxShadow: "none !important",
  },

  notificationIconDrawerOpen: {
    borderRadius:"10px",
    margin: theme.spacing(1, 0, 0, -0.5),
    padding: "5px 30px 5px 10px",
  },

  notificationIconDrawerClose: {
    borderRadius:"10px",
    margin: theme.spacing(1, 0, 0, 1),
    padding: "5px 30px 5px 10px",
  },

  notificationIconNotAtMenu: {
    visibility: "hidden",
  },
}));

const NotificationsPopOver = (props) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const ticketIdUrl = +history.location.pathname.split("/")[2];
  const ticketIdRef = useRef(ticketIdUrl);
  const anchorEl = useRef();
  const soundAlertRef = useRef();

  const { allUsers } = useAllUsers();
  const { tickets } = useTickets({ withUnreadMessages: "true" });
  const [play] = useSound(alertSound);

  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [, setDesktopNotifications] = useState([]);

  let iconButtonClassNames = ``;
  if (props.drawerOpen && props.isNotAtMenu) {
    iconButtonClassNames = `${classes.notificationIconDrawerOpen} ${classes.notificationIconNotAtMenu}`;
  }
  else if (props.drawerOpen && !props.isNotAtMenu) {
    iconButtonClassNames = `${classes.notificationIconDrawerOpen}`;
  }
  else if (!props.drawerOpen && props.isNotAtMenu) {
    iconButtonClassNames = `${classes.notificationIconDrawerClose} ${classes.notificationIconNotAtMenu}`;
  }
  else if (!props.drawerOpen && !props.isNotAtMenu) {
    iconButtonClassNames = `${classes.notificationIconDrawerClose}`;
  }

  

  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const pendingTickets = tickets.filter(ticket => ticket.status === "pending");
    const sumUnreadMessages = pendingTickets.reduce((total, current) => total + current.unreadMessages, 0);
    document.title = sumUnreadMessages > 0 ? `(${sumUnreadMessages}) BestZap` : document.title = "BestZap";
  }, [tickets]);

  useEffect(() => {
    soundAlertRef.current = play;
    if (!("Notification" in window)) toast.info(i18n.t("notifications.notificationsNotSupported"));
    else Notification.requestPermission();
  }, [play]);

  useEffect(() => {
    const updatedNotifications = tickets.filter(ticket => ticket.status !== "closed").map(ticket => {
      const index = notifications.findIndex(notification =>
        notification.ticket.id === ticket.id &&
        (notification.notifiedUser === user.id || notification.notifiedUser === -1)
      );
    
      if (index !== -1) {
        notifications[index].ticket = ticket;
        return null;
      }
    
      if (ticket.status === "group") {
        const groupNotifications = allUsers.map(user => ({ notifiedUser: user.id, ticket: ticket }));
        return groupNotifications;
      }
    
      return [{ notifiedUser: -1, ticket: ticket }];
    });
    
    
    // Flatten the array of arrays and filter out nulls
    const newNotifications = [].concat(...updatedNotifications).filter(Boolean);
    
    // Add new notifications to existing ones
    setNotifications(newNotifications);
  }, [allUsers, tickets, user]);

  useEffect(() => { ticketIdRef.current = ticketIdUrl; }, [ticketIdUrl]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleNotifications = async (data, sendNotificationsChatsGroupsValue, sendNotificationsValue) => {
    // ***********
    // ** Datas **
    // ***********
    const { message, contact, ticket } = data;



    // *************************
    // ** Native Notification **
    // *************************
    if (!("Notification" in window)) { toast.info(i18n.t("notifications.notificationsNotSupported")); }
    else {
      Notification.requestPermission(function(result) {
        // Condition is true when:
        //
        //  1 - notification setting is to both (chats and groups) and:
        //    1.1 - ticket status is group OR:
        //    1.2 - chat notification setting is for both open and pending tickets and ticket status is either open or pending;
        //    1.3 - chat notification setting is only for open ticket and ticket status is open;
        //    1.4 - chat notification setting is only for pending tickets and ticket status is pending;
        //  2 - notification setting is only to groups and ticket is a group;
        //  3 - notification setting is only to chats and:
        //    3.1 - chat notification setting is for both open and pending tickets and ticket status is either open or pending;
        //    3.2 - chat notification setting is only for open tickets and ticket status is open;
        //    3.3 - chat notification setting is only for pending tickets and ticket status is pending.
        //
        const chatNotificationCondition = (sendNotificationsValue === "both" && (data.ticket.status === "open" || data.ticket.status === "pending")) ||
          (sendNotificationsValue === "open" && data.ticket.status === "open") ||
          (sendNotificationsValue === "pending" && data.ticket.status === "pending");

        const notificationSettingCondition =
          (sendNotificationsChatsGroupsValue === "both" && (data.ticket.status === "group" || (chatNotificationCondition))) ||
          (sendNotificationsChatsGroupsValue === "groups" && data.ticket.status === "group") ||
          (sendNotificationsChatsGroupsValue === "chats" && chatNotificationCondition);

        if (result === 'granted' && notificationSettingCondition) {
          navigator.serviceWorker.ready.then(function(registration) {
            const options = {
              body: `${message.body} - ${format(new Date(), "HH:mm")}`,
              icon: contact.profilePicUrl,
              tag: ticket.id,
              renotify: true,
              vibrate: [200, 100, 200],
            };
  
            const notification = registration.showNotification(
              `${i18n.t("tickets.notification.message")} ${contact.name}`,
              options
            );
  
            setDesktopNotifications(notifications => {
              const notfiticationIndex = notifications.findIndex(notification => notification.tag === ticket.id);
              if (notfiticationIndex !== -1) {
                notifications[notfiticationIndex] = notification;
                return [...notifications];
              }
              return [notification, ...notifications];
            });
        
            localStorage.getItem("flagNotificationSound") === "true" && soundAlertRef.current();
          });
        }
      });
    }
  };

  const handleClick = () => { setIsOpen(prevState => !prevState); };
  const handleClickAway = () => { setIsOpen(false); };
  const NotificationTicket = ({ children }) => { return <div onClick={handleClickAway}>{children}</div>; };

  

  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <SocketNotifications
        setNotifications={setNotifications}
        setDesktopNotifications={setDesktopNotifications}
        ticketIdRef={ticketIdRef}
        handleNotifications={handleNotifications}
      />

      <IconButton
        onClick={handleClick}
        ref={anchorEl}
        aria-label="Open Notifications"
        color="inherit"
        className={iconButtonClassNames}
      >
        <Badge
          badgeContent={
            notifications
              .filter(notification => (notification.notifiedUser === user.id || notification.notifiedUser === -1))
              .length
          }
          color="primary"
        >
          {notifications
            .filter(notification => (notification.notifiedUser === user.id || notification.notifiedUser === -1))
            .length === 0 ? (<NotificationsOutlined />) : (<NotificationsActiveOutlined />)}
        </Badge>
      </IconButton>

      <Popover
        disableScrollLock
        open={isOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
        transformOrigin={{ vertical: "top", horizontal: "right", }}
        classes={props.isAtAppBar ? { paper: classes.popoverPaperAppBar } : { paper: classes.popoverPaper }}
        onClose={handleClickAway}
      >
        <List dense className={classes.tabContainer}>
          {notifications
            .filter(notification => (notification.notifiedUser === user.id || notification.notifiedUser === -1))
            .length === 0 ? (
            <ListItem><ListItemText>{i18n.t("notifications.noTickets")}</ListItemText></ListItem>
          ) : (
            notifications
              .filter(notification => (notification.notifiedUser === user.id || notification.notifiedUser === -1))
              .map(notification =>
                <NotificationTicket key={notification?.ticket?.id}>
                  <TicketListItem ticket={notification?.ticket} />
                </NotificationTicket>
              )
          )}
        </List>
      </Popover>
    </>
  );
};

export default NotificationsPopOver;