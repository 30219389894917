import React, { useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import ProcessingImg from '../../assets/processing.svg';
import UploadImg from '../../assets/upload.svg';

const useStyles = makeStyles(theme => ({
  //  *************************
  //  ** Drag-Drop Container **
  //  *************************
  dragDropContainer: {
    width: "350px",
    height: "350px",
    display: "none",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    padding: "5px",

    border: `5px solid ${theme.palette.primary.main}`,
    borderStyle: "dashed",
    borderRadius: "20px",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,

    zIndex: "999",
    position: "absolute",
    top: "calc(50vh - (350px / 2))",
    left: "calc(50vw - (350px / 2))",    
  },

  dragDropImg: { width: "250px" },

  dragDropSubContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "7px",
  },

  dragDropObs: { fontSize: "12px" },
}));


const DragDropModal = ({ handleOpenFileContextModal }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const dragDropContainer = useRef(null);
  const { user } = useContext(AuthContext);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tabRequest = (urlParams.get('tab') ? urlParams.get('tab') : "open");


  
  //  ***************
  //  ** Functions **
  //  ***************
  const handleDragOver = (e) => {
    // 
    // Função que identifica o início de drag de arquivos na página. Tal função
    // apenas executará o código se (o ticket estiver aberto) OU (o ticket estiver
    // pendente E o usuário for administrador).
    //
    // Caso uma das condições seja verdadeira, verifica se o cursor está dentro
    // da página considerando uma margem de 25px em todas as direções.
    //
    // Caso estiver, a página toda tem 10% do brilho reduzido e o DragDrop Container
    // é exibido em modo "flex".
    //
    e.preventDefault();
    e.stopPropagation();


    if (tabRequest === "open" 
      && (
          (localStorage.getItem("tabOpen") !== "pending") 
          || (localStorage.getItem("tabOpen") === "pending" && user.profile === "admin")
         )
    ) {

      if (e.clientX > 25
        && e.clientY > 25
        && e.clientX < (window.innerWidth - 25) 
        && e.clientY < (window.innerHeight - 25))
      {
        const spanAwait = document.getElementById("awaitMsg");
        const processingImg = document.getElementById("processingImg");

        spanAwait.style.display = "none";
        processingImg.style.display = "none";
        document.body.style.filter = "brightness(90%)";
        dragDropContainer.current.style.display = "flex";
      }
    }
  };

  const handleDragLeave = (e) => {
    // 
    // Função que identifica o final de drag de arquivos na página. Tal função
    // apenas executará o código se (o ticket estiver aberto) OU (o ticket estiver
    // pendente E o usuário for administrador).
    //
    // Caso uma das condições seja verdadeira, verifica se o cursor está fora
    // da página considerando uma margem de 25px em todas as direções.
    //
    // Caso estiver, a página toda tem volta ao brilho de 100% e o DragDrop Container
    // não é mais exibido.
    //
    e.preventDefault();
    e.stopPropagation();


    if (tabRequest === "open") {
      if (e.clientX < 25
        || e.clientY < 25
        || e.clientX > (window.innerWidth - 25) 
        || e.clientY > (window.innerHeight - 25)) 
      {
        document.body.style.filter = "brightness(100%)";
        dragDropContainer.current.style.display = "none";
      }
    }
  }

  const handleDrop = (e) => {
    // 
    // Função que identifica o drop de arquivos na página. Tal função
    // apenas executará o código se (o ticket estiver aberto) OU (o ticket estiver
    // pendente E o usuário for administrador).
    //
    // Caso uma das condições seja verdadeira, verifica se o cursor está fora
    // da página considerando uma margem de 25px em todas as direções.
    //
    // Caso estiver, a página toda tem volta ao brilho de 100% e o DragDrop Container
    // não é mais exibido.
    //
    e.preventDefault();
    e.stopPropagation();

    if (tabRequest === "open" 
       && (
        (localStorage.getItem("tabOpen") !== "pending") 
        || (localStorage.getItem("tabOpen") === "pending" && user.profile === "admin")
       )
    ) {

      document.body.style.filter = "brightness(100%)";
      dragDropContainer.current.style.display = "none";

      if (!e.dataTransfer.files) { return; }
      const uploadedFiles = [];
      for (let index = 0; index < e.dataTransfer.files.length && index < 5; index++) {
        uploadedFiles.push(e.dataTransfer.files[index]);
      }
      handleOpenFileContextModal(uploadedFiles);
    }
  }



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('drop', handleDrop);
  
    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleDrop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  //  ************
  //  ** Return **
  //  ************
	return ( 
    <div className={classes.dragDropContainer} ref={dragDropContainer}>
      <img id="uploadImg" className={classes.dragDropImg} src={UploadImg} alt="Drag and Drop Modal" />
      <img id="processingImg" className={classes.dragDropImg} src={ProcessingImg} alt="Drag and Drop Modal" />

      <div className={classes.dragDropSubContainer}>
        <span id="spanMsg" className={classes.dragDropSpan}>{i18n.t("dragDropModal.span")}</span>
        <span id="obsMsg" className={classes.dragDropObs}>{i18n.t("dragDropModal.obs")}</span>
        <span id="awaitMsg" className={classes.dragDropAwait}>{i18n.t("dragDropModal.await")}</span>
      </div>
    </div>
	);
};

export default DragDropModal;
