import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketQRCode = ({ onClose, setQrCode, whatsAppId }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { socket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
		if (!whatsAppId) return;

    const handleWhatsappSession = (data) => {
			if (data.action === "update" && data.session.id === whatsAppId && `${api.defaults.headers.tenantId}` === `${data.tenantId}`) {
				setQrCode(data.session.qrcode);
			}

			if (data.action === "update" && data.session.qrcode === "" && `${api.defaults.headers.tenantId}` === `${data.tenantId}`) {
				onClose();
			}
		};

		socket.on("whatsappSession", handleWhatsappSession);

		return () => {
      socket.off("whatsappSession", handleWhatsappSession);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onClose, setQrCode, socket, whatsAppId]);


  
  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketQRCode;