import React, { useEffect, useState } from 'react';
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import formatPhoneNumberVCards from '../../utils/formatPhoneNumberVCards';
import MultiVCardModal from '../MultiVCardModal';
import toastError from '../../errors/toastError';
import truncateString from '../../utils/truncateString';

const useStyles = makeStyles((theme) => ({
  multiVCardInfo: {
    color: theme.palette.secondary.ackCheck,
    userSelect: "none",
    "&:hover": { textDecoration: "underline", },
  },
}));


const MultiVcardPreview = ({ contacts, enableStartChat=true }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const truncateThreshold = 15;

  const [contactsList, setContactsList] = useState([
    { name: "-", number: 0, profilePicUrl: "" },
    { name: "-", number: 0, profilePicUrl: "" }, 
    { name: "-", number: 0, profilePicUrl: "" }
  ]);

  const [multiVCardModalOpen, setMultiVCardModalOpen] = useState(false);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const delayDebounceFN = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const insertedContacts = [];

          for (let index = 0; index < contacts.length; index += 1) {
            if (contacts[index].number === undefined) {
              const contactObject = {
                name: contacts[index].name,
                number: 0,
                email: ""
              };
              insertedContacts.push(contactObject);
            } else {
              const contactObject = {
                name: contacts[index].name,
                number: contacts[index].number !== undefined && formatPhoneNumberVCards(contacts[index].number),
                email: ""
              };
  
              const { data } = await api.post("/contact", contactObject);
              insertedContacts.push(data);
            }
          }


          setContactsList(insertedContacts);
        } catch (error) {
          console.log("Multi VCard Preview Use Effect Error:", error);
          toastError(error);
        }
      };

      fetchContacts();
    }, 500);

    return () => clearTimeout(delayDebounceFN);
  }, [contacts, contactsList]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenMultiVCardModal = () => {
    setMultiVCardModalOpen(true);
  };

  const handleCloseMultiVCardModal = () => {
    setMultiVCardModalOpen(false);
  };

  

  //  ************
  //  ** Return **
  //  ************
  return (
    <div style={{ minWidth: "250px" }}>
      <MultiVCardModal
        open={multiVCardModalOpen}
        onClose={handleCloseMultiVCardModal}
        contactsList={contactsList}
        enableStartChat={enableStartChat}
      />


      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AvatarGroup max={4} spacing="small">
            {contactsList.map(contactItem => (
              <Avatar src={contactItem.profilePicUrl} />
            ))}
          </AvatarGroup>
          <Typography
            onClick={handleOpenMultiVCardModal}
            className={classes.multiVCardInfo}
            style={{ marginTop: "12px", marginLeft: "10px", cursor: "pointer", }}
            variant="subtitle1"
            gutterBottom
          >
            {`${truncateString(contactsList[0].name, truncateThreshold)} ${i18n.t("multiVCard.texts.and")} ${contactsList.length } ${i18n.t("multiVCard.texts.otherContacts")}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          
          <Button fullWidth color="inherit" onClick={handleOpenMultiVCardModal}>
            {i18n.t("multiVCard.buttons.showCards")}
          </Button>
        </Grid>
      </Grid>
    </div>    
  );
};

export default MultiVcardPreview;