import React from "react";
import { useHistory } from "react-router-dom";
import { Card, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

import TicketHeaderSkeleton from "../TicketHeaderSkeleton";

const useStyles = makeStyles((theme) => ({  
  ticketHeader: {
    borderRadius:"20px",
    display: "flex",
    backgroundColor: theme.palette.background.default,
    flex: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",

    [theme.breakpoints.down("sm")]: {
      position: "sticky",
      position: "-webkit-sticky",
      flexWrap: "wrap",
      zIndex: "1",
      width: "100%",
      top: 0,
    },
  },
  arrowIcon: { color: theme.palette.text.primary, },
}));

const TicketHeader = ({ loading, children }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tabRequest = (urlParams.get('tab') ? urlParams.get('tab') : "open");



  //  ***************
  //  ** Functions **
  //  ***************
  const handleBack = () => {
    localStorage.setItem("selectedTicketContactId", "-1");
    history.push(`/tickets?tab=${tabRequest}`);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      {loading ? (
        <TicketHeaderSkeleton />
      ) : (
        <Card className={classes.ticketHeader} square>
          <Button color="primary" onClick={handleBack}>
            <ArrowBackIos className={classes.arrowIcon} />
          </Button>
          {children}
        </Card>
      )}
    </>
  );
};

export default TicketHeader;
