import { makeStyles } from "@material-ui/core/styles";
import { ResponsiveBar } from '@nivo/bar';

import { i18n } from "../../../../translate/i18n";
import ErrorBoundary from "../../../../errors/Boundary";
import NoDataImg from '../../../../assets/noData.svg';
import useClosedTicketsPerSubqueue from "../../../../hooks/useClosedTicketsPerSubqueue";

const useStyles = makeStyles(theme => ({
    dashboardContainer: {
		width: "100%",
		height: "100%",
		backgroundColor: theme.palette.background.paper,
		borderRadius: "5px",
		padding: "20px 0px 20px 0px",
		textIndent: "15px",
	},

  emptyBoxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "50px",
  },

  emptyBoxImage: { width: "250px", height: "250px", },

  emptyBoxSpan: { fontSize: "20px", }
}))

const ChartTicketsPerSubqueue = ({ initialDate, finalDate }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  ***************
  //  ** Functions **
  //  ***************
  const GetClosedTicketsPerSubqueue = (initialDate, finalDate) => {
    const structuredInitialDate = initialDate ? `${initialDate}T00:00:01` : "2020-12-29T00:00:01";
    const structuredFinalDate = finalDate ? `${finalDate}T23:59:59` : "2022-12-29T23:59:59";
    const queryResult = useClosedTicketsPerSubqueue({
      initialDate: structuredInitialDate, 
      finalDate: structuredFinalDate,
      considerInternalChats: false
    });
    return queryResult;
  }

  

  //  ***********
  //  ** Datas **
  //  ***********
  const closedTicketsList = GetClosedTicketsPerSubqueue(initialDate, finalDate);
  const closedTicketsListCount = closedTicketsList === 0 ? 0 : closedTicketsList.count;
  const closedTicketsListRows = closedTicketsList === 0 ? 0 : closedTicketsList.rows.map(obj => obj.subqueue)

  let structuredClosedTicketsList = closedTicketsList === 0 ? 0 : closedTicketsListCount.map(countItem => {
    const matchingRow = closedTicketsListRows.find(row => row.id === countItem.subqueueId);
    return { ...countItem, name: matchingRow ? matchingRow.name : null };
  });

  structuredClosedTicketsList = closedTicketsList === 0 ? 0 : structuredClosedTicketsList.map(item => {
    return { subqueueId: item.subqueueId, Quantidade: item.count, name: item.name };
  })



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.dashboardContainer}>
      <ErrorBoundary>
        {(structuredClosedTicketsList !== 0 && structuredClosedTicketsList.length > 0) && (
          <ResponsiveBar
              data={structuredClosedTicketsList}
              keys={['Quantidade']}
              theme={{ 
                textColor: "#999",
                tooltip: { container: { background: "#999", color: "#fff" } },
              }}
              indexBy="name"
              margin={{ top: 50, right: 130, bottom: 50, left: 100 }}
              padding={0.3}
              layout="horizontal"
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={"#F4323C"}
              borderColor={{from: 'color', modifiers: [['darker', 2]]}}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legendPosition: 'middle',
                  legendOffset: 32
              }}
              axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legendPosition: 'middle',
                  legendOffset: -40,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={"#fff"}
              legends={[{
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemTextColor: "#999",
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [{ on: 'hover', style: { itemOpacity: 1 } }],
              }
            ]}
            motionConfig={{
              mass: 1,
              tension: 170,
              friction: 71,
              clamp: false,
              precision: 0.01,
              velocity: 0
            }}
            role="application"
          />
        )}
        
        {(structuredClosedTicketsList === 0 || structuredClosedTicketsList.length === 0) && (
          <div className={classes.emptyBoxContainer}>
            <img className={classes.emptyBoxImage} src={NoDataImg} alt="No Data" />
            <span className={classes.emptyBoxSpan}>{i18n.t("dashboard.emptyBox")}</span>
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default ChartTicketsPerSubqueue;