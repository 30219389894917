import React, { useState, useEffect, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Videoplayer } from "@openabos/react-circular-player";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Divider,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ClearIcon from "@material-ui/icons/Clear";
import FormatListBulletedOutlined from '@material-ui/icons/FormatListBulletedOutlined';
import GetApp from '@material-ui/icons/GetApp';
import GifOutlinedIcon from '@material-ui/icons/GifOutlined';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LocationOnOutlined from "@material-ui/icons/LocationOnOutlined";
import MicIcon from "@material-ui/icons/Mic";
import MoodIcon from "@material-ui/icons/Mood";
import MoreVert from "@material-ui/icons/MoreVert";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import SendIcon from "@material-ui/icons/Send";
import TripOriginOutlinedIcon from '@material-ui/icons/TripOriginOutlined';
import WallpaperOutlinedIcon from '@material-ui/icons/WallpaperOutlined';
import clsx from "clsx";
import data from '@emoji-mart/data';
import GifPicker from 'gif-picker-react';
import MicRecorder from "mic-recorder-to-mp3";
import Picker from '@emoji-mart/react';
import "alertifyjs/build/css/alertify.css";
import "@openabos/react-circular-player/dist/cjs/index.css";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import api from "../../services/api";
import Audio from "../Audio";
import CameraModal from "../CameraModal";
import formatPhoneNumberBaileys from "../../utils/formatPhoneNumberBaileys";
import ForwardMessageContactModal from "../ForwardMessageContactModal";
import GroupMembersMentionList from "../GroupMembersMentionList";
import LocationModal from "../LocationModal";
import LocationPreview from "../LocationPreview";
import MessageEditModal from "../MessageEditModal";
import ModalImageCors from "../ModalImageCors";
import MultiVcardPreview from "../MultiVcardPreview";
import PollModal from "../PollModal";
import PollPreviewQuotedMessage from "../PollPreviewQuotedMessage";
import QuickAnswersList from "../QuickAnswersList";
import RecordingTimer from "./RecordingTimer";
import saveFile from "../../utils/saveFile";
import ShareContactsModal from "../ShareContactsModal";
import StickersModal from "../StickersModal";
import toastError from "../../errors/toastError";
import VcardPreview from "../VcardPreview";
import ViewOnceMessage from "../ViewOnceMessage";
import "../../styles/react-circular-player-new-styles.css";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mediaNameSpan: { inlineSize: "80%", overflowWrap: "break-word", },

  messageMediaVideo: { width: "200px", height: "200px", },

  mainWrapper: {
    borderRadius:"20px",
    background: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: { position: "fixed", bottom: 0, width: "100%", },
  },

  newMessageBox: {
    borderRadius:"20px",
    background: theme.palette.background.default,
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },

  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    background: theme.palette.background.paper,
    display: "flex",
    borderRadius: 20,
    flex: 1,
    position: "relative",
  },

  messageInput: { borderRadius:"20px", paddingLeft: 10, flex: 1, border: "none", },

  blockedContactPlaceholder: { color: "#888", cursor: "not-allowed", },

  sendMessageIcons: { borderRadius:"20px", color: "grey", },

  uploadInput: { borderRadius:"20px", display: "none", },

  viewMediaInputWrapper: {
    borderRadius:"20px",
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  emojiBox: {
    borderRadius:"20px",
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
    zIndex: 99999,
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: { color: green[500], opacity: "70%", },

  recorderWrapper: { display: "flex", alignItems: "center", alignContent: "middle", },

  cancelAudioIcon: { color: "red", },

  sendAudioIcon: { color: "green", },

  replyingMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
  },

  replyingMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  replyingMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyingContactMsgSideColor: { flex: "none", width: "4px", backgroundColor: "#35cd96", },

  replyingSelfMsgSideColor: { flex: "none", width: "4px", backgroundColor: "#6bcbef", },

  messageContactName: { display: "flex", color: "#6bcbef", fontWeight: 500, },

  moreVert: { color: "grey", },

  stickerMessage: {
    maxHeight: 225,
    maxWidth: 225,
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: { maxHeight: 150, maxWidth: 150, },
  },
}));

const MessageInput = ({ ticketStatus, isForwarding, updateIsForwarding, selectedForwardMessages,
  updateSelectedForwardMessages, ticketType, lastMessage, handleOpenFileContextModal,
  handleOpenViewOnceFileContextModal, handleOpenImageToStickerContextModal, isContactBlocked,
  isContactInactive, isContactChannel, setBotAnswerDelay, ticketReadOnly, whatsappId
}) => {  
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { setReplyingMessage, replyingMessage } = useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);
  const { ticketId } = useParams();

  const [cameraModalOpen, setCameraModalOpen] = useState(false);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [pollModalOpen, setPollModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const [inputTextBlurInteraction, setInputTextBlurInteraction] = useState(true);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [signMessageSetting, setSignMessageSetting] = useState();
  const [enterIsSendMobileSetting, setEnterIsSendMobileSetting] = useState("disabled");
  const [shareContactModalOpen, setShareContactModalOpen] = useState(false);
  const [forwardMessageContactModalOpen, setForwardMessageContactModalOpen] = useState(false);
  const [stickersModalOpen, setStickersModalOpen] = useState(false);
  
  const [isSendingGif, setIsSendingGif] = useState(false);
  const [isSendingSticker, setIsSendingSticker] = useState(false);

  const inputContainer = useRef();
  const inputRef = useRef();

  const [messageEditModalOpen, setMessageEditModalOpen] = useState(false);

  const [lastMessageModal, setLastMessageModal] = useState(null);

  // ***---- Text Message Miscellaneous ----***
  const [groupMembers, setGroupMembers] = useState([]);
  const [typeAt, setTypeAt] = useState(false);
  const [mentionsArray, setMentionsArray] = useState([]);
  const [groupTicketId, setGroupTicketId] = useState(-1);

  const [quickAnswers, setQuickAnswer] = useState([]);
  const [typeBar, setTypeBar] = useState(false);


  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const apiCalls = [
          api.get("/settings/sendSignatureOnMessages"),
          api.get("/settings/enterIsSendMobile")
        ];

        const [
          SendSignatureOnMessagesResponse,
          EnterIsSendMobileResponse
        ] = await Promise.all(apiCalls);

        setSignMessageSetting(SendSignatureOnMessagesResponse.data.value); // getting "sendSignatureOnMessages" setting
        setEnterIsSendMobileSetting(EnterIsSendMobileResponse.data.value); // getting "enterIsSendMobile" setting
      } catch (error) {
        console.log("Message Input Use Effect Error:", error);
        toastError(error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    if (lastMessage && lastMessage.fromMe && lastMessage.mediaType && !messageEditModalOpen) {
      setLastMessageModal(lastMessage);
    }
  }, [lastMessage, messageEditModalOpen]);

  useEffect(() => {
    inputRef.current.focus();
    isSmallScreen && inputRef.current.blur();
  }, [replyingMessage, isSmallScreen]);

  useEffect(() => {
    inputRef.current.focus();
    isSmallScreen && inputRef.current.blur();
    return () => {
      setShowEmoji(false);
      setShowGif(false);
      setIsSendingGif(false);
      setIsSendingSticker(false);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage,  isSmallScreen]);

  useEffect(() => {
    if (isSendingGif || isSendingSticker) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
      }, 800);
      return () => { clearInterval(timer); };
    } 
    else {
      setProgress(0);
    }
  }, [isSendingGif, isSendingSticker]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenMessageEditModal = () => { setMessageEditModalOpen(true); };
  const handleCloseMessageEditModal = () => { setMessageEditModalOpen(false); };
  
  const handleOpenCameraModal = () => {setCameraModalOpen(true); };
  const handleCloseCameraModal = () => { setCameraModalOpen(false); };

  const handleOpenLocationModal = () => { setLocationModalOpen(true); };
  const handleCloseLocationModal = () => { setLocationModalOpen(false); };

  const handleOpenPollModal = () => { setPollModalOpen(true); };
  const handleClosePollModal = () => { setPollModalOpen(false); };
  
  const handleDeviceHasCameras = () => { handleOpenCameraModal(); };

  const checkMessageMedia = (message) => {
    if (message.mediaType === "location" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]
      let linkLocation = locationParts[1]

      let descriptionLocation = null

      if (locationParts.length > 2)
        descriptionLocation = message.body.split('|')[2]

      return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    } else if (message.mediaType === "button") { 
      const buttonMessage = message.body;
      const buttonMessageTitleFooter = buttonMessage.split("##");
      const buttonMessageArray = buttonMessageTitleFooter[0].split("||");
      const buttonTitle = buttonMessageTitleFooter[1];
      const buttonFooter = buttonMessageTitleFooter[2];

      const buttonBody = buttonMessageArray[0];
      const buttonOptionsArray = buttonMessageArray.slice(1);

      return(
        <>
          <b>{buttonTitle}</b>
          <p>{buttonBody}</p>
          <i className={classes.buttonListFooter}>{buttonFooter}</i>
          <hr />
          <center>
            {buttonOptionsArray.map((option, i) => (
              <Button
                color="inherit"
                variant="outlined"
                target="_blank"
                disabled
              >
                {option}
              </Button>
            ))}
          </center>
        </>
      );

    } else if (message.mediaType === "list") { 
      const listMessage = message.body;
      const listMessageArray = listMessage.split("||");

      const headerArray = listMessageArray[0].split("%%");
      const optionsArray = listMessageArray.slice(1);
      return (
        <>
          <b>{headerArray[0]}</b>
          <p>{headerArray[1]}</p>
          <i className={classes.buttonListFooter}>{headerArray[3]}</i>
          <center>
            <Button
              startIcon={<FormatListBulletedOutlined />}
              color="inherit"
              variant="outlined"
              target="_blank"
              href={message.mediaUrl}
              disabled
            >
              {headerArray[2]}
            </Button>
          </center>
          
          <hr />

          <center>
            {optionsArray.map((option, i) => (
              <Button
                color="inherit"
                variant="outlined"
                target="_blank"
                href={message.mediaUrl}
                disabled
              >
                {option.split("%%")[0]}
              </Button>
            ))}
          </center>
        </>
      );
    } else if (message.mediaType === "vcard") {
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      return <VcardPreview contact={contact} numbers={obj[0]?.number} enableHandleNewChat={false} />
    } else if (message.mediaType === "multi_vcard") {
      const vcardsLimiter = "\n----vcardLimiter----\n";
      const vcards = message.body.split(vcardsLimiter).filter(vcard => vcard !== "");
      const vcardsElements = [];
      const contactsList = [];

      for (let vcardIndex = 0; vcardIndex < vcards.length; vcardIndex += 1) {
        vcardsElements.push(vcards[vcardIndex].split("\n"));
      }

      for (let vcardElementIndex = 0; vcardElementIndex < vcardsElements.length; vcardElementIndex += 1) {
        try {
          contactsList.push({
            number: vcardsElements[vcardElementIndex][4].split("waid=")[1].split(":")[0],
            name: vcardsElements[vcardElementIndex][3].split(":")[1],
          })
        } catch (err) {
          try {
            contactsList.push({
              number: vcardsElements[vcardElementIndex][6].split("waid=")[1].split(":")[0],
              name: vcardsElements[vcardElementIndex][3].split(":")[1],
            })
          } catch (err) {
            contactsList.push({
              number: undefined,
              name: vcardsElements[vcardElementIndex][3].split(":")[1],
            });
          }
        }
      }

      return <MultiVcardPreview contacts={contactsList} enableStartChat={false} />;
    } else if (message.mediaType === "image" && !message.isViewOnce) {
      return <ModalImageCors imageUrl={message.mediaUrl} replyMessage={true} />;
    } else if (message.mediaType === "image" && message.isViewOnce) {
      return <ViewOnceMessage message={message} isPhoto={true} />
    } else if (message.mediaType === "sticker") {
      return <img className={classes.stickerMessage} src={message.mediaUrl} alt={message.body} />
    } else if (message.mediaType === "audio") {
      return <Audio url={message.mediaUrl} isVoice={message.isVoice} isFromMe={message.fromMe} />
    } else if (message.mediaType === "video" && !message.isViewOnce) {
      return (
        <video
          className={classes.messageMediaVideo}
          src={message.mediaUrl}
          controls={!message.isGif}
          autoPlay={message.isGif}
          muted={message.isGif}
          loop={message.isGif}
        />
      );
    } else if (message.mediaType === "video" && message.isViewOnce) {
      return <ViewOnceMessage message={message} isPhoto={false} />
    } else if (message.mediaType === "ptv") {
      return (
        <Videoplayer size={200} src={message.mediaUrl} boundaryColor={"#ff6961"} />
      );
    } else if (message.mediaType === "poll_creation") {
      return <PollPreviewQuotedMessage message={message} />
    } else {

      const handleClick = (event) => {
        event.preventDefault();
        saveFile(message);
      }

      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="inherit"
              variant="outlined"
              target="_blank"
              href="#"
              onClick={handleClick}
            >
              {i18n.t("messagesInput.download")}
            </Button>
            
          </div>
          <Divider />
        </>
      );
    }
  };

  const handleOpenShareContactModal = () => { setShareContactModalOpen(true); };

  const handleCloseShareContactModal = () => {
    setShareContactModalOpen(false);
    setReplyingMessage(null);
  };

  const handleOpenStickersModalOpen = () => { setStickersModalOpen(true); };
  const handleCloseStickersModalOpen = () => { setStickersModalOpen(false); };

  const handleOpenForwardMessageContactModal = () => { setForwardMessageContactModalOpen(true); };
  const handleCloseForwardMessageContactModal = () => { setForwardMessageContactModalOpen(false); };

  const handleChangeInput = (e) => {
    if (inputMessage === "" && e.target.value === "\n") return;

    const charsLimit = 64000;
    const newValue = e.target.value.length >= charsLimit
      ? e.target.value.substring(0, charsLimit - 1)
      : e.target.value;

    setInputMessage(newValue);
    handleLoadQuickAnswer(newValue);
    handleLoadGroupMembers(newValue);
    setInputTextBlurInteraction(false);
  };

  const handleQuickAnswersClick = (value) => {
    setInputMessage(value);
    setTypeBar(false);
  };

  const handleGroupMembersClick = (memberNumber) => {
    setMentionsArray((previousValue) => {
      return groupTicketId === ticketId
        ? [...previousValue, { memberNumber: memberNumber }]
        : [{ memberNumber: memberNumber }];
    });
    
    setInputMessage((previousValue) => {
      const lastAtIndex = previousValue.lastIndexOf("@");
      const beforeMentionText = previousValue.substring(0, lastAtIndex + 1);
      return `${beforeMentionText}${memberNumber} `;
    });

    setTypeAt(false);
  };

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) { return; }

    const uploadedFiles = [];
    for (let index = 0; index < e.target.files.length && index < 5; index++) {
      uploadedFiles.push(e.target.files[index]);
    }

    handleOpenFileContextModal(uploadedFiles);
  };

  const handleChangeViewOnceMedia = (e) => {
    if (!e.target.files) { return; }

    const fileType = e.target.files[0].type.split("/")[0];
    if (fileType !== "image" && fileType !== "video") {
      e.target.value = "";
      toast.info(i18n.t("messagesInput.toasts.onlyImageAndVideoAllowed"));
    } else {
      const uploadedFile = e.target.files[0];
      handleOpenViewOnceFileContextModal(uploadedFile);
    }
  };

  const handleImageToSticker = (e) => {
    if (!e.target.files) { return; }
    
    const file = e.target.files[0];
    const fileType = file.type.split("/")[0];

    if (fileType === "image") { handleOpenImageToStickerContextModal(file); }
    else {
      toast.info(i18n.t("messagesInput.toasts.onlyImageAllowed"));
      document.getElementById("upload-image-to-sticker").value = "";
    }
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) { 

      const uploadedFiles = [];
      for (let index = 0; index < e.clipboardData.files.length && index < 5; index++) {
        uploadedFiles.push(e.clipboardData.files[index]);
      }

      handleOpenFileContextModal(uploadedFiles);
    }
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
    
    setLoading(true);

    if (ticketType === 2) { setBotAnswerDelay(true); }
    
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessageSetting === "enabled" || ticketType !== 0
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim(),
      quotedMsg: replyingMessage,
      groupMembersMention: mentionsArray
    };

    setInputMessage("");
    setMentionsArray([]);

    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (error) {
      console.log("Handle Send Message Error:", error);
      toastError(error);
    }

    setShowEmoji(false);
    setShowGif(false);
    setLoading(false);
    setReplyingMessage(null);

    if (ticketType === 2) { setBotAnswerDelay(false); }
  };

  const handleStartRecording = async () => {
    setLoading(true);
    
    navigator.permissions.query({ name: 'microphone' }).then(async permissionStatus => {

      // ***---- Permission: Granted or Requested ----***
      if (permissionStatus.state === "granted" || permissionStatus.state === "prompt") {
        try {
          let track;
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          const audioTracks = stream.getAudioTracks();

          // ***---- No Mic Detected ----***
          if (audioTracks.length === 0) {
            toast.error(i18n.t("messagesInput.micAudioConditions.micNotDetected"));
            setLoading(false);
            return;
          }

          // ***----- Mic is Muted by Device ----***
          track = audioTracks[0];
          if (track.muted) {
            toast.error(i18n.t("messagesInput.micAudioConditions.permissionDeniedBySystem"));
            setLoading(false);
            return;
          }

          // ***---- Mic is Disabled by User ----***
          if (!track.enabled) {
            toast.error(i18n.t("messagesInput.micAudioConditions.micNotEnabled"));
            setLoading(false);
            return;
          }

          // ***---- Lost Connection with Mic ----***
          if (track.readyState === "ended") {
            toast.error(i18n.t("messagesInput.micAudioConditions.lostConnection"));
            setLoading(false);
            return;
          }

          await Mp3Recorder.start();
          setRecording(true);
          setLoading(false);
        } catch (error) {
          if (error.message === "Permission denied by system") { toast.error(i18n.t("messagesInput.micAudioConditions.permissionDeniedBySystem")); }
          else {
            console.log("Handle Start Recording Error:", error);
            toastError(error);
          }

          setLoading(false);
        }
      }
      
      // ***---- Permission: Denied ----***
      else if (permissionStatus.state === "denied") {
        toast.info(i18n.t("messagesInput.toasts.audioAccessNotAllowed"));
      }
    })
    setLoading(false);
  };

  const handleLoadGroupMembers = async (value) => {
    // ***---- There are no typed text ----***
    if (value === null || value === undefined) {
      setTypeAt(false);
      return;
    }

    // ***---- There are no typed '@' ----***
    const atIndex = value.lastIndexOf("@");

    if (atIndex === -1) {
      setTypeAt(false);
      return;
    }

    // ***---- Fetching group members ----***
    const isAtLastCharacter = atIndex === value.length - 1;
    const isFiltering = !["", " "].includes(value[atIndex + 1]);

    if (ticketStatus === "group" && (isAtLastCharacter || isFiltering)) {
      try {
        if (groupMembers.length > 0 && groupTicketId === ticketId) {
          setTypeAt(true);
          return;
        }

        setMentionsArray([]);
        setGroupMembers([]);

        const apiCalls = [api.get(`/groupParticipants/${ticketId}`), api.get(`/whatsappInfo/${whatsappId}`)];
        const [GroupParticipantsResponse, WhatsappInfoResponse] = await Promise.all(apiCalls);
        const whatsappJid = formatPhoneNumberBaileys(WhatsappInfoResponse.data.id);
        
        const groupMembersArray = GroupParticipantsResponse.data.filter(member => member.number !== whatsappJid);
        groupMembersArray.sort((memberA, memberB) => memberA.name.localeCompare(memberB.name));

        setGroupMembers(groupMembersArray);
        setTypeAt(groupMembersArray.length > 0);
        setGroupTicketId(ticketId);
      }
      catch (exception) {
        setTypeAt(false);
      }
    }

    // ***---- Ticket is not group or typed '@' is not last character ----***
    else {
      setTypeAt(false);
    }
  };

  const handleLoadQuickAnswer = async (value) => {
    // ***---- There are no typed text ----***
    if (value === null || value === undefined) {
      setTypeBar(false);
      return;
    }

    // ***---- There are no typed "/" ----***
    const slashIndex = value.indexOf("/");

    if (slashIndex === -1) {
      setTypeBar(false);
      return;
    }

    // ***---- Fetching Quick Answers ----***
    const isFirstCharacter = slashIndex === 0;
    const isFiltering = !["", " "].includes(value[slashIndex + 1]);

    if (isFirstCharacter && isFiltering) {
      try {
        const { data: { quickAnswers } } = await api.get("/allQuickAnswers/");
        setQuickAnswer(quickAnswers);

        if (quickAnswers.length > 0) setTypeBar(true);
        else setTypeBar(false);
      } catch (exception) {
        setTypeBar(false);
      }
    }

    // ***---- "/" is not first character AND filter is disabled ----***
    else {
      setTypeBar(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);
      if (replyingMessage) { formData.append("quotedMsgId", replyingMessage.id); }

      await api.post(`/messages/${ticketId}`, formData);
    } catch (error) {
      console.log("Handle Upload Audio Error:", error);
      toastError(error);
    }

    setRecording(false);
    setReplyingMessage(null);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (error) {
      console.log("Handle Cancel Audio Error:", error);
      toastError(error);
    }
  };

  const handleOpenMenuClick = (event) => { setAnchorEl(event.currentTarget); };

  const handleMenuItemClick = (event) => { setAnchorEl(null); };

  const handleCancelForwarding = () => {
    updateIsForwarding(false);
    updateSelectedForwardMessages([]);
  };

  const handleConfirmForwarding = () => {
    if (selectedForwardMessages.length === 0) {
      toast.error(i18n.t("messagesList.toast.forwardMessage.noSelectedMessages"));
    } else {
      handleOpenForwardMessageContactModal();
    }
  };

  const renderReplyingMessage = (message) => {
    return (
      <div className={classes.replyingMsgWrapper}>
        <div className={classes.replyingMsgContainer}>
          <span
            className={clsx(classes.replyingContactMsgSideColor, {
              [classes.replyingSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          <div className={classes.replyingMsgBody}>
            {!message.fromMe && (
              <span className={classes.messageContactName}>
                {message.contact?.name}
              </span>
            )}

            {(message.mediaType !== "vcard"
            && message.mediaType !== "multi_vcard"
            && message.mediaType !== "image"
            && message.mediaType !== "audio"
            && message.mediaType !== "sticker"
            && message.mediaType !== "video"
            && message.mediaType !== "location"
            && message.mediaType !== "application"
            && message.mediaType !== "poll_creation"
            ) && message.body}

            {(message.mediaType === "vcard" 
            || message.mediaType === "multi_vcard"
            || message.mediaType === "image"
            || message.mediaType === "sticker"
            || message.mediaType === "audio"
            || message.mediaType === "video"
            || message.mediaType === "location"
            || message.mediaType === "application"
            || message.mediaType === "ptv"
            || message.mediaType === "poll_creation"
            ) && checkMessageMedia(message)}
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={((ticketReadOnly) || (ticketStatus !== "open" && ticketStatus !== "group" && user.profile === "user"))}
          onClick={() => setReplyingMessage(null)}
        >
          <ClearIcon className={classes.sendMessageIcons} />
        </IconButton>
      </div>
    );
  };

  const handleIsSendingSticker = () => { setIsSendingSticker(prevStatus => !prevStatus); };

  const handleSendGif = (tenorImageUrl) => {
    setIsSendingGif(true);

    try {
      const request = new XMLHttpRequest();
      request.open("GET", tenorImageUrl, true);
      request.responseType = "blob";
      request.onload = async function sendGif() {
        if (request.status >= 200 && request.status < 400) {
          const formData = new FormData();
          formData.append("fromMe", true);
          formData.append("isGif", true);
          formData.append("medias", this.response, tenorImageUrl.split("/").slice(-1).toString());

          await api.post(`/messagesGif/${ticketId}`, formData);
          setIsSendingGif(false);
        }
        else {
          toast.error(i18n.t("messagesInput.toasts.sendGif"));
          setIsSendingGif(false);
        }
      };
      request.send();
    } catch (error) {
      setIsSendingGif(false);
      console.log("Handle Send Gif Error:", error);
      toastError(error);
    }
    setShowGif(false);
  };

  const handleEditMessageArrowUp = async (event) => {
    if (event.shiftKey) {
      return;
    }

    else if (event.key === "Enter" && (!isSmallScreen || (isSmallScreen && enterIsSendMobileSetting === "enabled"))) {
      handleSendMessage();
    }

    else if (lastMessage && lastMessage.fromMe) {
      const userIdOrAdmin = lastMessage.userId === user.id || user.profile === "admin";
      const notTicketPendingAndUserNotAdmin = !(ticketStatus === "pending" && user.profile === "user");

      if (
        event.key === "ArrowUp"
        && inputMessage === ""
        && userIdOrAdmin
        && (
          (
            ticketType === 0
            && lastMessage.ack !== 0 && lastMessage.ack !== 1
            && notTicketPendingAndUserNotAdmin
            && !lastMessage.isViewOnce
            && [
              // "application",
              "chat",
              // "image",
              "transference_observation",
              // "video"
            ].includes(lastMessage.mediaType)
          )
          || (
            ticketType === 1
            && ["application", "chat", "image", "video"].includes(lastMessage.mediaType)
          )
          || (
            ticketType === 3
            && lastMessage.userId === user.id
            && ["application", "chat", "image", "video"].includes(lastMessage.mediaType)
          )
        )
      ) {
        try {
          await api.put(`/messagesValidateEdit/${lastMessage.id}`, { ticketType });
          handleOpenMessageEditModal();
        } catch (exception) {
          console.log("Exception caught while arrow upping to edit messages:", exception);
          toastError(exception);
        }
      }
    }
  }



  //  ************
  //  ** Return **
  //  ************

  // ***---- Message Input not Enabled ----***
  if (isContactBlocked || isContactInactive || isContactChannel) {
    return (
      <Paper square elevation={0} className={classes.mainWrapper}>
        <div className={classes.newMessageBox}>
          {/* 
            ****************
            ** Input Area **
            ****************
          */}
          <div className={classes.messageInputWrapper}>
            <Typography variant="subtitle1" className={`${classes.messageInput} ${classes.blockedContactPlaceholder}`}>
              {isContactBlocked && i18n.t("messagesInput.placeholderBlockedContact")}
              {isContactInactive && i18n.t("messagesInput.placeholderInactiveContact")}
              {isContactChannel && i18n.t("messagesInput.placeholderChannelContact")}
						</Typography>
          </div>
        </div>
      </Paper>
    );
  }
  
  // ***---- Message Input Temporary not Enabled ----***
  else if (isSendingGif || isSendingSticker) {
    return (
      <Paper ref={inputContainer} elevation={0} square className={classes.viewMediaInputWrapper}>
        {(loading || isSendingGif || isSendingSticker) && (
          <div>
            <CircularProgress className={classes.circleLoading} variant="determinate" value={progress} />
          </div>
        )}

        <IconButton
          aria-label="send-upload"
          component="span"
          disabled={ticketReadOnly || loading || isSendingGif || isSendingSticker || (ticketStatus !== "open" && ticketStatus !== "group" && user.profile === "user")}
        >
          {(isSendingGif || isSendingSticker) && <HourglassEmptyIcon className={classes.sendMessageIcons} />}
        </IconButton>
      </Paper>
    );
  }
  
  // ***---- Message Input Enabled ----***
  else {
    return (
      <Paper square elevation={0} className={classes.mainWrapper}>

        {lastMessageModal && (
          <MessageEditModal
            open={messageEditModalOpen}
            onClose={handleCloseMessageEditModal}
            message={lastMessageModal}
          />
        )}

        <PollModal
          open={pollModalOpen}
          onClose={handleClosePollModal}
          ticketId={ticketId}
        />

        
        <LocationModal
          open={locationModalOpen}
          onClose={handleCloseLocationModal}
          ticketId={ticketId}
        />

        <CameraModal
          open={cameraModalOpen}
          onClose={handleCloseCameraModal}
          ticketId={ticketId}
        />

        <StickersModal
          open={stickersModalOpen}
          onClose={handleCloseStickersModalOpen}
          ticketId={ticketId}
          handleIsSendingSticker={handleIsSendingSticker}
        />

        <ShareContactsModal
          open={shareContactModalOpen}
          onClose={handleCloseShareContactModal}
          ticket={ticketId}
          ticketType={ticketType}
        />

        {replyingMessage && (
          <ShareContactsModal
            open={shareContactModalOpen}
            onClose={handleCloseShareContactModal}
            ticket={ticketId}
            replyingMessageId={replyingMessage.id}
            ticketType={ticketType}
          />
        )}

        <ForwardMessageContactModal
          ticketId={ticketId}
          open={forwardMessageContactModalOpen}
          onClose={handleCloseForwardMessageContactModal}
          selectedForwardMessages={selectedForwardMessages}
          updateIsForwarding={updateIsForwarding}
          updateSelectedForwardMessages={updateSelectedForwardMessages}
        />

        {replyingMessage && renderReplyingMessage(replyingMessage)}

        <div className={classes.newMessageBox}>
          {/* 
            *********************
            ** Emojis and Gifs **
            *********************
          */}       
          <Tooltip title={i18n.t("messagesInput.tooltips.emojis")} placement="top-start" arrow>
            <IconButton
              aria-label="emojiPicker"
              component="span"
              disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
              onClick={(e) => setShowEmoji((prevState) => !prevState)}
            >
              <MoodIcon className={classes.sendMessageIcons} />
            </IconButton>
          </Tooltip>
          
          {(ticketType === 0 || ticketType === 3) && (
            <Tooltip title={i18n.t("messagesInput.tooltips.emojis")} placement="top-start" arrow>
              <IconButton
                aria-label="gifPicker"
                component="span"
                disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                onClick={(e) => setShowGif((prevState) => !prevState)}
              >
                <GifOutlinedIcon className={classes.sendMessageIcons} />
              </IconButton>
            </Tooltip>
          )}

          {showEmoji ? (
            <ClickAwayListener onClickAway={() => setShowEmoji(false)}>
              <div className={classes.emojiBox}>
                <Picker
                  data={data}
                  onEmojiSelect={handleAddEmoji}
                  emojiButtonSize={30}
                  emojiSize={25}
                  icons={"outline"}
                  locale={localStorage.getItem("i18nextLng").substring(0, 2)}
                  navPosition={"bottom"}
                  perLine={12}
                  showPreview={true}
                  previewEmoji={"point_up"}
                  previewPosition={"bottom"}
                  set={"native"}
                  showSkinTones={true}
                  skinTonePosition={"search"}
                  theme={localStorage.getItem("theme")}
                />
              </div>
            </ClickAwayListener>
          ) : null}

          {showGif ? (
            <ClickAwayListener  onClickAway={() => setShowGif(false)}>
              <div className={classes.emojiBox}>
                <GifPicker 
                  tenorApiKey={"AIzaSyCB-xlgy5fxcRNg-_YVAimFEJufwPTORbc"}
                  theme={localStorage.getItem("theme")}
                  onGifClick={(tenorImage) => handleSendGif(tenorImage.url)}
                  autoFocusSearch={"true"}
                  clientKey={"bestzap"}
                  locale={localStorage.getItem("i18nextLng")}
                />
              </div>
            </ClickAwayListener>
          ) : null}


          
            
          {/* 
            ****************
            ** Input Area **
            ****************
          */}
          <div className={classes.messageInputWrapper}>
            <InputBase
              id="textArea"
              spellCheck="true"
              inputRef={(input) => {
                input && input.focus();
                isSmallScreen && inputTextBlurInteraction && inputRef.current.blur();
                input && (inputRef.current = input);
              }}
              className={classes.messageInput}
              placeholder={
                (ticketStatus === "open" || user.profile === "admin" || ticketStatus === "group") && !isSmallScreen && !ticketReadOnly
                  ? i18n.t("messagesInput.placeholderOpen")
                  : (ticketStatus === "open" || user.profile === "admin" || ticketStatus === "group") && isSmallScreen && !ticketReadOnly
                  ? i18n.t("messagesInput.placeholderOpenMobile")
                  : ticketStatus === "group" && ticketReadOnly
                  ? i18n.t("messagesInput.placeholderReadOnlyGroup")
                  : i18n.t("messagesInput.placeholderClosed")
              }
              multiline
              maxRows={5}
              value={inputMessage}
              onChange={handleChangeInput}
              disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
              onPaste={(e) => {
                ticketStatus === "open" && ticketType !== 2 && handleInputPaste(e);
                ticketStatus === "pending" && ticketType !== 2 && user.profile === "admin" && handleInputPaste(e);
                ticketStatus === "group" && ticketType !== 2 && handleInputPaste(e);
              }}
              onKeyDown={async (event) => await handleEditMessageArrowUp(event)}
            />

            <QuickAnswersList
              quickAnswers={quickAnswers}
              handleQuickAnswersClick={handleQuickAnswersClick}
              typeBar={typeBar}
              setTypeBar={setTypeBar}
              filter={inputMessage}
            />

            <GroupMembersMentionList
              groupMembers={groupMembers}
              handleGroupMembersClick={handleGroupMembersClick}
              typeAt={typeAt}
              setTypeAt={setTypeAt}
              filter={inputMessage}
            />
          </div>


        
          {/* 
            ************************
            ** Forwarding Message **
            ************************        
          */}
          {isForwarding && (
            <>

            {/* 
              ************
              ** Cancel **
              ************
            */}
            <Tooltip title={i18n.t("messagesInput.tooltips.cancel")} placement="top-start" arrow>
              <IconButton onClick={handleCancelForwarding}>
                <CancelOutlinedIcon className={classes.sendMessageIcons} />
              </IconButton>
            </Tooltip>



            {/* 
              *************
              ** Confirm **
              *************
            */}
            <Tooltip title={i18n.t("messagesInput.tooltips.confirm")} placement="top-start" arrow>
              <IconButton onClick={handleConfirmForwarding}>
                <CheckCircleOutlineOutlinedIcon className={classes.sendMessageIcons} />
              </IconButton>
            </Tooltip>
            </>
          )}

        

          {/* 
            ****************************
            ** Not Forwarding Message **
            ****************************
            
            // just when ticket is not a bot chat
          */}
          {!isForwarding && ticketType !== 2 && (
            <>
            <div>
              {/* 
                ***********************
                ** Icon to Open Menu **
                ***********************
              */}
              <Tooltip title={i18n.t("messagesInput.tooltips.moreVert")} placement="top-start" arrow>
                <IconButton 
                  className={classes.moreVert} 
                  aria-controls="simple-menu" 
                  aria-haspopup="true" 
                  onClick={handleOpenMenuClick}
                  disabled={ticketReadOnly}
                >
                  <MoreVert></MoreVert>
                </IconButton>
              </Tooltip>

              {/* 
                **********
                ** Menu **
                **********
              */}
              <Menu
                id="simple-menu"
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuItemClick}
              >
                {/*
                  ****************
                  ** Take Photo **
                  ****************
                */}
                {!isSmallScreen && (
                  <MenuItem onClick={handleMenuItemClick}>
                    <Tooltip title={i18n.t("messagesInput.tooltips.takePhoto")} placement="top-start" arrow>
                      <IconButton
                        aria-label="upload"
                        component="span"
                        disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                        onClick={handleDeviceHasCameras}
                      >
                        <CameraAltOutlinedIcon className={classes.sendMessageIcons} />
                      </IconButton>
                    </Tooltip>
                  </MenuItem>
                )}

                {/* 
                  **************
                  ** Stickers **
                  **************
                */}
                {(ticketType === 0 || ticketType === 3) && (
                  <MenuItem onClick={handleMenuItemClick}>
                    <Tooltip title={i18n.t("messagesInput.tooltips.stickers")} placement="top-start" arrow>
                      <IconButton
                        aria-label="emojiPicker"
                        component="span"
                        disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                        onClick={handleOpenStickersModalOpen}
                      >
                        <InsertDriveFileOutlinedIcon className={classes.sendMessageIcons} />
                      </IconButton>
                    </Tooltip>
                  </MenuItem>
                )}

                {/* 
                  ********************
                  ** Create Sticker **
                  ********************
                */}
                {(ticketType === 0 || ticketType === 3) && (
                  <MenuItem onClick={handleMenuItemClick}>
                    <input
                      type="file"
                      id="upload-image-to-sticker"
                      disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                      className={classes.uploadInput}
                      onChange={handleImageToSticker}
                      accept="image/*"
                    />

                    <Tooltip title={i18n.t("messagesInput.tooltips.createStickers")} placement="top-start" arrow>
                      <label htmlFor="upload-image-to-sticker">
                        <IconButton
                          aria-label="emojiPicker"
                          component="span"
                          disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                        ><WallpaperOutlinedIcon className={classes.sendMessageIcons} /></IconButton>
                      </label>
                    </Tooltip>
                  </MenuItem>
                )}

                {/* 
                  *******************
                  ** Contact VCard **
                  *******************
                */}
                <MenuItem onClick={handleMenuItemClick}>
                  <Tooltip title={i18n.t("messagesInput.tooltips.vcard")} placement="top-start" arrow>
                    <IconButton
                      aria-label="upload"
                      component="span"
                      disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                      onClick={handleOpenShareContactModal}
                    >
                      <PersonOutlineOutlinedIcon className={classes.sendMessageIcons} />
                    </IconButton>
                  </Tooltip>
                </MenuItem>

                {/* 
                  **********
                  ** Poll **
                  **********
                */}
                {ticketType === 0 && (
                  <MenuItem onClick={handleMenuItemClick}>
                    <Tooltip title={i18n.t("messagesInput.tooltips.poll")} placement="top-start" arrow>
                      <IconButton
                        aria-label="upload"
                        component="span"
                        disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                        onClick={handleOpenPollModal}
                      >
                        <PollOutlinedIcon className={classes.sendMessageIcons} />
                      </IconButton>
                    </Tooltip>
                  </MenuItem>
                )}

                {/* 
                  **************
                  ** Location **
                  **************
                */}
                {ticketType === 0 && (
                  <MenuItem onClick={handleMenuItemClick}>
                    <Tooltip title={i18n.t("messagesInput.tooltips.location")} placement="top-start" arrow>
                      <IconButton
                        aria-label="upload"
                        component="span"
                        disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                        onClick={handleOpenLocationModal}
                      >
                        <LocationOnOutlined className={classes.sendMessageIcons} />
                      </IconButton>
                    </Tooltip>
                  </MenuItem>
                )}

                {/* 
                  ***************
                  ** View Once **
                  ***************
                */}
                {ticketType === 0 && (
                  <MenuItem onClick={handleMenuItemClick}>
                    <input
                      type="file"
                      multiple={false}
                      id="upload-view-once-button"
                      disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                      className={classes.uploadInput}
                      onChange={handleChangeViewOnceMedia}
                      accept="image/*, video/*"
                    />
                    <Tooltip title={i18n.t("messagesInput.tooltips.viewOnce")} placement="top-start" arrow>
                      <label htmlFor="upload-view-once-button">
                        <IconButton
                          aria-label="upload"
                          component="span"
                          disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                        >
                          <TripOriginOutlinedIcon className={classes.sendMessageIcons} />
                        </IconButton>
                      </label>
                    </Tooltip>
                  </MenuItem>
                )}
                

                {/* 
                  *****************
                  ** Attach File **
                  *****************
                */}
                <MenuItem onClick={handleMenuItemClick}>
                  <input
                    type="file"
                    multiple
                    id="upload-button"
                    disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                    className={classes.uploadInput}
                    onChange={handleChangeMedias}
                  />
                  <Tooltip title={i18n.t("messagesInput.tooltips.attach")} placement="top-start" arrow>
                    <label htmlFor="upload-button">
                      <IconButton
                        aria-label="upload"
                        component="span"
                        disabled={ticketReadOnly || recording || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                      >
                        <AttachFileIcon className={classes.sendMessageIcons} />
                      </IconButton>
                    </label>
                  </Tooltip>
                </MenuItem>
              </Menu>
            </div>



            {/* 
              ******************
              ** Record Audio **
              ******************
            */}
            {inputMessage ? (
              <Tooltip title={i18n.t("messagesInput.tooltips.send")} placement="top-start" arrow>
                <IconButton
                  aria-label="sendMessage"
                  component="span"
                  onClick={handleSendMessage}
                  disabled={((ticketReadOnly) || (ticketStatus !== "open" && ticketStatus !== "group" && user.profile === "user"))}
                >
                  <SendIcon disabled={((ticketReadOnly) || (ticketStatus !== "open" && ticketStatus !== "group" && user.profile === "user"))} className={classes.sendMessageIcons} />
                </IconButton>
              </Tooltip>
            ) : recording ? (
              <div className={classes.recorderWrapper}>
                <IconButton
                  aria-label="cancelRecording"
                  component="span"
                  fontSize="large"
                  onClick={handleCancelAudio}
                >
                  <HighlightOffIcon className={classes.cancelAudioIcon} />
                </IconButton>
                {loading ? (
                  <div>
                    <CircularProgress className={classes.audioLoading} />
                  </div>
                ) : (
                  <RecordingTimer />
                )}

                <IconButton
                  aria-label="sendRecordedAudio"
                  component="span"
                  onClick={handleUploadAudio}
                >
                  <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
                </IconButton>
              </div>
            ) : (
              <Tooltip title={i18n.t("messagesInput.tooltips.record")} placement="top-start" arrow>
                <IconButton
                  aria-label="showRecorder"
                  component="span"
                  disabled={ticketReadOnly || ticketStatus === "closed" || (ticketStatus === "pending" && user.profile !== "admin")}
                  onClick={handleStartRecording}
                >
                  <MicIcon className={classes.sendMessageIcons} />
                </IconButton>
              </Tooltip>
            )}

          </>
          )}



          {/* 
            ****************************
            ** Not Forwarding Message **
            ****************************
            
            // ticket is a bot chat: only send icon is available
          */}
          {ticketType === 2 && (
            <Tooltip title={i18n.t("messagesInput.tooltips.send")} placement="top-start" arrow>
              <IconButton
                aria-label="sendMessage"
                component="span"
                onClick={handleSendMessage}
                disabled={((ticketReadOnly) || (ticketStatus !== "open" && ticketStatus !== "group" && user.profile === "user"))}
              >
                <SendIcon disabled={((ticketReadOnly) || (ticketStatus !== "open" && ticketStatus !== "group" && user.profile === "user"))} className={classes.sendMessageIcons} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Paper>
    );
  }
};

export default MessageInput;
