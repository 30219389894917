function checkOutTimeInMinutesComparisons(comparisons) {
  /*
    Recebe um array de comparações de dois horários convertidos em minutos.
  Tal array pode contér apenas três valores distintos, [0, 1, 2], sendo:
    
    - 0 >> horário 1 é menor do que o horário 2
    - 1 >> horário 1 é igual ao horário 2
    - 2 >> horário 1 é maior ao horário 2

    Com isto, esta função poderá retornar um dos seguintes três valores, [0, 1, 2],
  sendo:

    - Retornos:
      \ 0 >> todos os horários 1 são menores do que os horários 2
      \ 1 >> há pelo menos um horário 1 que é igual ao horário 2
      \ 2 >> há pelo menos um horário 1 que é maior ao horário 2

    - Exemplo:

      comparisons = [0, 0, 0, 1, 2, 0, 1]
      checkOutTimeInMinutesComparisons(comparisons) // Output: 1
  */
  return (
      comparisons.includes(2) ? 2 :
      comparisons.includes(1) ? 1 :
      0
  );
}

export default checkOutTimeInMinutesComparisons;