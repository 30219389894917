function compareTimeInMinutes(time1, time2) {
    /*
        Essa função recebe dois horários no formato HH:MM, converte ambos
    em minutos usando a fórmula "HH * 60 + MM" e retorna se o horário 1
    é igual, maior ou menor do que o horário 2.

        Retornos:
            - 0 >> horário 1 é menor do que o horário 2
            - 1 >> horário 1 é igual ao horário 2
            - 2 >> horário 1 é maior do que o horário 2
    */
    let minutes1 = time1.split(':');
    minutes1 = parseInt(minutes1[0]) * 60 + parseInt(minutes1[1]);

    let minutes2 = time2.split(':');
    minutes2 = parseInt(minutes2[0]) * 60 + parseInt(minutes2[1])

    return (
        minutes1 < minutes2 ? 0  :
        minutes1 === minutes2 ? 1 :
        2
    );
}

export default compareTimeInMinutes;