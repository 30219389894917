import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import { useSocketAuthenticated } from "../../context/Socket/SocketContext";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
  floatingButton: {
    transition: "transform 0.3s",
    "&:hover": { transform: "translateY(-5px)", },
  },

  textField: { flex: 1, },

  extraAttr: { display: "flex", justifyContent: "center", alignItems: "center", },

  btnWrapper: { position: "relative", },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  fields: { display: "flex", flexDirection: "column", },

  actionButton: { "&:hover": { color: theme.palette.primary.main, }, },
}));

const NewInternalChatModal = ({ modalOpen, onClose, internalChatSelectedUserId = null, setTabOpen = null }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { user: loggedInUser } = useContext(AuthContext);

  const [chatName, setChatName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [usersList, setUsersList] = useState([]);
  const { onlineUsers } = useSocketAuthenticated();



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (modalOpen && internalChatSelectedUserId !== null && internalChatSelectedUserId !== undefined) {
      setSelectedUserId(internalChatSelectedUserId);
    }
  }, [internalChatSelectedUserId, modalOpen]);

  useEffect(() => {
    const fetchUsersList = async () => {
      try {
        const apiCalls = [api.get("/AllUsers")];
        const [AllUsersResponse] = await Promise.all(apiCalls);
        setUsersList(AllUsersResponse.data.users);
      } catch (error) {
        console.log("New Internal Chat Modal Use Effect Error:", error);
        toastError(error);
      }
    };

    fetchUsersList();
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose =() => {
    onClose();
    setSelectedUserId("");
    setChatName("");
  };

  const handleChatNameChange = (event) => { setChatName(event.target.value); };

  const handleSelectedUserChange = (event) => { setSelectedUserId(event.target.value); };

  const handleSaveInternalChat = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (chatName === null || chatName === undefined || chatName.length < 3) {
      toast.info(i18n.t("newInternalChatModal.toasts.chatNameInfo"));
    }
    
    else if (selectedUserId === null || selectedUserId === undefined || selectedUserId === "") {
      toast.info(i18n.t("newInternalChatModal.toasts.selectedUserIdInfo"));
    }

    else {
      try {
        const { data: ticket } = await api.post("/internalChats", {
          name: chatName,
          userId: loggedInUser.id,
          userIdInternalChat: selectedUserId,
          noteCreatedBy: loggedInUser.id
        });

        history.push(`/tickets/${ticket.id}`);

        if (setTabOpen !== null && setTabOpen !== undefined) setTabOpen("open");
        localStorage.setItem("tabOpenTickets", "open");
        toast.success(i18n.t("newInternalChatModal.toasts.success"));

        handleClose();
      } catch (error) {
        console.log("Handle Save Internal Chat Error:", error);
        toastError(error);
      }  
    }

    setLoading(false);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
      <form onSubmit={handleSaveInternalChat}>
        {/* 
          ***********
          ** Title **
          ***********
        */}
        <DialogTitle>
          {i18n.t("newInternalChatModal.title")}
        </DialogTitle>



        {/* 
          *************
          ** Content **
          *************
        */}
        <DialogContent dividers>
          {/* 
            ****************
            ** Name Field **
            ****************
          */}
          <TextField
            value={chatName}
            label={i18n.t("newInternalChatModal.form.name")}
            autoFocus
            name="name"
            variant="outlined"
            margin="dense"
            fullWidth
            className={classes.textField}
            multiline={false}
            onChange={handleChatNameChange}
          />



          <br />
          <Divider />
          <br />



          {/* 
            ***********************
            ** User Select Field **
            ***********************
          */}
          <FormControl variant="outlined" fullWidth>
            <InputLabel>{i18n.t("newInternalChatModal.form.user")}</InputLabel>

            <Select
              value={selectedUserId}
              onChange={handleSelectedUserChange}
              label={i18n.t("newInternalChatModal.form.user")}
            >
              <MenuItem value={''}>&nbsp;</MenuItem>

              {/* ***---- Online Users ----*** */}
              <ListSubheader>{i18n.t("newInternalChatModal.listSubheaders.onlineUsers")}</ListSubheader>
              {usersList.map(user => {
                return onlineUsers.filter(onlineUser => onlineUser.userId !== loggedInUser.id)
                                  .some(onlineUser => onlineUser.userId === user.id)
                  ? <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                  : <></>;
              })}

              {/* ***---- Offline Users ----*** */}
              <ListSubheader>{i18n.t("newInternalChatModal.listSubheaders.offlineUsers")}</ListSubheader>
              {usersList.map(user => {
                return !onlineUsers.some(onlineUser => onlineUser.userId === user.id)
                  ? <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                  : <></>;
              })}
            </Select>
          </FormControl>
        </DialogContent>



        {/* 
          ********************
          ** Dialog Actions **
          ********************
        */}
        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            disabled={loading}
            variant="outlined"
            className={classes.floatingButton}
          >
            {i18n.t("newInternalChatModal.buttons.cancel")}
          </Button>

          <ButtonWithSpinner
            variant="contained"
            type="submit"
            color="primary"
            loading={loading}
            className={classes.floatingButton}
          >
            {i18n.t("newInternalChatModal.buttons.ok")}
          </ButtonWithSpinner>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewInternalChatModal;