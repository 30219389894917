import React from "react";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
  makeStyles,
  Divider,
} from '@material-ui/core';


import { i18n } from "../../translate/i18n";
import ReleaseNotesContents from "../ReleaseNotesContents";


const useStyles = makeStyles(theme => ({
  root: {
		display: "flex",
		flexWrap: "wrap",
	},
	floatingButton: {
		transition: 'transform 0.3s',
	
		'&:hover': {
			transform: 'translateY(-5px)',
		},
	},
  dialogContent: {
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  releaseImg: {
    width: "100%",
  },
}));


const ReleasesModal = ({ open, onClose }) => {
	const classes = useStyles();
	const handleClose = () => { onClose(); };

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
        width="1000px"
				maxWidth="1000px"
				scroll="paper"
			>



        {/* ***---- Title ----*** */}
        <DialogTitle id="form-dialog-title">
          🎁 {i18n.t("releasesModal.title")}
				</DialogTitle>



        {/* ***---- Contents ----*** */}
        <Divider />
        <DialogContent dividers className={classes.dialogContent}>
          <ReleaseNotesContents open={open} />
        </DialogContent>



        {/* ***---- Close Button ----*** */}
        <Divider />
        <DialogActions>
					<Button
            onClick={handleClose}
            color="inherit"
            variant="outlined"
            className={classes.floatingButton}
          >
            {i18n.t("releasesModal.buttons.close")}
          </Button>
				</DialogActions>
      </Dialog>
    </div>
	);
};

export default ReleasesModal;
