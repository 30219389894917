import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import {
  Container,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { AssessmentOutlined, ViewHeadlineOutlined } from "@material-ui/icons";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ChartAllUsersRatings from "./Charts/AllUsersRatings/Chart";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

const useStyles = makeStyles(theme => ({
  mainContainerScroll: { overflowY: "scroll", ...theme.scrollbarStyles, paddingBottom: "20px", },

  container: { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), },

  flexContainer: {
    display: "flex",
    justifyContent: "right",
    alignItems: "baseline",
    gap: "15px",
    paddingRight: "10px",
  },

  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down('sm')]: { justifyContent: "center", gap: "10px", },
  },

  tableCaption: { color: theme.palette.text.primary },

  cardItem: { width: "300px" },

  customFixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    height: 120,
    transition: "transform 0.3s",
    position: "relative",
    "&:hover": { filter: "brightness(0.85)", transform: "translateY(-5px)", },
  },

  ticketsTable: {
		// scroll
		"& .MuiDataGrid-virtualScroller": { ...theme.scrollbarStyles, },

		// header
		"& .MuiDataGrid-columnHeaders": {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.primary,
			fontSize: 16,
		},

		// rows
		"& .MuiDataGrid-cell": { color: theme.palette.text.primary, },

		// pair rows
		"& .MuiDataGrid-virtualScrollerRenderZone": {
			"& .MuiDataGrid-row": {
				"&:nth-child(2n)": { backgroundColor: theme.palette.background.paper, }
			}
		},

		// buttons
		"& .MuiButtonBase-root": { color: theme.palette.text.primary, },

		// checkboxes - not checked
		"& [data-testId='CheckBoxOutlineBlankIcon']": { color: theme.palette.text.primary, },

		// checkboxes - checked
		"& [data-testId='CheckBoxIcon']": { color: theme.palette.primary.main, },

		// checkbox - header (unselect all)
		"& [data-testId='IndeterminateCheckBoxIcon']": { color: theme.palette.primary.main, },

		// footer - selected rows
		"& .MuiDataGrid-footerContainer": { color: theme.palette.text.primary, },

		// footer - pagination
		"& .MuiTablePagination-root": { color: theme.palette.text.primary, },
	},

  dashboardContainer: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
		borderRadius: "5px",
		padding: "20px 0px 20px 0px",
		textIndent: "15px",
	},

  icon: { color: theme.palette.text.primary },

  viewActiveOption: { color: theme.palette.primary.main },

  actionButton: {
    transition: "all 0.3s ease",
    "&:hover": { color: theme.palette.primary.main, transform: "translateY(-5px)" },
  },
}));

const RatingsDashboard = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes= useStyles();
  const history = useHistory();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { user } = useContext(AuthContext);

  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const considerInternalChats = false;

  const [avgRate, setAvgRate] = useState(0.00);
  const [numberTickets, setNumberTickets] = useState(0);
  const [numberContacts, setNumberContacts] = useState(0);
  const [usersRating, setUsersRating] = useState([]);

  const [viewStyle, setViewStyle] = useState("table");

  // ***---- Tables Structure ----***
  const topAndBottomUserscolumns = [
    {
      field: "id", type: "number", headerName: i18n.t("ratingsDashboard.table.id"),
      flex: 0.33, align: "center", headerAlign: "center"
    },
    {
      field: "name", type: "string", headerName: i18n.t("ratingsDashboard.table.user"),
      flex: 0.33, align: "center", headerAlign: "center"
    },
    {
      field: "rate", type: "number", headerName: i18n.t("ratingsDashboard.table.rate"),
      flex: 0.33, align: "center", headerAlign: "center",
      valueGetter: (params) => { return params.value && params.value !== "0.00" ? params.value : "-" },
    }
  ];

  const allUsersColumns = [
    {
      field: "id", type: "number", headerName: i18n.t("ratingsDashboard.table.id"),
      flex: 0.25, align: "center", headerAlign: "center"
    },
    {
      field: "name", type: "string", headerName: i18n.t("ratingsDashboard.table.user"),
      flex: 0.25, align: "center", headerAlign: "center"
    },
    {
      field: "rate", type: "number", headerName: i18n.t("ratingsDashboard.table.rate"),
      flex: 0.25, align: "center", headerAlign: "center",
      valueGetter: (params) => { return params.value && params.value !== "0.00" ? params.value : "-" }
    },
    {
      field: "ratingsCount", type: "number", headerName: i18n.t("ratingsDashboard.table.ratingsCount"),
      flex: 0.25, align: "center", headerAlign: "center"
    }
  ];



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (user.profile === "user" || !user.configEnabled) { history.push(`/tickets`); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    if (month < 10) { month = '0' + month.toString(); }
    if (day < 10) { day = '0' + day.toString(); }

    setInitialDate(`${year}-${month}-${day}`);
    setFinalDate(`${year}-${month}-${day}`);
  }, []);

  useEffect(() => {
    const delayDebounceFN = setTimeout(() => {
			const fetchRatingCardsInfo = async () => {
        const apiCalls = [
          api.get("/ratingCardsInfo", { params: { initialDate: `${initialDate}T00:00:00`, finalDate: `${finalDate}T23:59:59`, considerInternalChats } }),
          api.get("/ratingUsersInfo", { params: { initialDate: `${initialDate}T00:00:00`, finalDate: `${finalDate}T23:59:59`, considerInternalChats } })
        ]

        const [ratingCardsInfoData, usersRatingInfoData] = await Promise.all(apiCalls);

				setAvgRate(parseFloat(ratingCardsInfoData.data.avgRate));
        setNumberTickets(ratingCardsInfoData.data.numberTickets);
        setNumberContacts(ratingCardsInfoData.data.numberContacts);

        setUsersRating(usersRatingInfoData.data.usersInfoRating);
			};
			fetchRatingCardsInfo();
		}, 500);
		return () => clearTimeout(delayDebounceFN);
  }, [initialDate, finalDate, considerInternalChats]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleFilterDate = (newDate, flagInitialDate) => {
    const dashboardInitialDate = document.getElementById("dashboardInitialDate");
    const dashboardFinalDate = document.getElementById("dashboardFinalDate");

    if (dashboardInitialDate.value === "" || dashboardFinalDate.value === "") {
      dashboardInitialDate.value = initialDate;
      dashboardFinalDate.value = finalDate;
    }
    else if (dashboardInitialDate.value > dashboardFinalDate.value) { toast.error(i18n.t("backendErrors.DASHBOARD_INITIAL_DATE_AFTER_FINAL_DATE")); }
    else if (flagInitialDate) { setInitialDate(newDate.target.value); }
    else { setFinalDate(newDate.target.value); }
  };

  const handleChangeInitialDate = (newDate) => { handleFilterDate(newDate, true); };
  const handleChangeFinalDate = (newDate) => { handleFilterDate(newDate, false); };

  const getTop5Users = (usersRating) => {
    return usersRating.filter(item => item.rate !== "0.00").slice(0, 5);
  };

  const getBottom5Users = (usersRating) => {
    return usersRating.filter(item => item.rate !== "0.00").slice(-5);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <MainContainer>
      {/* 
        ***********
        ** Title **
        ***********
      */}
      <MainHeader>
        <Title>{i18n.t("ratingsDashboard.title")}</Title>
      </MainHeader>



      {/* 
        ***************
        ** Container **
        ***************
      */}
      <div className={classes.mainContainerScroll}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* 
              *****************
              ** Date Inputs **
              *****************
            */}
            <Grid item xs={12}>
              <div className={classes.flexContainer}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="date"
                  id="dashboardInitialDate"
                  name="dashboardInitialDate"
                  label={i18n.t("dashboard.fields.initialDate")}
                  value={initialDate}
                  onChange={handleChangeInitialDate}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  type="date"
                  id="dashboardFinalDate"
                  name="dashboardFinalDate"
                  label={i18n.t("dashboard.fields.finalDate")}
                  value={finalDate}
                  onChange={handleChangeFinalDate}
                />
              </div>
            </Grid>



            {/* 
              ***********
              ** Cards **
              ***********
            */}
            <Grid item xs={12} className={classes.sectionContainer}>

              {/* ***---- General AVG Rating ----*** */}
              <Grid className={classes.cardItem}>
                <Paper className={classes.customFixedHeightPaper}>
                  <Typography component="h3" variant="h6" paragraph>
                    {i18n.t("ratingsDashboard.cards.rate")}
                  </Typography>

                  <Grid item>
                    <Typography component="h1" variant="h4">
                      {avgRate === 0 ? "-" : avgRate}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>

              {/* ***---- Amount of Tickets ----*** */}
              <Grid className={classes.cardItem}>
                <Paper className={classes.customFixedHeightPaper}>
                  <Typography component="h3" variant="h6" paragraph>
                    {i18n.t("ratingsDashboard.cards.tickets")}
                  </Typography>

                  <Grid item>
                    <Typography component="h1" variant="h4">
                      {numberTickets === 0 ? "-" : numberTickets}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>

              {/* ***---- Amount of Contacts ----*** */}
              <Grid className={classes.cardItem}>
                <Paper className={classes.customFixedHeightPaper}>
                  <Typography component="h3" variant="h6" paragraph>
                    {i18n.t("ratingsDashboard.cards.contacts")}
                  </Typography>

                  <Grid item>
                    <Typography component="h1" variant="h4">
                      {numberContacts === 0 ? "-" : numberContacts}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>



            {/* 
              ************
              ** Tables **
              ************
            */}
            <Grid item xs={isSmallScreen ? 12 : 6}>
              <Typography component="h3" variant="h6" paragraph className={classes.tableCaption}>
                {i18n.t("ratingsDashboard.captions.top5Users")}
              </Typography>

              <div style={{ height: 318, width: '100%' }}>
                <DataGrid
                  className={classes.ticketsTable}
                  rows={getTop5Users(usersRating)} 
                  columns={topAndBottomUserscolumns}
                  rowsPerPageOptions={[5]}
                  checkboxSelection={false}
                  disableColumnMenu={true}
                  hideFooterPagination={true}
                  hideFooter={true}
                />
              </div>
            </Grid>

            <Grid item xs={isSmallScreen ? 12 : 6}>
              <Typography component="h3" variant="h6" paragraph className={classes.tableCaption}>
                {i18n.t("ratingsDashboard.captions.bottom5Users")}
              </Typography>

              <div style={{ height: 318, width: '100%' }}>
                <DataGrid
                  className={classes.ticketsTable}
                  rows={getBottom5Users(usersRating)}
                  columns={topAndBottomUserscolumns}
                  rowsPerPageOptions={[5]}
                  checkboxSelection={false}
                  disableColumnMenu={true}
                  hideFooterPagination={true}
                  hideFooter={true}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography component="h3" variant="h6" paragraph className={classes.tableCaption}>
                {i18n.t("ratingsDashboard.captions.allUsers")}

                <Tooltip title={i18n.t("ratingsDashboard.tooltips.tableView")} placement="top-start" arrow>
                  <IconButton
                    className={classes.icon}
                    size="small"
                    onClick={() => setViewStyle("table")}
                  >
                    <ViewHeadlineOutlined className={viewStyle === "table" ? `${classes.viewActiveOption} ${classes.actionButton}` : `${classes.actionButton}`} />
                  </IconButton>
                </Tooltip>

                <Tooltip title={i18n.t("ratingsDashboard.tooltips.chartView")} placement="top-start" arrow>
                  <IconButton
                    className={classes.icon}
                    size="small"
                    onClick={() => setViewStyle("chart")}
                  >
                    <AssessmentOutlined className={viewStyle === "chart" ? `${classes.viewActiveOption} ${classes.actionButton}` : `${classes.actionButton}`} />
                  </IconButton>
                </Tooltip>
              </Typography>

              {viewStyle === "table" && (
                <div style={{ height: 500, width: '100%' }}>
                  <DataGrid
                    className={classes.ticketsTable}
                    rows={usersRating}
                    columns={allUsersColumns}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    checkboxSelection={false}
                    disableColumnMenu={true}
                  />
                </div>
              )}

              {viewStyle === "chart" && (
                <Paper className={classes.dashboardContainer}>
                  <ChartAllUsersRatings data={usersRating} />
                </Paper>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </MainContainer>
  );
};

export default RatingsDashboard;