import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useDashboardChartsQueuesUsers = () => {
  const [data, setData] = useState();

  useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
          const fetchClosedTickets = async() => {
              try {
                  const { data } = await api.get("/listQueuesDashboardCharts")
                  setData(data)
              } catch (error) {
                console.log("Use Dashboard Charts Queues Users Error:", error);
                toastError(error)
              }
          }
          fetchClosedTickets()
      }, 500)
      return () => clearTimeout(delayDebounceFn)
  }, [])

    return data;
}

export default useDashboardChartsQueuesUsers;