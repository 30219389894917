import React, { useState, useEffect, useRef } from "react";
import { ExpandMore, FilterNoneOutlined } from "@material-ui/icons";
import { 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import audioIcon from "../../assets/audio-waves.png";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.3s",
  },

  deletedMediaMessage: {
    opacity: 0.7,
    filter: "grayscale(80%)",
  },

  audioContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "1em",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-around",
  },

  audioTranscriptContainer: {
    maxWidth: "250px",
    borderLeft: "5px solid #7159c1",
    backgroundColor: "rgba(113, 89, 193, 0.25)",
    borderRadius: "5px",
    
    "& .MuiAccordionSummary-expandIcon": {
      color: theme.palette.text.primary,
    },
  },

  audioTranscript: {
    textAlign: "justify",
    textIndent: "1em",
  },

  audioAvatar: {
		margin: 15,
		width: 35,
		height: 35,
    padding: 3.5,
		cursor: "default",
	},

  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)' },
  },

  icon: {
		color: theme.palette.text.primary,
	},

  actionButton: {
    "&:hover": { color: theme.palette.primary.main },
  },
}));

const LS_NAME = 'audioMessageRate';

const Audio = ({
  transcript, url, setAudioPlayer, setCurrentPlayingAudioTime, ticket,
  messageId, isFromStarredMessages=false, currentTicketId=null, isDeleted=false,
  isVoice, isFromMe
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const audioRef = useRef(null);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [audioRate, setAudioRate] = useState(1);
  const [showButtonRate, setShowButtonRate] = useState(false);

  const [transcriptionAccordionOpen, setTranscriptionAccordionOpen] = useState(true);
  const [loadingTranscript, setLoadingTranscript] = useState(false);

  const [isTranscriptCopied, setIsTranscriptCopied] = useState(false);
  
  let avatar;
  if (isVoice && isFromMe) avatar = undefined;
  else if (isVoice && ticket?.contact && ticket?.contact !== undefined) avatar = ticket.contact.profilePicUrl;
  else if (isVoice) avatar = undefined;
  else avatar = audioIcon;

  let interval;
  const toogleRate = () => {
    let newRate = null;

    switch(audioRate) {
      case 0.25:
        newRate = 0.5;
        break;
      case 0.5:
        newRate = 0.75;
        break;
      case 0.75:
        newRate = 1;
        break;
      case 1:
        newRate = 1.25;
        break;
      case 1.25:
        newRate = 1.5;
        break;
      case 1.5:
        newRate = 1.75;
        break;
      case 1.75:
        newRate = 2;
        break;
      case 2:
        newRate = 0.25;
        break;
      default:
        newRate = 1;
        break;
    }
    
    setAudioRate(newRate);
  };

  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    audioRef.current.playbackRate = audioRate;
    localStorage.setItem(LS_NAME, audioRate);
  }, [audioRate]);

  useEffect(() => {
    if (ticket !== undefined) {
      audioRef.current.onplay = () => {
        setAudioPlayer(
          url,
          audioRef.current.currentTime,
          currentTicketId ? currentTicketId.toString() : ticket.id.toString(),
          ticket.contact?.profilePicUrl,
          ticket.contact?.name || ticket.noteName,
          ticket.type === 0 ? ticket.contact?.id.toString() : "0"
        );
      };

      audioRef.current.onplaying = () => {
        setShowButtonRate(true); 
        interval = setInterval(() => { 
          try {
            setCurrentPlayingAudioTime(audioRef.current.currentTime);
            localStorage.setItem("currentPlayingAudioTime", audioRef.current.currentTime);
          } catch (err) {
            clearInterval(interval);
          }
        }, 1000);
      };

      audioRef.current.onpause = () => {
        setShowButtonRate(false);
      };

      audioRef.current.onended = () => {
        setShowButtonRate(false);
        setAudioPlayer("", "", "", "", "", "");
      };
    }
  }, [url, setAudioPlayer, setCurrentPlayingAudioTime, ticket]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleTranscribeAudio = async () => {
    try {
      setLoadingTranscript(true);

      await api.get(`/openAi/transcribeAudio/${messageId}`, {
        params: {
          audioUrl: url,
          currentTicketId,
          ticketType: ticket.type
        }
      });
    } catch (exception) {
      toastError(exception);
    } finally {
      setLoadingTranscript(false);
    }
  };

  const handleCopyTranscript = async (transcript) => {
    setIsTranscriptCopied(true);
    await navigator.clipboard.writeText(transcript);
    setTimeout(() => { setIsTranscriptCopied(false); }, 500);
  };
  


  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={isDeleted ? `${classes.mainContainer} ${classes.deletedMediaMessage}` : classes.mainContainer}>
      <div className={classes.audioContainer}>
        <audio onPause={() => { setAudioPlayer("", "", "", "", "", ""); }} ref={audioRef} controls>
          <source src={url} type="audio/ogg"></source>
        </audio>
        
        <Avatar src={avatar} className={classes.audioAvatar} />

        {!isFromStarredMessages && (!isSmallScreen || ticket !== undefined) && (
          <Button disabled={!showButtonRate} onClick={toogleRate}>
            {audioRate}<span>x</span>
          </Button>
        )}
      </div>
      
      {messageId && (
        <Button
          className={classes.floatingButton}
          color="inherit"
          variant="outlined"
          disabled={!url}
          onClick={handleTranscribeAudio}
        >
          {i18n.t("audioComponent.accordionTitle")}
        </Button>
      )}

      {loadingTranscript && !transcript && ( <CircularProgress size={24} /> )}
      
      {messageId && transcript && (
        <>
        <Accordion 
          className={classes.audioTranscriptContainer}
          expanded={transcriptionAccordionOpen} 
        >
          <AccordionSummary
            id="transcription-accordion-header"
            aria-controls="transcription-accordion-header"
            expandIcon={<ExpandMore />}
            TransitionProps={{ unmountOnExit: true }}
            onClick={() => setTranscriptionAccordionOpen((prevValue) => !prevValue )}
          >
            <Typography className={classes.accordionHeading}>
              🤫 {i18n.t("audioComponent.accordionTitle")}
            </Typography>
          </AccordionSummary>

          <AccordionDetails className={classes.audioTranscript}>
            <span>{transcript}</span>
          </AccordionDetails>
        </Accordion>

        <Tooltip 
          title={isTranscriptCopied ? i18n.t("audioComponent.tooltips.copied") : i18n.t("audioComponent.tooltips.copy") }
          placement="top-start"
          arrow
        >
          <IconButton
            className={`${classes.icon} ${classes.floatingButton}`}
            size="small"
            onClick={() => handleCopyTranscript(transcript)}
          >
            <FilterNoneOutlined className={classes.actionButton} />
          </IconButton>
        </Tooltip>
        </>
      )}
    </div>
  );
}

export default Audio;