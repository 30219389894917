import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";

const validateBirthday = (birthday) => {
  if (birthday) {
    if (new Date(birthday) > new Date()) {
      toast.info(i18n.t("contactModal.toasts.invalidBirthday"));
      return false;
    }
    return true;
  }
  return true;
};

export default validateBirthday;