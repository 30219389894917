import React from "react";
import Typography from "@material-ui/core/Typography";
import { i18n } from "../../../../translate/i18n";

const Title = (ticketStatus) => {
	return (
		<>
		{ticketStatus.ticketStatus === "open" && (
			<Typography component="h2" variant="h6" gutterBottom>
				{i18n.t("dashboard.charts.openTicketsPerUsers.title")}
			</Typography>
		)}

		{ticketStatus.ticketStatus === "closed" && (
			<Typography component="h2" variant="h6" gutterBottom>
				{i18n.t("dashboard.charts.closedTicketsPerUsers.title")}
			</Typography>
		)}
		</>
	);
};

export default Title;
