import React, { useState } from "react";
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import {
  Button,
  makeStyles
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import ListPreviewModal from "../ListPreviewModal";

const useStyles = makeStyles((theme) => ({
  listMenuContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  },

  listMenu: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",

    width: "250px",
    color: theme.palette.secondary.ackCheck,
    backgroundColor: theme.palette.background.paper,
    "&:hover": { backgroundColor: theme.palette.background.paper, },
  },

  listMenuSpan: { marginBottom: "-1.5px", },
  
  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)' },
  },
}));

const ListPreview = ({ message, ticketId }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [listOptionsModalState, setListOptionsModalState] = useState(false);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenListOptionsModal = () => {
    setListOptionsModalState(true);
  };

  const handleCloseListOptionsModal = () => {
    setListOptionsModalState(false);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
    <ListPreviewModal
      message={message}
      ticketId={ticketId}
      open={listOptionsModalState}
      onClose={handleCloseListOptionsModal}
    />

    <br />

    <div className={classes.listMenuContainer}>
      <Button
        className={`${classes.listMenu} ${classes.floatingButton}`}
        variant="contained"
        startIcon={<ListOutlinedIcon className={classes.actionButton} />}
        onClick={handleOpenListOptionsModal}
      >
        <span className={classes.listMenuSpan}>{i18n.t("listPreview.select")}</span>
      </Button>
    </div>

    <br />
    </>
  );
};

export default ListPreview;