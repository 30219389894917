import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff, AccountCircle, LockOutlined } from '@material-ui/icons';
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Link
} from '@material-ui/core';

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import WhatsappButton from "../../components/WhatsappButton";

import iconLight from '../../assets/icon.png';
import iconDark from '../../assets/icon-dark.png';
import iconFX from '../../assets/fx_sistemas_logo.png';
import SignInImg from '../../assets/signIn.svg';

const Copyright = () => {
 	return (
 		<Typography variant="body2" color="textSecondary" align="center">
 			{"Copyright "}
 			<Link color="inherit" href="https://www.fxsistemas.com.br" target="_blank">
 				FX Sistemas
 			</Link>{" "}
 			{new Date().getFullYear()}
 			{"."}
 		</Typography>
 	);
};

const useStyles = makeStyles((theme) => ({
  iconButton: { color: theme.palette.text.primary, },
  disabledTextField: { "& input.Mui-disabled": { color: theme.palette.text.primary, }, },

  loginContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100vw",
    height: "90vh",
    gap: "70px",
    marginTop: "5vh",
    name: theme.name,
  },

  signInImgContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('sm')]: { display: 'none', },
  },

  signInImg: { width: "500px", height: "500px", },

  loginForm: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  avatar: {
    margin: theme.spacing(0),
    width: "150px",
    height: "auto",
  },

  logo: {
    margin: theme.spacing(1),
    width: "50px",
    height: "auto",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  
  submit: { margin: theme.spacing(3, 0, 2), },

  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  }
}));

const Login = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { handleLogin } = useContext(AuthContext);

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const themeValue = localStorage.getItem("theme");
  const currentIcon =  themeValue === "dark" ? iconDark : iconLight;
  user.email = api.defaults.headers.email;
  


  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => { document.title = "BestZap"; }, [])

  useEffect(() => {
   if (user.email === undefined || user.email === null || user.email === "") { handleBack(); }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  //  ***************
  //  ** Functions **
  //  ***************
  const handleBack = () => {
    window.location.href = "https://bestzap.fxsistemas.com.br";
    history.push("/loginGroup");
  };

  const handleChangeInput = (e) => { setUser({ ...user, [e.target.name]: e.target.value }); };
  const handlSubmit = (e) => { e.preventDefault(); handleLogin(user); };

  
  
  //  ************
  //  ** Return **
  //  ************
  return (
    <Container className={classes.loginContainer} component="main">
      <CssBaseline />

      <div className={classes.signInImgContainer}>
        <img src={SignInImg} className={classes.signInImg} alt="" />
      </div>
      
      <div className={classes.loginForm}>
        <img className={classes.avatar} src={currentIcon} alt="BestZap Logo" />

        <Typography component="h1" variant="h2">
          {i18n.t("login.title")}
        </Typography>

        <Typography component="h2" variant="h6">
          <br></br>
          {i18n.t("login.subtitlePassword")}
        </Typography>        

        <form className={classes.form} noValidate onSubmit={handlSubmit}>
          <TextField
            className={classes.disabledTextField}
            style={{width: "80%"}}
            variant="outlined"
            margin="normal"
            required
            id="email"
            label={i18n.t("login.form.email")}
            name="email"
            value={user.email}
            autoComplete="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              )
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{width: "80%"}}
            name="password"
            label={i18n.t("login.form.password")}
            id="password"
            value={user.password}
            onChange={handleChangeInput}
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className={classes.iconButton}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((e) => !e)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            type="submit"
            style={{width: "80%"}}
            variant="contained"
            color="primary"
            className={`${classes.submit} ${classes.floatingButton}`}
          >
            {i18n.t("login.buttons.submit")}
          </Button>
          <Button
            type="button"
            style={{width: "80%"}}
            variant="contained"
            color="primary"
            className={`${classes.submit} ${classes.floatingButton}`}  
            onClick={handleBack}
          >
            {i18n.t("login.buttons.back")}
          </Button>    
        </form>
        
        <Box mt={8}>
          <a href="https://www.fxsistemas.com.br" target="_blank" rel="noreferrer">
            <img className={classes.logo} src={iconFX} alt="FX Sistemas Logo" />
          </a>
          {<Copyright />}
        </Box>
      </div>

      <WhatsappButton />
    </Container>
  );
};

export default Login;
