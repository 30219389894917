import React from "react";
import Typography from "@material-ui/core/Typography";
import { i18n } from "../../../../translate/i18n";

const Title = props => {
	return (
		<Typography component="h2" variant="h6" gutterBottom>
			{i18n.t("dashboard.charts.ticketsPerQueues.title")}
		</Typography>
	);
};

export default Title;
