import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MessageOutlined } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";
import truncateString from "../../utils/truncateString";


const useStyles = makeStyles(theme => ({
  //  ********************
  //  ** Main Container **
  //  ********************
	adMainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    gap: "5px",
    marginBottom: "1em",
  },

  //  ******************************
  //  ** Identification Container **
  //  ******************************
  adIdentificationContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",

    fontSize: "12px",
    opacity: "0.75",
    fontStyle: "italic",
  },

  //  ********************
  //  ** Info Container **
  //  ********************
  adInfoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",

    backgroundColor: theme.palette.background.paper,
    padding: "5px",
    cursor: "pointer",
    transition: "all 0.3s ease",
    borderRadius: "5px",

    "&:hover": {
      opacity: "0.9",
      transform: "translateY(-5px)",
    },
  },

  adInfoTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "3px",
    padding: "1em",
  },

  adInfoThumbnail: {
    width: "75px",
    height: "75px",
  },

  adInfoTitle: {

  },

  adInfoSourceUrl: {
    opacity: "0.80",
    fontSize: "14px",
  },
}));

const AdsMessage = ({ message, redirectFlag }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();



	// 	***************
	// 	** Functions **
	// 	***************
  const handleAdsRedirect = () => {
    if (message.adsSourceUrl && message.adsSourceUrl !== undefined && redirectFlag) {
      window.open(message.adsSourceUrl, "_blank");
    }
  };



	// 	************
	// 	** Return **
	// 	************
	return (
		<div onClick={handleAdsRedirect} className={classes.adMainContainer}>
      <div className={classes.adIdentificationContainer}>
        <MessageOutlined style={{fontSize: "18px"}} />
        <span>{i18n.t("adsComponent.identification")}</span>
      </div>

      <div className={classes.adInfoContainer}>
        <img className={classes.adInfoThumbnail} alt="ADS" src={message.adsThumbnailUrl} />
        <div className={classes.adInfoTextContainer}>
          <span className={classes.adInfoTitle}>
            {message.adsTitle && message.adsTitle !== undefined
              ? truncateString(message.adsTitle, 30)
              : "-"
            }
          </span>
          <span className={classes.adInfoSourceUrl}>fb.me</span>
        </div>
      </div>
    </div>
	);
};

export default AdsMessage;
