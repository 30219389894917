import { useState, useEffect } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useDashboardChartsListUsers = () => {
  const [data, setData] = useState();

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      const fetchClosedTickets = async() => {
        try {
          const { data } = await api.get("/listUsersDashboardCharts");
          setData(data);
        } catch (error) {
          console.log("Use Dashboard Charts List Users Error:", error);
          toastError(error);
        }
      }

      await fetchClosedTickets();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  return data;
}

export default useDashboardChartsListUsers;