import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import openSocket from "socket.io-client";

import { AuthContext } from "../Auth/AuthContext";
import { getBackendUrl } from "../../config";
import api from "../../services/api";

//  **************
//  ** Contexts **
//  **************
const SocketContext = createContext();
const SocketAuthenticatedContext = createContext();
const SocketLoginContext = createContext();

const useSocket = () => useContext(SocketContext);
const useSocketAuthenticated = () => useContext(SocketAuthenticatedContext);
const useSocketLogin = () => useContext(SocketLoginContext);



//  ***************
//  ** Providers **
//  ***************
const SocketProvider = ({ children }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  let socket;
  try {
    console.log("- Trying to connect Socket!!");
    const token = localStorage.getItem("token");
    const groupId = token ? JSON.parse(atob(token.split(".")[1])).groupId : undefined;

    const config = groupId > 0 ? { path: `/group${groupId}/socket.io` } : {};
    config.transports = ["websocket"];
    config.reconnection = true;

    socket = openSocket(getBackendUrl(), config);
  } catch (exception) {
    console.log(`- Exception caught when trying to connect socket: ${JSON.stringify(exception)}`);
    alert(`- Entre em contato com o suporte: ${JSON.stringify(exception)}`);
    window.location.reload();
  }

  const reloadTimeout = useRef(null);
  const reloadTimeoutLimit = 8 * 60 * 1000; // 8 minutes in milliseconds
  


  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const errorListener = (err) => {
      console.log('Connect error');
      console.log(err.message, err.description, err.context);

      if (!reloadTimeout.current) {
        reloadTimeout.current = setTimeout(() => {
          window.location.reload();
        }, reloadTimeoutLimit);
      }
    }

    const connectionListener = () => {
      if (socket.recoverd) { console.log("- Socket Connection Recovered!!"); }
      else { console.log("- Socket Connection Established!!"); }
      
      clearTimeout(reloadTimeout.current);
      reloadTimeout.current = null;
    }

    const disconnectListener = (Reason) => {
      console.log(`- Socket Disconnected: ${Reason}!!`);
    }


    console.log("- Socket Provider Use Effect - On!!");
    socket.on("disconnect", disconnectListener)
    socket.on("connect_error", errorListener);
    socket.on("connect", connectionListener);

    return () => {
      console.log("- Socket Provider Cleanup - Off!!");
      socket.off("disconnect", disconnectListener)
      socket.off("connect_error", errorListener);
      socket.off("connect", connectionListener);
      socket.disconnect();
    };
  }, [reloadTimeoutLimit, socket]);



  //  ************
  //  ** Return **
  //  ************
  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

const SocketAuthenticatedProvider = ({ children }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { socket } = useSocket();
  const { user } = useContext(AuthContext);
  const [onlineUsers, setOnlineUsers] = useState([]);

  if (user.tenantId) socket.emit("joinTenant", user.tenantId.toString());
  
  
  
  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    // ***---- Functions ----***
    const connectListener = () => {
      if (user.tenantId) socket.emit("joinTenant", user.tenantId.toString());
    };

    const intervalId = setInterval(
      () => socket.emit("onlineUsers", api.defaults.headers.tenantId),
      30 * 1000 // 30 seconds in milliseconds (2 * 60 * 1000 // 2 minutes)
    );

    const handleOnlineUsersList = (data) => {
      const isOnlineUsersArrayNull = !data.onlineUsers;
      const isContextUserIdNotIntoOnlineUsersArray = !data.onlineUsers?.some(element => element.userId.toString() === user.id.toString());
      const isSocketLoginEmitionNeeded = isOnlineUsersArrayNull || isContextUserIdNotIntoOnlineUsersArray;

      if (isSocketLoginEmitionNeeded) {
        socket.emit("login", user.tenantId.toString(), user.id.toString());
      }
  
      else if (user.tenantId.toString() === data.tenantId.toString()) {
        setOnlineUsers(data.onlineUsers);
      }
    };



    // ***---- On Listeners ----***
    socket.on("connect", connectListener);
    socket.on("onlineUsersList", handleOnlineUsersList);
  



    // ***---- Off Listeners ----***
    return () => {
      clearInterval(intervalId);
      socket.off("connect", connectListener);
      socket.off("onlineUsersList", handleOnlineUsersList);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, user]);



  //  ************
  //  ** Return **
  //  ************
  return (
    <SocketAuthenticatedContext.Provider value={{ socket, onlineUsers }}>
      {children}
    </SocketAuthenticatedContext.Provider>
  );
};

const SocketLoginProvider = ({ children }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { socket } = useSocket();
  let userState = useRef(null);



  //  ***************
  //  ** Callbacks **
  //  ***************
  const handleUserCallback = useCallback(newUserState => {
    userState.current = newUserState;
  }, []);



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {    
    const handleUser = (data) => {
      if (!userState.current) return;
      if (data.action === "update" && data.user.id === userState.current.user.id && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
        userState.current.setUser(data.user);
      }
    };

    socket.on("user", handleUser);

    return () => {
      socket.off("user", handleUser);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleSocketLogin = (tenantId, userId) => {
    socket.emit("login", tenantId, userId);
  }

  const handleSocketLogout = (tenantId, userId) => {
    socket.emit("logout", tenantId, userId);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <SocketLoginContext.Provider value={{ handleSocketLogin, handleSocketLogout, handleUserCallback }}>
      {children}
    </SocketLoginContext.Provider>
  );
};

export { SocketProvider, SocketAuthenticatedProvider, SocketLoginProvider, useSocket, useSocketAuthenticated, useSocketLogin };