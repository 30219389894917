import { useState, useEffect } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useTicketsDashboardTable = ({ initialDate, finalDate, considerInternalChats }) => {
  const [rows, setRows] = useState(0);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchClosedTickets = async() => {
        try {
          const { data } = await api.get("/listTicketsTable", {
            params: { initialDate, finalDate, considerInternalChats },
          });
                  
          setRows(data);
        } catch (error) {
          console.log("Use Tickets Dashboard Table Error:", error);
          toastError(error);
        }
      }
      
      fetchClosedTickets();
    }, 500);
    
    return () => clearTimeout(delayDebounceFn);
  }, [ initialDate, finalDate, considerInternalChats ])
  
  return rows;
}

export default useTicketsDashboardTable;