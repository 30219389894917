import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";

import useQueues from "../../hooks/useQueues";
import useSubqueues from "../../hooks/useSubqueues";

const useStyles = makeStyles(theme => ({
  floatingButton: {
    transition: 'transform 0.3s',
  
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
  textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
  extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
  btnWrapper: {
		position: "relative",
	},
  buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
  fields: {
    display: "flex",
    flexDirection: "column",
  },
	actionButton: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const NoteSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
});

const NewNoteModal = ({ modalOpen, onClose, setTabOpen }) => {

	// ***---- Datas ----***
	const classes = useStyles();
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const { user } = useContext(AuthContext);



	// ***---- Initial State ----***
  const initialState = { 
    name: "",
    assignToMe: true,
  };
  const [note, setNote] = useState(initialState);



	// ***---- Select Fields ----***
	const [queues, setQueues] = useState([]);
	const [allQueues, setAllQueues] = useState([]);
	const [selectedQueue, setSelectedQueue] = useState('');
	const { findAll: findAllQueues } = useQueues();

	const [subqueues, setSubqueues] = useState([]);
	const [allSubqueues, setAllSubqueues] = useState([]);
	const [selectedSubqueue, setSelectedSubqueue] = useState('');
	const { findAll: findAllSubqueues } = useSubqueues();



	// ***----Use Effects ----***
	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			setAllQueues(list);
			setQueues(list);
		}

		loadQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	// ***---- Functions ----***
	const handleQueueChange = (e) => {
		setSelectedQueue(e.target.value);
		setSelectedSubqueue("");

		const loadSubqueues = async () => {
			const list = await findAllSubqueues();
			setAllSubqueues(list);
			setSubqueues(list);
		}

		loadSubqueues();
	};

  const handleClose = () => {
    onClose();
    setNote(initialState);
  };

	const handleSaveNote = async values => {
    // starting loading
    setLoading(true);

    // trying to create a new ticket
		try {
      let params = {
        name: values.name,
        assignToMe: values.assignToMe,
        extraInfo: values.extraInfo ? values.extraInfo : [],
        userId: values.assignToMe ? user.id : null,
        status: values.assignToMe ? "open" : "pending",
				noteCreatedBy: user.id,
				queueId: selectedQueue !== "" ? selectedQueue : null,
				subqueueId: selectedSubqueue !== "" ? selectedSubqueue : null,
				isCreatedByUser: true,
      };

      const { data: ticket } = await api.post("/notes", params);
		  history.push(`/tickets/${ticket.id}`);

			setTabOpen(values.assignToMe ? "open" : "pending");
			localStorage.setItem("tabOpenTickets", values.assignToMe ? "open" : "pending");
			toast.success(i18n.t("newNoteModal.success"));
		} 
    
    // if an error happens, a message is thrown
    catch (err) {
			toast.error(err);
		}

    // finishing loading
    setLoading(false);
    handleClose();
	};



	// ***---- Return ----***
	return (
		<>
			<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">



        {/*  
          ***********
          ** Title **
          ***********
        */}
				<DialogTitle id="form-dialog-title">
					{i18n.t("newNoteModal.title")}
				</DialogTitle>



        {/* 
          ************
          ** Formik **
          ************
        */}
        <Formik
					initialValues={note}
					enableReinitialize={true}
					validationSchema={NoteSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveNote(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>

          {({ values, errors, touched, isSubmitting }) => (



            /*
              **********
              ** Form **
              **********
            */
            <Form>
              <DialogContent dividers>
                

                <div className={classes.fields}>
									{/* 
										****************
										** Name Field **
										****************
									*/}
									<Field
										as={TextField}
										label={i18n.t("newNoteModal.form.name")}
										name="name"
										autoFocus
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>



									{/* 
										*************************
										** Assign to Me? Field **
										*************************
									*/}
                
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="assignToMe"
												checked={values.assignToMe}
											/>
										}
										label={i18n.t("newNoteModal.buttons.toogle")}								
									/>


									<br />
									<Divider />
									<br />



									{/* 
										***************************
										** Queue Selection Field **
										***************************
									*/}
									<FormControl variant="outlined" className={classes.maxWidth}>
										<InputLabel>{i18n.t("editTicketModal.fieldQueueLabel")}</InputLabel>
										<Select
											value={selectedQueue}
											onChange={(e) => handleQueueChange(e)}
											label={i18n.t("editTicketModal.fieldQueuePlaceholder")}
											className={classes.textField}
										>
											<MenuItem value={''}>&nbsp;</MenuItem>
											{queues.map((queue) => (
												<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
											))}
										</Select>
									</FormControl>



									{/* 
										******************************
										** SubQueue Selection Field **
										******************************
									*/}
									{selectedQueue !== "" && (
										<>
										<br /><br />
										<FormControl variant="outlined" className={classes.maxWidth}>
											<InputLabel>{i18n.t("editTicketModal.fieldSubqueueLabel")}</InputLabel>
									
											<Select 
												value={selectedSubqueue}
												onChange={(e) => setSelectedSubqueue(e.target.value)}
												label={i18n.t("editTicketModal.fieldSubqueuePlaceholder")}
											>
												<MenuItem value={''}>&nbsp;</MenuItem>
												{subqueues.map((subqueue) => (
													subqueue.queueId === selectedQueue && (
														<MenuItem key={subqueue.id} value={subqueue.id}>{subqueue.name}</MenuItem>
													)
												))}
											</Select>
										</FormControl>
									</>
									)}
								</div>


								<br />
								<Divider />
								<br />



                {/* 
                  **************************
                  ** Aditional Info Title **
                  **************************
                */}
                <Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
									{i18n.t("newNoteModal.form.extraInfo")}
								</Typography>



                {/* 
                  ***************************
                  ** Adicional Info Fields **
                  ***************************
                */}
                <FieldArray name="extraInfo">
									{({ push, remove }) => (
										<>
											{values.extraInfo &&
												values.extraInfo.length > 0 &&
												values.extraInfo.map((info, index) => (
													<div
														className={classes.extraAttr}
														key={`${index}-info`}
													>
														<Field
															as={TextField}
															label={i18n.t("newNoteModal.form.extraName")}
															name={`extraInfo[${index}].name`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														<Field
															as={TextField}
															label={i18n.t("newNoteModal.form.extraValue")}
															name={`extraInfo[${index}].value`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														<IconButton
															size="small"
															onClick={() => remove(index)}
														>
															<DeleteOutlineIcon className={classes.actionButton} />
														</IconButton>
													</div>
												))}
											<div className={classes.extraAttr}>
												<Button
													style={{ flex: 1, marginTop: 8 }}
													variant="outlined"
													color="inherit"
													onClick={() => push({ name: "", value: "" })}
													className={classes.floatingButton}
												>
													{`+ ${i18n.t("newNoteModal.buttons.addExtraInfo")}`}
												</Button>
											</div>
										</>
									)}
								</FieldArray>
              </DialogContent>



              {/* 
                ********************
                ** Action Buttons **
                ********************
              */}
              <DialogActions>



                {/* 
                  *******************
                  ** Cancel Button **
                  *******************
                */}
                <Button
                  onClick={handleClose}
                  color="inherit"
                  disabled={loading}
                  variant="outlined"
                  className={classes.floatingButton}
                >
                  {i18n.t("newTicketModal.buttons.cancel")}
                </Button>



                {/* 
                  ****************
                  ** Add Button **
                  ****************
                */}
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={`${classes.btnWrapper} ${classes.floatingButton}`}
								>
									{i18n.t("newTicketModal.buttons.ok")}

									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
            </Form>
          )}
        </Formik>
			</Dialog>
		</>
	);
};

export default NewNoteModal;
