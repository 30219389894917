import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { ResponsivePie } from '@nivo/pie'

import { i18n } from "../../../../translate/i18n";
import ErrorBoundary from "../../../../errors/Boundary";
import NoDataImg from '../../../../assets/noData.svg';
import useClosedTicketsPerQueue from "../../../../hooks/useClosedTicketsPerQueue";

const useStyles = makeStyles(theme => ({
	dashboardContainer: {
		width: "100%",
		height: "100%",
		backgroundColor: theme.palette.background.paper,
		borderRadius: "5px",
		padding: "20px 0px 20px 0px",
		textIndent: "15px",
	},

  emptyBoxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "50px",
  },

  emptyBoxImage: { width: "250px", height: "250px", },

  emptyBoxSpan: { fontSize: "20px", }
}))

const ChartTicketsPerQueue = ({ initialDate, finalDate }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  ***************
  //  ** Functions **
  //  ***************
  const GetClosedTicketsPerQueue = (initialDate, finalDate) => {
    const structuredInitialDate = initialDate ? `${initialDate}T00:00:01` : "2020-12-29T00:00:01";
    const structuredFinalDate = finalDate ? `${finalDate}T23:59:59` : "2022-12-29T23:59:59";
    const queryResult = useClosedTicketsPerQueue({
      initialDate: structuredInitialDate, 
      finalDate: structuredFinalDate,
      considerInternalChats: false
    });
    return queryResult;
  }



  //  **********
  //  ** Data **
  //  **********
  const queryResult = GetClosedTicketsPerQueue(initialDate, finalDate);
  let ticketsPerQueueData = queryResult.count === 0 ? [] : queryResult.count;
  let ticketsPerQueueColors = [];

  for (let i = 0; i < ticketsPerQueueData.length; i += 1) {
    ticketsPerQueueColors.push(ticketsPerQueueData[i].color);
  }



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.dashboardContainer}>
      <ErrorBoundary>
        {ticketsPerQueueData.length > 0 && (
          <ResponsivePie
            data={ticketsPerQueueData}
            theme={{ tooltip: { container: { background: "#999", color: "#fff" } }, }}
            colors={ticketsPerQueueColors}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor='#999'
            arcLinkLabelsThickness={20}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: 'color', modifiers: [[ 'darker', 10 ]] }}
            legends={[{
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 5,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'top-to-bottom',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [{ on: 'hover', style: { itemTextColor: '#c6c6c6' } }]
            }]}
            motionConfig={{
              mass: 1,
              tension: 170,
              friction: 26,
              clamp: false,
              precision: 0.01,
              velocity: 0,
            }}
            transitionMode="outerRadius"
          />
        )}

        {ticketsPerQueueData.length === 0 && (
          <div className={classes.emptyBoxContainer}>
            <img className={classes.emptyBoxImage} src={NoDataImg} alt="No Data" />
            <span className={classes.emptyBoxSpan}>{i18n.t("dashboard.emptyBox")}</span>
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default ChartTicketsPerQueue;