import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AccountCircle } from '@material-ui/icons';
import {
  Box,
  Button,
  CssBaseline,
  Container,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import WhatsappButton from "../../components/WhatsappButton";

import iconLight from '../../assets/icon.png';
import iconDark from '../../assets/icon-dark.png';
import iconFX from '../../assets/fx_sistemas_logo.png';
import SignInImg from '../../assets/signIn.svg';

const Copyright = () => {
 	return (
 		<Typography variant="body2" color="textSecondary" align="center">
 			{"Copyright "}
 			<Link color="inherit" href="https://www.fxsistemas.com.br" target="_blank">
 				FX Sistemas
 			</Link>{" "}
 			{new Date().getFullYear()}
 			{"."}
 		</Typography>
 	);
};

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100vw",
    height: "90vh",
    gap: "70px",
    marginTop: "5vh",
    name: theme.name,
  },

  signInImgContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('sm')]: { display: 'none', },
  },

  signInImg: { width: "500px", height: "500px", },

  loginForm: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  avatar: {
    margin: theme.spacing(0),
    width: "150px",
    height: "auto",
  },

  logo: {
    margin: theme.spacing(1),
    width: "50px",
    height: "auto",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  
  submit: { margin: theme.spacing(3, 0, 2), },

  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  }
}));

const LoginGroup = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const { handleLoginGroup } = useContext(AuthContext);

  const [user, setUser] = useState({ email: "" });
  const currentIcon = localStorage.getItem("theme") === "dark" ? iconDark : iconLight;

  const location = useLocation();
  

  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    user.email = params.get("email") !== "" ? params.get("email")  : user.email;
		document.title = "BestZap";
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  useEffect(() => {
    const paramsEmail = new URLSearchParams(location.search).get("email");
    if (paramsEmail !== undefined && paramsEmail !== null && paramsEmail !== "") handleLoginGroup(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleChangeInput = (e) => { setUser({ ...user, [e.target.name]: e.target.value }); };
  const handleSubmit = (e) => { e.preventDefault(); handleLoginGroup(user); };




  //  ************
  //  ** Return **
  //  ************
  return (
    <Container className={classes.loginContainer} component="main">
      <CssBaseline />

      <div className={classes.signInImgContainer}>
        <img src={SignInImg} className={classes.signInImg} alt="" />
      </div>
      
      <div className={classes.loginForm}>
        <img className={classes.avatar} src={currentIcon} alt="BestZap Logo" />

        <Typography component="h1" variant="h2">
          {i18n.t("login.title")}
        </Typography>

        <Typography component="h2" variant="h6">
          <br></br>
          {i18n.t("login.subtitleGroup")}
        </Typography>        

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{width: "80%"}}
            id="email"
            label={i18n.t("login.form.email")}
            name="email"
            value={user.email}
            onChange={handleChangeInput}
            autoComplete="email"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              )
            }}
          />
          
          <Button
            type="submit"
            style={{width: "80%"}}
            variant="contained"
            color="primary"
            className={`${classes.submit} ${classes.floatingButton}`}
          >
            {i18n.t("login.buttons.submit")}
          </Button>
        </form>
        
        <Box mt={8}>
          <a href="https://www.fxsistemas.com.br" target="_blank" rel="noreferrer">
            <img className={classes.logo} src={iconFX} alt="FX Sistemas Logo" />
          </a>
          {<Copyright />}
        </Box>
      </div>

      <WhatsappButton />
    </Container>
  );
};

export default LoginGroup;
