import { useState } from "react";
import { makeStyles } from "@material-ui/core";

import { formatTextMessage, isTextMessageLarge } from "../../utils/formatTextMessage";
import { i18n } from "../../translate/i18n";
import MarkdownWrapper from "../MarkdownWrapper";

const useStyles = makeStyles((theme) => ({
  specialDataInText: {
    cursor: "pointer",
    color: theme.palette.secondary.ackCheck,
    textDecoration: "none",
    "&:hover": { textDecoration: "underline", },
  },
}));

const MessageBodyWrapper = ({
  messageBody,
  isMessageFromMe,
  handleOpenPhoneNumberMessageOptionsMenu
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [isReadMoreActive, setIsReadMoreActive] = useState(false);



  //  ***************
  //  ** Functions **
  //  ***************
  const toggleReadMore = () => { setIsReadMoreActive(previousValue => !previousValue); };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <MarkdownWrapper
        handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
        isMsgFromMe={isMessageFromMe}
      >
        {isReadMoreActive ? messageBody : formatTextMessage(messageBody)}
      </MarkdownWrapper>

      {isTextMessageLarge(messageBody) && (
        <span className={classes.specialDataInText} onClick={toggleReadMore}>
          <br />
          {isReadMoreActive ? i18n.t("messageBodyWrapper.readLess") : i18n.t("messageBodyWrapper.readMore")}
        </span>
      )}
    </>
  );
};

export default MessageBodyWrapper;
