import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, } from '@material-ui/core';
import { CancelOutlined, SendOutlined } from "@material-ui/icons";
import ReactImgEditor from "@cloudogu/react-img-editor";
import "@cloudogu/react-img-editor/lib/index.css";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import "../../styles/react-img-editor-new-styles.css";

const useStyles = makeStyles(theme => ({
  //  *************
  //  ** Content **
  //  *************
  dialogContent: { overflowY: "scroll", ...theme.scrollbarStyles },



  //  ********************
  //  ** Dialog Actions **
  //  ********************
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "3px",
    margin: "1em",
  },

  floatingButton: {
    transition: "all 0.3s ease",
    "&:hover": { transform: "translateY(-5px)" },
  },
}));

const ImageToStickerContextModal = ({ open, onClose, imageToSticker, setImageToSticker, ticketId }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const stageRef = useRef(null);
  const imageUrl = imageToSticker ? URL.createObjectURL(imageToSticker) : "";



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    return () => { URL.revokeObjectURL(imageUrl); };
  }, [imageUrl]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => { 
    setImageToSticker(null);
    onClose();
    document.getElementById("upload-image-to-sticker").value = "";
  };

  const setStage = (stage) => { stageRef.current = stage; };

  const handleSendEditedImage = () => {
    const canvas = stageRef.current.clearAndToCanvas({ pixelRatio: stageRef.current._pixelRatio });
    canvas.toBlob(async (blob) => {
      const formData = new FormData();
      formData.append("fromMe", true);
      formData.append("medias", blob, "image.png");
      handleClose();
      await api.post(`/stickers/${ticketId}`, formData);
    });
  };

 

  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        scroll="paper"
        fullWidth
      >
        <DialogTitle>
          <span>{i18n.t("imageToStickerModal.title.up")}</span>
        </DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <ReactImgEditor 
            language="en"
            src={imageUrl}
            crossOrigin="anonymous"
            getStage={setStage}
            toolbar={{
              items: ["pen", "eraser", "arrow", "rect", "circle", "mosaic", "text"
            , "|", "repeal", "crop"]
            }} // excluded: "download", "zoomIn", "zoomOut"
          />
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.floatingButton}
            startIcon={<CancelOutlined />}
            color="inherit"
            variant="outlined"
            onClick={handleClose}
          >{i18n.t("imageToStickerModal.buttons.cancel")}</Button>

          <Button
            className={classes.floatingButton}
            startIcon={<SendOutlined />}
            color="inherit"
            variant="outlined"
            onClick={handleSendEditedImage}
          >{i18n.t("imageToStickerModal.buttons.send")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageToStickerContextModal;