import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";

const SocketSubqueues = ({ dispatch }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { socket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const handleSubqueue = (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_SUBQUEUES", payload: data.subqueue });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_SUBQUEUE", payload: data.subqueueId });
      }
    };

    socket.on("subqueue", handleSubqueue);

    return () => {
      socket.off("subqueue", handleSubqueue);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, socket]);


  
  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketSubqueues;