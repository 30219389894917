import React, { useState, useEffect, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardActionArea,
  Avatar,
  CardContent,
  TextField,
  InputAdornment,
  Typography
} from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ContactModal from "../../components/ContactModal";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) { state[contactIndex] = contact; }
      else { newContacts.push(contact); }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) { state.splice(contactIndex, 1); }
    return [...state];
  }

  if (action.type === "RESET") { return []; }
};

const useStyles = makeStyles(theme => ({
  
  // ***********
  // ** Label **
  // ***********
  label: {
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    color: "#f5f5ff",
  },
  
  // *************
  // ** Content **
  // *************
  dialogContent: { overflowY: "scroll", ...theme.scrollbarStyles, },
  contactCard: { borderRadius: "20px", padding: "10px", marginBottom: "5px", },
  contactName: { fontSize: "16px", },
  contactNumber: { fontSize: "13px", color: "hsl(217, 12%, 63%)", },

  contactCardActionArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "20px",
    padding: "5px",
  },
}));

const NewContactsModal = ({ open, onClose, initialDate, finalDate, contactType }) => {
  //  ***********
  //  ** Datas **
  //  ***********
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [contacts, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    if (open) {
      setLoading(true);

      const delayDebounceFn = setInterval(() => {
        const fetchContacts = async () => {
          try {
            const { data } = await api.get("/newContacts", {
              params: {
                searchParam,
                pageNumber,
                contactType,
                initialDate: `${initialDate}T00:00:00`, 
                finalDate: `${finalDate}T23:59:59`
              }
            });

            dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
            setHasMore(data.hasMore);
            setLoading(false);
          } catch (error) {
            console.log("New Contacts Modal Use Effect Error:", error);
            toastError(error);
          }
        };
        fetchContacts();
      }, 2000);

      return () => clearInterval(delayDebounceFn);
    }
  }, [open, searchParam, pageNumber, initialDate, finalDate]);




  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();

    if (contacts.length > 40) { dispatch({ type: "RESET" }); }
    setPageNumber(1);
    setSearchParam("");
    setSelectedContactId(null);
  };

  const handleSearch = (event) => { setSearchParam(event.target.value.toLowerCase()); };

  const loadMore = () => { setPageNumber((prevState) => prevState + 1); }

  const handleScroll = (e) => {
    if (!hasMore || loading) { return; }
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) { loadMore(); }
  };

  const handleOpenContactModal = (contact) => {
    setSelectedContactId(contact.id);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  

  //  ************
  //  ** Return **
  //  ************
  return (
    <>
    <div className={classes.root}>
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        contactId={selectedContactId}
        contactType={contactType}
      />

      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
        <DialogTitle>
          <span>{i18n.t("newContactsModal.title.up")}</span>

          <br /><br />

          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            autoFocus
            type="search"
            fullWidth
            value={searchParam}
            onChange={handleSearch}
            InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon style={{ color: "gray" }} /></InputAdornment>) }}
          />
        </DialogTitle>

        <DialogContent className={classes.dialogContent} onScroll={handleScroll} dividers>
          {contacts.map((contact) => (
            <Card className={classes.contactCard}>
              <CardActionArea className={classes.contactCardActionArea} onClick={() => handleOpenContactModal(contact)}>
                <Avatar
                  alt={contact?.name}
                  src={contact?.profilePicUrl}
                  className={classes.contactAvatar}
                />
                
                <CardContent>
                  <Typography className={classes.contactName} gutterBottom component="h3">{contact.name}</Typography>
                  <Typography className={classes.contactNumber} gutterBottom component="h3">
                    {contact.isGroup ? i18n.t("newContactsModal.contactType.group") : contact.number}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </DialogContent>
      </Dialog>
    </div>
    </>
  );
};

export default NewContactsModal;