import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketBusinessHours = ({ dispatch }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { socket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const handleBusinessHours = (data) => {
      if ((data.action === "update" || data.action === "create") && (`${api.defaults.headers.tenantId.toString()}` === `${data.tenantId.toString()}`)) {
        dispatch({ type: "UPDATE_BUSINESS_HOURS", payload: data.businessHours });
      }
    };

    socket.on("businessHours", handleBusinessHours);

    return () => {
      socket.off("businessHours", handleBusinessHours);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, socket]);


  
  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketBusinessHours;