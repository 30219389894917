import React from "react";
import { CheckCircle } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((themes) => ({
  //  ********************
  //  ** Main Container **
  //  ********************
  pollContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    gap: "2px",
    padding: "3px",
  },



  //  ********************
  //  ** Type Container **
  //  ********************
  // 
  // - multiple answers
  // - single answer
  // 
  allowAnswersContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1em",
    gap: "2px",
    color: "#998",
  },

  multipleAnswersContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
}));

const PollPreviewQuotedMessage = ({ message }) => {
  //  *************
  //  ** Classes **
  //  *************
  const classes = useStyles();



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.pollContainer}>
      <b>📊 {message.body}</b>

      {message.allowMultipleAnswers ?
        <div className={classes.allowAnswersContainer}>
          <span className={classes.multipleAnswersContainer}>
            <CheckCircle style={{ fontSize: "1em" }} />
            <CheckCircle style={{ fontSize: "1em" }} />
          </span>
          {i18n.t("pollPreview.multipleAnswers")}
        </div> :
        <div className={classes.allowAnswersContainer}>
          <CheckCircle style={{ fontSize: "1em" }} />
          {i18n.t("pollPreview.singleAnswer")}
        </div>
      }
    </div>
  );
};

export default PollPreviewQuotedMessage;