import React, { useState, useCallback, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import {
	Button,
	CircularProgress,
	IconButton,
	Paper,
	Tooltip,
	Typography,
} from "@material-ui/core";
import {
	AutorenewOutlined,
	CheckCircleOutlineOutlined,
	CropFree,
	DeleteOutline,
	Edit,
	FilterNoneOutlined,
	PhonelinkErase,
	SignalCellularAlt,
	SignalCellularConnectedNoInternet0Bar,
	SignalCellularConnectedNoInternet2Bar,
	Smartphone,
} from "@material-ui/icons";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import api from "../../services/api";
import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import QrcodeModal from "../../components/QrcodeModal";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import WhatsAppInfoModal from "../../components/WhatsAppInfoModal";
import WhatsAppModal from "../../components/WhatsAppModal";

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		padding: theme.spacing(1),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	connectionsPaper: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		flexWrap: "wrap",
		gap: "15px",
		paddingTop: "20px",
	},

	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: theme.typography.pxToRem(14),
		border: "1px solid #dadde9",
		maxWidth: 450,
	},

	tooltipPopper: { textAlign: "center", },
	buttonProgress: { color: green[500], },
	icon: { color: theme.palette.text.primary, },

	floatingButton: {
		transition: 'transform 0.3s',
		'&:hover': { transform: 'translateY(-5px)', },
	},

	actionButton: { "&:hover": { color: theme.palette.primary.main, }, },

	// Chips Count
	usedChipsCountContainer: { textAlign: "center", padding: "5px", },
	usedChipsCount: { fontSize: "1.25em", },

	// Connection Card
	connectionSlot: {
		border: `2px solid ${theme.palette.text.primary}`,
		borderRadius: "20px",
		padding: "20px 50px 20px 50px",

		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-around",
		gap: "20px",
		height: "fit-content",
		transition: 'transform 0.3s',
		opacity: "0.90",

		'&:hover': { transform: "translateY(-5px)", opacity: "1", },
	},

	connectionNameAndPhoneNumberContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		gap: "1.5px",
	},

	connectionName: { fontWeight: "bold", fontSize: "1.75em", },

	connectionPhoneNumber: {
		padding: "5px",
    borderRadius: "5px",
		borderBottom: `2px solid ${theme.palette.primary.main}`,
		cursor: "default",
    fontSize: "1.1em",
	},

	connectionStatus: { fontSize: "2em", },
	phoneIcon: { fontSize: "5em", },
	isDefaultConnection: { fontWeight: "bold", fontSize: "1.15em", },

	actionButtonsContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end",
	},

	//  **********************************
	// 	** Importing Messages Animation **
	// 	**********************************
	"@keyframes loadingMessages": {
		"0%": { opacity: 1, rotate: "z 0deg" },
		"50%": { opacity: 0.75, rotate: "z 180deg" },
		"100%": { opacity: 1, rotate: "z 360deg" },
  },

	importingMessagesStatus: { fontSize: "2em", color: red[500], animation: "$loadingMessages 3s ease infinite", },
	importedMessagesStatus: { fontSize: "2em", color: green[500], },
}));

const CustomToolTip = ({ title, content, children }) => {
	const classes = useStyles();

	return (
		<Tooltip
			arrow
			classes={{ tooltip: classes.tooltip, popper: classes.tooltipPopper, }}
			title={
				<>
					<Typography gutterBottom color="inherit">{title}</Typography>
					{content && <Typography>{content}</Typography>}
				</>
			}
		>
			{children}
		</Tooltip>
	);
};

const Connections = () => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const { whatsApps } = useContext(WhatsAppsContext);

	const [limitWhatsappCount, setLimitWhatsappCount] = useState(0);
	const [whatsAppInfoModalOpen, setWhatsAppInfoModalOpen] = useState(false);
	const [whatsAppIdInfo, setWhatsAppIdInfo] = useState(null);

	const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
	const [qrModalOpen, setQrModalOpen] = useState(false);
	const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const confirmationModalInitialState = {
		action: "",
		title: "",
		message: "",
		whatsAppId: "",
		open: false,
	};
	const [confirmModalInfo, setConfirmModalInfo] = useState(confirmationModalInitialState);
	const [isApiTokenCopied, setIsApiTokenCopied] = useState(false);
	const [importMessages, setImportMessages] = useState(null);
	


	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		const fetchSettings = async () => {
			try {
				const apiCalls = [
					api.get("/importMessages"),
					api.get("/limitWhatsappCount")
				];

				const [
					ImportMessagesResponse,
					LimitWhatsappCountResponse
				] = await Promise.all(apiCalls);

				setImportMessages(ImportMessagesResponse.data.importMessages);
				setLimitWhatsappCount(LimitWhatsappCountResponse.data.totalChips);
			} catch (error) {
				setImportMessages(null);
				console.log("Connections Use Effect 1 Error:", error);
				toastError(error);
			}
		};

		fetchSettings();
	}, []);

	useEffect(() => {
		if (user.profile === "user" || !user.configEnabled) { history.push(`/tickets`); }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	



	// 	***************
	// 	** Functions **
	// 	***************
	const handleCopyApiToken = async (apiToken) => {
		setIsApiTokenCopied(true);
		await navigator.clipboard.writeText(apiToken);
		setTimeout(() => { setIsApiTokenCopied(false); }, 500);
	};

	const handleRequestNewQrCode = async (whatsAppId) => {
		try {
			await api.put(`/whatsappsession/${whatsAppId}`);
		} catch (error) {
			console.log("Handle Request New QR Code Error:", error);
			toastError(error);
		}
	};

	const handleOpenWhatsAppInfoModal = (whatsappId) => {
		setWhatsAppInfoModalOpen(true);
		setWhatsAppIdInfo(whatsappId);
	};

	const handleCloseWhatsAppInfoModal = () => {
		setWhatsAppInfoModalOpen(false);
		setWhatsAppIdInfo(null);
	};

	const handleOpenWhatsAppModal = () => {
		setSelectedWhatsApp(null);
		setWhatsAppModalOpen(true);
	};

	const handleCloseWhatsAppModal = useCallback(() => {
		setWhatsAppModalOpen(false);
		setSelectedWhatsApp(null);
	}, [setSelectedWhatsApp, setWhatsAppModalOpen]);

	const handleOpenQrModal = (whatsApp) => {
		setSelectedWhatsApp(whatsApp);
		setQrModalOpen(true);
	};

	const handleCloseQrModal = useCallback(() => {
		setSelectedWhatsApp(null);
		setQrModalOpen(false);
	}, [setQrModalOpen, setSelectedWhatsApp]);

	const handleEditWhatsApp = whatsApp => {
		setSelectedWhatsApp(whatsApp);
		setWhatsAppModalOpen(true);
	};

	const handleOpenConfirmationModal = (action, whatsAppId) => {
		if (action === "disconnect") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("connections.confirmationModal.disconnectTitle"),
				message: i18n.t("connections.confirmationModal.disconnectMessage"),
				whatsAppId: whatsAppId,
			});
		}

		if (action === "delete") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("connections.confirmationModal.deleteTitle"),
				message: i18n.t("connections.confirmationModal.deleteMessage"),
				whatsAppId: whatsAppId,
			});
		}

		setConfirmModalOpen(true);
	};

	const handleSubmitConfirmationModal = async () => {
		if (confirmModalInfo.action === "disconnect" || confirmModalInfo.action === "delete") {
			try {
				await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
				toast.success(i18n.t("connections.toasts.deleted"));
			} catch (error) {
				console.log("Handle Submit Confirmation Modal 1 Error:", error);
				toastError(error);
			} finally {
				setConfirmModalInfo(confirmationModalInitialState);
			}
		}
	};

	const renderActionButtons = (whatsApp) => {
		return (
			<>
				{whatsApp.status === "qrcode" && (
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => handleOpenQrModal(whatsApp)}
						className={classes.floatingButton}
					>
						{i18n.t("connections.buttons.qrcode")}
					</Button>
				)}

				{whatsApp.status === "DISCONNECTED" && (
					<Button
						size="small"
						variant="outlined"
						color="inherit"
						onClick={() => handleRequestNewQrCode(whatsApp.id)}
						className={classes.floatingButton}
					>
						{i18n.t("connections.buttons.newQr")}
					</Button>
				)}

				{(whatsApp.status === "CONNECTED" && (
					<>
						<Button
							size="small"
							variant="outlined"
							color="inherit"
							onClick={() => {
								handleOpenWhatsAppInfoModal(whatsApp.id);
							}}
							className={classes.floatingButton}
						>
							{i18n.t("connections.buttons.info")}
						</Button>
						
						{" "}

						<Button
							size="small"
							variant="outlined"
							color="inherit"
							onClick={() => {
								handleOpenConfirmationModal("disconnect", whatsApp.id);
							}}
							className={classes.floatingButton}
						>
							{i18n.t("connections.buttons.delete")}
						</Button>
					</>
				))}

				{(whatsApp.status === "PAIRING" || whatsApp.status === "TIMEOUT") && (
					<Button
						size="small"
						variant="outlined"
						color="inherit"
						onClick={() => {
							handleOpenConfirmationModal("delete", whatsApp.id);
						}}
						className={classes.floatingButton}
					>
						{i18n.t("connections.buttons.delete")}
					</Button>
				)}

				{whatsApp.status === "OPENING" && (
					<Button size="small" variant="outlined" disabled color="default" className={classes.floatingButton}>
						{i18n.t("connections.buttons.connecting")}
					</Button>
				)}
			</>
		);
	};

	const renderStatusToolTips = (whatsApp) => {
		return (
			<>
				{whatsApp.status === "DISCONNECTED" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.disconnected.title")}
						content={i18n.t("connections.toolTips.disconnected.content")}
					>
						<SignalCellularConnectedNoInternet0Bar className={classes.connectionStatus} color="secondary" />
					</CustomToolTip>
				)}

				{(whatsApp.status === "OPENING" || whatsApp.status === "PAIRING" || whatsApp.status === "PENDING") && (
					<CircularProgress size={24} className={`${classes.buttonProgress} ${classes.connectionStatus}`} />
				)}
				
				{whatsApp.status === "qrcode" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.qrcode.title")}
						content={i18n.t("connections.toolTips.qrcode.content")}
					>
						<CropFree className={classes.connectionStatus} />
					</CustomToolTip>
				)}

				{whatsApp.status === "CONNECTED" && (
					<CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
						<SignalCellularAlt className={classes.connectionStatus} style={{ color: green[500] }} />
					</CustomToolTip>
				)}

				{(whatsApp.status === "TIMEOUT") && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.timeout.title")}
						content={i18n.t("connections.toolTips.timeout.content")}
					>
						<SignalCellularConnectedNoInternet2Bar className={classes.connectionStatus} color="secondary" />
					</CustomToolTip>
				)}
			</>
		);
	};

	const renderMessagesImportationStatusTooltips = (whatsApp) => {
		return (
			<>
				{whatsApp.importationStatus === "importing" && importMessages === "enabled" && (
					<CustomToolTip title={i18n.t("connections.toolTips.importingMessages.title")}>
						<AutorenewOutlined className={classes.importingMessagesStatus} />
					</CustomToolTip>
				)}

				{whatsApp.importationStatus === "imported" && (
					<CustomToolTip title={i18n.t("connections.toolTips.importedMessages.title")}>
						<CheckCircleOutlineOutlined className={classes.importedMessagesStatus} />
					</CustomToolTip>
				)}
			</>
		);
	};



	// 	************
	// 	** Return **
	// 	************
	return (
		<MainContainer>
			<ConfirmationModal
				title={confirmModalInfo.title}
				open={confirmModalOpen}
				onClose={setConfirmModalOpen}
				onConfirm={handleSubmitConfirmationModal}
			>
				{confirmModalInfo.message}
			</ConfirmationModal>

			<QrcodeModal
				open={qrModalOpen}
				onClose={handleCloseQrModal}
				whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
			/>

			<WhatsAppModal
				open={whatsAppModalOpen}
				onClose={handleCloseWhatsAppModal}
				whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
			/>

			<WhatsAppInfoModal 
				open={whatsAppInfoModalOpen}
				onClose={handleCloseWhatsAppInfoModal}
				whatsAppId={whatsAppIdInfo}
			/>

			<MainHeader>
				<Title>{i18n.t("connections.title")}</Title>
				<MainHeaderButtonsWrapper>
					<Button
						variant="contained"
						color="primary"
						onClick={handleOpenWhatsAppModal}
						className={classes.floatingButton}
					>
						{i18n.t("connections.buttons.add")}
					</Button>
				</MainHeaderButtonsWrapper>
			</MainHeader>

			<Paper className={classes.mainPaper} variant="outlined">
				<div className={classes.usedChipsCountContainer}>
					<Typography className={classes.usedChipsCount}>
						<b>{whatsApps.length}</b> {i18n.t("connections.usedChipsCount.outOf")} {limitWhatsappCount} {i18n.t("connections.usedChipsCount.chips")}
					</Typography>
				</div>

				<Paper className={classes.connectionsPaper} elevation={0}>
					{whatsApps?.length > 0 && whatsApps.map(whatsApp => (
						<div className={classes.connectionSlot}>
							
							{/* Connection Name and Phone Number */}
							<div className={classes.connectionNameAndPhoneNumberContainer}>
								<Typography className={classes.connectionName} gutterBottom>{whatsApp.name}</Typography>

								{whatsApp.phoneNumber && whatsApp.phoneNumber !== "" && (
									<Typography className={classes.connectionPhoneNumber} gutterBottom>{whatsApp.phoneNumber}</Typography>
								)}
							</div>

							{/* Connection Status */}
							<div>
								{renderStatusToolTips(whatsApp)}
								{renderMessagesImportationStatusTooltips(whatsApp)}
							</div>

							{/* Icon */}
							<div>
								{whatsApp.status === "CONNECTED" && ( <Smartphone className={classes.phoneIcon} /> )}
								{whatsApp.status !== "CONNECTED" && ( <PhonelinkErase className={classes.phoneIcon} /> )}
							</div>

							{/* Action Button */}
							<div> {renderActionButtons(whatsApp)} </div>

							{/* Default Connection */}
							<div>
								{whatsApp.isDefault && (
									<div>
										<Typography className={classes.isDefaultConnection} gutterBottom>
											{i18n.t("connections.card.defaultConnection")}
										</Typography>
									</div>
								)}

								{!whatsApp.isDefault && (
									<div>
										<Typography className={classes.isDefaultConnection} gutterBottom>
											{i18n.t("connections.card.notDefaultConnection")}
										</Typography>
									</div>
								)}
							</div>

							{/* Action Buttons */}
							<div className={classes.actionButtonsContainer}>
								{whatsApp.status === "CONNECTED" && (
									<Tooltip title={
											isApiTokenCopied 
											? i18n.t("connections.toolTips.copied")
											: i18n.t("connections.toolTips.copyApiToken")
										} 
										placement="top-start" arrow
									>
										<IconButton className={classes.icon}
											size="small"
											onClick={() => handleCopyApiToken(whatsApp.apiToken)}
										>
											<FilterNoneOutlined className={classes.actionButton} />
										</IconButton>
									</Tooltip>
								)}

								<IconButton className={classes.icon}
									size="small"
									onClick={() => handleEditWhatsApp(whatsApp)}
								>
									<Edit className={classes.actionButton} />
								</IconButton>
								
								{whatsApp.status !== "CONNECTED" 
								&& whatsApp.status !== "TIMEOUT" 
								&& (
									<IconButton className={classes.icon}
										size="small"
										onClick={e => {
											handleOpenConfirmationModal("delete", whatsApp.id);
										}}
									>
										<DeleteOutline className={classes.actionButton} />
									</IconButton>
								)}
							</div>
						</div>
					))}
				</Paper>
			</Paper>
		</MainContainer>
	);
};

export default Connections;
