import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { IconButton, Tooltip } from '@material-ui/core';
import { WhatsApp } from '@material-ui/icons';

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	whatsappContainer: {
    position: "absolute",
    right: "2em",
    bottom: "2em",
  },

  whatsappButton: {
    width: "50px",
    height: "50px",
    color: "#f6f6ff",
    backgroundColor: "#25d366",
    padding: "6px 6px 7px 7px",
    "&:hover": {
      opacity: "0.8",
      color: "#f6f6ff",
      backgroundColor: "#25d366",
    },
  },

  whatsappIcon: {
    fontSize: "30px",
  },

  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  }
}));

const WhatsappButton = ({ open, onClose, whatsAppId }) => {
  //  ***************
  //  ** Variables **
  //  ***************
	const classes = useStyles();
  const phoneNumber = "5518991129967";
  const textMessage = encodeURI("Olá, tudo bem? Preciso de ajuda com o BestZap!");
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${textMessage}`;
  



 

  //  ***************
  //  ** Functions **
  //  ***************
	const handleWhatsappRedirect = () => { window.open(url, "_blank").focus(); };



  //  ************
  //  ** Return **
  //  ************
	return (
    <div className={classes.whatsappContainer}>
      <Tooltip title={i18n.t("whatsappButtonComponent.tooltip")} placement="top-start" arrow>
        <IconButton className={`${classes.whatsappButton} ${classes.floatingButton}`} onClick={handleWhatsappRedirect}>
          <WhatsApp className={classes.whatsappIcon} />
        </IconButton>
      </Tooltip>
    </div>
	);
};

export default WhatsappButton;
