import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { green } from "@material-ui/core/colors";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  IconButton,
  Switch,
  TextField
} from '@material-ui/core';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
  // ***---- Dialog Content Components ----***
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "2em",
  },

  toogleButton: {
		padding: "5px",
		display: "flex",
		alignItems: "center",
		justifyContent: "left",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "5px",
		fontSize: "16px",
	},

	toogleSpan: { cursor: "pointer", userSelect: "none" },

  extraOptionsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  // ***---- Dialog Action Components ----***
  floatingButton: {
    transition: "transform 0.3s",
    "&:hover": { transform: "translateY(-5px)", },
  },

  actionButton: {
    color: theme.palette.text.primary,
    "&:hover": { color: theme.palette.primary.main },
  },

  wrapperButton: { position: "relative" },

  progressButton: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const PollModal = ({ open, onClose, ticketId }) => {
  //  ***********
  //  ** Datas **
  //  ***********
  const classes = useStyles();

  const initialPollState = {
    title: "",
    allowMultipleAnswers: true,
    optionA: "",
    optionB: "",
  };
  const [pollData, setPollData] = useState(initialPollState);



  //  ************************
  //  ** Validation Schemas **
  //  ************************
  const pollSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .min(2, i18n.t("pollModal.schemaValidations.tooShort"))
      .max(150, i18n.t("pollModal.schemaValidations.tooLong"))
      .required(i18n.t("pollModal.schemaValidations.required")),
    
    allowMultipleAnswers: Yup.boolean().required(),

    optionA: Yup.string()
      .trim()
      .min(2, i18n.t("pollModal.schemaValidations.tooShort"))
      .max(50, i18n.t("pollModal.schemaValidations.tooLong"))
      .required(i18n.t("pollModal.schemaValidations.required")),

    optionB: Yup.string()
      .trim()
      .min(2, i18n.t("pollModal.schemaValidations.tooShort"))
      .max(50, i18n.t("pollModal.schemaValidations.tooLong"))
      .required(i18n.t("pollModal.schemaValidations.required"))
  });



  // ***************
  // ** Functions **
  // ***************
  const handleClose = () => {
    onClose();
    setPollData(initialPollState);
  };

  const handleSendPoll = async (values) => {
    try {
      values.title = values.title.trim();
      values.optionA = values.optionA.trim();
      values.optionB = values.optionB.trim();
      values.extraOptions = values.extraOptions
        ? values.extraOptions.filter(item => item.option.trim() !== '').map(item => ({ option: item.option.trim() }))
        : [];

      await api.post(`/messagesPoll/${ticketId}`, values);
      handleClose();
    } catch (error) {
      console.log("Handle Send Poll Error:", error);
      toastError(error);
    }
  };





  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          <span>{i18n.t("pollModal.title")}</span>
        </DialogTitle>

        
        {/* 
          **********
          ** Form **
          **********
        */}
        <Formik
          initialValues={pollData}
          enableReinitialize={true}
          validationSchema={pollSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSendPoll(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                {/* 
                  ***********
                  ** Title **
                  ***********
                */}
                <Field 
                  as={TextField}
                  label={i18n.t("pollModal.fields.titleLabel")}
                  name="title"
                  autoFocus
                  fullWidth
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                  variant="outlined"
                  margin="dense"
                />

                {/* 
                  *****************************
                  ** Enable Multiple Answers **
                  *****************************
                */}
                <div>
                  <FormControlLabel
                    label={i18n.t("pollModal.fields.allowMultipleAnswers")}
                    control={
                      <Field 
                        as={Switch}
                        color="primary"
                        name="allowMultipleAnswers"
                        checked={values.allowMultipleAnswers}
                      />
                    }
                  />
                </div>

                {/* 
                  **************
                  ** Option A **
                  **************
                */}
                <Field 
                  as={TextField}
                  label={i18n.t("pollModal.fields.optionA")}
                  name="optionA"
                  autofocus
                  fullWidth
                  error={touched.optionA && Boolean(errors.optionA)}
                  helperText={touched.optionA && errors.optionA}
                  variant="outlined"
                  margin="dense"
                />

                {/* 
                  **************
                  ** Option B **
                  **************
                */}
                <Field 
                  as={TextField}
                  label={i18n.t("pollModal.fields.optionB")}
                  name="optionB"
                  autofocus
                  fullWidth
                  error={touched.optionB && Boolean(errors.optionB)}
                  helperText={touched.optionB && errors.optionB}
                  variant="outlined"
                  margin="dense"
                />

                {/* 
                  *******************
                  ** Extra Options **
                  *******************
                */}
                <FieldArray name="extraOptions">
                  {({ push, remove }) => (
                    <>
                    {values.extraOptions &&
                     values.extraOptions.length > 0 &&
                     values.extraOptions.map((info, index) => (
                      <div
                        className={classes.extraOptionsContainer}
                        key={`${index}-info`}
                      >
                        <Field
                          as={TextField}
                          label={i18n.t("pollModal.fields.newOptionLabel")}
                          name={`extraOptions[${index}].option`}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />

                        <IconButton size="small" onClick={() => remove(index)}>
                          <DeleteOutlineIcon className={`${classes.actionButton} ${classes.floatingButton}`} />
                        </IconButton>
                      </div>
                     ))}

                    <div className={classes.extraOptionsContainer}>
                      <Button
                        style={{ flex: 1, marginTop: 8 }}
                        variant="outlined"
                        color="inherit"
                        onClick={() => push({ option: "" })}
                        className={classes.floatingButton}
                      >{i18n.t("pollModal.buttons.newExtraOption")}</Button>
                    </div>
                    </>
                  )}
                </FieldArray>
              </DialogContent>

              <DialogActions>
                <Button
                  className={classes.floatingButton}
                  color="inherit"
                  variant="outlined"
                  onClick={handleClose}
                >{i18n.t("pollModal.buttons.cancel")}</Button>

                <Button
                  type="submit"
                  className={`${classes.floatingButton} ${classes.wrapperButton}`}
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {i18n.t("pollModal.buttons.send")}
                      
                  {isSubmitting && (
                    <CircularProgress size={24} className={classes.progressButton} />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default PollModal;