import React, { useContext, useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import {
  Button,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import EditClosingTicketModal from "../EditClosingTicketModal";
import toastError from "../../errors/toastError";
import useWhatsappQueues from "../../hooks/useWhatsappQueues";

const useStyles = makeStyles(theme => ({
	mainButton: {
		backgroundColor: theme.palette.primary.main,
    color: "#f6f6ff",
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow:"inset 0 0 0 99999px rgba(0, 0, 0, 0.2)",
   },
	},

  popper: { zIndex: "9999", },

  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  }
}));

const SplitButton = ({ ticket }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const isMounted = useRef(true);  
  const anchorRef = useRef(null);
  const { user } = useContext(AuthContext);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editClosingTicketModalOpen, setEditClosingTicketModalOpen] = useState(false);
  
  const [whatsappQueues, setWhatsappQueues] = useState(0);
	const { findAll: findAllWhatsappQueues } = useWhatsappQueues(ticket.whatsappId);

  const [allowResolveTicketWithoutGoodBye, setAllowResolveTicketWithoutGoodBye] = useState("enabled");
  const [requireTagOnContactWhenClosingTicket, setRequireTagOnContactWhenClosingTicket] = useState("disabled");



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const apiCalls = [
          api.get("/settings/allowResolveTicketWithoutGoodBye"),
          api.get("/settings/requireTagOnContactWhenClosingTicket")
        ];

        const [
          AllowResolveTicketWithoutGoodByeResponse,
          RequireTagOnContactWhenClosingTicketResponse
        ] = await Promise.all(apiCalls);

        setAllowResolveTicketWithoutGoodBye(AllowResolveTicketWithoutGoodByeResponse.data.value); // getting "allowResolveTicketWithoutGoodBye" setting
        setRequireTagOnContactWhenClosingTicket(RequireTagOnContactWhenClosingTicketResponse.data.value); // getting "requireTagOnContactWhenClosingTicket" setting
      } catch (error) {
        console.log("Split Button Use Effect Error:", error);
        toastError(error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
		const loadWhatsappQueues = async() => {
			const list = await findAllWhatsappQueues();
			setWhatsappQueues(list);
		}

		loadWhatsappQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => { setOpen((prevOpen) => !prevOpen); };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  const handleOpenEditTicketModal = event => { setEditClosingTicketModalOpen(true); }

  const handleCloseEditClosingTicketModal = event => {
		if (isMounted.current) setEditClosingTicketModalOpen(false);
	};

  const handleUpdateTicketStatus = async (e, status, userId, selectedIndex) => { 
    // ***---- WhatsApp Queues not Loaded ----***
    //
    // - if whatsappQueues is not loaded yet, a toast message is shown to the user
    // informing they to wait 10 seconds before closing a ticket
    //
    if (whatsappQueues === 0) {
      toast.info(i18n.t("messagesList.toast.closeTicket.whatsappQueuesNotLoaded"));
      return; 
    }


    // ***---- Variables ----***
    let subqueuesCount = 0;
    const fxSistemasTenantId = (user.tenantId.toString() === "1");
		const ticketType = (ticket.type.toString() === "0");
    const isBotChat = (ticket.type.toString() === "2");

    // ***---- Queued Id Assigned ----***
    //
    // - if queue id is already assigned to the ticket, this one is closed
    //
    if (ticket.queueId !== null) {
      subqueuesCount = await api.get(`/subqueueCount/${ticket.queueId}`);
      subqueuesCount = subqueuesCount.data;
    }

    // ***---- Queue Id Required ----***
    //
    // - if queue id is not assigned to the ticket and connection contains related queues
    // - if subqueue id is not assigned and there are subqueues related to the assigned queue id
    // - if tenant is 1 (FX Systems) and ticket type is not 2 (Little B Chat)
    // - if contact does not have serial number and resale serial number
    // Result: a modal is open requesting the required info
    //
    if (!isBotChat && ((!ticket.queueId && whatsappQueues.length > 0)
        || (!ticket.subqueueId && subqueuesCount > 0)
        || (ticket.type === 0 && requireTagOnContactWhenClosingTicket === "enabled" && ticket.contact.labels.length <= 0)
        || ((fxSistemasTenantId && ticketType)
        && ((!ticket.contact?.serialNumber)
        || (!ticket.contact?.resaleSerialNumber)))))
    {
      handleOpenEditTicketModal();
    }

    // *** ---- Queue Id Not Required ----***
    //
    // - ticket is closed
    //
    else {
      setLoading(true);

      try {
        if (selectedIndex === 0) {
          await api.put(`/tickets/${ticket.id}`, {
            status: status,
            userId: ticket.type === 3 ? ticket.userId : userId || null,
            queueId: ticket.queueId,
          });
        }
        else {
          if (ticket.type === 0) {
            try {
              await api.put(`/contacts/${ticket.contactId}`, { 
                name: ticket.contact.name,
                number: ticket.contact.number,
                isBot: true
              });
            } catch (error) {
              setLoading(false);
              console.log("Handle Update Ticket Status Error:", error);
              toastError(error);
            }
          }
          
          await api.put(`/tickets/${ticket.id}`, {
            status: status,
            userId: ticket.type === 3 ? ticket.userId : userId || null,
            queueId: ticket.queueId,
          });

          if (ticket.type === 0) {
            try {
              await api.put(`/contacts/${ticket.contactId}`, { 
                name: ticket.contact.name,
                number: ticket.contact.number,
                isBot: false
              });
            } catch (error) {
              setLoading(false);
              console.log("Handle Update Ticket Status Error:", error);
              toastError(error);
            }
          }
          
        }

        setLoading(false);
        if (status === "open") { history.push(`/tickets/${ticket.id}`); } 
        else { history.push("/tickets"); }
      } catch (error) {
        setLoading(false);
        console.log("Handle Update Ticket Status Error:", error);
        toastError(error);
      }
    }
	};



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      {ticket.type === 0 && allowResolveTicketWithoutGoodBye === "enabled" && (
        <>
        <ButtonGroup 
          size="small"
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
          className={classes.floatingButton}
        >
          <Button 
            className={classes.mainButton} 
            onClick={e => handleUpdateTicketStatus(e, "closed", user?.id, selectedIndex)}>
              {selectedIndex === 0 && (
                i18n.t("messagesList.header.buttons.resolve")
              )}
              {selectedIndex === 1 && (
                i18n.t("messagesList.header.buttons.resolveWithoutFarewell")
              )}
          </Button>
          <Button
            className={classes.mainButton}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Popper
          className={classes.popper}
          sx={{ zIndex: 1, }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal={isSmallScreen ? false : true}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                      key={i18n.t("messagesList.header.buttons.resolve")}
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      {i18n.t("messagesList.header.buttons.resolve")}
                    </MenuItem>
                    <MenuItem
                      key={i18n.t("messagesList.header.buttons.resolveWithoutFarewell")}
                      onClick={(event) => handleMenuItemClick(event, 1)}
                    >
                      {i18n.t("messagesList.header.buttons.resolveWithoutFarewell")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </>
      )}

      {(ticket.type !== 0 || allowResolveTicketWithoutGoodBye === "disabled") && (
        <Button 
          className={`${classes.mainButton} ${classes.floatingButton}`} 
          onClick={e => handleUpdateTicketStatus(e, "closed", user?.id, selectedIndex)}
        >
          {i18n.t("messagesList.header.buttons.resolve")}
        </Button>
      )}

      <EditClosingTicketModal
				modalOpen={editClosingTicketModalOpen}
				onClose={handleCloseEditClosingTicketModal}
				ticket={ticket}
        selectedIndex={selectedIndex}
        requireTagOnContactWhenClosingTicket={requireTagOnContactWhenClosingTicket}
			/>
    </>
  );
}

export default SplitButton;