const rules = {
	user: {
		static: [],
	},

	admin: {
		static: [
			"drawer-admin-items:view",
			"tickets-manager:showall",
			"user-modal:editProfile",
			"user-modal:editQueues",
			"user-modal:editWhatsapps",
			"user-modal:forceAcceptLongAwaitingTimeTickets",
			"user-modal:configEnabled",
			"ticket-options:deleteTicket",
			"ticket-options:transferWhatsapp",
			"contacts-page:deleteContact",
			"contacts-page:blockContact",
			"contacts-page:unblockContact",
			"ticket-options:searchActiveTickets"			
		],
	},
};

export default rules;
