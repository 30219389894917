import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Button,
	Drawer,
	IconButton,
	InputLabel,
	Link,
	Modal,
	Paper,
	Tooltip,
	Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';

import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import Viewer from 'react-viewer';

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import BezinhoImg from '../../assets/bezinho.svg';
import ContactModal from "../ContactModal";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import GroupParticipantsModal from "../GroupParticipantsModal";
import InternalChatImg from "../../assets/internalChat.png";
import MarkdownWrapper from "../MarkdownWrapper";
import MediasGalleryModal from "../MediasGalleryModal";
import truncateString from '../../utils/truncateString';
import WhatsappOfficialImg from "../../assets/whatsappOfficial.jpg";
const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	closeIcon: { color: theme.palette.text.primary, },

	accordionSummary: { "& .MuiAccordionSummary-expandIcon": { color: theme.palette.text.primary }, },

	accordionSummaryMainContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "center",
		gap: "5px",
		cursor: "default",
	},

	showMediasButton: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "center",
		gap: "0px",
		cursor: "pointer",
	},

	drawer: { width: drawerWidth, flexShrink: 0, },

	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},

	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: theme.palette.background.default,
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},

	content: {
		display: "flex",
		backgroundColor: theme.palette.background.paper,
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactNumber: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		flexWrap: "wrap",
		gap: "3px",
	},

	contactAvatar: { margin: 15, width: 160, height: 160, cursor: "pointer", },

	contactAvatarModal: {
		margin: 15,
		cursor: "pointer",
		width: 800,
		height: 800,
		[theme.breakpoints.down("sm")]: { width: 350, height: 350, },
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": { margin: 4, },
	},

	contactDetails: { marginTop: 8, padding: 8, display: "flex", flexDirection: "column", },

	contactExtraInfo: { marginTop: 4, padding: 6, },

	floatingButton: {
		transition: 'all 0.3s ease',
		'&:hover': { transform: 'translateY(-5px)', color: theme.palette.primary.main, },
	},

	imgModal: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backdropFilter: 'blur(5px)',
		animation: '$fadeIn 0.7s ease',
	},

	'@keyframes fadeIn': {
    from: { opacity: 0, transform: 'translateY(-50%)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  },

	imgContainer: { borderRadius: "50%", width: "250px", },

	label: {
		margin: 2,
		border: `2px solid ${theme.palette.primary.main}`,
		color: theme.palette.text.primary,
		padding: "7px",
		borderRadius: "5px"
	},

	lastThreeImagesContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		jsutifyContent: "space-around",
		gap: "1em",
	},

	lastImage: {
		borderRadius: "5px",
		width: "75px",
		height: "75px",
		cursor: "pointer",
		transition: "all 0.3s ease",
		userDrag: "none",

		"&:hover": { opacity: "0.9", transform: "translateY(-5px)", },
	},

	customBadge: {
		border: `1px solid ${theme.palette.primary.main}`,
		color: theme.palette.text.primary,
	
		padding: "3px",
		paddingLeft: "10px",
		paddingRight: "10px",
		borderRadius: "15px",
		fontSize: "10px",
		width: "fit-content",
		display: "flex",
		gap: "3px",
		alignItems: "center",
	},
}));

const ContactDrawer = ({ open, handleDrawerClose, contact, ticket, loading }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const { width, height } = useWindowSize();

	const [groupParticipantsModalOpen, setGroupParticpantsModalOpen] = useState(false);
	const [selectedGroupTicket, setSelectedGroupTicket] = useState(null);

	const [connectionAccordionOpen, setConnectionAccordionOpen] = useState(true);
	const [labelsAccordionOpen, setLabelsAccordionOpen] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [openModalImg, setOpenModalImg] = useState(false);

	const [mediasAccordionOpen, setMediasAccordionOpen] = useState(true);
	const [mediasCount, setMediasCount] = useState(0);
	const [lastThreeImages, setLastThreeImages] = useState([]);
	const [mediasGalleryModalOpen, setMediasGalleryModalOpen] = useState(false);

	const [viewerVisible, setViewerVisible] = useState(false);
	const [viewerImageUrl, setViewerImageUrl] = useState("");

	const [isContactNumberCopied, setIsContactNumberCopied] = useState(false);
	
	let contactType;
	if (contact?.isGroup) contactType = 1;
	else if (contact?.isChannel) contactType = 2;
	else contactType = 0;



	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		if (open && ticket.type === 0) {
			const delayDebounceFN = setTimeout(() => {
				const fetchLastThreeImages = async () => {
					const { data: lastThreeImages } = await api.get(`/lastThreeImages/${ticket.contactId}`);
					const { data: mediasCount } = await api.get(`/mediasCount/${ticket.contactId}`);

					setLastThreeImages(lastThreeImages);
					setMediasCount(mediasCount);
				};
				fetchLastThreeImages();
			}, 500);
			return () => clearTimeout(delayDebounceFN);
		}
	}, [open, ticket]);



	// 	***************
	// 	** Functions **
	// 	***************
  const handleAvatarClick = () => {
    setOpenModalImg(true);
  };

  const handleClose = () => {
    setOpenModalImg(false);
		setViewerVisible(false);
		setViewerImageUrl("");
  };

	const handleOpenGroupParticipantsModal = (groupTicket) => {
		setSelectedGroupTicket(groupTicket);
		setGroupParticpantsModalOpen(true);
	};

	const handleCloseGroupParticipantsModal = () => {
		setGroupParticpantsModalOpen(false);
		setSelectedGroupTicket(null);
	};
		
	const handleCopyContactNumber = async (contactNumber) => {
		setIsContactNumberCopied(true);

		let formattedNumber;
		if (contactNumber.startsWith("55")) { contactNumber = contactNumber.substring(2); }
		if (contactNumber.length === 10) { formattedNumber = [contactNumber.substring(0, 2), "9", contactNumber.substring(2)].join(""); }
		else { formattedNumber = contactNumber; }

		await navigator.clipboard.writeText(formattedNumber);
		setTimeout(() => { setIsContactNumberCopied(false); }, 500);
	};

	const handleViewerImage = (imageUrl) => {
		setViewerVisible(true);
		setViewerImageUrl(imageUrl);
	};

	const resetViewerImage = () => {
		setViewerVisible(false);
		setViewerImageUrl("");
	};

	const renderLastThreeImages = () => {
		return (
			lastThreeImages.map(image => (
				image.mediaUrl && image.mediaUrl !== undefined && image.mediaUrl !== "" && (
					<Tooltip title={image.mediaUrl.split("/")[image.mediaUrl.split("/").length - 1]} placement="top-start" arrow>
						<img onClick={() => { handleViewerImage(image.mediaUrl); }} className={classes.lastImage} src={image.mediaUrl} alt="" />
					</Tooltip>
				)
			))
		)
	};

	const handleOpenMediasGalleryModal = () => {
		setMediasGalleryModalOpen(true);
	};
	
	const handleCloseMediasGalleryModal = () => {
		setMediasGalleryModalOpen(false);
	};



	// 	************
	// 	** Return **
	// 	************
	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{ container: document.getElementById("drawer-container"), style: { position: "absolute" }, }}
			classes={{ paper: classes.drawerPaper, }}
		>

			{ticket.status === "group" && (
				<GroupParticipantsModal
					open={groupParticipantsModalOpen}
					onClose={handleCloseGroupParticipantsModal}
					ticketId={selectedGroupTicket}
				/>
			)}

			<ContactModal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				contactId={contact?.id}
				contactType={contactType}
			/>

			<Viewer
				downloadable={true}
				downloadInNewWindow={true}
				images={[{ src: viewerImageUrl, downloadUrl: viewerImageUrl }]}
				onClose={resetViewerImage}
				onMaskClick={resetViewerImage}
				visible={viewerVisible}
				zIndex={10000}
			/>

			<MediasGalleryModal
				open={mediasGalleryModalOpen}
				onClose={handleCloseMediasGalleryModal}
				contactId={ticket.contactId}
			/>

			<div className={classes.header}>
				<IconButton className={classes.closeIcon} onClick={handleDrawerClose}><CloseIcon /></IconButton>
				<Typography style={{ justifySelf: "center" }}>{i18n.t("contactDrawer.header")}</Typography>
			</div>

			{loading 
				? (<ContactDrawerSkeleton classes={classes} />)
				: (
					<div className={classes.content}>
						<Paper square variant="outlined" className={classes.contactHeader}>
							{(ticket.type === 0 || ticket.type === 1) && (
								<>
								<Avatar
									alt={contact?.name}
									src={contact?.name === "WhatsApp Official ✔️" ? WhatsappOfficialImg : contact?.profilePicUrl}
									className={classes.contactAvatar}
									onClick={handleAvatarClick}
								/>
						
								<Modal className={classes.imgModal} open={openModalImg} onClose={handleClose}>
									<>
										<Avatar
											alt={contact?.name}
											src={contact?.name === "WhatsApp Official ✔️" ? WhatsappOfficialImg : contact?.profilePicUrl}
											className={classes.contactAvatarModal}
											onClick={handleClose}
										/>

										{/*
											****************
											** Easter Egg **
											****************
										*/}
										<Confetti width={width} height={height} />	
									</>
								</Modal>
								</>
							)}

							{(ticket.type === 2 || ticket.type === 3) && (
								<>
								<Avatar
									alt={contact?.name}
									src={ticket.type === 2 ? BezinhoImg : InternalChatImg}
									className={classes.contactAvatar}
									onClick={handleAvatarClick}
								/>
						
								<Modal className={classes.imgModal} open={openModalImg} onClose={handleClose}>
									<>
										<Avatar
											alt={contact?.name}
											src={ticket.type === 2 ? BezinhoImg : InternalChatImg}
											className={classes.contactAvatarModal}
											onClick={handleClose}
										/>

										{/*
											****************
											** Easter Egg **
											****************
										*/}
										<Confetti width={width} height={height} />	
									</>
								</Modal>
								</>
							)}
	
							{ticket.type === 0 && contact.name !== "WhatsApp Official ✔️" && (
								<>
									<Typography>{truncateString(contact.name, 27)}</Typography>
									<Typography className={classes.contactNumber}>
										<Link href={`tel:${contact.number}`}>{contact.number}</Link>

										<Tooltip
											title={ isContactNumberCopied ? i18n.t("contactDrawer.tooltips.copied") : i18n.t("contactDrawer.tooltips.copy") }
											placement="top-start"
											arrow
										>
											<IconButton
												color="inherit"
												className={classes.floatingButton}
												onClick={() => { handleCopyContactNumber(contact.number); }}
											>
												<FileCopyOutlinedIcon fontSize="small" />
											</IconButton>
										</Tooltip>
									</Typography>

									{ticket.status !== "channel" && (
										<Button
											variant="outlined"
											color="inherit"
											className={classes.floatingButton}
											onClick={() => setModalOpen(true)}
										>
											{ticket.status === "group"
												? i18n.t("contactDrawer.buttons.editGroup")
												: i18n.t("contactDrawer.buttons.edit")}
										</Button>
									)}

									{ticket.status === "group" && (
										<Button
											variant="outlined"
											color="inherit"
											className={classes.floatingButton}
											onClick={() => handleOpenGroupParticipantsModal(ticket.id)}
										>
											{i18n.t("contactDrawer.buttons.participants")}
										</Button>
									)}
								</>
							)}

							{ticket.type === 0 && contact.name === "WhatsApp Official ✔️" && (
								<Typography>{truncateString(contact.name, 27)}</Typography>
							)}

							{(ticket.type === 1 || ticket.type === 2) && (
								<Typography>{truncateString(ticket.noteName, 27)}</Typography>
							)}

							{ticket.type === 3 && (
								<>
								<Typography>{truncateString(ticket.noteName, 27)}</Typography>
								<span className={classes.customBadge}>{ticket.user?.name} || {ticket.userInternalChat?.name}</span>
								</>
							)}
						</Paper>

						{ticket.type === 0 && (
							<>
							
							{!ticket.isGroup && !ticket.isChannel && ticket.type === 0 && (
								<Accordion 
									expanded={mediasAccordionOpen} 
								>
									<AccordionSummary
										id="medias-accordion-header"
										aria-controls="medias-accordion-header"
										expandIcon={<ExpandMoreIcon />}
										TransitionProps={{ unmountOnExit: true }}
										className={classes.accordionSummary}
										onClick={() => setMediasAccordionOpen((prevValue) => !prevValue )}
									>
										<div className={classes.accordionSummaryMainContainer}>
											<Typography className={classes.accordionHeading}>
												{i18n.t("contacts.accordion.title3")}
											</Typography>

											<Tooltip title={i18n.t("contacts.accordion.showAllMedias")} placement="top-start" arrow>
												<div
													className={`${classes.floatingButton} ${classes.showMediasButton}`} 
													onClick={handleOpenMediasGalleryModal}
												>
													<span>{mediasCount}</span>
													<KeyboardArrowRightOutlinedIcon color="inherit" />
												</div>
											</Tooltip>
										</div>
									</AccordionSummary>

									{lastThreeImages.length > 0 && (
										<AccordionDetails className={classes.contactLabelsField}>
											<div className={classes.lastThreeImagesContainer}>{renderLastThreeImages()}</div>
										</AccordionDetails>
									)}
								</Accordion>
							)}

							<Accordion className={classes.accordionSummary} expanded={connectionAccordionOpen}>
								<AccordionSummary
									id="connection-accordion-header"
									aria-controls="connections-accordion-header"
									expandIcon={<ExpandMoreIcon />}
									TransitionProps={{ unmountOnExit: true }}
									onClick={() => setConnectionAccordionOpen((prevValue) => !prevValue )}
								>
									<Typography className={classes.accordionHeading}>
										{i18n.t("contacts.accordion.title2")}
									</Typography>
								</AccordionSummary>
								<AccordionDetails className={classes.contactLabelsField}>
									<span className={classes.label}>{contact.whatsapp ? contact.whatsapp.name : "-"}</span>
								</AccordionDetails>
							</Accordion>

							{!ticket.isGroup && !ticket.isChannel && (
								<Accordion className={classes.accordionSummary} expanded={labelsAccordionOpen}>
									<AccordionSummary
										id="labels-accordion-header"
										aria-controls="labels-accordion-header"
										expandIcon={<ExpandMoreIcon />}
										TransitionProps={{ unmountOnExit: true }}
										onClick={() => setLabelsAccordionOpen((prevValue) => !prevValue )}
									>
										<Typography className={classes.accordionHeading}>
											{i18n.t("contacts.accordion.title")}
										</Typography>
									</AccordionSummary>

									<AccordionDetails className={classes.contactLabelsField}>
										{/* Contact has labels */}
										{contact.labels && contact.labels.map((label) => (
											<span style={{ border: `2px solid ${label.color}`, padding: "7px", borderRadius: "20px" }}>
												{label.name}
											</span>
										))}

										{/* Contact does not have labels */}
										{(
											(contact.labels && contact.labels.length === 0)
											|| (contact.labels === null || contact.labels === undefined)
										) && (
											<Typography>
												{i18n.t("contacts.accordion.noLabels")}
											</Typography>)
										}
									</AccordionDetails>
								</Accordion>
							)}
							
							<Paper square variant="outlined" className={classes.contactDetails}>
								<Typography variant="subtitle1">
									{i18n.t("contactDrawer.extraInfo")}
								</Typography>

								{contact?.extraInfo?.map(info => (
									<Paper
										key={info.id}
										square
										variant="outlined"
										className={classes.contactExtraInfo}
									>
										<InputLabel>{info.name}</InputLabel>
										<Typography component="div" noWrap style={{ paddingTop: 2 }}>
											<MarkdownWrapper>{info.value}</MarkdownWrapper>
										</Typography>
									</Paper>
								))}
							</Paper>
							</>
						)}
					</div>
			)}
		</Drawer>
	);
};

export default ContactDrawer;
