import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import CurrentLocationImg from "../../assets/currentLocation.svg";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "2em",
  },  

  currentLocationImg: {
    width: "100%",
  },

  buttonsMainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  buttonsPrimaryContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "2em",
    paddingBottom: "1em",
  },

  floatingButton: {
    transition: "transform 0.3s",
    "&:hover": { transform: "translateY(-5px)", },
  },
}));

const LocationModal = ({ open, onClose, ticketId }) => {
  //  ***********
  //  ** Datas **
  //  ***********
  const classes = useStyles();



  //  ***************
  //  ** Callbacks **
  //  ***************
  const successLocationCallback = async (position) => {
    await api.post(`/messagesCurrentLocation/${ticketId}`, {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    });
    onClose();
  }
  
  const errorLocationCallback = (error) => {
    //  Error Codes:
    //
    //   - 1: (PERMISSION_DENIED) - The acquisition of the geolocation information failed because the page didn't
    // have the permission to do it.
    //   - 2: (POSITION_UNAVAILABLE) - The acquisition of the geolocation failed because one or several internal
    // sources of position returned an internal error.
    //   - 3: (TIMEOUT) - Geolocation information was not obtained in the allowed time.
    //
    if (error.code === 1) {
      toast.error(i18n.t("location.errors.permissionNotGranted"));
    } else if (error.code === 2) {
      toast.error(i18n.t("location.errors.positionUnavaible"));
    } else {
      toast.error(i18n.t("location.errors.timeout"));
    }
  };


  

  //  ***************
  //  ** Functions **
  //  ***************
  const handleSendCompanyLocation = async () => {
    try {
      await api.post(`/messagesCompanyLocation/${ticketId}`);
      onClose();
    } catch(error) {
      console.log("Handle Send Company Location Error:", error);
      toastError(error);
    }
  };

  const handleSendCurrentLocation = async () => {
    try {
      navigator.geolocation.getCurrentPosition(successLocationCallback, errorLocationCallback);
    } catch(error) {
      console.log("Handle Send Current Location Error:", error);
      toastError(error);
    }
  };


  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          <span>{i18n.t("locationModal.title")}</span>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <img className={classes.currentLocationImg} src={CurrentLocationImg} alt="" />
          
          <div className={classes.buttonsMainContainer}>
            <div className={classes.buttonsPrimaryContainer}>
              <Button
                className={classes.floatingButton}
                color="inherit"
                variant="outlined"
                onClick={handleSendCurrentLocation}
              >{i18n.t("locationModal.buttons.currentLocation")}</Button>

              <Button
                className={classes.floatingButton}
                color="inherit"
                variant="outlined"
                onClick={handleSendCompanyLocation}
              >{i18n.t("locationModal.buttons.companyLocation")}</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LocationModal;