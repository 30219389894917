import { useEffect, useState } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useCountTickets = ({
  status,
  showAll,
  queueIds,
  considerInternalChats,
  userId,
  userProfile
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const countTickets = async() => {
        try {
          const { data } = await api.get("/countTickets", {
            params: {
              status,
              showAll,
              queueIds,
              considerInternalChats,
              userId,
              userProfile
            },
          });

          setCount(data.count);
        } catch (error) {
          console.log("Use Count Tickets Error:", error);
          toastError(error);
        }
      };

      countTickets();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [status, showAll, queueIds, considerInternalChats, userId, userProfile]);

  return { count };
};

export default useCountTickets;