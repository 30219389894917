import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, styled } from "@material-ui/core/styles";
import { Badge, Collapse, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Switch, } from "@material-ui/core";

import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import AccountTreeOutlined from '@material-ui/icons/AccountTreeOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import CallMergeOutlined from '@material-ui/icons/CallMergeOutlined';
import CardGiftcardOutlined from '@material-ui/icons/CardGiftcardOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import ChatBubbleOutline from '@material-ui/icons/ChatBubbleOutline';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import DynamicFeedOutlined from '@material-ui/icons/DynamicFeedOutlined';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import EmojiPeopleOutlined from '@material-ui/icons/EmojiPeopleOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import LabelOutlined from "@material-ui/icons/LabelOutlined";
import LoyaltyOutlined from '@material-ui/icons/LoyaltyOutlined';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import PhonelinkRingOutlinedIcon from '@material-ui/icons/PhonelinkRingOutlined';
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import StarOutlineOutlinedIcon from '@material-ui/icons/StarOutlineOutlined';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import ReleasesModal from "../components/ReleasesModal";

const useStyles = makeStyles(theme => ({
	icon: { color: theme.palette.text.primary },
  roundButton: { borderRadius:"20px", },
  subMenuButton: { borderRadius:"20px", width: "100%", },
  subMenu: { borderRadius:"20px", display: "flex", alignItems: "center", minHeight: "48px", },
  subMenuArrow: { color: theme.palette.text.primary, },
  ticketButton: { borderRadius:"20px", padding: theme.spacing(1, 0, 1, 3), },
  themeSwitch: { margin: theme.spacing(1, 0, 0, 0), },
  themeLabel: { userSelect: "none", cursor: "pointer", margin: theme.spacing(0, 0, 0, 2), fontSize: "16px", },
  spanContent: { fontSize: "16px", margin: theme.spacing(0, 0, 0, 4), },
  listItemContainer: { display: "flex", alignItems: "center", },
  collapseListOptions: { paddingLeft: "25px", },
  badge: { borderRadius:"20px", right: "-2px", }
}));

const MainListItems = ({ closeDrawer, isSmallScreen }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { handleLogout } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const { whatsApps } = useContext(WhatsAppsContext);
  
  const [checked, setChecked] = useState(localStorage.getItem("theme") === "light" ? false : true);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openContactsMenu, setOpenContactsMenu] = useState(false);
  const [openDashboardMenu, setOpenDashboardMenu] = useState(false);
  const [openMessageMenu, setOpenMessageMenu] = useState(false);
  const [openReleasesModal, setOpenReleasesModal] = useState(false);
  const [openTicketsMenu, setOpenTicketsMenu] = useState(false);
  const [openTriagemMenu, setOpenTriagemMenu] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);



  //  ***************
  //  ** Functions **
  //  ***************
  function reqListener () {
    if (localStorage.getItem("latestNews") !== this.responseText) {
      localStorage.setItem("latestNews", this.responseText);
      if (this.responseText !== "") { setOpenReleasesModal(true); }
    }
  }

  function handleCheckLatestNews() {
    const xhr = new XMLHttpRequest();
    xhr.onload = reqListener;
    xhr.open('GET', "https://service.fxsistemas.com.br/fx-news/noticiaAPI.php?numeroSerie=19&sistema=7&visualizacaoUnica=1", true);
    xhr.send(null);
  }  

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);

    // - Close drawer when device is mobile
    if (isSmallScreen) closeDrawer();

    // Chats
    if (index === 0) { history.push("/tickets?tab=open"); }
    else if (index === 1) { history.push("/tickets?tab=closed"); }

    // Contacts
    else if (index === 2) { history.push("/contacts"); }
    else if (index === 3) { history.push("/labels"); }

    // Dashboards
    else if (index === 4) { history.push("/dashboard"); }
    else if (index === 5) { history.push("/attendantsDashboard"); }
    else if (index === 6) { history.push("/ticketsDashboard"); }
    else if (index === 7) { history.push("/ratingsDashboard"); }
    else if (index === 8) { history.push("/groupsDashboard"); }
    else if (index === 9) { history.push("/internalChatsDashboard"); }

    // Messages
    else if (index === 10) { history.push("/quickAnswers"); }
    else if (index === 11) { history.push("/starredMessages"); }

    // Users
    else if (index === 12) { history.push("/users"); }

    // Conections
    else if (index === 13) { history.push("/connections"); }

    // Business Hours
    else if (index === 14) { history.push("/businessHours"); }

    // Screening
    else if (index === 15) { history.push("/queues"); }
    else if (index === 16) { history.push("/categories"); }
    else if (index === 17) { history.push("/subqueues"); }
    else if (index === 18) { history.push("/flow"); }

    // My Profile
    else if (index === 19) { history.push("/myProfile"); }

    // Settings
    else if (index === 20) { history.push("/settings"); }
  };

  const handleClickTicketsMenu = () => { setOpenTicketsMenu((prevOpen) => !prevOpen); };
  const handleClickContactsMenu = () => { setOpenContactsMenu((prevOpen) => !prevOpen); };
  const handleClickDashboardMenu = () => { setOpenDashboardMenu((prevOpen) => !prevOpen); }
  const handleClickMessageMenu = () => { setOpenMessageMenu((prevOpen) => !prevOpen); };
  const handleClickTriagemMenu = () => { setOpenTriagemMenu((prevOpen) => !prevOpen); }

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 55,
    height: 30,
    padding: 7,
    left: 9,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: 'blue',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#f6f6ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : theme.palette.primary.main,
      width: 25,
      height: 25,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#1e2b31',
      borderRadius: 20 / 2,
    },
  }));

  const handleChange = () => {
    const containerToggle = document.getElementById("containerToggle");
    containerToggle.click();

    if(checked === false) {
      document.getElementById("root").style.background = "#2c2f33";
      document.body.style.background = "#2c2f33";
      localStorage.setItem("theme", "dark");
      setChecked(true);
    }
    else if (checked === true ){
      document.getElementById("root").style.background = "#eeeee4";
      document.body.style.background = "#eeeee4";
      localStorage.setItem("theme", "light");
      setChecked(false);
    } 
  };

  function preprendThemeToggle() {
    const themeToggle = document.getElementById(themeToggle);
    const containerToggle = document.getElementById(containerToggle);
    themeToggle.prepend(containerToggle);
  }

  const handleClickLogout = () => {
    handleLogout();

    // Reseting CurrentPlayingAudio LS
    localStorage.setItem("currentPlayingAudio", "");
    localStorage.setItem("currentPlayingAudioTime", "");
    localStorage.setItem("currentPlayingAudioTicketId", "");
    localStorage.setItem("currentPlayingAudioContactProfilePic", "");
    localStorage.setItem("currentPlayingAudioContactName", "");
  };

  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => { handleCheckLatestNews(); });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });

        if (offlineWhats.length > 0) { setConnectionWarning(true); }
        else { setConnectionWarning(false); }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
    <ReleasesModal open={openReleasesModal} onClose={() => setOpenReleasesModal(false)} />

    <div className={classes.roundButton} onLoad={ preprendThemeToggle }>

      {/* 
        ***---- Chats ----***
      */}
      <div onClick={handleClickTicketsMenu} className={classes.subMenu}>
        <ListItem
          button
          className={classes.roundButton}
          selected={[0, 1].includes(selectedIndex)}
        >
          <ListItemIcon className={classes.icon}>
            <WhatsAppIcon />
          </ListItemIcon>
          <ListItemText primary={i18n.t("mainDrawer.listItems.tickets")} />
        </ListItem>

        <IconButton>
          {openTicketsMenu ? <ExpandLess className={classes.subMenuArrow}/> : <ExpandMore className={classes.subMenuArrow}/>}
        </IconButton>
      </div>

      {/* 
        ***---- Chats Collapse ----***
      */}
      <Collapse component="li" in={openTicketsMenu} timeout="auto" unmountOnExit>
        <List disablePadding>

          {/* 
            ***---- Active Chats ----***
          */}
          <ListItem
            button
            className={`${classes.roundButton} ${classes.collapseListOptions}`}
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItemIcon className={classes.icon}>
              <ChatBubbleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("mainDrawer.listItems.ticketsOpen")} />
          </ListItem>

          {/* 
            ***---- Closed Chats ----***
          */}
          <ListItem
            button
            className={`${classes.roundButton} ${classes.collapseListOptions}`}
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemIcon className={classes.icon}>
              <CheckBoxOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("mainDrawer.listItems.ticketsClosed")} />
          </ListItem>
        </List>
      </Collapse>

      {/* 
        ***---- Contacts ----***
      */}
      <div onClick={handleClickContactsMenu} className={classes.subMenu}>
        <ListItem
          button
          className={classes.roundButton}
          selected={[2, 3].includes(selectedIndex)}
        >
          <ListItemIcon className={classes.icon}>
            <ContactPhoneOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={i18n.t("mainDrawer.listItems.contacts")} />
        </ListItem>

        <IconButton>
          {openContactsMenu ? <ExpandLess className={classes.subMenuArrow}/> : <ExpandMore className={classes.subMenuArrow}/>}
        </IconButton>
      </div>

      {/* 
        ***---- Contacts Collapse ----***
      */}
      <Collapse component="li" in={openContactsMenu} timeout="auto" unmountOnExit>
        <List disablePadding>

          {/* 
            ***---- Contacts ----***
          */}
          <ListItem
            button
            className={`${classes.roundButton} ${classes.collapseListOptions}`}
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
          >
            <ListItemIcon className={classes.icon}>
              <ContactsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("mainDrawer.listItems.contacts")} />
          </ListItem>

          {/* 
            ***---- Labels ----***
          */}
          <ListItem
            button
            className={`${classes.roundButton} ${classes.collapseListOptions}`}
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
          >
            <ListItemIcon className={classes.icon}>
              <LoyaltyOutlined />
            </ListItemIcon>
            <ListItemText primary={i18n.t("mainDrawer.listItems.labels")} />
          </ListItem>
        </List>
      </Collapse>
    


      {/* 
        ***---- Dashboards ----***
      */}
      <div onClick={handleClickDashboardMenu} className={classes.subMenu}>
        <ListItem
          button
          className={classes.roundButton}
          selected={[4, 5, 6, 7, 8, 9].includes(selectedIndex)}
        >
          <ListItemIcon className={classes.icon}>
            <DashboardOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={i18n.t("mainDrawer.listItems.dashboard")} />
        </ListItem>
        <IconButton>
          {openDashboardMenu ? <ExpandLess className={classes.subMenuArrow}/> : <ExpandMore className={classes.subMenuArrow}/>}
        </IconButton>
      </div>

      {/* 
        ***---- Dashboards Collapse ----***
      */}
      <Collapse component="li" in={openDashboardMenu} timeout="auto" unmountOnExit>
        <List disablePadding>

          {/* 
            ***---- General Dashboard ----***
          */}
          <ListItem
            button
            className={`${classes.roundButton} ${classes.collapseListOptions}`}
            selected={selectedIndex === 4}
            onClick={(event) => handleListItemClick(event, 4)}
          >
            <ListItemIcon className={classes.icon}>
              <DynamicFeedOutlined />
            </ListItemIcon>
            <ListItemText primary={i18n.t("mainDrawer.listItems.general")} />
          </ListItem>

          {/* 
            ***---- Attendants Dashboard ----***
          */}
          <ListItem
            button
            className={`${classes.roundButton} ${classes.collapseListOptions}`}
            selected={selectedIndex === 5}
            onClick={(event) => handleListItemClick(event, 5)}
          >
            <ListItemIcon className={classes.icon}>
              <EmojiPeopleOutlined />
            </ListItemIcon>
            <ListItemText primary={i18n.t("mainDrawer.listItems.attendants")} />
          </ListItem>

          {/* 
            ***---- Chats Dashboard ----***
          */}
          <ListItem
            button
            className={`${classes.roundButton} ${classes.collapseListOptions}`}
            selected={selectedIndex === 6}
            onClick={(event) => handleListItemClick(event, 6)}
          >
            <ListItemIcon className={classes.icon}>
              <ChatBubbleOutline />
            </ListItemIcon>
            <ListItemText primary={i18n.t("mainDrawer.listItems.tickets")} />
          </ListItem>

          {/* 
            ***---- Ratings Dashboard ----***
          */}
          {user.profile === "admin" && (
            <ListItem
              button
              className={`${classes.roundButton} ${classes.collapseListOptions}`}
              selected={selectedIndex === 7}
              onClick={(event) => handleListItemClick(event, 7)}
            >
              <ListItemIcon className={classes.icon}>
                <EmojiEmotionsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("mainDrawer.listItems.ratings")} />
            </ListItem>
          )}

          {/* 
            ***---- Groups Dashboard ----***
          */}
          <ListItem
            button
            className={`${classes.roundButton} ${classes.collapseListOptions}`}
            selected={selectedIndex === 8}
            onClick={(event) => handleListItemClick(event, 8)}
          >
            <ListItemIcon className={classes.icon}>
              <PeopleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("mainDrawer.listItems.groupsDashboard")} />
          </ListItem>

          {/* 
            ***---- Internal Chats Dashboard ----***
          */}
          <ListItem
            button
            className={`${classes.roundButton} ${classes.collapseListOptions}`}
            selected={selectedIndex === 9}
            onClick={(event) => handleListItemClick(event, 9)}
          >
            <ListItemIcon className={classes.icon}>
              <RecentActorsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("mainDrawer.listItems.internalChats")} />
          </ListItem>
        </List>
      </Collapse>


      {/* 
        ***---- Messages ----***
      */}
      <div onClick={handleClickMessageMenu} className={classes.subMenu}>
        <ListItem
          button
          className={classes.roundButton}
          selected={[10, 11].includes(selectedIndex)}
        >
          <ListItemIcon className={classes.icon}>
            <MessageOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={i18n.t("mainDrawer.listItems.messages")} />
        </ListItem>
        <IconButton>
          {openMessageMenu ? <ExpandLess className={classes.subMenuArrow} /> : <ExpandMore className={classes.subMenuArrow} />}
        </IconButton>
      </div>

      {/* 
        ***---- Messages Collpase ----***
      */}
      <Collapse component="li" in={openMessageMenu} timeout="auto" unmountOnExit>
        <List disablePadding>
          {/*
            ***---- Quick Answers ----***
          */}
          <ListItem
            button
            className={`${classes.roundButton} ${classes.collapseListOptions}`}
            selected={selectedIndex === 10}
            onClick={(event) => handleListItemClick(event, 10)}
          >
            <ListItemIcon className={classes.icon}>
              <QuestionAnswerOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("mainDrawer.listItems.quickAnswers")} />
          </ListItem> 


          {/*
            ***---- Starred Messages ----***
          */}
          <ListItem
            button
            className={`${classes.roundButton} ${classes.collapseListOptions}`}
            selected={selectedIndex === 11}
            onClick={(event) => handleListItemClick(event, 11)}
          >
            <ListItemIcon className={classes.icon}>
              <StarOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("mainDrawer.listItems.starredMessages")} />
          </ListItem>
        </List>
      </Collapse>

      {/* 
        ***---- Users ----***
      */}
      <ListItem
        button
        className={classes.roundButton}
        selected={selectedIndex === 12}
        onClick={(event) => handleListItemClick(event, 12)}
      >
        <ListItemIcon className={classes.icon}>
          <PeopleAltOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={i18n.t("mainDrawer.listItems.users")} />
      </ListItem>

      {user.configEnabled && <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider className={classes.roundButton} />

            {/* 
              ***---- Connections ----***
            */}
            <ListItem
              button
              className={classes.roundButton}
              selected={selectedIndex === 13}
              onClick={(event) => handleListItemClick(event, 13)}
            >
              <ListItemIcon className={classes.icon}>
                <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                  <PhonelinkRingOutlinedIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={i18n.t("mainDrawer.listItems.connections")} />
            </ListItem>

            {/* 
              ***---- Business Hours ----***
            */}
            <ListItem
              button
              className={classes.roundButton}
              selected={selectedIndex === 14}
              onClick={(event) => handleListItemClick(event, 14)}
            >
              <ListItemIcon className={classes.icon}>
                <AccessTimeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("mainDrawer.listItems.businessHours")} />
            </ListItem>
            
            {/* 
              ***---- Screenings ----***
            */}
            <div onClick={handleClickTriagemMenu} className={classes.subMenu}>
              <ListItem
                button
                className={classes.roundButton}
                selected={[15, 16, 17, 18].includes(selectedIndex)}
              >
                <ListItemIcon className={classes.icon}>
                  <AccountTreeOutlined />
                </ListItemIcon>
                <ListItemText primary={i18n.t("mainDrawer.listItems.screening")} />
              </ListItem>

              <IconButton>
                {openTriagemMenu ? <ExpandLess className={classes.subMenuArrow}/> : <ExpandMore className={classes.subMenuArrow}/>}
              </IconButton>
            </div>

            {/* 
              ***---- Screening Collapse ***----
            */}
            <Collapse component="li" in={openTriagemMenu} timeout="auto" unmountOnExit>
              <List disablePadding>

                {/* 
                  ***---- Departments Screening ----***
                */}
                <ListItem
                  button
                  className={`${classes.roundButton} ${classes.collapseListOptions}`}
                  selected={selectedIndex === 15}
                  onClick={(event) => handleListItemClick(event, 15)}
                >
                  <ListItemIcon className={classes.icon}>
                    <BookmarksOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={i18n.t("mainDrawer.listItems.queues")} />
                </ListItem>
                
                {/* 
                  ***---- Categories Screenings ----***
                */}
                <ListItem
                  button
                  className={`${classes.roundButton} ${classes.collapseListOptions}`}
                  selected={selectedIndex === 16}
                  onClick={(event) => handleListItemClick(event, 16)}
                >
                  <ListItemIcon className={classes.icon}>
                    <LabelOutlined />
                  </ListItemIcon>
                  <ListItemText primary={i18n.t("mainDrawer.listItems.categories")} />
                </ListItem>

                {/* 
                  ***---- Sectors Screening ----***
                */}
                <ListItem
                  button
                  className={`${classes.roundButton} ${classes.collapseListOptions}`}
                  selected={selectedIndex === 17}
                  onClick={(event) => handleListItemClick(event, 17)}
                >
                  <ListItemIcon className={classes.icon}>
                    <BookOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={i18n.t("mainDrawer.listItems.subqueues")} />
                </ListItem>

                {/* 
                  ***---- Flow Screening ----***
                */}
                <ListItem
                  button
                  className={`${classes.roundButton} ${classes.collapseListOptions}`}
                  selected={selectedIndex === 18}
                  onClick={(event) => handleListItemClick(event, 18)}
                >
                  <ListItemIcon className={classes.icon}>
                    <CallMergeOutlined />
                  </ListItemIcon>
                  <ListItemText primary={i18n.t("mainDrawer.listItems.flow")} />
                </ListItem>
              </List>
            </Collapse>

            {/* 
              ***---- Company ----***
            */}
            <ListItem
              button
              className={classes.roundButton}
              selected={selectedIndex === 19}
              onClick={(event) => handleListItemClick(event, 19)}
            >
              <ListItemIcon className={classes.icon}>
                <BusinessOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("mainDrawer.listItems.profile")} />
            </ListItem>

            {/* 
              ***---- Settings ----***
            */}
            <ListItem
              button
              className={classes.roundButton}
              selected={selectedIndex === 20}
              onClick={(event) => handleListItemClick(event, 20)}
            >
              <ListItemIcon className={classes.icon}>
                <SettingsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("mainDrawer.listItems.settings")} />
            </ListItem>
          </>
        )}
      />}

      {/* 
        ***---- Releases ----***
      */}
      <ListItem
        className={classes.roundButton}
        onClick={() => setOpenReleasesModal(true)}
        style={{ cursor: "pointer" }}
        button
      >
        <div className={classes.listItemContainer}>
          <div><CardGiftcardOutlined /></div>
          <span className={classes.spanContent}>{i18n.t("mainDrawer.listItems.releases")}</span>
        </div>
      </ListItem>

      {/* 
        ***---- Logout ----***
      */}
      <ListItem
        button
        className={classes.roundButton}
        onClick={handleClickLogout}
      >
        <ListItemIcon className={classes.icon}>
          <KeyboardReturnIcon />
        </ListItemIcon>
        <ListItemText primary={i18n.t("mainDrawer.appBar.user.logout")} />
      </ListItem>


      {/* 
        ***---- Theme Toogle ----***
      */}
      <div id="themeToggle">
        <MaterialUISwitch
          className={classes.themeSwitch}
          checked={checked}
          onChange={handleChange}
        />
        <span onClick={handleChange} className={classes.themeLabel}>{i18n.t("mainDrawer.appBar.theme")}</span>
      </div>
    </div>
    </>
  );
};

export default MainListItems;
