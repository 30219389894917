import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import RenderErrorImg from '../assets/renderError.svg';
import { i18n } from "../translate/i18n";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, isHovering: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  handleRefresh = () => {
    // Reset the error boundary by updating its state
    this.setState({ hasError: false });
    window.location.replace(window.location.pathname);
  };

  render() {
    const emptyBoxContainerStyle = {
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "flex-direction": "column",
      "gap": "50px",
      "padding": "5px",
      "height": "100%",
    };

    const emptyBoxImageStyle = { "width": "250px", "height": "250px", };
    const emptyBoxSpanStyle = { "font-size": "20px", };

    const floatingButtonStyle = {
      "transition": "transform 0.3s",
      "transform": this.state.isHovering ? "translateY(-5px)" : "translateY(0px)",
    };

    const handleMouseEnter = () => { return this.setState({ isHovering: true }); };
    const handleMouseLeave = () => { return this.setState({ isHovering: false }); };

    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // Fallback UI when an error occurs
      return (
        <div style={emptyBoxContainerStyle}>
          <img style={emptyBoxImageStyle} src={RenderErrorImg} alt="Error Rendering" />
          <span style={emptyBoxSpanStyle}>{i18n.t("errorBoundary.message")}</span>

          <Button
            onClick={this.handleRefresh}
            style={floatingButtonStyle}
            color="inherit"
            variant="outlined"       
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}    
          >
            {i18n.t("errorBoundary.button")}
          </Button>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;