function compareOpenClosedRadiosTimeInMinutes(initialFinalHoursComparisons, openClosedRadioComparions) {
  /*
    Primeiro, a função coleta todos os indexes do array das horas de início e final
  que têm valor igual a 1, ou seja, onde a hora inicial é igual à hora final.

    Depois, e checado se a empresa está aberta ou fechada nestes dias em que
  o horário inicial é igual ao final, caso a empresa estiver aberta, é retornado
  'true', caso contrário, 'false'.
  */

  let flag = false;

  const initialFinalHoursIndexes = initialFinalHoursComparisons.reduce((acc, val, index) => {
    if (val === 1) { acc.push(index); }
    return acc;
  }, []);

  initialFinalHoursIndexes.forEach((index) => {
    if (openClosedRadioComparions[index]) { flag = true; }
  });

  return flag;
}

export default compareOpenClosedRadiosTimeInMinutes;