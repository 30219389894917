import { useEffect, useState } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useCountGroupsTickets = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const countGroupsTickets = async () => {
        try {
          const { data } = await api.get("/countGroupsTickets");
          setCount(data.count);
        } catch (exception) {
          console.log("Use Count Groups Tickets Exception:", exception);
          toastError(exception);
        }
      };

      countGroupsTickets();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  return { count };
};

export default useCountGroupsTickets;