import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Checkbox,
	Chip,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	chips: { display: "flex", flexWrap: "wrap" },
	
	chip: { 
		margin: 2,
		color: theme.palette.text.primary,
		border: "2px solid red",
	},

	chipCheckbox: {
		color: theme.palette.primary.main,
		'&.Mui-checked': { color: theme.palette.primary.main, }
	},

	selectInputField: {
		"& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary },
		width: "250px",
	},
}));

const ConnectionSelect = ({ selectedConnectionIds, onChange, connectionOptions, styleSelectInputField=true }) => {
	// 	***************
	// 	** Variables **
	// 	***************
  const classes = useStyles();
	const [connections, setConnections] = useState([]);



	// 	*****************
	// 	** Use Effects **
	// 	*****************
  useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/whatsapp");
				setConnections(data);
			} catch (error) {
				console.log("Connection Select Use Effect Error:", error);
				toastError(error);
			}
		})();
	}, []);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleChange = e => { onChange(e.target.value); };



	// 	************
	// 	** Return **
	// 	************
  return (
		<div style={{ marginTop: 6 }}>
			<FormControl 
				fullWidth
				size="small"
				margin="dense"
				variant="outlined"
				classes={styleSelectInputField ? { root: classes.selectInputField } : { }}
			>
				<InputLabel>{i18n.t("connectionSelect.inputLabel")}</InputLabel>
				<Select
					multiple
					label={i18n.t("connectionSelect.inputLabel")}
					value={selectedConnectionIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: { vertical: "bottom", horizontal: "left" },
						transformOrigin: { vertical: "top", horizontal: "left" },
						getContentAnchorEl: null,
					}}
					renderValue={selected => (
						<div className={classes.chips}>
							{selected?.length > 0 &&
								selected.map(id => {
									const connection = connections.find(c => c.id === id);
									return connection ? (
										<Chip
											key={id}
											variant="outlined"
											label={connection.name}
											className={classes.chip}
										/>
									) : null;
								})}
						</div>
					)}
				>
					{connectionOptions.map(connection => (
						<MenuItem key={connection.id} value={connection.id}>
							<Checkbox className={classes.chipCheckbox} checked={selectedConnectionIds.indexOf(connection.id) > -1} />
              <ListItemText primary={connection.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default ConnectionSelect;