import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useClosedTickets = ({
  initialDate,
  finalDate,
  considerInternalChats
}) => {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
      setLoading(true);
      const delayDebounceFn = setTimeout(() => {
          const fetchClosedTickets = async() => {
              try {
                  const { data } = await api.get("/closedTickets", {
                      params: {
                        initialDate,
                        finalDate,
                        considerInternalChats
                      },
                  })

                  setCount(data.count)
                  setLoading(false)
              } catch (error) {
                  setLoading(false)
                  console.log("Use Closed Tickets Error:", error);
                  toastError(error)
              }
          }
          fetchClosedTickets()
      }, 500)
      return () => clearTimeout(delayDebounceFn)
  }, [
      initialDate,
      finalDate,
  ])

  return { loading, count };
};

export default useClosedTickets;