import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { InfoOutlined, HelpOutlineOutlined } from "@material-ui/icons";
import {
	Button,
	ClickAwayListener,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Switch,	
	TextField,
	Tooltip,
	useMediaQuery,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import QueueSelectModals from "../QueueSelectModals";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	infoSection: { display: "flex", alignItems: "center", flexWrap: "wrap", gap: "5px", },
	root: { display: "flex", flexWrap: "wrap", },
	btnWrapper: { position: "relative", },

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	floatingButton: {
		transition: 'transform 0.3s',
		'&:hover': { transform: 'translateY(-5px)', },
	}
}));

const SessionSchema = Yup.object().shape({
	name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const initialState = {
		name: "",
		greetingMessage: "",
		awaitMessage: "",
		farewellMessage: "",
		absenceMessage: "",
		isDefault: false,
		isDispatch: false,
		sendRating: false,
		waitForBotInteraction: false
	};

	const [waitForBotInteractionDisabled, setWaitForBotInteractionDisabled] = useState(true);
	const [waitForBotInteractionTooltip, setWaitForBotInteractionTooltip] = useState(false);
	const [whatsApp, setWhatsApp] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);



	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsapp/${whatsAppId}`);
				setWhatsApp(data);

				const whatsQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(whatsQueueIds);

				setWaitForBotInteractionDisabled(whatsQueueIds.length === 0);
			} catch (error) {
				console.log("Whatsapp Modal Use Effect 1 Error:", error);
				toastError(error);
			}
		};
		fetchSession();
	}, [whatsAppId]);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleSaveWhatsApp = async values => {
		values.waitForBotInteraction = selectedQueueIds.length === 0
			? false
			: values.waitForBotInteraction;

		const whatsappData = { ...values, queueIds: selectedQueueIds };

		try {
			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
			} else {
				await api.post("/whatsapp", whatsappData);
			}

			toast.success(i18n.t("whatsappModal.success"));
			handleClose();
		} catch (error) {
			console.log("Handle Save WhatsApp Error:", error);
			toastError(error);
		}
	};

	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
		setSelectedQueueIds([]);
		setWaitForBotInteractionTooltip(false);
		setWaitForBotInteractionDisabled(true);
	};

	const handleOpenWaitForBotInteractionTooltip = () => { setWaitForBotInteractionTooltip(true); };
	const handleCloseWaitForBotInteractionTooltip = () => { setWaitForBotInteractionTooltip(false); };



	// 	************
	// 	** Return **
	// 	************
	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth scroll="paper">

				<DialogTitle>
					{whatsAppId ? i18n.t("whatsappModal.title.edit") : i18n.t("whatsappModal.title.add")}
				</DialogTitle>
				
				<Formik
					initialValues={whatsApp}
					enableReinitialize={true}
					validationSchema={SessionSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveWhatsApp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div>
									<FormControlLabel
										control={<Field as={Switch} color="primary" name="isDefault" checked={values.isDefault} disabled={values.isDefault} />}
										label={i18n.t("whatsappModal.form.default")}
									/>

									<FormControlLabel
										control={<Field as={Switch} color="primary" name="isDispatch" checked={values.isDispatch} />}
										label={i18n.t("whatsappModal.form.dispatch")}
									/>

									<FormControlLabel
										control={<Field as={Switch} color="primary" name="sendRating" checked={values.sendRating} />}
										label={i18n.t("whatsappModal.form.sendRating")}
									/>
								</div>

								<div>
									<FormControlLabel
										control={<Field as={Switch} color="primary" name="waitForBotInteraction" checked={values.waitForBotInteraction} />}
										disabled={waitForBotInteractionDisabled}
										label={
											<>
											{i18n.t("whatsappModal.form.waitForBotInteraction")}

											{!isSmallScreen && (
												<ClickAwayListener onClickAway={handleCloseWaitForBotInteractionTooltip}>
													<Tooltip
														arrow
														disableFocusListener
														disableTouchListener

														placement="top-start"
														PopperProps={{ disablePortal: true }}
														onClose={handleCloseWaitForBotInteractionTooltip}
														open={waitForBotInteractionTooltip}
														title={i18n.t("whatsappModal.tooltips.waitForBotInteraction")}
													>
														<sup onClick={handleOpenWaitForBotInteractionTooltip}>
															<IconButton color="inherit" className={classes.floatingButton}>
																<HelpOutlineOutlined style={{ fontSize: "0.75em" }} />
															</IconButton>
														</sup>
													</Tooltip>
												</ClickAwayListener>
											)}
											</>
										}
									/>
								</div>

								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
										className={classes.textField}
									/>
								</div>

								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										rows={5}
										fullWidth
										name="greetingMessage"
										error={touched.greetingMessage && Boolean(errors.greetingMessage)}
										helperText={touched.greetingMessage && errors.greetingMessage}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.awaitingMessage")}
										type="awaitMessage"
										multiline
										rows={5}
										fullWidth
										name="awaitMessage"
										error={touched.awaitMessage && Boolean(errors.awaitMessage)}
										helperText={touched.awaitMessage && errors.awaitMessage}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.farewellMessage")}
										type="farewellMessage"
										multiline
										rows={5}
										fullWidth
										name="farewellMessage"
										error={touched.farewellMessage && Boolean(errors.farewellMessage)}
										helperText={touched.farewellMessage && errors.farewellMessage}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<div>
									<Field
										id="absenceMessageField"
										as={TextField}
										label={i18n.t("whatsappModal.form.absenceMessage")}
										type="absenceMessage"
										multiline
										rows={5}
										fullWidth
										name="absenceMessage"
										error={touched.absenceMessage && Boolean(errors.absenceMessage)}
										helperText={touched.absenceMessage && errors.absenceMessage}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<QueueSelectModals
									selectedQueueIds={selectedQueueIds}
									onChange={selectedIds => {
										setSelectedQueueIds(selectedIds);
										setWaitForBotInteractionDisabled(selectedIds.length === 0);
									}}
								/>

								<div>
									<br />
									<span className={classes.infoSection}>
										<InfoOutlined /> {i18n.t("whatsappModal.info.message")}
									</span>
									<span className={classes.infoSection}>
										{i18n.t("whatsappModal.info.variablesText")}
										{i18n.t("whatsappModal.info.variablesList")}
									</span>
								</div>
							</DialogContent>

							<DialogActions>
								<Button
									onClick={handleClose}
									color="inherit"
									disabled={isSubmitting}
									variant="outlined"
									className={classes.floatingButton}
								>
									{i18n.t("whatsappModal.buttons.cancel")}
								</Button>

								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={`${classes.btnWrapper} ${classes.floatingButton}`}
								>
									{whatsAppId
										? i18n.t("whatsappModal.buttons.okEdit")
										: i18n.t("whatsappModal.buttons.okAdd")}

									{isSubmitting && (<CircularProgress size={24} className={classes.buttonProgress} />)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default React.memo(WhatsAppModal);
