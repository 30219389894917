import React, { useState, useEffect } from "react";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import OpenPrivateChatModal from "../OpenPrivateChatModal";
import toastError from "../../errors/toastError";


const useStyles = makeStyles(theme => ({
  // ***********
  // ** Label **
  // ***********
  label: {
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    color: "#f5f5ff",
  },
  
  // *************
  // ** Content **
  // *************
  dialogContent: { overflowY: "scroll", ...theme.scrollbarStyles, },

  participantCard: { borderRadius: "20px", padding: "10px", marginBottom: "5px", },

  participantCardActionArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "20px",
    padding: "5px",
  },

  participantName: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.5em",

    fontSize: "16px",
  },

  participantNumber: { fontSize: "13px", color: "hsl(217, 12%, 63%)", },

  actionButton: { "&:hover": { color: theme.palette.primary.main, }, },

  //  ***********************
  //  ** Circular Progress **
  //  ***********************
  circularProgressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const GroupParticipantsModal = ({ open, onClose, ticketId }) => {
  //  ***********
  //  ** Datas **
  //  ***********
  const classes = useStyles();

  const [openNewTicketModal, setOpenNewTicketModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState({ id: null, name: null });

  const [filteredParticipantsList, setFilteredParticipantsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [participantsList, setParticipantsList] = useState([]);
  const [searchParam, setSearchParam] = useState("");



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (open) {
      const delayDebounceFn = setTimeout(() => {
        const fetchParticipants = async () => {
          setLoading(true);
          try {
            const { data: participantsList } = await api.get(`/groupParticipants/${ticketId}`);
            
            participantsList.sort((participantA, participantB) => {
              if (participantA.isAdmin === participantB.isAdmin) return participantA.name.localeCompare(participantB.name);
              if (participantA.isAdmin && participantB.isAdmin) return participantA.name.localeCompare(participantB.name);
              return participantA.isAdmin ? -1 : 1;
            });

            setParticipantsList(participantsList);
            setFilteredParticipantsList(participantsList);
          } catch (error) {
            console.log("Group Participants Modal Error:", error);
            toastError(error);
          }
          finally {
            setLoading(false);
          }
        };
        fetchParticipants();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [open, ticketId]);




  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    setFilteredParticipantsList([]);
    setParticipantsList([]);
    setSearchParam("");

    setOpenNewTicketModal(false);
    setSelectedContact({ id: null, name: null });

    onClose();
  };

  const handleOpenNewTicketModal = (contactId, contactName) => {
    setSelectedContact({ id: contactId, name: contactName });
    setOpenNewTicketModal(true);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
    if (event.target.value.toLowerCase() === "") {
      setFilteredParticipantsList(participantsList);
    } else {
      setFilteredParticipantsList(participantsList.filter(participant => {
        if (participant.name && participant.name.toLowerCase().includes(event.target.value.toLowerCase())) { return true; }
        if (participant.number && participant.number.toLowerCase().includes(event.target.value.toLowerCase())) { return true; }
        return false;
      }));
    }
  };
 
  

  //  ************
  //  ** Return **
  //  ************
  return (
    <>
    <OpenPrivateChatModal
      onClose={setOpenNewTicketModal}
      onCloseAll={handleClose}
      open={openNewTicketModal}
      selectedContactId={selectedContact.id}
      selectedContactName={selectedContact.name}
    />

    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>
        <span>{participantsList.length} {i18n.t("groupParticipantsModal.title.up")}</span>

        <br /><br />

        <TextField
          placeholder={i18n.t("contacts.searchPlaceholder")}
          autoFocus
          type="search"
          fullWidth
          value={searchParam}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: "gray" }} />
              </InputAdornment>
            ),
          }}
        />
      </DialogTitle>

      <DialogContent className={classes.dialogContent} dividers>
        {!loading && open && participantsList.length > 0 && filteredParticipantsList.map((participant) => (
          <Card className={classes.participantCard} onClick={() => handleOpenNewTicketModal(participant.id, participant.name)}>
            <CardActionArea className={classes.participantCardActionArea}>
              <Avatar
                alt={participant?.name}
                src={participant?.profilePicUrl}
                className={classes.participantAvatar}
              />

              <CardContent>
                <Typography className={classes.participantName} gutterBottom component="h3">
                  {participant.isAdmin && (
                    <Tooltip title={i18n.t("users.tooltips.admin")} placement="top-start" arrow>
                      <VpnKeyIcon className={classes.actionButton} />
                    </Tooltip>
                  )}

                  {participant.name}
                </Typography>

                <Typography className={classes.participantNumber} gutterBottom component="h3">
                  {participant.number}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}

        {loading && (
          <div className={classes.circularProgressContainer}>
            <CircularProgress size={24} className={classes.buttonProgress} />
          </div>
        )}
      </DialogContent>
    </Dialog>
    </>
  );
};

export default GroupParticipantsModal;