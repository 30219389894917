import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardActionArea,
  Avatar,
  CardContent,
  TextField,
  InputAdornment,
  Typography
} from '@material-ui/core';

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) { state[contactIndex] = contact; }
      else { newContacts.push(contact); }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "RESET") { return []; }
};

const useStyles = makeStyles(theme => ({
  
  // ***********
  // ** Label **
  // ***********
  label: {
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    color: "#f5f5ff",
  },
  
  // *************
  // ** Content **
  // *************
  dialogContent: { overflowY: "scroll", ...theme.scrollbarStyles, },
  contactCard: { borderRadius: "20px", padding: "10px", marginBottom: "5px", },

  contactCardActionArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "20px",
    padding: "5px",
  },

  contactName: { fontSize: "16px", },
  contactNumber: { fontSize: "13px", color: "hsl(217, 12%, 63%)", },
}));

const ForwardMessageContactModal = ({ ticketId, open, onClose, selectedForwardMessages, updateIsForwarding, updateSelectedForwardMessages }) => {
  // ***---- Datas ----***
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [contacts, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [hasMore, setHasMore] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(false);



  // ***---- Use Effects ----***
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {

          // ***---- List Contacts and Groups ----***
          const { data: { value: allowGroupsSetting } } = await api.get("/settings/allowGroups");
          const typeOfContact = allowGroupsSetting === "enabled" ? "9" : "0";
          const { data } = await api.get("/contacts/", { params: { searchParam, pageNumber, typeOfContact }, });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (error) {
          console.log("Forward Message Contact Modal Use Effect Error:", error);
          toastError(error);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);


  

  // ***---- Functions ----***
  const handleClose = () => {
    onClose();
    setConfirmationOpen(false);
    if (contacts.length > 40) { dispatch({ type: "RESET" }); }
    setPageNumber(1);
    setSearchParam("");
  };

  const handleSearch = (event) => { setSearchParam(event.target.value.toLowerCase()); };
  const loadMore = () => { setPageNumber((prevState) => prevState + 1); }

  const handleScroll = (e) => {
    if (!hasMore || loading) { return; }
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) { loadMore(); }
  };

  const handleOpenConfirmationModal = (contactId) => {
    const contact = contacts.filter(contact => contact.id === contactId);
    setSelectedContact(contact[0]);
    setConfirmationOpen(true);
  };

  const handleForwardMessages = async () => {
    const { data } = await api.post("/messagesForward", {
      selectedForwardMessages,
      selectedContact,
      ticketId
    });

    updateIsForwarding(false);
    updateSelectedForwardMessages([]);
    setSearchParam("");
    onClose();

    history.push(`/tickets/${data.forwardedMessagesTicketId}`);
  };



  // ***---- Return ----***
  return (
    <>
      <ConfirmationModal
        title={i18n.t("forwardMessageContactModal.confirmationModal.title")}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleForwardMessages}
      >
        <span className={classes.label}>{i18n.t("forwardMessageContactModal.confirmationModal.name")} {selectedContact.name}</span>
        <br /><br />
        <span className={classes.label}>{i18n.t("forwardMessageContactModal.confirmationModal.number")} {selectedContact.number}</span>
      </ConfirmationModal>

    <div className={classes.root}>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          <span>{i18n.t("forwardMessageContactModal.title.up")}</span>

          <br /><br />

          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            autoFocus
            type="search"
            fullWidth
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
        </DialogTitle>

        <DialogContent className={classes.dialogContent} onScroll={handleScroll} dividers>
          <>
            {contacts.map((contact) => (
              <>
              <Card 
                className={classes.contactCard}
                onClick={() => handleOpenConfirmationModal(contact.id)}
              >
                <CardActionArea className={classes.contactCardActionArea}>
                  <Avatar alt={contact?.name} src={contact?.profilePicUrl} className={classes.contactAvatar} />
                  
                  <CardContent>
                    <Typography className={classes.contactName} gutterBottom component="h3">{contact.name}</Typography>
                    <Typography className={classes.contactNumber} gutterBottom component="h3">
                      {contact.isGroup ? i18n.t("forwardMessageContactModal.contactType.group") : contact.number}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              </>
            ))}
          </>
        </DialogContent>
      </Dialog>
    </div>
    </>
  );
};

export default ForwardMessageContactModal;