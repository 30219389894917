import React, { useState, useEffect } from "react";
import { Paper, makeStyles } from "@material-ui/core";

import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import api from "../../services/api";
import MessagesListTicketView from "../MessagesListTicketView";
import TicketInfo from "../TicketInfo";
import toastError from "../../errors/toastError";

const drawerWidth = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 0 0 5px",
    borderRadius:"20px",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    position: "relative",
    overflowY: "scroll",
		...theme.scrollbarStyles,
  },

  ticketInfo: {
    borderRadius:"20px",
    backgroundColor: theme.palette.background.default,
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: { maxWidth: "80%", flexBasis: "80%", },
  },

  ticketActionButtons: {
    borderRadius:"20px",
    backgroundColor: theme.palette.background.default,
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: { maxWidth: "100%", flexBasis: "100%", marginBottom: "5px", },
  },

  mainWrapper: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    borderRadius:"20px",
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderRadius:"20px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const TicketView = (viewTicketId) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const ticketId = viewTicketId.viewTicketId;
  const classes = useStyles();

  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});

  

  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/" + ticketId);
          setContact(data.contact);
          setTicket(data);
        } catch (error) {
          console.log("Ticket View Use Effect Error:", error);
          toastError(error);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId]);



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Paper variant="outlined" elevation={0} className={classes.mainWrapper}>
        <div className={classes.ticketInfo}>
          <TicketInfo contact={contact} ticket={ticket} />
        </div>
        
        <ReplyMessageProvider>
          <MessagesListTicketView ticketId={ticketId} ticketType={ticket.type} />
        </ReplyMessageProvider>
      </Paper>
    </div>
  );
};

export default TicketView;
