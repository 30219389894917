import api from "../../services/api";

const useWhatsappQueues = (whatsappId) => {
    const findAll = async () => {
        const { data } = await api.get(`/whatsappQueues/${whatsappId}`);
        return data;
    }

	return { findAll };
}

export default useWhatsappQueues;
