import api from "../../services/api";

const useUserQueues = () => {
    const findAll = async () => {
        const { data } = await api.get("/userQueues");
        return data;
    }

	return { findAll };
}

export default useUserQueues;
