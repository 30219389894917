import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Tree, TreeNode } from 'react-organizational-chart';
import { styled } from '@material-ui/core/styles';
import { 
  makeStyles,
  Paper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress
} from "@material-ui/core";

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import NoWhatsappImg from '../../assets/noWhatsapp.svg';
import NoFlowImg from '../../assets/noFlow.svg';
import Title from "../../components/Title";
import TreeCard from "../../components/TreeCard";

import WhatsAppModal from "../../components/WhatsAppModal";
import QueueModal from "../../components/QueueModal";
import CategoryModal from "../../components/CategoryModal";
import SubqueueModal from "../../components/SubqueueModal";
import dragScroll from "../../utils/dragScroll";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    userSelect: "none",
    cursor: "grab",
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    position: "relative",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  emptyBoxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "50px",
  },

  emptyBoxImage: { width: "250px", height: "250px" },
  emptyBoxSpan: { fontSize: "20px" },
  speedDialIconButton: { borderRadius:"20px", color: theme.palette.text.primary },

  countContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    gap: "5px",

    width: "fit-content",
    borderRadius: "20px",
    border: `2px solid ${theme.palette.primary.main}`,
    position: "relative",
    left: "1em",
    top: "1em",

    backgroundColor: "#f6f6ff",
    color: "#363636",
    boxShadow: "0 1px 1px #b3b3b3",
    transform: "filter 0.3s",
    transition: "all 0.3s ease",
    cursor: "default",

    "&:hover": {
      filter: "brightness(0.92)",
      transform: "translateY(-5px)",
    },
  },
}));

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const Flow = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [whatsappId, setWhatsappId] = useState(0);
  const [whatsApps, setWhatsApps] = useState(0);
  const [treeFlow, setTreeFlow] = useState(0);
  const [loading, setLoading] = useState(true);

  const [openSpeedDialMenu, setOpenSpeedDialMenu] = useState(false);

  const [whatsappModalOpen, setWhatsappModalOpen] = useState(false);
  const [queueModalOpen, setQueueModalOpen] = useState(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [subqueueModalOpen, setSubqueueModalOpen] = useState(false);

  const [selectedQueue, setSelectedQueue] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubqueue, setSelectedSubqueue] = useState(null);

  const [shouldReloadInfo, setShouldReloadInfo] = useState(false);
  dragScroll("treePaper");

  const [queueCount, setQueueCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);
  const [subqueueCount, setSubqueueCount] = useState(0);
  

  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (user.profile === "user" || !user.configEnabled) { history.push(`/tickets`); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
			try {
        const { data } = await api.get("/whatsapp/");
				setWhatsApps(data);
        setWhatsappId(data[0].id);
        setLoading(false);
			} catch (err) {
        setLoading(false);
			}
		};

		fetchData();
	}, []);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
			try {
        const { data } = await api.get(`/treeFlow/${whatsappId}`);
				setTreeFlow(data);
        
        
        let queueSet = new Set();
        let categorySet = new Set();
        let subqueueSet = new Set();

        data.forEach(item => {
          if (item.queues) {
            item.queues.forEach(queue => {
              queueSet.add(queue.id);

              if (queue.category) {
                queue.category.forEach(category => {
                  categorySet.add(category.id);

                  if (category.subqueue) {
                    category.subqueue.forEach(subqueue => {
                      subqueueSet.add(subqueue.id);
                    });
                  }
                });
              }
            });
          }
        });

        
        setQueueCount(queueSet.size);
        setCategoryCount(categorySet.size);
        setSubqueueCount(subqueueSet.size);


        setLoading(false);
			} catch (err) {
        setLoading(false);
			}
		};

		fetchData();
	}, [whatsappId, shouldReloadInfo]);



  //  ***************
  //  ** Functions **
  //  ***************

  // ***---- Open ----***
  const handleOpenWhatsappModal = () => {
    setWhatsappModalOpen(true);
    setShouldReloadInfo(true);
  };

  const handleOpenQueueModal = () => { 
    setQueueModalOpen(true);
    setShouldReloadInfo(true);
  };

  const handleOpenCategoryModal = () => {
    setCategoryModalOpen(true);
    setShouldReloadInfo(true);
  };

  const handleOpenSubqueueModal = () => {
    setSubqueueModalOpen(true);
    setShouldReloadInfo(true);
  };

  // ***----- Close -----***
  const handleCloseWhatsappModal = () => {
    setWhatsappModalOpen(false);
    setShouldReloadInfo(false);
  };

  const handleCloseQueueModal = () => {
    setQueueModalOpen(false);
    setSelectedQueue(null);
    setShouldReloadInfo(false);
  };

  const handleCloseCategoryModal = () => {
    setCategoryModalOpen(false);
    setSelectedCategory(null);
    setShouldReloadInfo(false);
  };

  const handleCloseSubqueueModal = () => {
    setSubqueueModalOpen(false);
    setSelectedSubqueue(null);
    setShouldReloadInfo(false);
  };

  // ***----- Edit -----***
  const handleEditWhatsapp = () => {
    setShouldReloadInfo(true);
    handleOpenWhatsappModal();
  };

  const handleEditQueue = (selectedQueue) => {
    setSelectedQueue(selectedQueue);
    setShouldReloadInfo(true);
    handleOpenQueueModal();
  };

  const handleEditCategory = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
    setShouldReloadInfo(true);
    handleOpenCategoryModal();
  };

  const handleEditSubqueue = (selectedSubueue) => {
    setSelectedSubqueue(selectedSubueue);
    setShouldReloadInfo(true);
    handleOpenSubqueueModal();
  };

  // ***---- Others ----***
  const onChangeWhatsappId = async (event) => {
    setWhatsappId(event.target.value);

    const { data } = await api.get(`/treeFlow/${whatsappId}`);
    setTreeFlow(data);
  }

  const handleOpenCloseSpeedDialMenu = () => { setOpenSpeedDialMenu((prevValue) => !prevValue); };

  const renderQueues = (queue) => {
    return (
      /* 
        ************
        ** Queues **
        ************
      */
      <TreeNode label={
        <TreeCard
          onClick={() => { handleEditQueue(queue) }}
          title={queue.name}
          subject={i18n.t("flow.tree.queue")}
          type="2"
        />
      }>
        {/* 
          ******************************
          ** Categories and Subqueues **
          ******************************
        */}
        {queue.category && (queue.category.map(category => renderCategories(category)))}
      </TreeNode>
    );
  }

  const renderCategories = (category) => {
    return (
      /* 
        ****************
        ** Categories **
        ****************
      */
      <TreeNode label={
        <TreeCard
          title={category.name}
          subject={i18n.t("flow.tree.category")}
          type="3"
          onClick={() => { handleEditCategory(category) }}
        />
      }>
        {category.subqueue && (category.subqueue.map(subqueue => renderSubqueues(subqueue)))}
      </TreeNode>
    );
  }

  const renderSubqueues = (subqueue) => {
    return (
      /* 
        ***************
        ** Subqueues **
        ***************
      */
      <TreeNode label={
        <TreeCard
          title={subqueue.name}
          subject={i18n.t("flow.tree.subqueue")}
          type="4"
          onClick={() => { handleEditSubqueue(subqueue) }}
        />
      }/>
    );
  }



  //  *************
  //  ** Actions **
  //  *************
  const actions = [
    { 
      icon: <BookmarksOutlinedIcon className={classes.speedDialIconButton} />, 
      name: 'New Department', 
      caption: i18n.t("flow.speedDial.newDepartment"), 
      onClick: handleOpenQueueModal
    },
    { 
      icon: <LabelOutlinedIcon className={classes.speedDialIconButton} />, 
      name: 'New Category', 
      caption: i18n.t("flow.speedDial.newCategory"), 
      onClick: handleOpenCategoryModal
    },
    { 
      icon: <BookOutlinedIcon className={classes.speedDialIconButton} />, 
      name: 'New Sector', 
      caption: i18n.t("flow.speedDial.newSector"), 
      onClick: handleOpenSubqueueModal
    },
  ];



  //  ************
  //  ** Return **
  //  ************
  return (
    <MainContainer>
      {/* 
        ***********
        ** Title **
        ***********
      */}
      <MainHeader>
        <Title>{i18n.t("flow.title")}</Title>
      </MainHeader>



      {/* 
        *********************
        ** Filter and Tree **
        *********************
      */}
      <Paper className={classes.mainPaper} variant="outlined" id="treePaper">
        <br /><br />


        {!loading && whatsApps !== 0 && treeFlow !== 0 && treeFlow.length > 0 && whatsappId !== 0 && (
          <>
            {/* 
              ************
              ** Filter **
              ************
            */}
            <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
              <InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
              <Select
                value={whatsappId}
                onChange={(e) => onChangeWhatsappId(e)}
                label={i18n.t("userModal.form.whatsapp")}
              >
                {whatsApps.map((whatsapp) => (
                  <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className={classes.countContainer}>
              <span>{i18n.t("flow.counts.queues")}: <b>{queueCount}</b></span>
              <span>{i18n.t("flow.counts.categories")}: <b>{categoryCount}</b></span>
              <span>{i18n.t("flow.counts.subqueues")}: <b>{subqueueCount}</b></span>
            </div>

            <br /><br />

            
            {/* 
              ***********
              ** Phone **
              ***********
            */}
            <Tree
              lineWidth={"2px"}
              lineColor={"#bbc"}
              lineBorderRadius={"12px"}
              label={
                <TreeCard
                  onClick={handleEditWhatsapp}
                  title={treeFlow[0].name}
                  subject={i18n.t("flow.tree.chip")}
                  type="1"
                />
              }
            >
              {/* 
                **************************************
                ** Queues, Subqueues and Categories **
                **************************************
              */}
              {treeFlow[0].queues.map(queue => renderQueues(queue))}
            </Tree>
          </>
        )}

        {/* 
          *******************
          ** Loading Datas **
          *******************
        */}
        {loading && (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        )}

        {/* 
          *****************
          ** No Whatsapp **
          *****************
        */}
        {(whatsApps === 0 || whatsappId === 0) && (
          <div className={classes.emptyBoxContainer}>
            <img className={classes.emptyBoxImage} src={NoWhatsappImg} alt="No Whatsapp" />
            <span className={classes.emptyBoxSpan}>{i18n.t("flow.messages.noWhatsapp")}</span>
          </div>
        )}

        {/* 
          *************
          ** No Flow **
          *************
        */}
        {((treeFlow === 0 || treeFlow.length < 1) && (whatsApps !== 0 && whatsappId !== 0)) && (
          <>
          <div className={classes.emptyBoxContainer}>
            <img className={classes.emptyBoxImage} src={NoFlowImg} alt="No Flow" />
            <span className={classes.emptyBoxSpan}>{i18n.t("flow.messages.noFlow")}</span>
          </div>
          </>
        )}



        {/* 
          ********************
          ** SpeedDial Menu **
          ********************
        */}
        <StyledSpeedDial
          ariaLabel="SpeedDial"
          direction="up"
          icon={<SpeedDialIcon />}
          onClick={handleOpenCloseSpeedDialMenu}
          open={openSpeedDialMenu}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.caption}
              onClick={action.onClick}
            />
          ))}
        </StyledSpeedDial>

        {whatsappModalOpen && (
          <WhatsAppModal
            open={whatsappModalOpen}
            onClose={handleCloseWhatsappModal}
            whatsAppId={whatsappId}
          />
        )}

        {queueModalOpen && (
          <QueueModal
            open={queueModalOpen}
            onClose={handleCloseQueueModal}
            queueId={selectedQueue?.id}
          />
        )}
        
        {categoryModalOpen && (
          <CategoryModal
            open={categoryModalOpen}
            onClose={handleCloseCategoryModal}
            categoryId={selectedCategory?.id}
          />
        )}

        {subqueueModalOpen && (
          <SubqueueModal
            open={subqueueModalOpen}
            onClose={handleCloseSubqueueModal}
            subqueueId={selectedSubqueue?.id}
          />
        )}
        
      </Paper>
    </MainContainer>
  );
};

export default Flow;
